import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Avatar, Button, Card, Grid, LinearProgress, Typography } from '@mui/material'
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link, Outlet, useLocation } from 'react-router-dom';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';

import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

function Campaign() {

    const location = useLocation();
    const propsData = location.state;

    const campaignData = propsData.campaignData;
    const gradientColor = propsData.gradientColor;
    const campaignStatus = propsData.campaignStatus;

    // console.log("campaignStatus")
    // console.log(campaignStatus)
    // console.log(campaignData.campaignId)

    // console.log("INSIDE EDIT BUTTON CAMPAIGN DATA")
    // console.log(campaignData.campaignId)

    const [tab, setTab] = React.useState(0);

    function handleTabChange(tab) {
        if (tab == 0) { setTab(0) }
        if (tab == 1) { setTab(1) }
        if (tab == 2) { setTab(2) }
    };

    const influencerButton = (tab == 0) ? "#E4F87F" : "white";
    const contentButton = (tab == 1) ? "#E4F87F" : "white";
    const analyticsButton = (tab == 2) ? "#E4F87F" : "white";


    //update campaign status
    const options = ['Active', 'Paused', 'Drafts', 'Archived'];

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(campaignStatus);

    const handleClick = () => {
        console.info(`You clicked ${options[selectedIndex]}`);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);

        updateCampaignStatus(index)
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const [campaignStatusName, setCampaignStatusName] = useState("")

    function getCampaignStatusName(campaignStatus) {
        switch (campaignStatus) {
            case 0:
                setCampaignStatusName("Active");
                break;
            case 1:
                setCampaignStatusName("Paused");
                break;
            case 2:
                setCampaignStatusName("Drafts");
                break;
            case 3:
                setCampaignStatusName("Archived");
                break;
            default:
                break;

        }
    }

    useEffect(() => {
        getCampaignStatusName(campaignStatus)
    }, [])

    const [isLoading, setIsLoading] = useState(false);

    async function updateCampaignStatus(status) {

        var campaignId = campaignData.campaignId;
        setIsLoading(true);

        let data = {
            campaignId: campaignId,
            status: status
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/change_campaign_status', options)
            .then(result => result.json())
            .then(body => {
                console.log("body"); console.log(body); setIsLoading(false);
            });
    }

    return (
        <div style={{ padding: "20px" }}>
            <Grid container style={{ display: "flex", justifyContent: "center", marginBottom: "20px", height: "120px" }}>
                <Card sx={{ width: "85%", border: "2px solid #DCDCDC", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "25px", boxShadow: "none" }}>
                    <Grid container pr={8} pl={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid item xs={5}>
                            <Grid container style={{ gap: "10px", display: "flex", alignItems: "center" }}>
                                <div style={{ width: "80px", height: "80px", background: `radial-gradient(#FFFFFF, ${gradientColor})`, borderRadius: "50%" }} />
                                <div style={{ display: "flex", height: "50%", gap: "10px" }}>
                                    <Grid item >
                                        <Grid item fontSize={20} >{campaignData.campaignName}</Grid>
                                        <Grid item fontSize={18} sx={{ display: "flex", alignItems: "center" }}><PeopleOutlineOutlinedIcon /> {campaignData.totalInfluencers}</Grid>
                                    </Grid>
                                    <Grid item >
                                        <Card sx={{ background: `${gradientColor}`, padding: "5px 10px 5px 10px", borderRadius: "5px" }}>Active</Card>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} style={{ display: "flex", justifyContent: "center", textAlign: "left" }}>
                            <Divider orientation="vertical" variant="middle" flexItem sx={{ marginRight: "20px" }} />
                            <Grid container sx={{ gap: "4px" }}>
                                <Grid item fontSize={18} xs={8}>Influencers Onboard</Grid>
                                <Grid item fontSize={26} xs={8} sx={{ fontWeight: 800 }}>{campaignData.totalInfluencers}</Grid>
                                {/* <Grid item xs={8}>
                                    <Box style={{ width: "100%", color: `${gradientColor}` }}>
                                        <LinearProgress variant="determinate" color='inherit' value={campaignData.totalInfluencers} style={{ width: "60%", height: "15px", borderRadius: "10px", border: "1px solid #DCDCDC" }} />
                                    </Box>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid item xs={4} style={{ display: "flex", justifyContent: "space-between" }} fontSize={30}>
                            <Link to="/campaign/chat" state={propsData}>
                                <Button style={{ padding: "20px 30px 20px 30px", display: "flex", alignItems: "center", justifyContent: "center", gap: "5px", background: '#5BCEFF', color: "black", borderRadius: "10px" }}><QuestionAnswerOutlinedIcon />  Chat</Button>
                            </Link>
                            <React.Fragment>
                                <ButtonGroup
                                    variant="contained"
                                    ref={anchorRef}
                                >
                                    <Button onClick={handleClick}>{options[selectedIndex]}</Button>
                                    <Button
                                        size="small"
                                        onClick={handleToggle}
                                    >
                                        <ArrowDropDownIcon />
                                    </Button>
                                </ButtonGroup>
                                <Popper
                                    sx={{
                                        zIndex: 1,
                                    }}
                                    open={open}
                                    anchorEl={anchorRef.current}
                                    role={undefined}
                                    transition
                                    disablePortal
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList autoFocusItem>
                                                        {options.map((option, index) => (
                                                            <MenuItem
                                                                key={option}
                                                                selected={index === selectedIndex}
                                                                onClick={(event) => handleMenuItemClick(event, index)}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </React.Fragment>
                            <Button style={{ padding: "20px 30px 20px 30px", display: "flex", alignItems: "center", justifyContent: "center", gap: "5px", background: `${gradientColor}`, color: "black", borderRadius: "10px" }}><EditOutlinedIcon />  Edit</Button>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>


            <Grid container style={{ justifyContent: "center", marginBottom: "20px", height: "80px" }}>
                <Card sx={{ width: "85%", border: "2px solid #DCDCDC", display: "flex", alignItems: "center", display: "flex", borderRadius: "15px", boxShadow: "none" }}>
                    <Grid container columns={9} style={{ display: "flex", textAlign: "center", padding: "5px" }}>
                        <Grid item xs={3}>
                            <Link to="/campaign/influencers" state={propsData}>
                                <Button startIcon={<PeopleOutlineOutlinedIcon />} onClick={() => handleTabChange(0)} style={{ color: "black", background: influencerButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Influencers</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to="/campaign/content" state={propsData}>
                                <Button startIcon={<PeopleOutlineOutlinedIcon />} onClick={() => handleTabChange(1)} style={{ color: "black", background: contentButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Content</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to="/campaign/analytics" state={propsData}>
                                <Button startIcon={<PeopleOutlineOutlinedIcon />} onClick={() => handleTabChange(2)} style={{ color: "black", background: analyticsButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Analytics</Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Outlet />
        </div>
    )
}

export default Campaign