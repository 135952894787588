import React from 'react'
import { Divider } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { Avatar, Box, Button, Card, CardMedia, Grid, SvgIcon, Typography } from '@mui/material'


const brands = ["Perfora", "Renee", "Sanfe"]

const style = (theme) => ({
    width: "50%",
    height: "fit-content",
    borderRadius: '20px',
    padding: '16px',
    backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
    boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
});

function formatNumber(number) {
    var number = parseInt(number);
    if (number >= 1e6) {
        return (number / 1e6).toFixed(2) + "M";
    } else if (number >= 1e3) {
        return (number / 1e3).toFixed(2) + "K";
    } else {
        return number.toFixed(0);
    }
}


function CampaignVideoCard(props) {

    const videoData = props.videoData;

    // console.log("VIDEO DATA")
    // console.log(videoData)

    var mediaComponent = "";
    var mediaUrl = "";
    var mediaType = ""
    mediaComponent = videoData.mediaComponent;
    mediaUrl = videoData.mediaUrl;
    mediaType = videoData.mediaType;

    var postStyle = { width: "350px", height: "450px", borderRadius: "20px", backgroundColor: "black", border: "1px solid black" }
    var storyReelStyle = { width: "350px", height: "450px", borderRadius: "20px", backgroundColor: "black", border: "1px solid black" }

    const openInNewTab = (url) => {
        // console.log("URL")
        // console.log(url)
        window.open(url, '_blank', 'noreferrer');
    };

    function openLink(permalink) {
        // console.log("OPEN LINK")
        // console.log(permalink)
        openInNewTab(permalink)
    }


    const commentsIcon = (
        <SvgIcon style={{ width: "20px", height: "20px" }}>
            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.60693 31.0434L4.71518 21.7187C3.23816 18.7655 2.86024 15.3831 3.64906 12.1767C4.43788 8.97036 6.34195 6.14934 9.02053 4.21849C11.6991 2.28764 14.9774 1.37299 18.2687 1.63821C21.56 1.90343 24.6495 3.33122 26.9843 5.66606C29.3192 8.0009 30.747 11.0904 31.0122 14.3817C31.2774 17.673 30.3627 20.9513 28.4319 23.6299C26.501 26.3084 23.68 28.2125 20.4737 29.0013C17.2673 29.7901 13.8849 29.4122 10.9317 27.9352L1.60693 31.0434Z"
                    stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </SvgIcon>
    )

    // const metricLabels = ((mediaType == "STORY") ? ["Comments", "Likes"] : ((mediaType == "REEL" || mediaType == "YOUTUBE") ? ["Views", "Comments", "Likes"] : ["Comments", "Likes"]))
    // const metricIcons = ((mediaType == "STORY") ? [commentsIcon, <FavoriteBorderOutlinedIcon />] : ((mediaType == "REEL" || mediaType == "YOUTUBE") ? [<RemoveRedEyeOutlinedIcon />, commentsIcon, <FavoriteBorderOutlinedIcon />] : [commentsIcon, <FavoriteBorderOutlinedIcon />]))
    // const metricValues = ((mediaType == "STORY") ? [videoData.comments, videoData.likes] : ((mediaType == "REEL" || mediaType == "YOUTUBE") ? [formatNumber(videoData.views), formatNumber(videoData.comments), formatNumber(videoData.likes)] : [formatNumber(videoData.comments), formatNumber(videoData.likes)]))

    const metricLabels = ((mediaType == "YOUTUBE") ? ["Comments", "Likes"] : ["Reach", "Comments", "Likes", "Shares", "Saves"])
    const metricIcons = ((mediaType == "YOUTUBE") ? [commentsIcon, <FavoriteBorderOutlinedIcon />] : [<RemoveRedEyeOutlinedIcon />, commentsIcon, <FavoriteBorderOutlinedIcon />, <SendOutlinedIcon />, <BookmarkBorderOutlinedIcon />])
    const metricValues = ((mediaType == "YOUTUBE") ? [formatNumber(videoData.comments), formatNumber(videoData.likes)] : [formatNumber(videoData.views), formatNumber(videoData.comments), formatNumber(videoData.likes), formatNumber(videoData.shares), formatNumber(videoData.saves)])


    return (
        <Box sx={style}>
            <Grid container style={{ height: "100%", borderRadius: "20px", }}>
                <Grid item xs={4} sx={{ display: "flex", alignItems: "center", borderRadius: "20px" }}>
                    <CardMedia
                        component={mediaComponent}
                        image={mediaUrl}
                        controls
                        style={mediaType == "POST" ? postStyle : storyReelStyle}>
                    </CardMedia>
                </Grid>
                <Grid item xs={8} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", paddingLeft: "10px" }}>
                    <Box sx={{ width: "100%", marginBottom: "20px", display: "flex", justifyContent: "flex-end", alignItems: "center", marginRight: "20px" }}>
                        {mediaType == "YOUTUBE" && <Button startIcon={<YouTubeIcon fontSize='large' />} onClick={() => openLink(videoData.permalink)} sx={{ background: "#FF0000", color: "white", border: "1px solid black", textShadow: 'black' }}>Open in Youtube</Button>}
                        {mediaType != "YOUTUBE" && mediaType != "STORY" && <Button startIcon={<InstagramIcon />} onClick={() => openLink(videoData.permalink)} sx={{ background: "#405DE6", color: "white", border: "1px solid black", textShadow: 'black' }}>Open in Instagram</Button>}
                    </Box>
                    <Grid container style={{ padding: "16px" }}>
                        {/* {metricLabels.map((value, index) => (
                            <Grid item xs key={index} sx={{ display: "flex", flexDirection: "column" }}>
                                <Grid item style={{ fontSize: "28px", fontWeight: "bold", marginBottom: "8px", justifyContent: "center", display: "flex" }}>{metricValues[index]}</Grid>
                                <Grid item style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "3px" }}>
                                    {metricIcons[index]}
                                    <Typography >{value}</Typography>
                                </Grid>
                            </Grid>
                        ))} */}

                        <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "10px" }}>
                            <Box sx={{ display: "flex", justifyContent: "center", fontSize: "28px", marginBottom: "8px", fontWeight: "bold", }}>
                                {metricValues[0]}
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", gap: "3px" }}>
                                {metricIcons[0]}
                                <Typography >{metricLabels[0]}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "10px" }}>
                            <Box sx={{ display: "flex", justifyContent: "center", fontSize: "28px", marginBottom: "8px", fontWeight: "bold", }}>
                                {metricValues[1]}
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", gap: "3px" }}>
                                {metricIcons[1]}
                                <Typography >{metricLabels[1]}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Box sx={{ display: "flex", justifyContent: "center", fontSize: "28px", marginBottom: "8px", fontWeight: "bold", }}>
                                {metricValues[2]}
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", gap: "3px" }}>
                                {metricIcons[2]}
                                <Typography >{metricLabels[2]}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Box sx={{ display: "flex", justifyContent: "center", fontSize: "28px", marginBottom: "8px", fontWeight: "bold", }}>
                                {metricValues[3]}
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", gap: "3px" }}>
                                {metricIcons[3]}
                                <Typography >{metricLabels[3]}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Box sx={{ display: "flex", justifyContent: "center", fontSize: "28px", marginBottom: "8px", fontWeight: "bold", }}>
                                {metricValues[4]}
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", gap: "3px" }}>
                                {metricIcons[4]}
                                <Typography >{metricLabels[4]}</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: "20px", marginBottom: "20px", display: "flex", justifyContent: "center" }}>
                            <Divider style={{ width: "95%" }} />
                        </Grid>

                        {videoData.brands.map((value, index) => (
                            <Grid item style={{ marginRight: "25px", display: "flex", flexDirection: "column", alignItems: "center" }} key={index}>
                                <Typography><Avatar sx={{ border: "1px solid black", width: "70px", height: "70px", marginBottom: "5px" }} src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/brands%2F" + value + ".jpg?alt=media"} /></Typography>
                                <Typography>{value}</Typography>
                            </Grid>
                        ))}
                        {/* {mediaType != "STORY" &&
                            <Grid item xs={10} sx={{ marginTop: "40px" }}>
                                <Typography variant="h7" >{videoData.caption.substring(0, 100)} ...</Typography>
                                <Typography>Caption</Typography>
                            </Grid>} */}
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    )
}

export default CampaignVideoCard