import React from 'react';
import Stack from '@mui/material/Stack';
import { LineChart } from '@mui/x-charts/LineChart';
import TrackingCustomTooltip from './TrackingCustomTooltip';


const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const vData = [1400, 2398, 5800, 6908, 3800, 5800, 8300];
const xLabels = [
    'Page A',
    'Page B',
    'Page C',
    'Page D',
    'Page E',
    'Page F',
    'Page G',
];

export default function TrackingLineCharts(props) {

    const showVideo = props.showVideo;
    const showRefresh = props.showRefresh;
    const campaignAnalytics = props.campaignAnalytics;

    const viewsGraph = campaignAnalytics["viewsGraph"]
    const sortedDatesViews = Object.keys(viewsGraph).sort();
    const viewsArray = sortedDatesViews.map(date => (viewsGraph[date].views) / 1000);

    const sharesSavesGraph = campaignAnalytics["sharesSavesGraph"]
    const sortedDates = Object.keys(sharesSavesGraph).sort();
    const sharesArray = sortedDates.map(date => (sharesSavesGraph[date].shares));
    const savesArray = sortedDates.map(date => (sharesSavesGraph[date].saves));
    const engagementArray = sortedDates.map(date => (sharesSavesGraph[date].engagement / 100));

    const stats = campaignAnalytics["stats"]
    // Create an array of 'refreshedAt' dates from the data
    const refreshedAtDates = stats.map(item => item.refreshedAt);

    // Create an array of 'totalViews' from the data
    const totalViews = stats.map(item => item.totalViews / 100);
    const totalShares = stats.map(item => item.totalShares);
    const totalEngagement = stats.map(item => item.totalEngagement / 10);
    const totalSaves = stats.map(item => item.totalSaves);


    const [color, setColor] = React.useState('#5BCEFF');

    const handleChange = (event, nextColor) => {
        setColor(nextColor);
    };

    if (showRefresh) {
        return (
            <LineChart
                width={800}
                height={420}
                // series={[
                //     { id: "Content", data: totalViews, label: 'Total Views', area: true, color: color }
                // ]}
                series={[
                    { data: totalViews, label: 'Total Views' },
                    { data: totalSaves, label: 'Total Saves' },
                    { data: totalShares, label: 'Total Shares' },
                    { data: totalEngagement, label: 'Total Engagement' },
                ]}
                xAxis={[{
                    scaleType: 'point', data: refreshedAtDates, tickLabelProps: () => ({
                        angle: 45,
                        textAnchor: 'start',
                        dominantBaseline: 'ideographic',
                    }),
                }]}
                sx={{
                    '& .MuiLineElement-root': {
                        strokeWidth: 2,
                    },
                    '& .MuiMarkElement-series-Content': {
                        fill: "#5BCEFF",
                        strokeWidth: 3
                    },
                    '& .MuiChartsTooltip-root': {
                        display: 'none !important',

                    },
                    '& .MuiAreaElement-series-Content': {
                        fill: "url('#myGradient')",
                    },
                    '& .MuiChartsAxis-tickLabel': {
                        transform: 'translate(-25px, 15px) rotate(-30deg)',
                    },
                    '& .css-iael1t-MuiChartsAxis-root.MuiChartsAxis-left .MuiChartsAxis-tickLabel': {
                        transform: 'translate(0px, 0px)',
                    }
                }}
            >
                <defs>
                    <linearGradient id="myGradient" gradientTransform="rotate(90)">
                        <stop offset="0%" stopColor="#89DCFF" />
                        <stop offset="100%" stopColor="#50CBFF00" />
                    </linearGradient>
                </defs>
            </LineChart>
        );
    }

    else if (showVideo) {
        return (
            <LineChart
                width={800}
                height={420}
                series={[{ id: "Content", data: viewsArray, label: 'Views (per 1K)', area: true, color: color }]}
                // tooltip={{ trigger: "axis", axisContent: TrackingCustomTooltip, itemContent: TrackingCustomTooltip }}
                xAxis={[{
                    scaleType: 'point', data: sortedDatesViews, tickLabelProps: () => ({
                        angle: 45,
                        textAnchor: 'start',
                        dominantBaseline: 'ideographic',
                    }),
                }]}
                sx={{
                    '& .MuiLineElement-root': {
                        strokeWidth: 2,
                    },
                    '& .MuiMarkElement-series-Content': {
                        fill: "#5BCEFF",
                        strokeWidth: 3
                    },
                    '& .MuiChartsTooltip-root': {
                        display: 'none !important',

                    },
                    '& .MuiAreaElement-series-Content': {
                        fill: "url('#myGradient')",
                    },
                    '& .MuiChartsAxis-tickLabel': {
                        transform: 'translate(-25px, 15px) rotate(-30deg)',
                    },
                    '& .css-iael1t-MuiChartsAxis-root.MuiChartsAxis-left .MuiChartsAxis-tickLabel': {
                        transform: 'translate(0px, 0px)',
                    }
                }}
            >
                <defs>
                    <linearGradient id="myGradient" gradientTransform="rotate(90)">
                        <stop offset="0%" stopColor="#89DCFF" />
                        <stop offset="100%" stopColor="#50CBFF00" />
                    </linearGradient>
                </defs>
            </LineChart>
        );
    } else {
        return (
            <LineChart
                width={800}
                height={420}
                series={[
                    { data: sharesArray, label: 'shares' },
                    { data: savesArray, label: 'saves' },
                    { data: engagementArray, label: 'eng (per 100 likes + comments)' },
                ]}
                xAxis={[{ scaleType: 'point', data: sortedDates }]}
                sx={{
                    '& .MuiLineElement-root': {
                        strokeWidth: 2,
                    },
                    '& .MuiMarkElement-series-Content': {
                        fill: "#5BCEFF",
                        strokeWidth: 3
                    },
                    '& .MuiChartsTooltip-root': {
                        display: 'none !important',

                    },
                    '& .MuiAreaElement-series-Content': {
                        fill: "url('#myGradient')",
                    },
                    '& .MuiChartsAxis-tickLabel': {
                        transform: 'translate(-25px, 15px) rotate(-30deg)',
                    },
                    '& .css-iael1t-MuiChartsAxis-root.MuiChartsAxis-left .MuiChartsAxis-tickLabel': {
                        transform: 'translate(0px, 0px)',
                    }
                }}
            />
        )
    }
}