import React from 'react'
import Error404 from "../assets/error404.png";
import Topbar from './Topbar';
import { AppBar, Box, Button, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import CloserPlusLogo from "../assets/closerNewLogo.svg";

function NotFound() {
    return (
        <div >
            <Box >
                <AppBar component="nav" style={{ backgroundColor: "#232323", height: "80px", display: "flex", justifyContent: "center" }}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    height: "30px",
                                    width: "171px",
                                }}
                                alt="Discovery No Results"
                                src={CloserPlusLogo}
                            />
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box sx={{ p: 3 }}>
                <Toolbar />
            </Box>
            <div style={{ display: "flex", justifyContent: "center", }}>
                <Box
                    component="img"
                    sx={{
                        height: "600px",
                        width: "700px",
                    }}
                    alt="Error 404"
                    src={Error404}
                />
            </div>
        </div>
    )
}

export default NotFound