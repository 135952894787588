import React, { useEffect, useState } from 'react'
import Slide from '@mui/material/Slide';
import AddIcon from '@mui/icons-material/Add';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Avatar, Box, Button, Card, CircularProgress, Divider, Grid, InputAdornment, InputBase, InputLabel, Modal, Paper, Snackbar, TextField, Tooltip, Typography } from '@mui/material'
import ShipProduct from './ShipProduct';
import Address from './Address';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

const theme = createTheme({
    palette: {
        primary: {
            main: "#E4F87F",
        },
    },
});

function EditInfluencerDetails(props) {

    const handleCloseProp = props.handleClose;
    const influencer = props.influencerUsername;
    const userData = props.userData;
    const campaignId = props.campaignId;


    const [influencerCost, setInfluencerCost] = useState("")
    const handleInfluencerCost = (newCost) => {
        setInfluencerCost(newCost);
    };

    const [influencerProducts, setInfluencerProducts] = useState([]); // Array of arrays
    const [influencerFullName, setInfluencerFullName] = useState([]); // Array of arrays

    const [influencerAddress, setInfluencerAddress] = useState("")
    // const handleInfluencerAddress = event => {
    //     setInfluencerAddress(event.target.value);
    // };

    const [influencerPhone, setInfluencerPhone] = useState('')
    const [editInfluencerPhone, setEditInfluencerPhone] = useState(false);
    const handleInfluencerPhone = (newPhone) => {
        setInfluencerPhone(newPhone);
    };
    const handleEditInfluencerPhone = () => {
        // setInfluencerPhone('')
        setEditInfluencerPhone(!editInfluencerPhone)
    }

    function saveInfluencerPhone() {
        setEditInfluencerPhone(false)
    }


    const [influencerEmail, setInfluencerEmail] = useState('')
    const handleInfluencerEmail = (newEmail) => {
        setInfluencerEmail(newEmail);
    };

    const [isLoading, setIsLoading] = useState(false);


    async function saveInfluencerDetails(transition) {

        setIsLoading(true);
        const token2 = await userData.getIdToken()

        let data = {
            influencerUsername: influencer,
            campaignId: campaignId,
            cost: influencerCost,
            influencerAddress: influencerAddress,
            influencerPhone: influencerPhone,
            influencerProducts: influencerProducts,
            influencerEmail: influencerEmail
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/save_campaign_influencer_details', options)
            .then(result => result.json())
            .then(body => {
                if (body == "Success") {
                    setState({
                        open: true,
                        transition,
                    });
                } setIsLoading(false);
            });
    }

    const [state, setState] = React.useState({
        open: false,
        Transition: Slide,
    });

    const handleClose = () => {
        setState({
            ...state,
            open: false,
        });

        handleCloseProp()
    };


    const [openProduct, setOpenProduct] = useState(false);
    const handleOpenProduct = () => setOpenProduct(true);
    const handleCloseProduct = () => { setOpenProduct(false); }

    const [openAddress, setOpenAddress] = useState(false);
    const handleOpenAddress = () => setOpenAddress(true);
    const handleCloseAddress = () => { setOpenAddress(false); }

    const saveAddress = (newAddress) => {
        setInfluencerAddress(newAddress);
        handleCloseAddress();
    }


    const setShippedProducts = (newProducts) => {
        var tempProducts = [...influencerProducts]
        tempProducts.push(...newProducts)
        setInfluencerProducts(tempProducts)
    }

    const [editInfluencerEmail, setEditInfluencerEmail] = useState(false);
    const [editInfluencerCost, setEditInfluencerCost] = useState(false);

    const handleEditInfluencerEmail = () => {
        // setInfluencerEmail("")
        setEditInfluencerEmail(!editInfluencerEmail)
    }
    function saveInfluencerEmail() {
        setEditInfluencerEmail(false)
    }


    const handleEditInfluencerCost = () => {
        setEditInfluencerCost(!editInfluencerCost)
    }
    function saveInfluencerCost() {
        setEditInfluencerCost(false)
    }

    const [influencerAffiliateId, setInfluencerAffiliateId] = useState('');



    const [isGoaffproConnected, SetIsGoaffproConnected] = useState(false);
    var displayShipProduct = (((influencerAddress == "" || influencerEmail == "") ? true : false));

    function setCampaignInfluencerDetails(campaignInfluencerObj) {

        setInfluencerCost(campaignInfluencerObj.influencerCost)
        setInfluencerEmail(campaignInfluencerObj.influencerEmail)
        setInfluencerProducts(campaignInfluencerObj.productSent)
        setInfluencerAddress(campaignInfluencerObj.influencerAddress)
        setInfluencerFullName(campaignInfluencerObj.influencerFullName)
        setInfluencerPhone(campaignInfluencerObj.influencerPhone)
        setInfluencerAffiliateId(campaignInfluencerObj.influencerAffiliateId)
        SetIsGoaffproConnected(campaignInfluencerObj.goaffproApiKey)

        setIsLoading(false);
    }

    const [isLoadingFetch, setIsLoadingFetch] = useState(false);

    async function fetchCampaignInfluencerDetails() {
        setIsLoadingFetch(true);
        const token2 = await userData.getIdToken()

        let data = {
            influencerUsername: influencer,
            campaignId: campaignId
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }


        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_campaign_influencer_details', options)
            .then(result => result.json())
            .then(body => { setCampaignInfluencerDetails(body); });
        setIsLoadingFetch(false)
    }

    useEffect(() => {
        fetchCampaignInfluencerDetails()
    }, []);

    if (isLoadingFetch) {
        return (
            <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress style={{ color: "black" }} size={50} />
            </Box>
        )
    } else {

        return (
            // <Modal
            // BackdropProps={{ style: { backgroundColor: 'rgb(0,0,0,0.1)' } }}
            //     open={openModal}
            //     onClose={handleCloseProp}
            // >
            //     <Slide direction="left" in={openModal} mountOnEnter unmountOnExit timeout={300}>
            // sx={style}
            <Box>
                <Box style={{ float: "right", margin: "5px 5px 0px 0px" }} >
                    <CloseOutlinedIcon style={{ cursor: "pointer" }} fontSize='medium' onClick={handleCloseProp} />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", padding: "30px", }}>
                    <Typography variant='h4' sx={{ marginBottom: "20px" }}>Edit Influencer Details</Typography>
                    <Box style={{ display: "flex", gap: "10px" }}>
                        <Avatar src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + influencer + ".jpg?alt=media"} />
                        <Typography variant='h6' sx={{ marginBottom: "20px" }}>@{influencer}</Typography>
                    </Box>

                    <Typography variant='h6' start>Enter Influencer Cost</Typography>
                    {/* <TextField sx={{ margin: "5px 10px 25px 0px" }} placeholder='Enter Influencer Cost' InputProps={{
                    startAdornment: <InputAdornment position='start'><CurrencyRupeeIcon fontSize='medium' /></InputAdornment>,
                }} value={influencerCost}
                    onChange={handleInfluencerCost} /> */}
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto', margin: "5px 0px 30px 0px", border: "1px solid #DCDCDC", boxShadow: "none" }}
                    >
                        {!editInfluencerCost && <Box sx={{ display: "flex", flexDirection: "row", padding: "10px", justifyContent: "space-between", width: "100%" }}>
                            <InputBase
                                sx={{ width: "100%" }}
                                value={influencerCost}
                                onChange={(e) => handleInfluencerCost(e.target.value)}
                                placeholder="Please enter Influencer Cost"
                                inputProps={{ readOnly: !editInfluencerCost }}
                            />
                            <Button sx={{ color: "black", border: "1px solid black", background: "#E4F87F" }} onClick={handleEditInfluencerCost}>Edit</Button>
                        </Box>}
                        {editInfluencerCost &&
                            <Box sx={{ display: "flex", gap: "5px", justifyContent: "space-between", width: "100%" }}>
                                <TextField
                                    sx={{ width: "100%" }}
                                    value={influencerCost}
                                    onChange={(e) => handleInfluencerCost(e.target.value)}
                                    placeholder="Please enter Influencer Cost"
                                    inputProps={{ readOnly: !editInfluencerCost }}
                                />
                                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                    <Button sx={{ color: "black", border: "1px solid black", background: "#E4F87F", height: "fit-content", }} onClick={() => saveInfluencerCost()}>Save</Button>
                                    <Button sx={{ color: "black", border: "1px solid black", height: "fit-content", }} onClick={handleEditInfluencerCost}>Cancel</Button>
                                </Box>
                            </Box>
                        }
                    </Paper>



                    <Typography variant='h6' start>Influencer Email</Typography>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto', margin: "5px 0px 30px 0px", border: "1px solid #DCDCDC", boxShadow: "none" }}
                    >
                        {!editInfluencerEmail && <Box sx={{ display: "flex", flexDirection: "row", padding: "10px", justifyContent: "space-between", width: "100%" }}>
                            <InputBase
                                sx={{ width: "100%" }}
                                value={influencerEmail}
                                onChange={(e) => handleInfluencerEmail(e.target.value)}
                                placeholder="Please enter Influencer Email"
                                inputProps={{ readOnly: !editInfluencerEmail }}
                            />
                            <Button sx={{ color: "black", border: "1px solid black", background: "#E4F87F" }} onClick={handleEditInfluencerEmail}>Edit</Button>
                        </Box>}
                        {editInfluencerEmail &&
                            <Box sx={{ display: "flex", gap: "5px", justifyContent: "space-between", width: "100%" }}>
                                <TextField
                                    sx={{ width: "100%" }}
                                    value={influencerEmail}
                                    onChange={(e) => handleInfluencerEmail(e.target.value)}
                                    placeholder="Please enter Influencer Email"
                                    inputProps={{ readOnly: !editInfluencerEmail }}
                                />
                                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                    <Button sx={{ color: "black", border: "1px solid black", background: "#E4F87F", height: "fit-content", }} onClick={() => saveInfluencerEmail()}>Save</Button>
                                    <Button sx={{ color: "black", border: "1px solid black", height: "fit-content", }} onClick={handleEditInfluencerEmail}>Cancel</Button>
                                </Box>
                            </Box>
                        }
                    </Paper>


                    <Typography variant='h6' start>Influencer Phone</Typography>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto', margin: "5px 0px 30px 0px", border: "1px solid #DCDCDC", boxShadow: "none" }}
                    >
                        {!editInfluencerPhone && <Box sx={{ display: "flex", flexDirection: "row", padding: "10px", justifyContent: "space-between", width: "100%" }}>
                            <InputBase
                                sx={{ width: "100%" }}
                                value={influencerPhone}
                                onChange={(e) => handleInfluencerPhone(e.target.value)}
                                placeholder="Please enter Influencer Phone"
                                inputProps={{ readOnly: !editInfluencerPhone }}
                            />
                            <Button sx={{ color: "black", border: "1px solid black", background: "#E4F87F" }} onClick={handleEditInfluencerPhone}>Edit</Button>
                        </Box>}
                        {editInfluencerPhone &&
                            <Box sx={{ display: "flex", gap: "5px", justifyContent: "space-between", width: "100%" }}>
                                <TextField
                                    sx={{ width: "100%" }}
                                    value={influencerPhone}
                                    onChange={(e) => handleInfluencerPhone(e.target.value)}
                                    placeholder="Please enter Influencer Phone"
                                    inputProps={{ readOnly: !editInfluencerPhone }}
                                />
                                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                    <Button sx={{ color: "black", border: "1px solid black", background: "#E4F87F", height: "fit-content", }} onClick={() => saveInfluencerPhone()}>Save</Button>
                                    <Button sx={{ color: "black", border: "1px solid black", height: "fit-content", }} onClick={handleEditInfluencerPhone}>Cancel</Button>
                                </Box>
                            </Box>
                        }
                    </Paper>


                    <Box sx={{ display: "flex", justifyContent: 'space-between', marginBottom: "10px" }}>
                        <Typography variant='h6'>Enter Address</Typography>
                        <Button sx={{ color: "black", background: "#E4F87F", width: "40%" }} onClick={handleOpenAddress}>Enter Address</Button>
                    </Box>

                    <InputLabel shrink>
                        Saved Address
                    </InputLabel>
                    <TextField
                        sx={{ margin: "0px 0px 50px 0px" }}
                        InputProps={{
                            readOnly: true
                        }}
                        placeholder={influencerAddress == "" ? 'No Saved Address' : ""}
                        multiline
                        rows={2}
                        value={influencerAddress == "" ? "" : (influencerAddress["address1"] + "," + influencerAddress["address2"] + "," + influencerAddress["city"] + "," + influencerAddress["province"] + "," + influencerAddress["zip"])}
                    />

                    <Modal
                        open={openAddress}
                        onClose={handleCloseAddress}>
                        <Box>
                            <Address saveAddress={saveAddress} savedAddress={influencerAddress} handleClose={handleCloseAddress} userData={userData} />
                        </Box>
                    </Modal>

                    <Box sx={{ display: "flex", justifyContent: 'space-between', marginBottom: "10px" }}>
                        <Typography variant='h6'>Ship Free Product</Typography>
                        <Tooltip title={isGoaffproConnected == false ? "Please Connect Your Goaffpro account with Closer" : (displayShipProduct == false ? "" : "Please add address and email")}>
                            <span style={{ width: "40%", textAlign: "center" }}>
                                <ThemeProvider theme={theme}>
                                    <Button disabled={isGoaffproConnected == false ? true : displayShipProduct} variant="contained" sx={{ boxShadow: "none", width: "100%" }} onClick={handleOpenProduct}>Add Products</Button>
                                </ThemeProvider>
                            </span>
                        </Tooltip>
                    </Box>

                    <InputLabel shrink>
                        Shipped Products: {influencerProducts.length}
                    </InputLabel>
                    <Box sx={{ maxHeight: "150px", minHeight: "30px", border: "1px solid #CDCDCD", overflow: "auto", marginBottom: "20px" }}>
                        {influencerProducts.map((value, index) => (
                            <Box sx={{ height: "fit-content", padding: "5px 0px 5px 0px", overflow: "auto", display: "flex", width: "100%" }}>
                                <Grid item xs={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img
                                        src={value["image"]}
                                        height="60px"
                                        width="60px"
                                    />
                                </Grid>
                                <Grid item xs={9} sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography >{value["title"]}</Typography>
                                    <Typography >{value["price"]}</Typography>
                                </Grid>
                            </Box>
                        ))}
                    </Box>

                    <Modal
                        open={openProduct}
                        onClose={handleCloseProduct}>
                        <Box>
                            <ShipProduct setShippedProducts={setShippedProducts} handleClose={handleCloseProduct} userData={userData} shipProductData={{ "campaignId": campaignId, "influencer": influencer, "influencerFullName": influencerFullName, "influencerEmail": influencerEmail, "influencerAddress": influencerAddress, "influencerPhone": influencerPhone, "influencerAffiliateId": influencerAffiliateId }} />
                        </Box>
                    </Modal>

                    <Box sx={{ display: "flex", justifyContent: 'space-between', marginBottom: "20px" }}>
                        <Typography variant='h6'>Affiliate Link</Typography>
                        <Button sx={{ color: "black", background: "#E4F87F", width: "fit-content" }} >Generate Affiliate Link</Button>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: 'space-between', marginBottom: "20px" }}>
                        <Typography variant='h6'>Coupon Codes</Typography>
                        <Button sx={{ color: "black", background: "#E4F87F", width: "fit-content" }}>Generate Coupon Codes</Button>
                    </Box>

                    <Button onClick={() => saveInfluencerDetails(SlideTransition)} sx={{ border: "1px solid black", background: "#E4F87F", color: "black" }}>SAVE</Button>
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        autoHideDuration={1000}
                        open={state.open}
                        onClose={handleClose}
                        TransitionComponent={state.Transition}
                        message="Data Saved Successfully"
                    />
                </Box>
            </Box>
        )
    }
}

export default EditInfluencerDetails