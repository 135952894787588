import React, { useEffect, useState } from 'react'
import { Avatar } from '@mui/material';
import { CardMedia, } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Button, Grid, Card, Typography, Divider } from '@mui/material'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CampaignAnalyticsNoResults from "../../assets/campaignAnalyticsNoResults.png";
import faker from 'faker';
import PieCharts from './PieCharts';
import LineCharts from './LineChart';

function CampaignAnalytics(props) {

    const location = useLocation();
    const propsData = location.state;

    const campaignData = propsData.campaignData;
    const userData = props.userData;

    // const options = {
    //     responsive: true,
    //     plugins: {
    //         legend: {
    //             position: 'top',
    //         },
    //         title: {
    //             display: true,
    //             text: 'Chart.js Line Chart',
    //         },
    //     },
    // };

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    // const data = {
    //     labels,
    //     datasets: [
    //         {
    //             fill: true,
    //             label: 'Dataset 2',
    //             data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
    //             // borderColor: 'rgb(53, 162, 235)',
    //             // backgroundColor: 'rgba(53, 162, 235, 0.1)',
    //             // backgroundColor: "linear-gradient(#E4F87F,#FFFFFF)"
    //             backgroundColor: (context) => {
    //                 const ctx = context.chart.ctx;
    //                 const gradient = ctx.createLinearGradient(0, 0, 0, 800);
    //                 gradient.addColorStop(0, "#E4F87F");
    //                 gradient.addColorStop(1, "#FFFFFF");
    //                 return gradient;
    //             },
    //         },
    //     ],
    // };

    const renderDetailsAvatar = (params) => {
        return (
            <Grid container style={{ gap: "10px" }}>
                <Grid item >
                    <Avatar >K</Avatar>
                </Grid>
                <Grid item>
                    <Grid item xs={1}>Username</Grid>
                    <Grid item xs={11}>Category</Grid>
                </Grid>
            </Grid>
        )
    }

    const renderRank = (params) => {
        return (
            <Card style={{ width: "5vh", height: "5vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#E4F87F" }}>
                #{params.id}
            </Card>
        )
    }

    const columns = [
        { field: 'id', headerName: 'Rank', flex: 1, renderCell: renderRank },
        { field: 'users', headerName: 'Users', flex: 2, renderCell: renderDetailsAvatar },
        // renderCell: renderDetailsAvatar
        { field: 'deliverables', headerName: 'Deliverables', flex: 2 },
        {
            field: 'lastName',
            headerName: 'Reach',
            flex: 1,
            editable: true,
        },
        {
            field: 'date',
            headerName: 'Engagement',
            flex: 1,
            editable: true,
        },
        {
            field: 'engagement',
            headerName: 'Revenue',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            flex: 2,
        },
        {
            field: 'affiliatedBrands',
            headerName: 'Link Clicks',
            flex: 1,
            editable: true,
        }
    ];

    const rows = [
        { id: 1, deliverables: ["2 Reels", "1 Story"], lastName: 'Snow', date: "11/12/2023", engagement: "2.3%", affiliatedBrands: "Perfora, Renee" },
        { id: 2, deliverables: ["Reel", "Story"], lastName: 'Lannister', date: "11/12/2023", engagement: "2.3%", affiliatedBrands: "Perfora, Renee" },
        { id: 3, deliverables: ["Reel", "Story"], lastName: 'Lannister', date: "11/12/2023", engagement: "2.3%", affiliatedBrands: "Perfora, Renee" },
        { id: 4, deliverables: ["Reel", "Story"], lastName: 'Stark', date: "11/12/2023", engagement: "2.3%", affiliatedBrands: "Perfora, Renee" },
        { id: 5, deliverables: ["Reel", "Story"], lastName: 'Targaryen', date: "11/12/2023", engagement: "2.3%", affiliatedBrands: "Perfora, Renee" }
    ];

    const reels = ["reel1", "reel2", "reel3", "reel4"];

    const mediaTypeBg = { "POST": "#5BCEFF", "STORY": "#E69EFF", "REEL": "#E4F87F" }

    const showCampaignAnalytics = true;

    const [campaignAnalytics, setCampaignAnalytics] = useState(false)
    const [displayCampaignAnalytics, setDisplayCampaignAnalytics] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    async function fetchCampaignAnalytics() {

        console.log("fetching campaign analytics")
        var campaignId = campaignData.campaignId;
        if (campaignId != null) {
            setIsLoading(true);
            const token2 = await userData.getIdToken()

            let data = {
                campaignId: campaignId
            }

            let options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }

            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_campaign_analytics', options)
                .then(result => result.json())
                .then(body => { console.log("body"); setCampaignAnalytics(body); setDisplayCampaignAnalytics(body["displayCampaignAnalytics"]); setIsLoading(false) });
        }
        else { console.log("Invalid campaign id") }
    }

    useEffect(() => {
        fetchCampaignAnalytics()
    }, [])


    if (displayCampaignAnalytics) {
        return (
            <Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ width: "80%", display: "flex", justifyContent: "right" }}>
                        {/* <Button sx={{ color: "black", border: "1px solid black", marginBottom: "20px" }} endIcon={<DownloadIcon />}>Export Report</Button> */}
                    </Box>
                </Box>
                <Grid container style={{ justifyContent: "center", marginBottom: "20px", height: "100%" }}>
                    <Card sx={{ width: "80%", border: "2px solid #DCDCDC", textAlign: "center", borderRadius: "25px", boxShadow: "none", padding: "20px 0px 20px 0px" }}>
                        <Grid container pr={8} pl={8} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Grid item xs={3} fontSize={30}>123</Grid>
                            <Grid item xs={3} fontSize={30}>123</Grid>
                            <Grid item xs={3} fontSize={30}>123</Grid>
                            <Grid item xs={3} fontSize={30}>123</Grid>

                            <div style={{ height: "40px" }}></div>
                            <Grid item xs={3} fontSize={15}>Total Influencers</Grid>
                            <Grid item xs={3} fontSize={15}>Content Generated</Grid>
                            <Grid item xs={3} fontSize={15}>Cost Incurred</Grid>
                            <Grid item xs={3} fontSize={15}>Total Views</Grid>
                        </Grid>
                        <Grid sx={{ display: "flex", justifyContent: "center", padding: "20px 0px 20px 0px" }}>
                            <Divider style={{ width: "90%", display: "flex", justifyContent: "center", alignItems: 'center' }} />
                        </Grid>
                        <Grid container pr={8} pl={8} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Grid item xs={3} fontSize={30}>123</Grid>
                            <Grid item xs={3} fontSize={30}>123</Grid>
                            <Grid item xs={3} fontSize={30}>123</Grid>
                            <Grid item xs={3} fontSize={30}>123</Grid>

                            <div style={{ height: "40px" }}></div>
                            <Grid item xs={3} fontSize={15}>CPV</Grid>
                            <Grid item xs={3} fontSize={15}>Total Engagement</Grid>
                            <Grid item xs={3} fontSize={15}>CPE</Grid>
                            <Grid item xs={3} fontSize={15}>Link Clicks</Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Grid container style={{ width: "80%", display: "flex", justifyContent: "center" }}>
                        <Grid item xs={12} style={{ border: "2px solid #DCDCDC", borderRadius: "25px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                            <Typography variant="h4" sx={{ textAlign: "center", marginTop: "10px" }}>Content Reach</Typography>
                            {/* <Line options={options} data={data} style={{ height: "50%", width: "100%" }} /> */}
                            <LineCharts />
                        </Grid>
                        <Grid item xs={5.9} style={{ height: "50vh", display: "flex", flexDirection: "column", justifyContent: "center", border: "2px solid #DCDCDC", borderRadius: "25px", marginBottom: "20px", marginRight: "10px" }}>
                            <Typography variant="h4" sx={{ textAlign: "center", marginTop: "10px" }}>Content Distribution</Typography>
                            <PieCharts labels={["Story", "Reel", "Post"]} distribution={["55", "15", "30"]} type="ContentDistribution" />
                        </Grid>
                        <Grid item xs={5.9} style={{ height: "50vh", display: "flex", flexDirection: "column", justifyContent: "center", border: "2px solid #DCDCDC", borderRadius: "25px", marginBottom: "20px", marginLeft: "10px" }}>
                            <Typography variant="h4" sx={{ textAlign: "center", marginTop: "10px" }}>Influencer Distribution</Typography>
                            <PieCharts labels={["Micro", "Macro", "Nano"]} distribution={["55", "15", "30"]} type="InfluencerDistribution" />
                        </Grid>
                        <Grid item xs={12} sx={{ border: "2px solid #DCDCDC", borderRadius: "25px", marginBottom: "20px", padding: "20px" }}>
                            <Box sx={{ height: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography variant="h4">Top Content</Typography>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "center", }}>
                                <Grid container style={{ width: "100%" }}>
                                    {reels.map((value, index) => (
                                        <Grid item xs={3} key={index} style={{ padding: "10px 5px 5px 5px", display: "flex", justifyContent: "center" }}>
                                            <Card style={{ width: "100%", borderRadius: "10px", border: "1px solid #DCDCDC", boxShadow: "none", padding: "10px" }}>
                                                <Grid container>
                                                    <Grid item xs={9} style={{ marginBottom: "10px", display: "flex", justifyContent: "left", alignItems: "center", gap: "5px" }}>
                                                        <Avatar style={{ width: "40px", height: "40px" }} src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + "anonymous" + ".jpg?alt=media"} />
                                                        <Typography >@influencer</Typography>
                                                    </Grid>
                                                    <Grid item xs={3} style={{ marginBottom: "10px", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                                        <Typography style={{ background: mediaTypeBg["STORY"], padding: "4px 7px 4px 7px", borderRadius: "10px" }}>STORY</Typography>
                                                    </Grid>
                                                    <Grid item >
                                                        <CardMedia
                                                            style={{ height: "400px", borderRadius: "10px" }}
                                                            component='video'
                                                            // https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/content%2FCVGCDbdIipi.mp4?alt=media
                                                            image={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2Fanonymous.mp4?alt=media"}
                                                            controls>
                                                        </CardMedia>
                                                    </Grid>
                                                    <Grid container xs sx={{ marginTop: "10px", padding: "0px 10px 0px 10px" }}>
                                                        <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                                                            <Typography sx={{ gap: "2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <FavoriteBorderOutlinedIcon /> 123
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                                                            <Typography sx={{ gap: "2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <CommentOutlinedIcon /> 123
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4} style={{ gap: "5px", display: "flex", alignItems: "center" }}>
                                                            <Typography sx={{ gap: "2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <VisibilityOutlinedIcon /> 123
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid item xs={12}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem distinctio quam repudiandae ea impedit? Perferendis sequi nihil totam necessitatibus ullam maxime porro, dolore maiores culpa quas eos quis deserunt repellendus?</Grid> */}
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ border: "2px solid #DCDCDC", borderRadius: "25px", padding: "10px", height: "600px", marginBottom: "10px" }}>
                            <Box sx={{ height: "10%", display: "flex", justifyContent: "center", alignItems: "center", margin: "10px 0px 10px 0px" }}>
                                <Typography variant="h4">Top Influencers</Typography>
                            </Box>
                            <Box sx={{ height: "90%" }}>
                                <DataGrid
                                    rowHeight={80}
                                    sx={{ height: "100%", border: "1px solid #DCDCDC", borderRadius: "20px" }}
                                    rows={rows}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[10]}
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box >
            </Box>
        )
    } else {
        return (
            // <div style={{ display: "flex", justifyContent: "center" }}>
            //     <Grid container style={{ width: "80%" }}>
            //         <Grid item xs={4} style={{ padding: "10px 5px 5px 5px", display: "flex", justifyContent: "center" }}>
            //             <Typography></Typography>
            //         </Grid>
            //     </Grid>
            // </div>
            <div style={{ display: "flex", justifyContent: "center", }}>
                <Box
                    component="img"
                    sx={{
                        height: "400px",
                        width: "300px",
                    }}
                    alt="Campaigns Analytics No Results"
                    src={CampaignAnalyticsNoResults}
                />
            </div>
        )
    }
}

export default CampaignAnalytics