import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import ShareCampaignVideoCard from './ShareCampaignVideoCard';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CampaignContentNoResults from "../../../assets/campaignContentNoResults.png";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { Avatar, Card, CardMedia, Grid, Typography, Box, CircularProgress, Modal, TextField, Button, Slide, Radio, FormLabel, FormControl, Pagination, Divider, InputLabel, ButtonGroup, LinearProgress, FormControlLabel, RadioGroup, Checkbox, Select, MenuItem } from '@mui/material'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import "../main.css";

const StyledModal = styled(Modal)`
position: fixed;
z-index: 1300;
inset: 0;
display: flex;
align-items: center;
justify-content: center;
`;

const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'MuiBackdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});

Backdrop.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
};

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: "40%",
    height: "60%",
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: "10px",
    p: 3,
};

const buttonStyleSelected = {
    background: "#E4F87F",
    color: "black"
}

const buttonStyleNotSelected = {
    background: "white",
    color: "black"
}

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const dropzoneStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: "100%",
    padding: '20px',
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    backgroundColor: '#f5f5f5',
    cursor: 'pointer',
    boxShadow: "none"
};

function ShareTrackingContent(props) {

    const location = useLocation();
    const propsData = location.state;

    const campaignId = props.campaignId;
    // console.log('campaign id content')
    // console.log(campaignId)
    const userData = props.userData;

    const [isLoading, setIsLoading] = useState(false)
    const [campaignContent, setCampaignContent] = useState([])
    const [filteredContent, setFilteredContent] = useState([])
    const [searchInfluencer, setSearchInfluencer] = useState("");

    const [contentLink, setContentLink] = useState("")

    const handleContentLink = event => {
        setContentLink(event.target.value);
    };

    // const mediaTypeBg = { "POST": "#5BCEFF", "STORY": "#E69EFF", "REEL": "#E4F87F", "YOUTUBE": "#FF0000" }
    const mediaTypeBg = { "POST": "#D9BF00", "STORY": "#E69EFF", "REEL": "#E4F87F", "YOUTUBE": "#FF0000", "TWEET": "#1A8CD8" }

    // const showCampaignContent = (campaignContent.length > 0) ? true : false;
    const showCampaignContent = true;

    const [openIndex, setOpenIndex] = useState(-1); // Initialize with -1
    const handleOpen = (index) => {
        setOpenIndex(index); // Set the index of the opened modal
    };
    const handleClose = () => {
        setOpenIndex(-1); // Reset the index when closing the modal
    };


    const [openNewContent, setOpenNewContent] = useState(false);
    const handleOpenNewContent = () => { setOpenNewContent(true); }
    const handleCloseNewContent = () => { setOpenNewContent(false); }

    async function fetchCampaignContent() {
        const c_id = campaignId;
        if (c_id != null) {
            setIsLoading(true);
            const token2 = await userData.getIdToken()

            let data = {
                campaignId: c_id
            }

            let options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }


            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_campaign_content', options)
                .then(result => result.json())
                .then(body => { setFilteredContent(body); setCampaignContent(body); setIsLoading(false); });
        }
        else { console.log("Invalid campaign id") }
    }

    useEffect(() => {
        fetchCampaignContent()
    }, [])

    function getMediaComponent(url) {
        const urlWithoutQuery = url.split('?')[0];

        const urlParts = urlWithoutQuery.split('/');
        const lastPart = urlParts[urlParts.length - 1];
        const fileExtension = lastPart.split('.').pop();

        return (fileExtension == "jpg") ? "image" : "video"
    }

    function formatNumber(number) {
        if (number >= 1e6) {
            return (number / 1e6).toFixed(1) + "M";
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(1) + "K";
        } else {
            if (number == 0) {
                return number
            } else {
                return number;
            }
        }
    }


    function getMediaStyle(mediaComponent) {
        const imageStyle = { width: "550px", height: "390px", borderRadius: "20px", cursor: "pointer", borderRadius: "20px" }
        const videoStyle = { width: "550px", height: "390px", background: "black", borderRadius: "20px", cursor: "pointer", borderRadius: "20px" }
        if (mediaComponent == "image") {
            return imageStyle;
        } else {
            return videoStyle;
        }
    }

    const [selectedValue, setSelectedValue] = useState('a');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    // async function addNewContent() {
    //     var campaignId = campaignData.campaignId;
    //     var campaignName = campaignData.campaignName;
    //     // console.log("CAMPAIGN NAME")
    //     // console.log(campaignName)
    //     if (campaignId != null) {
    //         setIsLoading(true);
    //         const token2 = await userData.getIdToken()

    //         const links = contentLink.split('\n');

    //         let data = {
    //             campaignId: campaignId,
    //             campaignName: campaignName,
    //             contentLinks: links
    //         }

    //         let options = {
    //             method: 'POST',
    //             body: JSON.stringify(data),
    //             headers: new Headers({
    //                 'Authorization': `Bearer ${token2}`,
    //                 'Content-Type': "application/json"
    //             })
    //         }


    //         await fetch(process.env.REACT_APP_API_ADDRESS + '/api/add_new_tracking_content', options)
    //             .then(result => result.json())
    //             .then(body => { console.log(body); setIsLoading(false); });
    //     }
    //     else { console.log("Invalid campaign id") }

    // }

    function getDateFromTimePosted() {
        const date = new Date(campaignContent[0]["timePosted"] * 1000);

        // Format the date to a readable string
        const dateString = date.toUTCString(); // Converts to a string with a UTC time zone
        // Or, for a local time string
        const localDateString = date.toLocaleString();

        return localDateString;
    }

    const handleSearchInfluencer = (event) => {
        const searchText = event.target.value.toLowerCase();
        setSearchInfluencer(searchText);

        if (searchText === '') {
            // If the search input is empty, reset to the complete list
            setFilteredContent(campaignContent);
            setPage(1);
        } else {
            // Otherwise, filter based on the search input
            const filteredData = campaignContent.filter(item =>
                item.influencer.toLowerCase().includes(searchText)
            );
            setFilteredContent(filteredData);
            setPage(1);
        }
    };

    const [page, setPage] = useState(1);
    const handlePageChange = (event, value) => {
        setPage(value);
    };


    const [openDeletedContent, setOpenDeletedContent] = useState(false);
    const handleOpenDeletedContent = () => { setOpenDeletedContent(true); }
    const handleCloseDeletedContent = () => { setOpenDeletedContent(false); }


    const [displayAddContentViaLinkScreen, setDisplayAddContentViaLinkScreen] = useState(true);
    function handleAddContentLink() {
        setDisplayAddContentViaLinkScreen(true);
    }
    function handleAddContentManually() {
        setDisplayAddContentViaLinkScreen(false);
    }

    const [displayDeletedContent, setDisplayDeletedContent] = useState(true);
    function handleDisplayDeletedContent() {
        setDisplayDeletedContent(true);
    }
    function handleDisplayDuplicateContent() {
        setDisplayDeletedContent(false);
    }

    const [uploadedFileName, setUploadedFileName] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileToUpload, setFileToUpload] = useState(null);

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        setUploadedFileName(file.name);

        // Simulate file upload progress (for demonstration purposes)
        let progress = 0;
        const interval = setInterval(() => {
            progress += 20;
            setUploadProgress(progress);

            if (progress >= 100) {
                clearInterval(interval);
                // uploadFile(file)
                setFileToUpload(file)
            }
        }, 100);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: ['.jpg', '.png', 'jpeg', '.mp4', '.wav'],
        onDrop
    });


    const [checkedStory, setCheckedStory] = useState(true)
    const [checkedReel, setCheckedReel] = useState(true)
    const [checkedPost, setCheckedPost] = useState(true)
    const [checkedTweet, setCheckedTweet] = useState(true)

    function handleMediaTypeFilter(mediaType) {
        if (mediaType == "reel") {
            setCheckedReel(!checkedReel)
        }
        else if (mediaType == "post") {
            setCheckedPost(!checkedPost)
        }
        else if (mediaType == "story") {
            setCheckedStory(!checkedStory)
        }
        else if (mediaType == "tweet") {
            setCheckedTweet(!checkedTweet)
        }
    }

    useEffect(() => {
        if (checkedStory && checkedPost && checkedReel && checkedTweet) {
            setFilteredContent(campaignContent);
            setPage(1);
        } else {
            const filteredData = campaignContent.filter(item =>
                (checkedStory && item.mediaType.toLowerCase() == "story") ||
                (checkedReel && item.mediaType.toLowerCase() == "reel") ||
                (checkedPost && item.mediaType.toLowerCase() == "post") ||
                (checkedTweet && item.mediaType.toLowerCase() == "tweet")
            );
            console.log("filtered data checkbox")
            // console.log(filteredData)
            setFilteredContent(filteredData);
            setPage(1);
        }
    }, [checkedStory, checkedPost, checkedReel, checkedTweet])

    const [sortBy, setSortBy] = useState();

    const options = ["Date(desc)", "Date(asc)", "Reach(desc)", "Likes(desc)", "Comments(desc)", "Shares(desc)", "Saves(desc)"]

    const handleChangeSortBy = (event) => {
        setSortBy(event.target.value);
    };

    useEffect(() => {
        // var sortByType = getSortByType(sortBy);
        var filteredData = [];
        if (sortBy == 0) {
            setFilteredContent(campaignContent);
            setPage(1);
        }
        else if (sortBy == 1) {
            var filteredData = filteredContent.slice(0).sort((a, b) => a.timePosted - b.timePosted);
            setFilteredContent(filteredData);
            setPage(1);
        }
        else if (sortBy == 2) {
            var filteredData = filteredContent.slice(0).sort((a, b) => b.views - a.views);
            setFilteredContent(filteredData);
            setPage(1);
        }
        else if (sortBy == 3) {
            var filteredData = filteredContent.slice(0).sort((a, b) => b.likes - a.likes);
            setFilteredContent(filteredData);
            setPage(1);
        }
        else if (sortBy == 4) {
            var filteredData = filteredContent.slice(0).sort((a, b) => b.comments - a.comments);
            setFilteredContent(filteredData);
            setPage(1);
        }
        else if (sortBy == 5) {
            var filteredData = filteredContent.slice(0).sort((a, b) => b.shares - a.shares);
            setFilteredContent(filteredData);
            setPage(1);
        }
        else if (sortBy == 6) {
            var filteredData = filteredContent.slice(0).sort((a, b) => b.saves - a.saves);
            setFilteredContent(filteredData);
            setPage(1);
        }
    }, [sortBy])


    if (showCampaignContent) {
        return (
            <>
                {isLoading && <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress style={{ color: "black", }} size={50} /></Box>}
                {!isLoading &&
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <Grid container sx={{ width: "80%", display: "flex", flexDirection: "row", gap: "20px", alignItems: "center", justifyContent: "space-between" }}>
                            <Grid item xs sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <TextField
                                    hiddenLabel
                                    value={searchInfluencer}
                                    onChange={handleSearchInfluencer}
                                    size='small'
                                    fullWidth
                                    placeholder='Search Meme Pages'
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                <SearchIcon />
                                            </Box>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography sx={{ textAlign: "center" }}>Media Type</Typography>
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <FormControlLabel
                                            value="reel"
                                            control={<Checkbox checked={checkedReel} onClick={() => handleMediaTypeFilter("reel")} defaultChecked size='small' />}
                                            label="Reel"
                                            labelPlacement="top"
                                        />
                                        <FormControlLabel
                                            value="static"
                                            control={<Checkbox checked={checkedPost} onClick={() => handleMediaTypeFilter("post")} defaultChecked size='small' />}
                                            label="Static"
                                            labelPlacement="top"
                                        />
                                        <FormControlLabel
                                            value="story"
                                            control={<Checkbox checked={checkedStory} onClick={() => handleMediaTypeFilter("story")} defaultChecked size='small' />}
                                            label="Story"
                                            labelPlacement="top"
                                        />
                                        <FormControlLabel
                                            value="tweet"
                                            control={<Checkbox checked={checkedTweet} onClick={() => handleMediaTypeFilter("tweet")} defaultChecked size='small' />}
                                            label="Tweet"
                                            labelPlacement="top"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel >Sort By</InputLabel>
                                        <Select
                                            value={sortBy}
                                            label="Sort By"
                                            onChange={(event) => handleChangeSortBy(event)}
                                        >{options.map((value, index) => (
                                            <MenuItem value={index}>{value}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                        </Grid>
                        <Grid container style={{ width: "80%" }}>
                            {filteredContent.slice((page - 1) * 20, page * 20).map((value, index) => (
                                <Grid item xs={3} key={index} style={{ padding: "10px 2px 5px 2px", display: "flex", justifyContent: "center" }}>
                                    <Card style={{ height: "500px", borderRadius: "20px", border: "1px solid #DCDCDC", boxShadow: "none", padding: "10px" }}>
                                        <Grid container>
                                            <Grid item xs={9} style={{ marginBottom: "10px", display: "flex", justifyContent: "left", alignItems: "center", gap: "5px" }}>
                                                <Avatar style={{ width: "40px", height: "40px" }} src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + value.influencer + ".jpg?alt=media"} />
                                                <Typography >@{value.influencer.substring(0, 12)}..</Typography>
                                            </Grid>
                                            <Grid item xs={3} style={{ marginBottom: "10px", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                                <Typography style={{ background: mediaTypeBg[value.mediaType], padding: "4px 7px 4px 7px", borderRadius: "10px" }}>{value.mediaType}</Typography>
                                            </Grid>
                                            <Grid item xs onClick={() => handleOpen(index)} sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                                <CardMedia
                                                    component={getMediaComponent(value.mediaUrl)}
                                                    image={value.mediaUrl}
                                                    style={getMediaStyle(getMediaComponent(value.mediaUrl))}
                                                >
                                                </CardMedia>
                                            </Grid>
                                            <StyledModal
                                                open={openIndex === index}
                                                onClose={handleClose}
                                                slots={{ backdrop: StyledBackdrop }}
                                            >
                                                <ShareCampaignVideoCard key={index} videoData={{ "mediaType": value.mediaType, "mediaComponent": getMediaComponent(value.mediaUrl), "mediaUrl": value.mediaUrl, "likes": value.likes, "comments": value.comments, "views": value.views, "shares": value.shares, "saves": value.saves, "caption": value.caption, "shortcode": value.shortcode, "permalink": value.permalink, "isManuallyUploaded": value.isManuallyUploaded, "isAnalyticsContent": false, "brands": [] }} />
                                            </StyledModal>
                                            <Grid container xs sx={{ marginTop: "10px" }}>
                                                <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                                                    {/* <Typography sx={{ gap: "2px", display: "flex", justifyContent: "center", alignItems: "center" }}> */}
                                                    <div className='trackingContentOuterCardMetrics'>
                                                        <FavoriteBorderOutlinedIcon fontSize='small' /> {formatNumber(value.likes)}
                                                    </div>
                                                    {/* </Typography> */}
                                                </Grid>
                                                <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                                                    {/* <Typography sx={{ gap: "2px", display: "flex", justifyContent: "center", alignItems: "center" }}> */}
                                                    <div className='trackingContentOuterCardMetrics'>
                                                        <CommentOutlinedIcon fontSize='small' /> {formatNumber(value.comments)}
                                                    </div>
                                                    {/* </Typography> */}
                                                </Grid>
                                                <Grid item xs={4} style={{ gap: "5px", display: "flex", alignItems: "center" }}>
                                                    {/* <Typography sx={{ gap: "2px", display: "flex", justifyContent: "center", alignItems: "center" }}> */}
                                                    <div className='trackingContentOuterCardMetrics'>
                                                        <VisibilityOutlinedIcon fontSize='small' /> {formatNumber(value.views)}
                                                    </div>
                                                    {/* </Typography> */}
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                <Typography variant='body1' sx={{ display: "flex", fontWeight: "800" }}>
                                                    {caption[index]}
                                                </Typography>
                                            </Grid> */}
                                        </Grid>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid >
                        <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                            <Pagination count={Math.ceil(filteredContent.length / 20)} page={page} color='standard' shape='rounded' onChange={handlePageChange} />
                        </Box>
                    </div >}
            </>
        )
    }
    else {
        return (
            <div style={{ display: "flex", justifyContent: "center", }}>
                <Box
                    component="img"
                    sx={{
                        height: "380px",
                        width: "350px",
                    }}
                    alt="Campaigns Content No Results"
                    src={CampaignContentNoResults}
                />
            </div>
        )
    }
}

export default ShareTrackingContent