import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Avatar, Chip, Divider, Grid, List, ListItem, ListItemText, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import CustomizedTabs from './influencerCard/CustomizedTabs';
import CircularProgress from '@mui/material/CircularProgress';

import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import ContactsIcon from '@mui/icons-material/Contacts';
import DisplayLists from './DisplayLists';
import { useEffect } from 'react';
import VideoCard from './influencerCard/VideoCard';
import clsx from 'clsx';
import { Mixpanel } from '../mixpanel/mixpanel.js';
import LockIcon from '@mui/icons-material/Lock';


const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: "20vh",
    boxShadow: "none"
}));

const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
};

const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'MuiBackdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});

const StyledBackdrop = styled(Backdrop)`
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: rgb(0 0 0 / 0.5);
    -webkit-tap-highlight-color: transparent;
`;

const StyledModal = styled(Modal)`
    position: fixed;
    z-index: 1300;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

function SearchListMainCard(props) {

    const influencerUsername = props.influencerUsername;
    const userData = props.userData;
    const handleCloseProp = props.handleClose;

    const [influencerData, setInfluencerData] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    class Influencer {
        constructor(username, followers, mediaCount, gender, fullName, collabBrands, category, avgEngagement, avgViews, state, website, hasCostData, estimatedCost, email) {
            this.username = username;
            this.followers = followers;
            this.mediaCount = mediaCount;
            this.gender = gender;
            this.fullName = fullName;
            this.collabBrands = collabBrands;
            this.category = category;
            this.avgEngagement = avgEngagement;
            this.avgViews = avgViews;
            this.state = state;
            this.website = website;
            this.hasCostData = hasCostData;
            this.estimatedCost = estimatedCost;
            this.email = email;
        }
    }

    function decodeInfluencer(jsonPayload) {

        var influencers_list = jsonPayload.map(influencer => {
            return new Influencer(influencer.username, influencer.followers, influencer.mediaCount, influencer.gender, influencer.fullName, influencer.collabBrands, influencer.category, influencer.avgEngagement, influencer.avgViews, influencer.state, influencer.website, influencer.hasCostData, influencer.estimatedCost, influencer.email);
        });
        setInfluencerData(influencers_list);
    }
    async function handleSearchInfluencerUsername() {
        setIsLoading(true)

        const token2 = await userData.getIdToken()

        let influencerUsernameData = {
            username: influencerUsername
        }

        let influencerUsernameOptions = {
            method: 'POST',
            body: JSON.stringify(influencerUsernameData),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_influencer_from_username/', influencerUsernameOptions)
            .then(result => result.json())
            .then(body => {
                if (body.length == 0) {
                    setInfluencerData([])
                    setIsLoading(false)
                }
                else {
                    decodeInfluencer(body);
                    fetchContent();
                }
            });
    }

    useEffect(() => {
        handleSearchInfluencerUsername();
        // fetchContent();
    }, [])


    const [openList, setOpenList] = useState(false);
    const handleOpenList = () => setOpenList(true);
    const handleCloseList = () => setOpenList(false);


    const [sponsored, setSponsored] = useState([]);
    const [nonSponsored, setNonSponsored] = useState([]);
    const [stats, setStats] = useState({});
    const [sponsoredStats, setSponsoredStats] = useState({});
    const [organicStats, setOrganicStats] = useState({});
    const [highlightsBrands, setHighlightsBrands] = useState([]);
    const [highlightsContent, setHighlightsContent] = useState([]);

    async function fetchContent() {

        const token2 = await userData.getIdToken()

        let contentApiPayload = {
            username: influencerUsername,
            collabBrands: [],
        }

        let contentApiOptions = {
            method: 'POST',
            body: JSON.stringify(contentApiPayload),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_influencer_content/', contentApiOptions)
            .then(result => result.json())
            .then(body => { createContentArrays(body) });
    };

    function createContentArrays(content) {
        setIsLoading(true);
        let sponsoredContent = []
        let nonSponsoredContent = []
        var hBrands = []

        var highlights = content["highlights"]
        var influencerContent = content["influencerContent"]
        var statsData = content["stats"]
        var sponsoredStatsBar = content["sponsoredStats"]
        var organicStatsBar = content["organicStats"]

        // setting sponsored, organic content
        for (const [key, value] of Object.entries(influencerContent)) {
            if (value["isSponsored"]) {
                let tempDict = value
                tempDict["shortcode"] = key
                sponsoredContent.push(tempDict)
            }
            else {
                let tempDict = value
                tempDict["shortcode"] = key
                nonSponsoredContent.push(tempDict)
            }
        }

        // setting highlightsBrands
        for (const [key, value] of Object.entries(highlights)) {
            hBrands.push(key);
        }

        setSponsored(sponsoredContent)
        setNonSponsored(nonSponsoredContent)
        setHighlightsBrands(hBrands)
        setHighlightsContent(highlights)
        setStats(statsData)
        setSponsoredStats(sponsoredStatsBar)
        setOrganicStats(organicStatsBar)
        setIsLoading(false)
    }

    // useEffect(() => {
    //     fetchContent()
    // }, []);


    const [isVideoCardOpen, setIsVideoCardOpen] = useState(false);
    const [videoData, setVideoData] = useState({})

    function handleOpenContent(value) {
        setIsVideoCardOpen(true);
        setOpen(true);
        setVideoData(highlightsContent[value])

    }

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    function formatNumber(number) {
        if (number >= 1e6) {
            return (number / 1e6).toFixed(2) + "M";
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(2) + "K";
        } else {
            return number.toFixed(2);
        }
    }

    function openInstagram(influencerUsername) {
        Mixpanel.identify(userData.email);
        Mixpanel.track('Instagram Redirected', { influencerUsername });
        openInNewTab('https://www.instagram.com/' + influencerUsername)
    }


    const [openContact, setOpenContact] = useState(false);
    const handleCloseContact = () => setOpenContact(false);

    const displayContactInfo = false;

    var contactIcon = displayContactInfo ? <ContactsIcon /> : <LockIcon />

    function openContactInfo(influencerUsername) {
        if (displayContactInfo) {
            setOpenContact(true)
        }
    }

    if (isLoading) {
        return (
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Box >
                    <CircularProgress style={{ color: "black", }} size={100} />
                </Box>
            </Box>
        )
    } else {
        if (influencerData.length > 0) {
            return (
                <Box sx={{ height: "100vh", overflow: 'auto' }} >
                    <CloseOutlinedIcon style={{ cursor: "pointer" }} onClick={handleCloseProp} />
                    <Grid container>
                        <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Avatar
                                src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + `${influencerData[0].username}` + ".jpg?alt=media"}
                                sx={{ width: "180px", height: "180px", border: "1px solid black" }}
                            />
                        </Grid>
                        <Grid item xs={9} style={{}}>
                            <Item style={{ textAlign: "left" }}>
                                <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }} >
                                    <Grid item xs={12} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <Typography variant="h5" fontWeight={900}>@{influencerData[0].username}</Typography>
                                        <Button style={{ color: "black", background: "#E4F87F", borderRadius: "10px", padding: "5px 15px 5px 15px", width: "fit-content" }} onClick={handleOpenList}>+ Add to List</Button>
                                        <Modal
                                            open={openList}
                                            onClose={handleCloseList}
                                        >
                                            <Box>
                                                <DisplayLists influencer={influencerData[0]} handleClose={handleCloseList} userData={userData} />
                                            </Box>
                                        </Modal>
                                        <Button style={{ color: "black", background: "#E4F87F", borderRadius: "10px", padding: "5px 15px 5px 15px", width: "fit-content" }} onClick={handleOpenList}>+ Add to Campaign</Button>
                                        <Modal
                                            open={openList}
                                            onClose={handleCloseList}
                                        >
                                            <Box>
                                                <DisplayLists influencer={influencerData[0]} handleClose={handleCloseList} userData={userData} />
                                            </Box>
                                        </Modal>
                                        <Button endIcon={<CallMadeOutlinedIcon />} onClick={() => openInstagram(`${influencerData[0].username}`)} style={{ color: "black", background: "#F0F0F0", borderRadius: "10px", padding: "5px 15px 5px 15px", width: "fit-content" }}>View on Instagram </Button>
                                        <Button endIcon={contactIcon} onClick={() => openContactInfo(`${influencerData[0].username}`)} style={{ color: "black", background: "#F0F0F0", borderRadius: "10px", padding: "5px 15px 5px 15px", width: "fit-content" }}>Contact Info </Button>
                                        <Modal
                                            open={openContact}
                                            onClose={handleCloseContact}
                                        >
                                            <Box sx={{ position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%', width: "30%", height: "auto", bgcolor: 'white', boxShadow: 24, borderRadius: "10px", p: 3 }}>
                                                <Typography variant="h5" sx={{ fontWeight: "900" }}>Contact Info</Typography>
                                                <Typography variant="h6" sx={{ marginTop: "10px" }}><span style={{ fontWeight: "700" }}>Email: </span>{influencerData[0].email}</Typography>
                                            </Box>
                                        </Modal>
                                    </Grid>

                                    <Grid item xs={3} style={{ gap: "5px", display: "flex" }}>
                                        <span style={{ fontWeight: "bold" }}>{formatNumber(influencerData[0].followers)}</span>
                                        <div>followers</div>
                                    </Grid>
                                    <Grid item xs={3} style={{ gap: "5px", display: "flex" }}>
                                        <span style={{ fontWeight: "bold" }}>{formatNumber(influencerData[0].avgEngagement)}</span>
                                        <span>% engagement</span>
                                    </Grid>
                                    <Grid item xs={3} style={{ gap: "5px", display: "flex" }}>
                                        <span style={{ fontWeight: "bold" }}>{formatNumber(influencerData[0].avgViews)}</span>
                                        <span>reel views</span>
                                    </Grid>
                                    <Grid item xs={3} >
                                        {influencerData[0].hasCostData &&
                                            <div style={{ gap: "5px", display: "flex" }}>
                                                <span style={{ fontWeight: "bold" }}>₹ {formatNumber(influencerData[0].estimatedCost)}</span>
                                                <span>price</span>
                                            </div>}
                                    </Grid>

                                    <Grid item style={{}}>{influencerData[0].website}</Grid>
                                    <Grid item style={{ display: "flex", alignItems: "center", width: "auto" }}>{influencerData[0].state && <FmdGoodOutlinedIcon />} {influencerData[0].state}</Grid>
                                </Grid>
                                <Grid item xs={12} style={{ display: "flex", marginTop: "15px", gap: "10px" }}>
                                    {influencerData[0]["category"].map((value, index) => (
                                        <Grid item key={index} style={{ width: "auto" }}><Chip label={value.charAt(0).toUpperCase() + value.slice(1)} /></Grid>
                                    ))}
                                </Grid>
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} marginLeft={8} marginTop={2} marginBottom={4} style={{ width: "90%" }}>
                        {highlightsBrands.map((value, index) => (
                            <Grid item key={index} style={{ width: "150px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                                <Avatar sx={{ width: "70px", height: "70px", cursor: "pointer", border: "1px solid black" }} src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/brands%2F" + value + ".jpg?alt=media"} onClick={() => handleOpenContent(value)} />
                                <Typography style={{ fontSize: "15px" }}>{value}</Typography>
                            </Grid>
                        ))}
                        {isVideoCardOpen && <StyledModal
                            open={open}
                            onClose={handleClose}
                            slots={{ backdrop: StyledBackdrop }}
                        >
                            <VideoCard videoData={videoData} />
                        </StyledModal>}
                    </Grid>
                    <div style={{ display: "flex", justifyContent: "center", }}>
                        <Divider style={{ width: "85%" }} />
                    </div>

                    {isLoading && <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80px" }}><CircularProgress size={60} /></Box>}
                    {!isLoading && <CustomizedTabs stats={stats} sponsored={sponsored} nonSponsored={nonSponsored} sponsoredStatsBar={sponsoredStats} organicStatsBar={organicStats} />}
                </Box>
            );
        } else {
            return (
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box >
                        <Typography>Influencer Not Found in Database. Please contact Closer Team.</Typography>
                    </Box>
                </Box>
            )
        }
    }
}

export default SearchListMainCard