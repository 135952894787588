import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useDropzone } from 'react-dropzone';
import SpeedIcon from '@mui/icons-material/Speed';
import ClearIcon from '@mui/icons-material/Clear';
import { pink, red, green } from '@mui/material/colors';
import React, { useEffect, useRef, useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { Link, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import { Avatar, Button, ButtonGroup, Card, Checkbox, CircularProgress, ClickAwayListener, Grid, Grow, LinearProgress, MenuItem, MenuList, Modal, Paper, Popper, TextField, Typography } from '@mui/material'

import { db } from '../../../api/firebase.js'
import { doc, onSnapshot } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import "../main.css"

const theme = createTheme({
    palette: {
        primary: {
            main: "#F8C036",
        },
    },
});

const style = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: "40%",
    height: "auto",
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: "10px",
    p: 3
};

const dropzoneStyles = {
    display: 'flex',
    flexDirection: 'row',
    cursor: "pointer",
    justifyContent: "space-between",
    alignItems: 'center',
    padding: '15px 10px 15px 10px',
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    backgroundColor: '#f5f5f5',
    boxShadow: "none",
    height: "auto"
};

function Tracking(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const propsData = location.state;

    const campaignData = propsData?.campaignData;
    const campaignStatus = propsData?.campaignStatus;
    const gradientColor = propsData?.gradientColor;
    const user = props.userData

    // console.log("campaignData")
    // console.log(campaignData)

    useEffect(() => {
        if (!campaignData) {
            navigate(`/dashboard`)
        }
    }, [campaignData, navigate])



    const [tab, setTab] = useState(1);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [editButtonText, setEditButtonText] = useState("Save")
    const [campaignDescription, setCampaignDescription] = useState("")
    const [entityDescription, setEntityDescription] = useState("")
    const [entityPerception, setEntityPerception] = useState("")
    // const [campaignName, setCampaignName] = useState(campaignData?.campaignName)
    const [isBliActive, setIsBliActive] = useState(false)

    const [newCampaignName, setNewCampaignName] = useState('')
    const [newCampaignStatus, setNewCampaignStatus] = useState(null)

    const handleCampaignDescription = event => {
        setCampaignDescription(event.target.value);
    };

    const handleEntityDescription = event => {
        setEntityDescription(event.target.value);
    };

    const handleEntityPerception = event => {
        setEntityPerception(event.target.value);
    };

    const handleNewCampaignName = event => {
        setNewCampaignName(event.target.value);
    };

    const [openEditModal, setOpenEditModal] = useState(false)
    const [fetchEditModalLoading, setFetchEditModalLoading] = useState(false)
    const [isEditButtonLoading, setIsEditButtonLoading] = useState(false)

    const [uploadedFileName, setUploadedFileName] = useState('');
    const [fileToUpload, setFileToUpload] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isCalendarUploaded, setIsCalendarUploaded] = useState(false);
    const [calendarUploadDate, setCalendarUploadDate] = useState('');

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        setUploadedFileName('');
        setUploadProgress(0);
        setIsCalendarUploaded(false);
        setCalendarUploadDate('');
        setIsEditButtonLoading(false);
    }

    function handleTabChange(tab) {
        setTab(tab)
    };

    const influencerButton = (tab == 0) ? "#F8C036" : "white";
    const contentButton = (tab == 1) ? "#F8C036" : "white";
    const analyticsButton = (tab == 2) ? "#F8C036" : "white";
    const bliButton = (tab == 3) ? "#F8C036" : "white";

    if (typeof campaignData !== "undefined" && campaignData.hasOwnProperty("documentId")) {
        const unsub = onSnapshot(doc(db, "campaigns", campaignData?.documentId), (doc) => {
            var docData = doc.data();
            var is_processing = docData["isCampaignProcessing"]
            setIsProcessing(is_processing)
            // propsData["isCampaignProcessing"] = is_processing;
        });
    }



    async function checkProcessing() {
        setIsLoading(true)
        const token2 = await user.getIdToken()

        let data = {
            campaignId: campaignData?.campaignId
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/check_tracking_processing', options)
            .then(result => result.json())
            .then(body => {
                if (body["isCampaignProcessing"]) {
                    setIsProcessing(true);
                } else {
                    setIsProcessing(false)
                }
            });
        setIsLoading(false)
    }

    async function refreshMetrics() {
        setIsProcessing(true)

        const token2 = await user.getIdToken()

        let data = {
            campaignId: campaignData.campaignId
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }


        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/refresh_tracking_metrics', options)
            .then(result => result.json())
            .then(body => { console.log("refresh body"); console.log(body) });
    }

    // useEffect(() => {
    //     if (campaignData != undefined) {
    //         checkProcessing()
    //     }
    // }, [])

    const [copyText, setCopyText] = useState("Copy")

    const [openShareableModal, setOpenShareableModal] = useState(false)
    const handleCloseShareableModal = () => { setOpenShareableModal(false); setCopyText("Copy") }
    async function generateShareableLink() {
        setOpenShareableModal(true)
    }


    function copyLink() {
        const linkText = `Link: ${campaignData.shareableLink}\nCode: ${campaignData.code}`;
        navigator.clipboard.writeText(linkText).then(() => {
            setCopyText("Copied!")
            // Optionally, update copyText state to give user feedback
        }).catch(err => {
            console.error('Error copying text: ', err);
        });
    }

    async function fetchEditCampaignDetails() {
        setFetchEditModalLoading(true);
        setOpenEditModal(true)
        const token2 = await user.getIdToken();

        var data = { campaignId: campaignData?.campaignId };

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_edit_campaign_details', options)
            .then(result => result.json())
            .then(body => { setNewCampaignName(body?.campaignName); setIsBliActive(body["isBliActive"]); setCampaignDescription(body['campaignDescription']); setEntityDescription(body['entityDescription']); setEntityPerception(body['entityPerception']); setFetchEditModalLoading(false); setCalendarUploadDate(body["calendarUploadDate"]); setIsCalendarUploaded(body['isCalendarUploaded']) });
    }

    async function uploadFileToStorage() {
        try {

            const storage = getStorage();

            var finalUploadFileName = `campaignCalendar/${uploadedFileName}`;
            // console.log('uploadedFileName')
            // console.log(uploadedFileName)

            // Create a reference to 'campaignContent/test123'
            const storageRef = ref(storage, finalUploadFileName);
            const uploadTask = uploadBytesResumable(storageRef, fileToUpload);

            return await new Promise((resolve, reject) => {
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                    },
                    (error) => {
                        console.log("error file upload");
                        // console.log(error);
                        resolve("error");
                    },
                    () => {
                        // Upload completed successfully, now we can get the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // console.log('File available at', downloadURL);
                            // return downloadURL;
                            resolve(downloadURL);
                        });
                    }
                );
            });


        } catch (error) {
            console.error("Error uploading file: ", error);
            throw error;
        }
    };

    async function saveEditCampaignDetails() {
        setIsEditButtonLoading(true);
        const token2 = await user.getIdToken();

        var downloadURL = ''
        if (fileToUpload != null) {
            downloadURL = await uploadFileToStorage();
            // console.log('File available at', downloadURL);
        }

        var data = {
            campaignId: campaignData?.campaignId,
            campaignDescription: campaignDescription,
            entityDescription: entityDescription,
            entityPerception: entityPerception,
            isBliActive: isBliActive,
            newCampaignName: newCampaignName,
            newCampaignStatus: selectedIndex,
            calendarUrl: downloadURL
        };

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/save_edit_campaign_details', options)
            .then(result => result.json())
            .then(body => {
                // console.log('body');
                // console.log(body);
                setIsEditButtonLoading(false);
                setEditButtonText('Data Saved Successfully');
                setTimeout(() => {
                    setEditButtonText("Save");
                    handleCloseEditModal();
                }, 2000);
            });
    }

    const options = ['Active', 'Paused', 'Drafts', 'Archived'];

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(campaignStatus);

    const handleClick = () => {
        console.info(`You clicked ${options[selectedIndex]}`);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);

        // updateCampaignStatus(index)
    };

    const dropzoneStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: "100%",
        padding: '20px',
        border: '2px dashed #cccccc',
        borderRadius: '4px',
        backgroundColor: '#f5f5f5',
        cursor: 'pointer',
        boxShadow: "none"
    };


    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        // console.log("FILE TYPE")
        // console.log(file)
        // console.log(file.type)
        setUploadedFileName(file.name);
        // setUploadedFileType(file.type);

        // Simulate file upload progress (for demonstration purposes)
        let progress = 0;
        const interval = setInterval(() => {
            progress += 20;
            setUploadProgress(progress);

            if (progress >= 100) {
                clearInterval(interval);
                setFileToUpload(file);
                // setIsUploadDisabled(false);
            }
        }, 100);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: ['.csv'],
        onDrop
    });


    return (
        <div style={{ padding: "20px" }}>
            <Grid container style={{ display: "flex", justifyContent: "center", marginBottom: "20px", height: "100px" }}>
                <Card sx={{ width: "85%", border: "2px solid #DCDCDC", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "25px", boxShadow: "none" }}>
                    <Grid container className='trackingGrid'>
                        <Grid item xs={5}>
                            <Grid container style={{ gap: "10px", display: "flex", alignItems: "center" }}>
                                {/* <div style={{ width: "40px", height: "40px", background: `radial-gradient(#FFFFFF, ${gradientColor})`, borderRadius: "50%" }} /> */}
                                <div className="trackingGradientCircle" style={{ background: `radial-gradient(#FFFFFF, ${gradientColor})` }} />
                                <div style={{ display: "flex", height: "50%", gap: "10px" }}>
                                    <Grid item >
                                        <Grid item fontSize={20} >{campaignData?.campaignName}</Grid>
                                    </Grid>
                                    <div className='activeTab'>
                                        <Grid item>
                                            <Card sx={{ background: `${gradientColor}`, padding: "5px 10px 5px 10px", borderRadius: "5px" }}>Active</Card>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} style={{ display: "flex", justifyContent: "center", textAlign: "left" }}>
                            <Divider orientation="vertical" variant="middle" flexItem sx={{ marginRight: "20px" }} />
                            <Grid container sx={{ gap: "4px" }}>
                                <Grid item fontSize={18} xs={6}>Total Content</Grid>
                                <Grid item fontSize={26} xs={6} sx={{ fontWeight: 800 }}>{campaignData?.totalContent}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <ThemeProvider theme={theme}>
                                {isLoading && <Button variant="contained" sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black" }} ><CircularProgress size={20} sx={{ color: "black" }} /></Button>}
                                {!isLoading &&
                                    <Box sx={{ display: "flex", gap: "5px" }}>
                                        <Button onClick={() => refreshMetrics()} variant="contained" sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black" }} disabled={isProcessing} endIcon={isProcessing && <CircularProgress sx={{ color: "black" }} size={20} />}>{isProcessing ? "Processing" : "Refresh Metrics"}</Button>
                                        <Button onClick={() => generateShareableLink()} variant="contained" sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black" }} disabled={isProcessing} endIcon={isProcessing && <CircularProgress sx={{ color: "black" }} size={20} />}>Shareable Link</Button>
                                        <Button onClick={() => fetchEditCampaignDetails()} variant="contained" sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black" }} >Edit</Button>
                                        <Modal
                                            open={openShareableModal}
                                            onClose={handleCloseShareableModal}
                                            BackdropProps={{ style: { backgroundColor: 'rgb(0,0,0,0.1)' } }}
                                        >
                                            <Box sx={style}>
                                                <Typography sx={{ textAlign: "center" }} variant='h6'>Shareable Link</Typography>
                                                <Box onClick={() => copyLink()} sx={dropzoneStyles}>
                                                    <Box sx={{ display: "flex", flexDirection: "column", width: "90%" }}>
                                                        <Typography>
                                                            <span style={{ fontSize: "20px" }}>Link: </span>
                                                            <a href={campaignData?.shareableLink} target="_blank" rel="noopener noreferrer">
                                                                {campaignData?.shareableLink}
                                                            </a>
                                                        </Typography>
                                                        <Typography><span style={{ fontSize: "20px" }}>Code: </span>{campaignData?.code}</Typography>
                                                    </Box>
                                                    <Box sx={{ width: "10%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                        <ContentCopyRoundedIcon fontSize='large' />
                                                        <Typography>{copyText}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Modal>
                                    </Box>}
                            </ThemeProvider>

                            <Modal
                                open={openEditModal}
                                onClose={handleCloseEditModal}
                                BackdropProps={{ style: { backgroundColor: 'rgb(0,0,0,0.1)' } }}
                            >
                                <Box sx={style}>
                                    {fetchEditModalLoading && <Box sx><CircularProgress sx={{ color: "black" }} size={40} /></Box>}
                                    {!fetchEditModalLoading && <Box>
                                        <Typography sx={{ textAlign: "center" }} variant='h6'>Edit Details</Typography>
                                        <Typography>
                                            Enter Campaign Description (optional)
                                        </Typography>
                                        <TextField
                                            style={{ width: "100%", margin: "0px 0px 15px 0px" }}
                                            required
                                            placeholder="Campaign Description"
                                            value={campaignDescription}
                                            onChange={handleCampaignDescription}
                                        // error={displayCampaignError}
                                        // helperText={displayCampaignError ? "Please enter campaign name" : ""}
                                        />


                                        <Typography>
                                            Enter Entity Description (optional)
                                        </Typography>
                                        <TextField
                                            style={{ width: "100%", margin: "0px 0px 15px 0px" }}
                                            required
                                            placeholder="Entity Description"
                                            value={entityDescription}
                                            onChange={handleEntityDescription}
                                        // error={displayCampaignError}
                                        // helperText={displayCampaignError ? "Please enter campaign name" : ""}
                                        />


                                        <Typography>
                                            Enter Entity Perception (optional)
                                        </Typography>
                                        <TextField
                                            style={{ width: "100%", margin: "0px 0px 15px 0px" }}
                                            required
                                            placeholder="Entity Perception"
                                            value={entityPerception}
                                            onChange={handleEntityPerception}
                                        />

                                        <Box sx={{ display: "flex", alignItems: "center" }}>

                                            <Typography>
                                                BLI Enabled:
                                            </Typography>
                                            <Checkbox
                                                icon={<ClearIcon sx={{ color: "red", border: "1px solid black" }} />}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: (isBliActive ? green[600] : red[600]),
                                                    },
                                                }} disabled={isBliActive ? true : false} onClick={() => setIsBliActive(true)} checked={isBliActive} size='medium' />
                                        </Box>

                                        <Typography>
                                            Change Campaign Name
                                        </Typography>
                                        <TextField
                                            style={{ width: "100%", margin: "0px 0px 15px 0px" }}
                                            required
                                            placeholder="Campaign Name"
                                            value={newCampaignName}
                                            onChange={handleNewCampaignName}
                                        />

                                        <Box sx={{ display: "flex", gap: '10px', alignItems: "center", marginBottom: "10px" }}>

                                            <Typography>
                                                Change Campaign Status
                                            </Typography>

                                            <React.Fragment>
                                                <ButtonGroup
                                                    variant="contained"
                                                    ref={anchorRef}
                                                >
                                                    <Button onClick={handleClick}>{options[selectedIndex]}</Button>
                                                    <Button
                                                        size="small"
                                                        onClick={handleToggle}
                                                    >
                                                        <ArrowDropDownIcon />
                                                    </Button>
                                                </ButtonGroup>
                                                <Popper
                                                    sx={{
                                                        zIndex: 1,
                                                    }}
                                                    open={open}
                                                    anchorEl={anchorRef.current}
                                                    role={undefined}
                                                    transition
                                                    disablePortal
                                                >
                                                    {({ TransitionProps, placement }) => (
                                                        <Grow
                                                            {...TransitionProps}
                                                        >
                                                            <Paper>
                                                                <ClickAwayListener onClickAway={handleClose}>
                                                                    <MenuList autoFocusItem>
                                                                        {options.map((option, index) => (
                                                                            <MenuItem
                                                                                key={option}
                                                                                selected={index === selectedIndex}
                                                                                onClick={(event) => handleMenuItemClick(event, index)}
                                                                            >
                                                                                {option}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </MenuList>
                                                                </ClickAwayListener>
                                                            </Paper>
                                                        </Grow>
                                                    )}
                                                </Popper>
                                            </React.Fragment>

                                        </Box>

                                        <Box>
                                            {/* <Button variant="contained" onClick={() => uploadCsv()}>Upload Calendar CSV</Button> */}
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Typography>Calendar Uploaded:</Typography>
                                                <Checkbox
                                                    icon={<ClearIcon sx={{ color: "red", border: "1px solid black" }} />}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: (isCalendarUploaded ? green[600] : red[600]),
                                                        },
                                                    }} disabled={true} checked={isCalendarUploaded} size='medium' />
                                                {calendarUploadDate != '' && <Typography>(Last Uploaded On: {calendarUploadDate})</Typography>}
                                            </Box>
                                            <Typography>Upload Calendar CSV</Typography>
                                            <Box sx={{ display: "flex", flexDirection: "column", marginTop: "5px" }}>
                                                {/* Upload your content image/video */}
                                                <Paper elevation={3} {...getRootProps()} style={dropzoneStyles}>
                                                    <input {...getInputProps()} />
                                                    {uploadProgress == 0 && <Typography component="div">
                                                        Drag or Drop your CSV
                                                    </Typography>}
                                                    {uploadProgress > 0 &&
                                                        <Typography>
                                                            File Uploaded: {uploadProgress}%
                                                        </Typography>}
                                                </Paper>
                                                <Typography variant="body1" style={{ marginTop: '10px' }}>
                                                    Uploaded File: {uploadedFileName}
                                                </Typography>
                                                <Box style={{ color: "#000", position: "relative" }}>
                                                    <LinearProgress
                                                        color='inherit'
                                                        variant="determinate"
                                                        value={uploadProgress}
                                                        style={{ marginTop: '5px', width: '100%', marginBottom: "10px" }}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>


                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <ThemeProvider theme={theme}>
                                                <Button onClick={() => saveEditCampaignDetails()} variant="contained" sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black" }} disabled={isEditButtonLoading} endIcon={isEditButtonLoading && <CircularProgress sx={{ color: "black" }} size={20} />}>{editButtonText}</Button>
                                                {/* <Button variant="contained" sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black" }} onClick={() => saveEditCampaignDetails()}>Save</Button> */}
                                            </ThemeProvider>
                                        </Box>
                                    </Box>}
                                </Box>
                            </Modal>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>


            <Grid container style={{ justifyContent: "center", marginBottom: "20px", height: "80px" }}>
                <Card sx={{ width: "85%", border: "2px solid #DCDCDC", display: "flex", alignItems: "center", display: "flex", borderRadius: "15px", boxShadow: "none" }}>
                    <Grid container columns={12} style={{ display: "flex", textAlign: "center", padding: "5px" }}>
                        <Grid item xs={3}>
                            <Link to={`/tracking/${campaignData?.campaignId}/content`} state={propsData}>
                                <Button startIcon={<OndemandVideoOutlinedIcon />} onClick={() => handleTabChange(1)} style={{ color: "black", background: contentButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Content</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to={`/tracking/${campaignData?.campaignId}/influencers`} state={propsData}>
                                <Button startIcon={<PeopleOutlineOutlinedIcon />} onClick={() => handleTabChange(0)} style={{ color: "black", background: influencerButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Meme Pages</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to={`/tracking/${campaignData?.campaignId}/analytics`} state={propsData}>
                                <Button startIcon={<InsertChartOutlinedRoundedIcon />} onClick={() => handleTabChange(2)} style={{ color: "black", background: analyticsButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Reporting</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to={`/tracking/${campaignData?.campaignId}/bli`} state={propsData}>
                                <Button startIcon={<SpeedIcon />} onClick={() => handleTabChange(3)} style={{ color: "black", background: bliButton, width: "99%", padding: "20px", borderRadius: "15px", }}>BLI<span style={{ verticalAlign: "super", fontSize: "90%" }}>®</span></Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Outlet />
        </div>
    )
}

export default Tracking