import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Avatar, Divider, Grid, InputBase, ListItemText, Menu, MenuItem, Paper, SvgIcon } from '@mui/material';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import { Link, useLocation } from 'react-router-dom';

import { signOut } from "firebase/auth";
import auth from '../../api/firebase';
import { useEffect } from 'react';
import { useState } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import MemechatLogo from "../../assets/memechatLogo4.png"

import { styled, alpha } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import '../../sass/algoliaStyles.css'
import Tracking from './trackingShare/Tracking';
import ShareTrackingInfluencers from './trackingShare/ShareTrackingInfluencers';
import ShareTrackingContent from './trackingShare/ShareTrackingContent';
import ShareTrackingAnalytics from './trackingShare/ShareTrackingAnalytics';
import ShareTrackingBli from './trackingShare/ShareTrackingBli';


const navItems = ['Discover', 'Lists', 'Campaigns', "Dashboard"];
const pageIcons = [<SearchOutlinedIcon />, <ReceiptLongOutlinedIcon />, <CampaignOutlinedIcon />, <SettingsIcon />];

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 315,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

function MemechatTopbarShare(props) {

    const source = props.source;

    const navigate = useNavigate();
    const location = useLocation();

    const trackingId = location.pathname.split("/")[1]


    const [user, setUser] = useState("");
    const [isUserFetched, setIsUserFetched] = useState(false);
    const [showPage, setShowPage] = useState(false);
    const [firstName, setFirstName] = useState("")


    const [showDashboard, setShowDashboard] = useState(true);
    const [showTracking, setShowTracking] = useState(false);
    const [brandHandle, setBrandHandle] = useState("");

    // async function getDashboardAccess() {
    //     if (user != "" && user != null) {
    //         // const token2 = await user.getIdToken();
    //         let options = {
    //             method: 'GET',
    //             headers: new Headers({
    //                 // 'Authorization': `Bearer ${token2}`,
    //                 'Content-Type': "application/json"
    //             })
    //         }
    //         // await fetch(process.env.REACT_APP_API_ADDRESS + '/api/check_dashboard_access', options)
    //         //     .then(result => result.json())
    //         //     .then(body => { setShowDashboard(body["showDashboard"]); setShowTracking(body["showTracking"]); setBrandHandle(body["instaHandle"]) });

    //         console.log("dashboard access")
    //         setShowTracking(true);
    //     }
    // }

    const [campaignData, setCampaignData] = useState({})

    async function fetchAllCampaigns(userObj) {

        // setIsLoading(true)
        // console.log("USER IN FETCH CAMPAIGNS")
        // console.log(userObj)
        const token2 = await userObj.getIdToken()

        let data = {
            showTrackingCampaigns: true
        }

        let campaignApiOptions = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_all_campaigns', campaignApiOptions)
            .then(result => result.json())
            .then(body => {
                // console.log("body"); console.log(body);
                let extractedCampaign;

                for (let key in body) {
                    const found = body[key].find(campaign => campaign.campaignId === trackingId);
                    if (found) {
                        extractedCampaign = found;
                        break;
                    }
                } setCampaignData(extractedCampaign);
            });
    }

    useEffect(() => {
        // const trackingId = "c7dedc10-8be2-43d0-84f7-15f268789349"
        // const campaignId = location.pathname.split("/")[1]

        // console.log("Inside topbar use effect")
        // console.log(trackingId)

        if (user == null && isUserFetched) {
            // console.log("no user found")
            setShowPage(true)
            // const trackingId = "c7dedc10-8be2-43d0-84f7-15f268789349"
            navigate(`/${trackingId}`)
        } else if (isUserFetched == false) {
            var localStorageTrackingId = JSON.parse(window.localStorage.getItem('campaignIds'));
            // console.log(localStorageTrackingId)
            // console.log(localStorageTrackingId[campaignId])

            onAuthStateChanged(auth, async (user) => {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                setIsUserFetched(true)
                setUser(user)
                await fetchAllCampaigns(user);
                if (user) {
                    // console.log("user found")
                    if (localStorageTrackingId == null || !(trackingId in localStorageTrackingId)) {
                        setShowPage(true)
                        // console.log("campaign opening for the first time or new campaign not found in local storage")
                        navigate(`/${trackingId}`)
                    }
                    else if (localStorageTrackingId[trackingId] == 1) {
                        // console.log("found campaign in local storage")
                        setShowPage(true)
                        // navigate(`/${trackingId}/share/influencers`)
                    } else {
                        // console.log("unable to navigate")
                        navigate(`/${trackingId}`)
                    }
                }
                else {
                    // console.log('no user found');
                    navigate(`/${trackingId}`)
                }
            });
        }
    }, [user])



    const tabStyles = {
        // border: "1px solid white",
        backgroundColor: "#F8C036",
        color: "black",
        borderRadius: "10px",
        marginTop: "5px",
        padding: "5px 10px 5px 10px",
    }

    const tabStyles2 = {
        borderRadius: "10px",
        marginTop: "5px",
        padding: "5px 10px 5px 10px",
    }


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);



    // getDashboardAccess();

    var displayToolbar = ((source == "share/influencers" || source == "share/content" || source == "share/analytics" || source == "share/bli") ? "none" : "block");

    function gotoHomePage() {
        navigate(`/${trackingId}`)
    }


    if (showPage == false) {
        return (
            <Box>

            </Box>
        );
    }
    else if (source != undefined) {

        return (
            <Box >
                <AppBar component="nav" style={{ backgroundColor: "#232323", height: "80px", display: "flex", justifyContent: "center" }}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 0.6, display: { xs: 'none', sm: 'block' } }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    height: "80px",
                                    width: "180px",
                                    cursor: "pointer"
                                }}
                                onClick={() => gotoHomePage()}
                                alt="Logo"
                                src={MemechatLogo}
                            // <img width="240" height="120" src={MemechatLogo} alt='' />
                            />
                        </Box>
                    </Toolbar >
                </AppBar >
                <Box sx={{ p: 1, display: displayToolbar }}>
                    <Toolbar />
                </Box>
                {/* {source == "shareHome" && <ShareTrackingHome userData={user} />} */}
                {source == "share" && <Tracking userData={user} campaignData={campaignData} />}
                {source == "share/influencers" && <ShareTrackingInfluencers userData={user} campaignName={campaignData.campaignName} campaignId={trackingId} />}
                {source == "share/content" && <ShareTrackingContent userData={user} campaignId={trackingId} />}
                {source == "share/analytics" && <ShareTrackingAnalytics userData={user} campaignId={trackingId} />}
                {source == "share/bli" && <ShareTrackingBli userData={user} campaignData={campaignData} />}
            </Box >
        );
    }
}

export default MemechatTopbarShare;