import React, { useEffect, useState } from 'react'
import StarIcon from '@mui/icons-material/Star';
import InstagramLogo from "../../assets/instagramLogo.png"
import InstaBackground from "../../assets/instaBackground.jpg"
// import EmprallLogo from "../../assets/emprallLogo.png"
import PeeperlyLogo from "../../assets/peeperlyLogo.png"
import PeeperlyLogoBlackBg from "../../assets/peeperlyLogoBlackBg2.png"
import Checkmark from "../../assets/checkmark.png"
import { Avatar, Box, Button, CircularProgress, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom';

function PeeperlyLeaderboard() {

    // const shop = "emprallofficial";

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [leaderboard, setLeaderboard] = useState([])
    const [monthName, setMonthName] = useState("")

    const location = useLocation();
    const campaignId = location.pathname.split("/")[2];

    // console.log("campaignId")
    // console.log(campaignId)


    function getCurrentMonthDates() {

        // Get the current date
        // new Date().toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })
        const today = new Date();
        // Get the current month (0-indexed)
        const month = today.getMonth();
        // Get the year
        const year = today.getFullYear();

        // Get the start date (1st of the month)
        const startDate = new Date(year, month, 1);
        const istOffset = today.getTimezoneOffset();
        startDate.setMinutes(startDate.getMinutes() - istOffset);

        // Format the start date string
        const startDateStr = startDate.toISOString().slice(0, 19).replace('T', ' ');

        // Get the end date (current date)
        const endDate = today;
        endDate.setMinutes(endDate.getMinutes() - istOffset);

        // Format the end date string
        const endDateStr = endDate.toISOString().slice(0, 19).replace('T', ' ');;

        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        setMonthName(months[month])

        return [startDateStr, endDateStr]
    }


    async function getInfluencersData() {

        // console.log("submit form called")
        setIsLoading(true)

        var startStr = "";
        var endStr = "";
        [startStr, endStr] = getCurrentMonthDates()

        let data = {
            startDate: startStr,
            endDate: endStr,
            brandId: "1033"
        }

        // console.log("data")
        // console.log(data)


        // Mixpanel.identify(shop);
        // Mixpanel.track(shop + ' Form Submitted', { data });

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_leaderboard_influencers_by_date', options)
            .then(result => result.json())
            .then(body => {
                // console.log("body");
                // console.log(body);
                if (body != "Error") {
                    setLeaderboard(body);
                    // console.log(body)
                }
                else {
                    setLeaderboard([])
                }
                setIsLoading(false)
            });
    }


    useEffect(() => {
        getInfluencersData();
    }, [])


    // const influencerRows = [
    //     { "rank": 2, "influencer": "peeperlyindia", "points": 1234, "contentPosted": { "story": 3, "reel": 2, "post": 1 } },
    //     { "rank": 2, "influencer": "peeperlyindia", "points": 1234, "contentPosted": { "story": 3, "reel": 2, "post": 1 } },
    //     { "rank": 2, "influencer": "peeperlyindia", "points": 1234, "contentPosted": { "story": 3, "reel": 2, "post": 1 } },
    //     { "rank": 2, "influencer": "peeperlyindia", "points": 1234, "contentPosted": { "story": 3, "reel": 2, "post": 1 } },
    //     { "rank": 2, "influencer": "peeperlyindia", "points": 1234, "contentPosted": { "story": 3, "reel": 2, "post": 1 } },
    //     { "rank": 2, "influencer": "peeperlyindia", "points": 1234, "contentPosted": { "story": 3, "reel": 2, "post": 1 } },
    //     { "rank": 2, "influencer": "peeperlyindia", "points": 1234, "contentPosted": { "story": 3, "reel": 2, "post": 1 } },
    // ]


    if (isFormSubmitted) {
        return (
            <Box style={{ background: "white", overflow: "auto", height: "100%", position: "fixed", width: "100%" }}>
                <Box sx={{ background: "#232323", height: "70px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px 20px 0px 10px" }}>
                    <img
                        src={PeeperlyLogoBlackBg}
                        width="150px"
                        height="30px"
                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "auto", marginBottom: "20px", padding: "15px", borderRadius: "10px", gap: "10px" }}>
                    <Typography variant="h4" sx={{ color: "black", textAlign: "center", fontWeight: "900" }}>Peeperly Influencer Leaderboard ({monthName})</Typography>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "30px", width: "100%" }}>
                        <img
                            src={Checkmark}
                            width="280px"
                            height="220px"
                        />
                        <Box sx={{ height: "auto", width: "90%", background: "black", padding: "10px", marginTop: "50px", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                            <Typography sx={{ color: "white", textAlign: "center" }}>Form submitted successfully</Typography>
                            <Typography sx={{ color: "white", textAlign: "center" }}>We will contact you shortly</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    } else {
        if (isLoading) {
            return (
                <Box style={{ background: "white", overflow: "auto", height: "100%", position: "fixed", width: "100%" }}>
                    <Box sx={{ background: "black", height: "70px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px 20px 0px 10px", border: "1px solid white" }}>
                        <img
                            src={PeeperlyLogoBlackBg}
                            width="110px"
                            height="90px"
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
                        <CircularProgress style={{ color: "black" }} size={60} />
                    </Box>
                </Box>
            )
        }
        else {
            return (
                <Box style={{ background: "white", overflow: "auto", height: "100%", position: "fixed", width: "100%" }}>
                    <Box sx={{ background: "black", height: "70px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px 20px 0px 10px", border: "1px solid white" }}>
                        <img
                            src={PeeperlyLogoBlackBg}
                            width="110px"
                            height="90px"
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", height: "auto" }}>
                        <Box style={{ padding: "20px", height: "auto", borderRadius: "25px", zIndex: 99, display: "flex", flexDirection: "column", width: "70%" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "auto", marginBottom: "20px", padding: "15px", borderRadius: "10px" }}>
                                <Typography variant="h4" sx={{ color: "black", textAlign: "center", fontWeight: "900" }}>Peeperly Influencer Leaderboard ({monthName})</Typography>
                                <Typography sx={{ color: "black", textAlign: "center", marginTop: "10px" }}>Points calculation : (Reel View: 1 Point, Likes: 2 Points, Comments: 5 Points, Shares: 10 Points, Saves: 10 Points)</Typography>
                            </Box>

                            <Box sx={{ height: "fit-content", borderRadius: "10px" }}>
                                <Grid container>
                                    <Grid item xs={2} sx={{ textAlign: "center" }}>
                                        <Typography sx={{ fontWeight: "900" }}>RANK</Typography>
                                    </Grid>
                                    <Grid item xs={4} sx={{ textAlign: "center" }}>
                                        <Typography sx={{ fontWeight: "900" }}>USERNAME</Typography>
                                    </Grid>
                                    <Grid item xs={2} sx={{ textAlign: "center" }}>
                                        <Typography sx={{ fontWeight: "900" }}>POINTS</Typography>
                                    </Grid>
                                    <Grid item xs={4} sx={{ textAlign: "center" }}>
                                        <Typography sx={{ fontWeight: "900" }}>TOTAL CONTENT POSTED</Typography>
                                    </Grid>
                                </Grid>
                                {leaderboard.map((value, index) => (
                                    // sx={{ display: "flex", alignItems: "center", marginBottom: "30px" }}
                                    <Box key={index}>
                                        <Grid container sx={{ display: "flex", alignItems: "center", marginBottom: "20px", marginTop: "20px" }}>
                                            <Grid item xs={2} sx={{ textAlign: "center" }}>
                                                <Typography>{value.rank}</Typography>
                                            </Grid>
                                            <Grid item xs={4} sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                                                <Avatar src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + value.influencer + ".jpg?alt=media"} />
                                                <Typography>{value.influencer}</Typography>
                                            </Grid>
                                            <Grid item xs={2} sx={{ textAlign: "center" }}>
                                                <Typography>{value.points}</Typography>
                                            </Grid>
                                            <Grid item xs={4} sx={{ textAlign: "center" }}>
                                                <Typography>{value.totalContent}</Typography>
                                                {/* <Box sx={{ display: "flex", gap: "15px", justifyContent: "center" }}>
                                                    <Typography>Story: {value.contentDelivered?.story}</Typography>
                                                    <Typography>Post: {value.contentDelivered?.post}</Typography>
                                                    <Typography>Reel: {value.contentDelivered?.reel}</Typography>
                                                </Box> */}
                                            </Grid>
                                        </Grid>
                                        <Divider sx={{ width: "100%" }} />
                                    </Box>
                                ))}
                            </Box>
                            {/* {isLoading && <Button sx={{ background: "black", color: "white", borderRadius: "5px", marginTop: "5px" }}><CircularProgress style={{ color: "white", }} size={25} /></Button>}
                            {!isLoading && <Button onClick={() => handleSubmitForm()} sx={{ background: "black", color: "white", borderRadius: "5px", marginTop: "5px" }}>SUBMIT FORM</Button>} */}
                        </Box>
                    </Box>
                </Box >
            )
        }
    }
}

export default PeeperlyLeaderboard