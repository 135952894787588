import React, { useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Avatar, Chip, Divider, Grid, List, ListItem, ListItemText, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import closerLogo from "../../assets/closerLogo.png"
import CustomizedTabs from './CustomizedTabs';
import CircularProgress from '@mui/material/CircularProgress';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import DisplayLists from '../DisplayLists';
import { useEffect } from 'react';
import VideoCard from './VideoCard';
import clsx from 'clsx';
import { Mixpanel } from '../../mixpanel/mixpanel.js';


const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: "20vh",
    boxShadow: "none"
}));

const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
};

const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'MuiBackdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});

const StyledBackdrop = styled(Backdrop)`
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: rgb(0 0 0 / 0.5);
    -webkit-tap-highlight-color: transparent;
`;

const StyledModal = styled(Modal)`
    position: fixed;
    z-index: 1300;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

function MainCard(props) {

    var handleCloseProp = props.handleClose;
    var influencer = props.influencer;
    var userData = props.userData;

    // const navigate = useNavigate();
    // onAuthStateChanged(auth, (user) => {
    //     if (user) {
    //         // User is signed in, see docs for a list of available properties
    //         // https://firebase.google.com/docs/reference/js/auth.user
    //         const uid = user.uid;
    //         // console.log("USER INSIDE MAIN CARD")
    //         // console.log(user)
    //         // setToken(user.accessToken)
    //     } else {
    //         console.log("no user found")
    //         navigate("/login")
    //     }
    // });


    const [openList, setOpenList] = useState(false);
    const handleOpenList = () => setOpenList(true);
    const handleCloseList = () => setOpenList(false);


    let contentApiPayload = {
        username: influencer.username,
        collabBrands: influencer.collabBrands,
        page: 3
    }

    const [sponsored, setSponsored] = useState([]);
    const [nonSponsored, setNonSponsored] = useState([]);
    const [stats, setStats] = useState({});
    const [sponsoredStats, setSponsoredStats] = useState({});
    const [organicStats, setOrganicStats] = useState({});
    const [highlightsBrands, setHighlightsBrands] = useState([]);
    const [highlightsContent, setHighlightsContent] = useState([]);

    async function fetchContent() {

        const token2 = await userData.getIdToken()

        let contentApiOptions = {
            method: 'POST',
            body: JSON.stringify(contentApiPayload),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_influencer_content/', contentApiOptions)
            .then(result => result.json())
            .then(body => { createContentArrays(body) });
    };

    function createContentArrays(content) {
        setIsLoading(true);
        let sponsoredContent = []
        let nonSponsoredContent = []
        var hBrands = []

        var highlights = content["highlights"]
        var influencerContent = content["influencerContent"]
        var statsData = content["stats"]
        var sponsoredStatsBar = content["sponsoredStats"]
        var organicStatsBar = content["organicStats"]

        // setting sponsored, organic content
        for (const [key, value] of Object.entries(influencerContent)) {
            if (value["source"] != 2) {
                let tempDict = value
                tempDict["shortcode"] = key
                sponsoredContent.push(tempDict)
            }
            else {
                let tempDict = value
                tempDict["shortcode"] = key
                nonSponsoredContent.push(tempDict)
            }
        }

        // setting highlightsBrands
        for (const [key, value] of Object.entries(highlights)) {
            hBrands.push(key);
        }

        setSponsored(sponsoredContent)
        setNonSponsored(nonSponsoredContent)
        setHighlightsBrands(hBrands)
        setHighlightsContent(highlights)
        setStats(statsData)
        setSponsoredStats(sponsoredStatsBar)
        setOrganicStats(organicStatsBar)
        setIsLoading(false)
    }

    useEffect(() => {
        fetchContent()
    }, []);

    const [isLoading, setIsLoading] = useState(true)


    const [isVideoCardOpen, setIsVideoCardOpen] = useState(false);
    const [videoData, setVideoData] = useState({})
    function handleOpenContent(value) {
        setIsVideoCardOpen(true);
        setOpen(true);
        setVideoData(highlightsContent[value])

    }

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    function formatNumber(number) {
        if (number >= 1e6) {
            return (number / 1e6).toFixed(2) + "M";
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(2) + "K";
        } else {
            return number.toFixed(2);
        }
    }

    function openInstagram(influencerUsername) {
        Mixpanel.identify(userData.email);
        Mixpanel.track('Instagram Redirected', { influencerUsername });
        openInNewTab('https://www.instagram.com/' + influencerUsername)
    }

    const containerRef = useRef(null);
    const [scrollAmount, setScrollAmount] = useState(0);


    // const handleScroll = (scrollOffset) => {
    //     if (containerRef.current) {
    //         const newScrollAmount = scrollAmount + scrollOffset;
    //         containerRef.current.scrollLeft = newScrollAmount;
    //         setScrollAmount(newScrollAmount);
    //     }
    // };

    const handleScroll = (scrollOffset) => {
        if (containerRef.current) {
            const container = containerRef.current;
            container.scrollBy({ left: scrollOffset, behavior: 'smooth' });
            setScrollAmount(scrollAmount + scrollOffset);
        }
    };

    return (
        <Box sx={{ height: "100vh", overflow: 'auto' }}>
            <CloseOutlinedIcon style={{ cursor: "pointer" }} onClick={handleCloseProp} />
            <Grid container>
                <Grid item xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Avatar
                        src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + `${influencer.username}` + ".jpg?alt=media"}
                        sx={{ width: "180px", height: "180px", border: "1px solid black" }}
                    />
                </Grid>
                <Grid item xs={8} style={{}}>
                    <Item style={{ textAlign: "left" }}>
                        <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }} >
                            <Grid item xs={12} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <Typography variant="h5" fontWeight={900}>@{influencer.username}</Typography>
                                <Button style={{ color: "black", background: "#E4F87F", borderRadius: "10px", padding: "5px 15px 5px 15px", width: "fit-content" }} onClick={handleOpenList}>+ Add to List</Button>
                                <Modal
                                    open={openList}
                                    onClose={handleCloseList}
                                >
                                    <Box>
                                        <DisplayLists influencer={influencer} handleClose={handleCloseList} userData={userData} />
                                    </Box>
                                </Modal>
                                {/* openInNewTab('https://www.instagram.com/' + `${influencer.username}`) */}
                                <Button endIcon={<CallMadeOutlinedIcon />} onClick={() => openInstagram(`${influencer.username}`)} style={{ color: "black", background: "#F0F0F0", borderRadius: "10px", padding: "5px 15px 5px 15px", width: "fit-content" }}>View on Instagram </Button>
                            </Grid>

                            <Grid item xs={3} style={{ gap: "5px", display: "flex" }}>
                                <span style={{ fontWeight: "bold" }}>{formatNumber(influencer.followers)}</span>
                                <div>followers</div>
                            </Grid>
                            <Grid item xs={3} style={{ gap: "5px", display: "flex" }}>
                                <span style={{ fontWeight: "bold" }}>{formatNumber(influencer.avgEngagement)}</span>
                                <span>% engagement</span>
                            </Grid>
                            <Grid item xs={3} style={{ gap: "5px", display: "flex" }}>
                                <span style={{ fontWeight: "bold" }}>{formatNumber(influencer.avgViews)}</span>
                                <span>reel views</span>
                            </Grid>
                            <Grid item xs={3} style={{}}> </Grid>

                            <Grid item style={{}}>{influencer.website}</Grid>
                            <Grid item style={{ display: "flex", alignItems: "center", width: "auto" }}>{influencer.state && <FmdGoodOutlinedIcon />} {influencer.state}</Grid>
                        </Grid>
                        <Grid item xs={12} style={{ display: "flex", marginTop: "15px", gap: "10px" }}>
                            {influencer["category"].map((value, index) => (
                                <Grid item key={index} style={{ width: "auto" }}><Chip label={value.charAt(0).toUpperCase() + value.slice(1)} /></Grid>
                            ))}
                        </Grid>
                    </Item>
                </Grid>
            </Grid>
            <Box sx={{ display: "flex" }}>
                {highlightsBrands.length > 0 && <Button onClick={() => handleScroll(-450)} ><NavigateBeforeOutlinedIcon sx={{ color: "black" }} /></Button>}
                <Grid container ref={containerRef} marginTop={2} marginBottom={4} style={{ width: "100%", flexWrap: "nowrap", overflowX: "hidden" }}>
                    {highlightsBrands.map((value, index) => (
                        <Grid item key={index} style={{ minWidth: "150px", width: "150px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                            <Avatar sx={{ width: "70px", height: "70px", cursor: "pointer", border: "1px solid black" }} src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/brands%2F" + value + ".jpg?alt=media"} onClick={() => handleOpenContent(value)} />
                            <Typography style={{ fontSize: "15px" }}>{value}</Typography>
                        </Grid>
                    ))}
                    {isVideoCardOpen && <StyledModal
                        open={open}
                        onClose={handleClose}
                        slots={{ backdrop: StyledBackdrop }}
                    >
                        <VideoCard videoData={videoData} />
                    </StyledModal>}
                </Grid>
                {highlightsBrands.length > 0 && <Button sx={{ color: "black" }} onClick={() => handleScroll(450)}><NavigateNextOutlinedIcon /></Button>}
            </Box>
            <div style={{ display: "flex", justifyContent: "center", }}>
                <Divider style={{ width: "85%" }} />
            </div>

            {isLoading && <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80px" }}><CircularProgress size={60} /></Box>}
            {!isLoading && <CustomizedTabs stats={stats} sponsored={sponsored} nonSponsored={nonSponsored} sponsoredStatsBar={sponsoredStats} organicStatsBar={organicStats} />}
        </Box>
    );
}

export default MainCard