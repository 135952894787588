import React, { useState } from 'react'
import DashboardAnalytics from './DashboardAnalytics';
import { Box, Button, ButtonGroup } from '@mui/material'
import TrackingHome from './tracking/TrackingHome';

function Dashboard(props) {
    const userData = props.userData;


    const [displayDashboard, setDisplayDashboard] = useState(false);
    function handleDisplayDashboard(bool) {
        setDisplayDashboard(bool);
    };

    return (
        // sx={{ padding: "10px" }}
        <Box >
            {/* <ButtonGroup variant="text" sx={{ color: "black" }} >
                <Button sx={{ background: displayDashboard ? "#E4F87F" : "", color: "black" }} onClick={() => handleDisplayDashboard(true)}>Dashboard</Button>
                <Button sx={{ background: displayDashboard ? "" : "#E4F87F", color: "black" }} onClick={() => handleDisplayDashboard(false)}>Campaigns</Button>
            </ButtonGroup> */}

            {/* {displayDashboard && <DashboardAnalytics userData={userData} />} */}
            {/* {!displayDashboard && <TrackingHome userData={userData} />} */}
            <TrackingHome userData={userData} />
        </Box>
    )
}

export default Dashboard