import React, { useState } from 'react'
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Configure } from 'react-instantsearch';
import { Avatar, Box, Button, Divider, Grid, Modal, Slide } from '@mui/material';
import SearchListMainCard from './SearchListMainCard';
import { Mixpanel } from '../mixpanel/mixpanel.js';

const algoliaClient = algoliasearch('IOLUAD7WPQ', '67aa7dd759e99d78f9e776cf7df51e38');

const searchClient = {
    ...algoliaClient,
    search(requests) {
        if (requests.every(({ params }) => !params.query)) {
            return Promise.resolve({
                results: requests.map(() => ({
                    hits: [],
                    nbHits: 0,
                    nbPages: 0,
                    page: 0,
                    processingTimeMS: 0,
                    hitsPerPage: 0,
                    exhaustiveNbHits: false,
                    query: '',
                    params: '',
                })),
            });
        }

        return algoliaClient.search(requests);
    },
};

const style = {
    position: 'absolute',
    left: '20%',
    // transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "100%",
    bgcolor: 'background.paper',
    boxShadow: 1,
    p: 1,
    border: "1px solid #C4C4C4"
};


function AlgoliaSearchBox(props) {

    const userData = props.userData;

    const [hitUsername, setHitUsername] = useState("")

    function searchCalled(username) {
        // console.log("INFLUENCER USERNAME");
        // console.log(username);
        Mixpanel.identify(userData.email);
        Mixpanel.track('Influencer Searched', { username });
        setHitUsername(username);
        setOpen(true);
    }

    function hitFunction(hit) {
        const influencerPhoto = "https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + hit.influencer + ".jpg?alt=media"

        const influencerUsername = hit.influencer;

        return (
            <div>
                {/* <Button onClick={handleOpen} variant="contained" style={{ borderRadius: "13px", backgroundColor: "white", color: 'black', boxShadow: "none", border: "1px solid #C4C4C4" }} startIcon={<RemoveRedEyeOutlinedIcon />}>
                View Full Profile
            </Button> */}
                < Grid container sx={{ boxShadow: 24, width: "88%", cursor: "pointer" }} onClick={() => searchCalled(influencerUsername)}>
                    <Grid container style={{ backgroundColor: "white", gap: "10px", padding: "10px 0px 10px 10px" }}>
                        <Grid item >
                            <Avatar src={influencerPhoto} />
                        </Grid>
                        <Grid item xs style={{ color: "black" }}>
                            <Grid item xs={4}>@{hit.influencer}</Grid>
                            <Grid item xs={12} style={{ marginTop: "5px" }}>{hit.fullName}</Grid>
                        </Grid>
                    </Grid>
                </Grid >
                <Divider style={{ color: "#797979", width: "80%" }} />
            </div>
        );
    }

    // const handleInfluencerSearch = (query, search) => {
    //     console.log("QUERY")
    //     console.log(query)
    //     console.log(search)

    //     if (query != 0) {
    //         search(query)
    //     }
    // }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    return (
        <div style={{ height: "40px" }}>
            <InstantSearch searchClient={searchClient} indexName="closerDiscoveryIndex">
                <SearchBox placeholder='Search Influencer' />
                <Configure hitsPerPage={7} />
                < Hits hitComponent={({ hit }) => hitFunction(hit)} />
            </InstantSearch>
            <Modal
                BackdropProps={{ style: { backgroundColor: 'rgb(0,0,0,0.1)' } }}
                open={open}
                onClose={handleClose}
            >
                <Slide direction="left" in={open} mountOnEnter unmountOnExit timeout={300}>
                    <Box sx={style}>
                        <SearchListMainCard userData={userData} influencerUsername={hitUsername} handleClose={handleClose} />
                    </Box>
                </Slide>
            </Modal>
        </div>
    )
}

export default AlgoliaSearchBox