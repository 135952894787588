import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import CampaignVideoCard from './CampaignVideoCard';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CampaignContentNoResults from "../../assets/campaignContentNoResults.png";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { Avatar, Card, CardMedia, Grid, Typography, Box, CircularProgress, Modal, TextField, Button, Slide, Radio, FormLabel, FormControl, Pagination, Divider, InputLabel, ButtonGroup, LinearProgress, FormControlLabel, RadioGroup, Checkbox } from '@mui/material'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import "../memechat/main.css";


const StyledModal = styled(Modal)`
position: fixed;
z-index: 1300;
inset: 0;
display: flex;
align-items: center;
justify-content: center;
`;

const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'MuiBackdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});

Backdrop.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
};

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: "50%",
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: "10px",
    p: 3,
};

const style2 = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: "50%",
    height: "60%",
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: "10px",
    p: 3,
};

const buttonStyleSelected = {
    background: "#F8C036",
    color: "black"
}

const buttonStyleNotSelected = {
    background: "white",
    color: "black"
}

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const dropzoneStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: "100%",
    padding: '20px',
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    backgroundColor: '#f5f5f5',
    cursor: 'pointer',
    boxShadow: "none"
};

function CommunityContent(props) {

    // const location = useLocation();
    // const propsData = location.state;

    // const campaignData = propsData.campaignData;
    // console.log("INSIDE LEADERBOARD CONTENT")
    const leaderboardContent = props.leaderboardContent;
    const leaderboardAnalytics = props.leaderboardAnalytics;

    const [isLoading, setIsLoading] = useState(false)
    const [campaignContent, setCampaignContent] = useState(leaderboardContent)
    const [filteredContent, setFilteredContent] = useState(leaderboardContent)
    const [searchInfluencer, setSearchInfluencer] = useState("");

    const [contentLink, setContentLink] = useState("")

    const handleContentLink = event => {
        setContentLink(event.target.value);
    };

    const mediaTypeBg = { "POST": "#5BCEFF", "STORY": "#E69EFF", "REEL": "#E4F87F", "YOUTUBE": "#FF0000" }

    // const showCampaignContent = (campaignContent.length > 0) ? true : false;
    const showCampaignContent = true;

    const [openIndex, setOpenIndex] = useState(-1); // Initialize with -1
    const handleOpen = (index) => {
        setOpenIndex(index); // Set the index of the opened modal
    };
    const handleClose = () => {
        setOpenIndex(-1); // Reset the index when closing the modal
    };


    const [openNewContent, setOpenNewContent] = useState(false);
    const handleOpenNewContent = () => { setOpenNewContent(true); }
    const handleCloseNewContent = () => { setOpenNewContent(false); setAddNewContentText(""); }

    // async function fetchCampaignContent() {
    //     var campaignId = campaignData.campaignId;
    //     if (campaignId != null) {
    //         setIsLoading(true);
    //         const token2 = await userData.getIdToken()

    //         let data = {
    //             campaignId: campaignId
    //         }

    //         let options = {
    //             method: 'POST',
    //             body: JSON.stringify(data),
    //             headers: new Headers({
    //                 'Authorization': `Bearer ${token2}`,
    //                 'Content-Type': "application/json"
    //             })
    //         }


    //         await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_campaign_content', options)
    //             .then(result => result.json())
    //             .then(body => { setFilteredContent(body); setCampaignContent(body); setIsLoading(false); });
    //     }
    //     else { console.log("Invalid campaign id") }
    // }

    // useEffect(() => {
    //     fetchCampaignContent()
    // }, [])

    function getMediaComponent(url) {
        const urlWithoutQuery = url.split('?')[0];

        const urlParts = urlWithoutQuery.split('/');
        const lastPart = urlParts[urlParts.length - 1];
        const fileExtension = lastPart.split('.').pop();

        return (fileExtension == "jpg") ? "image" : "video"
    }

    function formatNumber(number) {
        if (number >= 1e6) {
            return (number / 1e6).toFixed(1) + "M";
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(1) + "K";
        } else {
            if (number == 0) {
                return number
            } else {
                return number;
            }
        }
    }


    function getMediaStyle(mediaComponent) {
        const imageStyle = { width: "550px", height: "390px", borderRadius: "20px", cursor: "pointer", borderRadius: "20px" }
        const videoStyle = { width: "550px", height: "390px", background: "black", borderRadius: "20px", cursor: "pointer", borderRadius: "20px" }
        if (mediaComponent == "image") {
            return imageStyle;
        } else {
            return videoStyle;
        }
    }

    const [selectedValue, setSelectedValue] = useState('a');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const [isLoadingAddNewContent, setIsLoadingAddNewContent] = useState(false)
    const [addNewContentText, setAddNewContentText] = useState("")

    // async function addNewContent() {
    //     var campaignId = campaignData.campaignId;
    //     var campaignName = campaignData.campaignName;
    //     // console.log("CAMPAIGN NAME")
    //     // console.log(campaignName)
    //     if (campaignId != null) {
    //         setIsLoadingAddNewContent(true);
    //         const token2 = await userData.getIdToken()

    //         const links = contentLink.split('\n');

    //         let data = {
    //             campaignId: campaignId,
    //             campaignName: campaignName,
    //             contentLinks: links
    //         }

    //         let options = {
    //             method: 'POST',
    //             body: JSON.stringify(data),
    //             headers: new Headers({
    //                 'Authorization': `Bearer ${token2}`,
    //                 'Content-Type': "application/json"
    //             })
    //         }


    //         await fetch(process.env.REACT_APP_API_ADDRESS + '/api/add_new_tracking_content', options)
    //             .then(result => result.json())
    //             .then(body => {
    //                 console.log(body);
    //                 setIsLoadingAddNewContent(false);
    //                 if (body == "Success") {
    //                     setAddNewContentText("Links added successfully");

    //                     setTimeout(() => {
    //                         setAddNewContentText("");
    //                         setOpenNewContent(false);
    //                     }, 2000);
    //                 }
    //                 else {
    //                     setAddNewContentText("Error while adding links. Please try again");
    //                 }

    //             });
    //     }
    //     else { console.log("Invalid campaign id") }

    // }

    function getDateFromTimePosted() {
        const date = new Date(campaignContent[0]["timePosted"] * 1000);

        // Format the date to a readable string
        const dateString = date.toUTCString(); // Converts to a string with a UTC time zone
        // Or, for a local time string
        const localDateString = date.toLocaleString();

        return localDateString;
    }

    const handleSearchInfluencer = (event) => {
        const searchText = event.target.value.toLowerCase();
        setSearchInfluencer(searchText);

        if (searchText === '') {
            // If the search input is empty, reset to the complete list
            setFilteredContent(campaignContent);
            setPage(1);
        } else {
            // Otherwise, filter based on the search input
            const filteredData = campaignContent.filter(item =>
                item.influencer.toLowerCase().includes(searchText)
            );
            setFilteredContent(filteredData);
            setPage(1);
        }
    };

    const [page, setPage] = useState(1);
    const handlePageChange = (event, value) => {
        setPage(value);
    };


    const [openDeletedContent, setOpenDeletedContent] = useState(false);
    const handleOpenDeletedContent = () => { setOpenDeletedContent(true); }
    const handleCloseDeletedContent = () => { setOpenDeletedContent(false); }


    const [displayAddContentViaLinkScreen, setDisplayAddContentViaLinkScreen] = useState(true);
    function handleAddContentLink() {
        setDisplayAddContentViaLinkScreen(true);
    }
    function handleAddContentManually() {
        setDisplayAddContentViaLinkScreen(false);
    }

    const [displayDeletedContent, setDisplayDeletedContent] = useState(true);
    function handleDisplayDeletedContent() {
        setDisplayDeletedContent(true);
    }
    function handleDisplayDuplicateContent() {
        setDisplayDeletedContent(false);
    }

    // const [uploadedFileName, setUploadedFileName] = useState('');
    // const [uploadProgress, setUploadProgress] = useState(0);
    // const [fileToUpload, setFileToUpload] = useState(null);

    // const onDrop = (acceptedFiles) => {
    //     const file = acceptedFiles[0];
    //     setUploadedFileName(file.name);

    //     // Simulate file upload progress (for demonstration purposes)
    //     let progress = 0;
    //     const interval = setInterval(() => {
    //         progress += 20;
    //         setUploadProgress(progress);

    //         if (progress >= 100) {
    //             clearInterval(interval);
    //             // uploadFile(file)
    //             setFileToUpload(file)
    //         }
    //     }, 100);
    // };

    // const { getRootProps, getInputProps } = useDropzone({
    //     accept: ['.jpg', '.png', 'jpeg', '.mp4', '.wav'],
    //     onDrop
    // });

    const [checkedStory, setCheckedStory] = useState(true)
    const [checkedReel, setCheckedReel] = useState(true)
    const [checkedPost, setCheckedPost] = useState(true)

    function handleMediaTypeFilter(mediaType) {
        if (mediaType == "reel") {
            setCheckedReel(!checkedReel)
        }
        else if (mediaType == "post") {
            setCheckedPost(!checkedPost)
        }
        else if (mediaType == "story") {
            setCheckedStory(!checkedStory)
        }
    }

    useEffect(() => {
        if (checkedStory && checkedPost && checkedReel) {
            setFilteredContent(campaignContent);
            setPage(1);
        } else {
            const filteredData = campaignContent.filter(item =>
                (checkedStory && item.mediaType.toLowerCase() == "story") ||
                (checkedReel && item.mediaType.toLowerCase() == "reel") ||
                (checkedPost && item.mediaType.toLowerCase() == "post")
            );
            console.log("filtered data checkbox")
            // console.log(filteredData)
            setFilteredContent(filteredData);
            setPage(1);
        }
    }, [checkedStory, checkedPost, checkedReel])

    // const stats = { "totalContent": 0, "totalViews": 0, "totalLikes": 0, "totalComments": 0, "totalInfluencers": 0, "totalShares": 0, "totalSaves": 0, "totalEngagement": 0 }


    if (showCampaignContent) {
        return (
            <>
                {isLoading && <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress style={{ color: "black", }} size={70} /></Box>}
                {!isLoading &&
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <Card sx={{ width: "70%", border: "2px solid #DCDCDC", textAlign: "center", borderRadius: "25px", boxShadow: "none", padding: "10px 0px 10px 0px", marginBottom: "10px" }}>
                            <Grid container pr={8} pl={8} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Grid item xs={3} fontSize={30}>{formatNumber(leaderboardAnalytics["totalInfluencers"])}</Grid>
                                <Grid item xs={3} fontSize={30}>{formatNumber(leaderboardAnalytics["totalContent"])}</Grid>
                                <Grid item xs={3} fontSize={30}>{formatNumber(leaderboardAnalytics["totalViews"])}</Grid>
                                <Grid item xs={3} fontSize={30}>{formatNumber(leaderboardAnalytics["totalLikes"])}</Grid>
                                {/* <Grid item xs={3} fontSize={30}>123</Grid> */}

                                <div style={{ height: "20px" }}></div>
                                <Grid item xs={3} fontSize={15}>Total Influencers</Grid>
                                <Grid item xs={3} fontSize={15}>Content Generated</Grid>
                                <Grid item xs={3} fontSize={15}>Total Views</Grid>
                                <Grid item xs={3} fontSize={15}>Total Likes</Grid>
                                {/* <Grid item xs={3} fontSize={15}>Cost Incurred</Grid> */}
                            </Grid>
                            <Grid sx={{ display: "flex", justifyContent: "center", padding: "20px 0px 20px 0px" }}>
                                <Divider style={{ width: "90%", display: "flex", justifyContent: "center", alignItems: 'center' }} />
                            </Grid>
                            <Grid container pr={8} pl={8} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Grid item xs={3} fontSize={30}>{formatNumber(leaderboardAnalytics["totalShares"])}</Grid>
                                <Grid item xs={3} fontSize={30}>{formatNumber(leaderboardAnalytics["totalSaves"])}</Grid>
                                <Grid item xs={3} fontSize={30}>{formatNumber(leaderboardAnalytics["totalEngagement"])}</Grid>
                                <Grid item xs={3} fontSize={30}>{formatNumber(leaderboardAnalytics["totalComments"])}</Grid>
                                {/* <Grid item xs={3} fontSize={30}>123</Grid> */}

                                <div style={{ height: "40px" }}></div>
                                <Grid item xs={3} fontSize={15}>Total Shares</Grid>
                                <Grid item xs={3} fontSize={15}>Total Saves</Grid>
                                <Grid item xs={3} fontSize={15}>Total Engagement</Grid>
                                <Grid item xs={3} fontSize={15}>Total Comments</Grid>
                                {/* <Grid item xs={3} fontSize={15}>Link Clicks</Grid> */}
                            </Grid>
                        </Card>
                        <Grid container sx={{ width: "80%", display: "flex", flexDirection: "row", gap: "20px", alignItems: "center", justifyContent: "space-between" }}>
                            <Grid item xs sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <TextField
                                    hiddenLabel
                                    value={searchInfluencer}
                                    onChange={handleSearchInfluencer}
                                    size='small'
                                    fullWidth
                                    placeholder='Search Influencers'
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                <SearchIcon />
                                            </Box>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography sx={{ textAlign: "center" }}>Media Type</Typography>
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <FormControlLabel
                                            value="reel"
                                            control={<Checkbox checked={checkedReel} onClick={() => handleMediaTypeFilter("reel")} defaultChecked size='small' />}
                                            label="Reel"
                                            labelPlacement="top"
                                        />
                                        <FormControlLabel
                                            value="static"
                                            control={<Checkbox checked={checkedPost} onClick={() => handleMediaTypeFilter("post")} defaultChecked size='small' />}
                                            label="Static"
                                            labelPlacement="top"
                                        />
                                        <FormControlLabel
                                            value="story"
                                            control={<Checkbox checked={checkedStory} onClick={() => handleMediaTypeFilter("story")} defaultChecked size='small' />}
                                            label="Story"
                                            labelPlacement="top"
                                        />

                                        {/* <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <Typography>Top</Typography>
                                            <Checkbox size='small' />
                                        </Box> */}
                                    </Box>
                                </Box>
                            </Grid>
                            {/* <Grid item xs sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Pagination count={Math.ceil(filteredContent.length / 20)} page={page} color='standard' shape='rounded' onChange={handlePageChange} />
                            </Grid> */}
                            {/* <Grid item xs></Grid> */}
                            <Grid item xs sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "right", gap: "5px" }}>
                                {/* <Button onClick={handleOpenDeletedContent} sx={{ border: "1px solid #DCDCDC", background: "#F8C036", color: "black", width: "fit-content" }}>Deleted & Duplicate Content</Button> */}

                                {/* <Button onClick={handleOpenNewContent} sx={{ border: "1px solid #DCDCDC", background: "#E4F87F", color: "black", width: "fit-content" }}>Add Content Link</Button> */}

                                {/*Modal for displaying deleted and duplicate content */}
                                {/* <Modal
                                    open={openDeletedContent}
                                    onClose={handleCloseDeletedContent}
                                    BackdropProps={{ style: { backgroundColor: 'rgb(0,0,0,0.1)' } }}
                                >
                                    <Box sx={style2}>
                                        <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "10px", height: "5%" }}>
                                            <Typography>Deleted and Duplicate Content</Typography>
                                        </Box>
                                        <Box sx={{ height: "95%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                                                <ButtonGroup variant="text" sx={{ border: "1px solid #DCDCDC", marginBottom: "20px" }}>
                                                    <Button sx={displayDeletedContent ? buttonStyleSelected : buttonStyleNotSelected} onClick={() => handleDisplayDeletedContent()}>Deleted Content</Button>
                                                    <Button sx={!displayDeletedContent ? buttonStyleSelected : buttonStyleNotSelected} onClick={() => handleDisplayDuplicateContent()}>Duplicate Content</Button>
                                                </ButtonGroup>
                                            </Box>
                                            {displayDeletedContent && <TableContainer component={Paper} sx={{}}>
                                                <Table >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: "center", fontWeight: "800" }}>DELETED CONTENT</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rows.map((row) => (
                                                            <TableRow
                                                                key={row.name}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0, } }}
                                                            >
                                                                <TableCell sx={{ textAlign: "center" }}>
                                                                    {row.name}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>}

                                            {!displayDeletedContent && <TableContainer component={Paper} sx={{}}>
                                                <Table >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: "center", fontWeight: "800" }}>DUPLICATE CONTENT</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rows.map((row) => (
                                                            <TableRow
                                                                key={row.name}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0, } }}
                                                            >
                                                                <TableCell sx={{ textAlign: "center" }}>
                                                                    {row.name}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>}
                                        </Box>
                                    </Box>
                                </Modal> */}

                                {/*Modal for adding new content */}
                                {/* <Modal
                                    open={openNewContent}
                                    onClose={handleCloseNewContent}
                                    BackdropProps={{ style: { backgroundColor: 'rgb(0,0,0,0.1)' } }}
                                > */}
                                {/* <Box sx={style}>
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <Typography variant='h5'>Add New Content Pieces</Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                                            <ButtonGroup variant="text" sx={{ border: "1px solid #DCDCDC" }}>
                                                <Button sx={displayAddContentViaLinkScreen ? buttonStyleSelected : buttonStyleNotSelected} onClick={() => handleAddContentLink()}>Add via link</Button>
                                                <Button sx={!displayAddContentViaLinkScreen ? buttonStyleSelected : buttonStyleNotSelected} onClick={() => handleAddContentManually()}>Upload Manually</Button>
                                            </ButtonGroup>
                                        </Box> */}

                                {/* {displayAddContentViaLinkScreen &&
                                            <Box sx={{ marginTop: "30px" }}>
                                                <TextField
                                                    sx={{ width: "100%", marginTop: "10px" }}
                                                    placeholder="Insert link(s) here"
                                                    value={contentLink}
                                                    onChange={handleContentLink}
                                                    rows={8}
                                                    multiline />
                                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                    {!isLoadingAddNewContent && addNewContentText == "" && <Button sx={{ background: "#F8C036", color: "black", marginTop: "10px" }} onClick={() => addNewContent()}>Add</Button>}
                                                    {!isLoadingAddNewContent && addNewContentText != "" && <Typography sx={{ marginTop: "10px" }} >{addNewContentText}</Typography>}
                                                    {isLoadingAddNewContent && <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}><CircularProgress style={{ color: "black", }} size={30} /></Box>}
                                                </Box>
                                            </Box>
                                        } */}
                                {/* </Box> */}
                                {/* </Modal> */}
                            </Grid>
                        </Grid>
                        <Grid container style={{ width: "80%" }}>
                            {filteredContent.slice((page - 1) * 20, page * 20).map((value, index) => (
                                <Grid item xs={3} key={index} style={{ padding: "10px 2px 5px 2px", display: "flex", justifyContent: "center" }}>
                                    <Card style={{ height: "500px", borderRadius: "20px", border: "1px solid #DCDCDC", boxShadow: "none", padding: "10px" }}>
                                        <Grid container>
                                            <Grid item xs={9} style={{ marginBottom: "10px", display: "flex", justifyContent: "left", alignItems: "center", gap: "5px" }}>
                                                <Avatar style={{ width: "40px", height: "40px" }} src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + value.influencer + ".jpg?alt=media"} />
                                                <Typography >@{value.influencer.substring(0, 12)}..</Typography>
                                            </Grid>
                                            <Grid item xs={3} style={{ marginBottom: "10px", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                                <Typography style={{ background: mediaTypeBg[value.mediaType], padding: "4px 7px 4px 7px", borderRadius: "10px" }}>{(value.mediaType == "POST" ? "STATIC" : value.mediaType)}</Typography>
                                            </Grid>
                                            <Grid item xs onClick={() => handleOpen(index)} sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                                <CardMedia
                                                    component={getMediaComponent(value.mediaUrl)}
                                                    image={value.mediaUrl}
                                                    style={getMediaStyle(getMediaComponent(value.mediaUrl))}
                                                >
                                                </CardMedia>
                                            </Grid>
                                            <StyledModal
                                                open={openIndex === index}
                                                onClose={handleClose}
                                                slots={{ backdrop: StyledBackdrop }}
                                            >
                                                <CampaignVideoCard key={index} videoData={{ "mediaType": value.mediaType, "mediaComponent": getMediaComponent(value.mediaUrl), "mediaUrl": value.mediaUrl, "likes": value.likes, "comments": value.comments, "views": value.views, "shares": value.shares, "saves": value.saves, "caption": value.caption, "shortcode": value.shortcode, "permalink": value.permalink, "brands": [] }} />
                                            </StyledModal>
                                            <Grid container xs={12} sx={{ marginTop: "10px" }}>
                                                <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                                                    {/* sx={{ gap: "2px", display: "flex", justifyContent: "center", alignItems: "center" }} */}
                                                    <div className='trackingContentOuterCardMetrics'>
                                                        <FavoriteBorderOutlinedIcon fontSize='small' /> {formatNumber(value.likes)}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                                                    <div className='trackingContentOuterCardMetrics'>
                                                        <CommentOutlinedIcon fontSize='small' /> {formatNumber(value.comments)}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={4} style={{ gap: "5px", display: "flex", alignItems: "center" }}>
                                                    <div className='trackingContentOuterCardMetrics'>
                                                        <VisibilityOutlinedIcon fontSize='small' /> {formatNumber(value.views)}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                <Typography variant='body1' sx={{ display: "flex", fontWeight: "800" }}>
                                                    {caption[index]}
                                                </Typography>
                                            </Grid> */}
                                        </Grid>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid >
                        <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                            <Pagination count={Math.ceil(filteredContent.length / 20)} page={page} color='standard' shape='rounded' onChange={handlePageChange} />
                        </Box>
                    </div >}
            </>
        )
    }
    else {
        return (
            <div style={{ display: "flex", justifyContent: "center", }}>
                <Box
                    component="img"
                    sx={{
                        height: "380px",
                        width: "350px",
                    }}
                    alt="Campaigns Content No Results"
                    src={CampaignContentNoResults}
                />
            </div>
        )
    }
}

export default CommunityContent