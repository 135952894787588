import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import CampaignVideoCard from '../../campaign/CampaignVideoCard';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CampaignContentNoResults from "../../../assets/campaignContentNoResults.png";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { Avatar, Card, CardMedia, Grid, Typography, Box, CircularProgress, Modal, TextField, Button, Slide, Radio, FormLabel, FormControl, Pagination } from '@mui/material'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const StyledModal = styled(Modal)`
position: fixed;
z-index: 1300;
inset: 0;
display: flex;
align-items: center;
justify-content: center;
`;

const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'MuiBackdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});

Backdrop.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
};

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: "30%",
    height: "auto",
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: "10px",
    p: 3,
};

function TrackingContent(props) {

    const location = useLocation();
    const propsData = location.state;

    const campaignData = propsData.campaignData;
    const userData = props.userData;

    const [isLoading, setIsLoading] = useState(false)
    const [campaignContent, setCampaignContent] = useState([])
    const [filteredContent, setFilteredContent] = useState([])
    const [searchInfluencer, setSearchInfluencer] = useState("");

    const [contentLink, setContentLink] = useState("")

    const handleContentLink = event => {
        setContentLink(event.target.value);
    };

    const mediaTypeBg = { "POST": "#5BCEFF", "STORY": "#E69EFF", "REEL": "#E4F87F", "YOUTUBE": "#FF0000" }

    // const showCampaignContent = (campaignContent.length > 0) ? true : false;
    const showCampaignContent = true;

    const [openIndex, setOpenIndex] = useState(-1); // Initialize with -1
    const handleOpen = (index) => {
        setOpenIndex(index); // Set the index of the opened modal
    };
    const handleClose = () => {
        setOpenIndex(-1); // Reset the index when closing the modal
    };


    const [openNewContent, setOpenNewContent] = useState(false);
    const handleOpenNewContent = () => { setOpenNewContent(true); }
    const handleCloseNewContent = () => { setOpenNewContent(false); setAddNewContentText(""); }

    async function fetchCampaignContent() {
        var campaignId = campaignData.campaignId;
        if (campaignId != null) {
            setIsLoading(true);
            const token2 = await userData.getIdToken()

            let data = {
                campaignId: campaignId
            }

            let options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }


            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_campaign_content', options)
                .then(result => result.json())
                .then(body => { setFilteredContent(body); setCampaignContent(body); setIsLoading(false); });
        }
        else { console.log("Invalid campaign id") }
    }

    useEffect(() => {
        fetchCampaignContent()
    }, [])

    function getMediaComponent(url) {
        const urlWithoutQuery = url.split('?')[0];

        const urlParts = urlWithoutQuery.split('/');
        const lastPart = urlParts[urlParts.length - 1];
        const fileExtension = lastPart.split('.').pop();

        return (fileExtension == "jpg") ? "image" : "video"
    }

    function formatNumber(number) {
        if (number >= 1e6) {
            return (number / 1e6).toFixed(2) + "M";
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(2) + "K";
        } else {
            if (number == 0) {
                return number
            } else {
                return number;
            }
        }
    }


    function getMediaStyle(mediaComponent) {
        const imageStyle = { width: "550px", height: "390px", borderRadius: "20px", cursor: "pointer", borderRadius: "20px" }
        const videoStyle = { width: "550px", height: "390px", background: "black", borderRadius: "20px", cursor: "pointer", borderRadius: "20px" }
        if (mediaComponent == "image") {
            return imageStyle;
        } else {
            return videoStyle;
        }
    }

    const [selectedValue, setSelectedValue] = useState('a');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };


    const [isLoadingAddNewContent, setIsLoadingAddNewContent] = useState(false)
    const [addNewContentText, setAddNewContentText] = useState("")


    async function addNewContent() {
        var campaignId = campaignData.campaignId;
        var campaignName = campaignData.campaignName;
        // console.log("CAMPAIGN NAME")
        // console.log(campaignName)
        if (campaignId != null) {
            setIsLoadingAddNewContent(true);
            const token2 = await userData.getIdToken()

            const links = contentLink.split('\n');

            let data = {
                campaignId: campaignId,
                campaignName: campaignName,
                contentLinks: links
            }

            let options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }


            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/add_new_tracking_content', options)
                .then(result => result.json())
                .then(body => {
                    console.log(body);
                    setIsLoadingAddNewContent(false);

                    if (body == "Success") {
                        setAddNewContentText("Links added successfully");

                        setTimeout(() => {
                            setAddNewContentText("");
                            setOpenNewContent(false);
                        }, 2000);
                    }
                    else {
                        setAddNewContentText("Error while adding links. Please try again");
                    }
                });
        }
        else { console.log("Invalid campaign id") }

    }

    function getDateFromTimePosted() {
        const date = new Date(campaignContent[0]["timePosted"] * 1000);

        // Format the date to a readable string
        const dateString = date.toUTCString(); // Converts to a string with a UTC time zone
        // Or, for a local time string
        const localDateString = date.toLocaleString();

        return localDateString;
    }

    const handleSearchInfluencer = (event) => {
        const searchText = event.target.value.toLowerCase();
        setSearchInfluencer(searchText);

        if (searchText === '') {
            // If the search input is empty, reset to the complete list
            setFilteredContent(campaignContent);
            setPage(1);
        } else {
            // Otherwise, filter based on the search input
            const filteredData = campaignContent.filter(item =>
                item.influencer.toLowerCase().includes(searchText)
            );
            setFilteredContent(filteredData);
            setPage(1);
        }
    };

    const [page, setPage] = useState(1);
    const handlePageChange = (event, value) => {
        setPage(value);
    };


    const [openDeletedContent, setOpenDeletedContent] = useState(false);
    const handleOpenDeletedContent = () => { setOpenDeletedContent(true); }
    const handleCloseDeletedContent = () => { setOpenDeletedContent(false); }


    if (showCampaignContent) {
        return (
            <>
                {isLoading && <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress style={{ color: "black", }} size={70} /></Box>}
                {!isLoading &&
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <Grid container sx={{ width: "80%", display: "flex", flexDirection: "row", gap: "20px", alignItems: "center", justifyContent: "space-between" }}>
                            <Box>
                                <TextField
                                    hiddenLabel
                                    value={searchInfluencer}
                                    onChange={handleSearchInfluencer}
                                    size='small'
                                    fullWidth
                                    placeholder='Search Influencer'
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                <SearchIcon />
                                            </Box>
                                        ),
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Pagination count={Math.ceil(filteredContent.length / 20)} page={page} color='standard' shape='rounded' onChange={handlePageChange} />
                            </Box>
                            <Box>
                                {/* <Button onClick={handleOpenDeletedContent} sx={{ border: "1px solid #DCDCDC", background: "#E4F87F", color: "black", width: "fit-content" }}>Deleted & Duplicate Content</Button>
                                <Modal
                                    open={openDeletedContent}
                                    onClose={handleCloseDeletedContent}
                                    BackdropProps={{ style: { backgroundColor: 'rgb(0,0,0,0.1)' } }}
                                >
                                    <Box>
                                        <Box sx={style}>
                                            <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                                                <Typography>Deleted and Duplicate Content</Typography>
                                            </Box>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                                                <TableContainer component={Paper}>
                                                    <Table >
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell sx={{ textAlign: "center", fontWeight: "800" }}>DELETED CONTENT</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rows.map((row) => (
                                                                <TableRow
                                                                    key={row.name}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0, } }}
                                                                >
                                                                    <TableCell sx={{ textAlign: "center" }}>
                                                                        {row.name}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                                <TableContainer component={Paper}>
                                                    <Table >
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell sx={{ textAlign: "center", fontWeight: "800" }}>DUPLICATE CONTENT</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rows.map((row) => (
                                                                <TableRow
                                                                    key={row.name}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0, } }}
                                                                >
                                                                    <TableCell sx={{ textAlign: "center" }}>
                                                                        {row.name}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Modal> */}

                                <Button onClick={handleOpenNewContent} sx={{ border: "1px solid #DCDCDC", background: "#E4F87F", color: "black", width: "fit-content" }}>Add Content Link</Button>
                                <Modal
                                    open={openNewContent}
                                    onClose={handleCloseNewContent}
                                    BackdropProps={{ style: { backgroundColor: 'rgb(0,0,0,0.1)' } }}
                                >
                                    <Box>
                                        <Box sx={style}>
                                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                <Typography variant='h5'>Add New Link</Typography>
                                            </Box>
                                            {/* <FormControl sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                                <Radio
                                                    checked={selectedValue === 'a'}
                                                    onChange={handleChange}
                                                    value="a"
                                                />
                                                <FormLabel >Instagram</FormLabel>
                                                <Radio
                                                    checked={selectedValue === 'b'}
                                                    onChange={handleChange}
                                                    value="b"
                                                />
                                                <FormLabel >YouTube</FormLabel>
                                            </FormControl> */}
                                            <TextField
                                                sx={{ width: "100%", marginTop: "10px" }}
                                                placeholder="Insert links here"
                                                value={contentLink}
                                                onChange={handleContentLink}
                                                multiline
                                                rows={6} />
                                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                {/* <Button sx={{ background: "#E4F87F", color: "black", marginTop: "10px" }} onClick={() => addNewContent()}>Add</Button> */}
                                                {!isLoadingAddNewContent && addNewContentText == "" && <Button sx={{ background: "#E4F87F", color: "black", marginTop: "10px" }} onClick={() => addNewContent()}>Add</Button>}
                                                {!isLoadingAddNewContent && addNewContentText != "" && <Typography sx={{ marginTop: "10px" }} >{addNewContentText}</Typography>}
                                                {isLoadingAddNewContent && <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}><CircularProgress style={{ color: "black", }} size={30} /></Box>}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Modal>
                            </Box>
                        </Grid>
                        <Grid container style={{ width: "80%" }}>
                            {filteredContent.slice((page - 1) * 20, page * 20).map((value, index) => (
                                <Grid item xs={3} key={index} style={{ padding: "10px 2px 5px 2px", display: "flex", justifyContent: "center" }}>
                                    <Card style={{ height: "500px", borderRadius: "20px", border: "1px solid #DCDCDC", boxShadow: "none", padding: "10px" }}>
                                        <Grid container>
                                            <Grid item xs={9} style={{ marginBottom: "10px", display: "flex", justifyContent: "left", alignItems: "center", gap: "5px" }}>
                                                <Avatar style={{ width: "40px", height: "40px" }} src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + value.influencer + ".jpg?alt=media"} />
                                                <Typography >@{value.influencer.substring(0, 12)}..</Typography>
                                            </Grid>
                                            <Grid item xs={3} style={{ marginBottom: "10px", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                                <Typography style={{ background: mediaTypeBg[value.mediaType], padding: "4px 7px 4px 7px", borderRadius: "10px" }}>{value.mediaType}</Typography>
                                            </Grid>
                                            <Grid item xs onClick={() => handleOpen(index)} sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                                <CardMedia
                                                    component={getMediaComponent(value.mediaUrl)}
                                                    image={value.mediaUrl}
                                                    style={getMediaStyle(getMediaComponent(value.mediaUrl))}
                                                >
                                                </CardMedia>
                                            </Grid>
                                            <StyledModal
                                                open={openIndex === index}
                                                onClose={handleClose}
                                                slots={{ backdrop: StyledBackdrop }}
                                            >
                                                <CampaignVideoCard key={index} videoData={{ "mediaType": value.mediaType, "mediaComponent": getMediaComponent(value.mediaUrl), "mediaUrl": value.mediaUrl, "likes": value.likes, "comments": value.comments, "views": value.views, "shares": value.shares, "saves": value.saves, "caption": value.caption, "shortcode": value.contentId, "permalink": value.permalink, "brands": [] }} />
                                            </StyledModal>
                                            <Grid container xs sx={{ marginTop: "10px", padding: "0px 10px 0px 10px" }}>
                                                <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                                                    <Typography sx={{ gap: "2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <FavoriteBorderOutlinedIcon /> {formatNumber(value.likes)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                                                    <Typography sx={{ gap: "2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <CommentOutlinedIcon /> {formatNumber(value.comments)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} style={{ gap: "5px", display: "flex", alignItems: "center" }}>
                                                    <Typography sx={{ gap: "2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <VisibilityOutlinedIcon /> {formatNumber(value.views)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                <Typography variant='body1' sx={{ display: "flex", fontWeight: "800" }}>
                                                    {caption[index]}
                                                </Typography>
                                            </Grid> */}
                                        </Grid>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid >
                        <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                            <Pagination count={Math.ceil(filteredContent.length / 20)} page={page} color='standard' shape='rounded' onChange={handlePageChange} />
                        </Box>
                    </div >}
            </>
        )
    }
    else {
        return (
            <div style={{ display: "flex", justifyContent: "center", }}>
                <Box
                    component="img"
                    sx={{
                        height: "380px",
                        width: "350px",
                    }}
                    alt="Campaigns Content No Results"
                    src={CampaignContentNoResults}
                />
            </div>
        )
    }
}

export default TrackingContent