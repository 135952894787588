import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Avatar, Button, Card, CircularProgress, Grid, LinearProgress, Typography } from '@mui/material'
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link, Outlet, useLocation } from 'react-router-dom';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';

import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import LeaderboardContent from "./LeaderboardContent"
import LeaderboardInfluencers from "./LeaderboardInfluencers"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function LeaderboardHome(props) {

    const userData = props.userData;

    // {"content": allContent, "influencers": totalCampaignInfluencers, "analytics": analytics}
    // {"totalContent": 0, "totalViews": 0, "totalLikes": 0, "totalComments": 0, "totalInfluencers": 0, "totalShares": 0, "totalSaves": 0, "totalEngagement": 0}


    const [tab, setTab] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const [leaderboardContent, setLeaderboardContent] = useState([])
    const [leaderboardInfluencers, setLeaderboardInfluencers] = useState([])
    const [leaderboardAnalytics, setLeaderboardAnalytics] = useState({})
    const [startDateStr, setStartDateStr] = useState("");
    const [endDateStr, setEndDateStr] = useState("");

    function handleTabChange(tab) {
        if (tab == 0) { setTab(0) }
        if (tab == 1) { setTab(1) }
        if (tab == 2) { setTab(2) }
    };

    const influencerButton = (tab == 0) ? "#E4F87F" : "white";
    const contentButton = (tab == 1) ? "#E4F87F" : "white";
    const analyticsButton = (tab == 2) ? "#E4F87F" : "white";

    function convertDate(start, end) {

        console.log("STRT")
        console.log(start)
        console.log("END")
        console.log(end)

        // Parse the date string
        const startDate = new Date(start);
        const endDate = new Date(end);

        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        console.log("INSIDE FUNCTION")
        console.log(startDate)
        console.log(endDate)

        const today = new Date();
        const istOffset = today.getTimezoneOffset();
        startDate.setMinutes(startDate.getMinutes() - istOffset);
        endDate.setMinutes(endDate.getMinutes() - istOffset);

        // Format the date in the desired format
        const startDateStr = startDate.toISOString().slice(0, 19).replace('T', ' ');
        const endDateStr = endDate.toISOString().slice(0, 19).replace('T', ' ');

        return [startDateStr, endDateStr];
    }

    function getCurrentMonthDates() {

        // Get the current date
        // new Date().toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })
        const today = new Date();
        // Get the current month (0-indexed)
        const month = today.getMonth();
        // Get the year
        const year = today.getFullYear();

        // Get the start date (1st of the month)
        const startDate = new Date(year, month, 1);
        const istOffset = today.getTimezoneOffset();
        startDate.setMinutes(startDate.getMinutes() - istOffset);

        // Format the start date string
        const startDateStr = startDate.toISOString().slice(0, 19).replace('T', ' ');

        // Get the end date (current date)
        const endDate = today;
        endDate.setMinutes(endDate.getMinutes() - istOffset);

        // Format the end date string
        const endDateStr = endDate.toISOString().slice(0, 19).replace('T', ' ');;

        // // Print the start and end dates
        // console.log("curr month dates")
        // console.log(`Start Date: ${startDateStr}`);
        // console.log(`End Date: ${endDateStr}`);

        return [startDateStr, endDateStr]
    }

    function getPreviousMonthDates() {

        // Get the current date with IST offset
        // new Date().toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })
        const today = new Date();

        // Get the year
        let year = today.getFullYear();

        // Get the previous month (0-indexed)
        let month = today.getMonth() - 1;

        // Handle month going to the previous year (December to January)
        if (month === -1) {
            month = 11; // Set month to December
            year = today.getFullYear() - 1; // Decrease year by 1
        } else {
            year = today.getFullYear();
        }

        // Get the start date (1st of the previous month)
        const startDate = new Date(year, month, 1);
        const istOffset = today.getTimezoneOffset();
        startDate.setMinutes(startDate.getMinutes() - istOffset);

        // Format the start date string
        const startDateStr = startDate.toISOString().slice(0, 19).replace('T', ' ');

        // Get the end date (last day of the previous month)
        const endDate = new Date(year, month + 1, 0); // Create a date object for the next month (same day as start date)
        endDate.setHours(23, 59, 59, 999); // Set time to the last millisecond of the day

        // Convert to IST using getTimezoneOffset()
        endDate.setMinutes(endDate.getMinutes() - istOffset);

        // Format the end date string
        const endDateStr = endDate.toISOString().slice(0, 19).replace('T', ' ');

        // // Print the start and end dates
        // console.log("prev month dates")
        // console.log(`Start Date: ${startDateStr}`);
        // console.log(`End Date: ${endDateStr}`);

        return [startDateStr, endDateStr];
    }

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    function onChange(dates) {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        var [startStr, endStr] = convertDate(start, end)
        setStartDateStr(startStr)
        setEndDateStr(endStr)
        fetchLeaderboardData(2)
    };

    // console.log("start custom date")
    // console.log(startDateStr)
    // console.log("end custom date")
    // console.log(endDateStr)




    async function fetchLeaderboardData(index) {

        var startStr = "";
        var endStr = "";
        if (index == 0) {
            [startStr, endStr] = getCurrentMonthDates()
        }
        else if (index == 1) {
            [startStr, endStr] = getPreviousMonthDates()
        }
        else if (index == 2) {
            startStr = startDateStr
            endStr = endDateStr
        }

        // console.log("final start date")
        // console.log(startStr)
        // console.log("final end date")
        // console.log(endStr)

        setIsLoading(true);
        const token2 = await userData.getIdToken()

        let data = {
            startDate: startStr,
            endDate: endStr,
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_leaderboard_by_date', options)
            .then(result => result.json())
            .then(body => {
                setLeaderboardAnalytics(body["analytics"]); setLeaderboardContent(body["content"]); setLeaderboardInfluencers(body["influencers"]); setIsLoading(false);
            });
    }

    const [displayDateText, setDisplayDateText] = useState("")

    // 'Custom Date Range'
    const options = ['This Month', 'Last Month'];
    const [campaignStatusName, setCampaignStatusName] = useState("")

    function fetchDates(index) {
        switch (index) {
            case 0:
                getCurrentMonthDates();
                break;
            case 1:
                getPreviousMonthDates();
                break;
            case 2:
                // selectCustomDates();
                break;
            default:
                break;

        }
    }

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleClick = () => {
        console.info(`You clicked ${options[selectedIndex]}`);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);

        fetchLeaderboardData(index)
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };



    // { "startDate": "2024-02-15 00:01:00", "endDate": "2024-03-15 00:01:00" }

    useEffect(() => {
        fetchLeaderboardData(0)
    }, [])


    return (
        <div style={{ padding: "20px" }}>
            <Grid container style={{ alignItems: "center", justifyContent: "center", marginBottom: "20px", height: "80px", gap: "10px" }}>
                <Card sx={{ width: "65%", border: "2px solid #DCDCDC", display: "flex", alignItems: "center", display: "flex", borderRadius: "15px", boxShadow: "none" }}>
                    <Grid container columns={12} style={{ display: "flex", textAlign: "center", padding: "5px" }}>
                        <Grid item xs={6}>
                            {/* <Link to="/leaderboard/content" state={propsData}> */}
                            <Button startIcon={<PeopleOutlineOutlinedIcon />} onClick={() => handleTabChange(0)} style={{ color: "black", background: influencerButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Influencers</Button>
                            {/* </Link> */}
                        </Grid>
                        <Grid item xs={6}>
                            {/* <Link to="/leaderboard/influencers" state={propsData}> */}
                            <Button startIcon={<PeopleOutlineOutlinedIcon />} onClick={() => handleTabChange(1)} style={{ color: "black", background: contentButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Content</Button>
                            {/* </Link> */}
                        </Grid>
                        {/* <Grid item xs={3}>
                            <Link to="/campaign/analytics" state={propsData}>
                                <Button startIcon={<PeopleOutlineOutlinedIcon />} onClick={() => handleTabChange(2)} style={{ color: "black", background: analyticsButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Analytics</Button>
                            </Link>
                        </Grid> */}
                    </Grid>
                </Card>
                <Box sx={{ width: "20%", display: "flex", justifyContent: "center" }}>
                    <ButtonGroup
                        // variant="contained"
                        ref={anchorRef}
                        size='small'
                        sx={{ color: "black", height: "50%" }}
                    >
                        <Button onClick={handleClick}>{options[selectedIndex]}</Button>
                        <Button
                            size="small"
                            onClick={handleToggle}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem>
                                            {options.map((option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    selected={index === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                    {/* <Box sx={{ border: "1px solid black" }}> */}
                    {/* </Box> */}
                </Box>
            </Grid>
            {selectedIndex == 2 && <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
            />}
            {isLoading && <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress style={{ color: "black" }} size={50} /></Box>}
            {!isLoading && tab == 0 && <LeaderboardInfluencers leaderboardInfluencers={leaderboardInfluencers} />}
            {!isLoading && tab == 1 && <LeaderboardContent leaderboardContent={leaderboardContent} leaderboardAnalytics={leaderboardAnalytics} />}
            <Outlet />
        </div>
    )
}

export default LeaderboardHome