import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Avatar, Divider, Grid, InputBase, ListItemText, Menu, MenuItem, Paper, SvgIcon } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import { Link, useLocation } from 'react-router-dom';

import { signOut } from "firebase/auth";
import auth from '../api/firebase';
import { useEffect } from 'react';
import { useState } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Discovery from './Discovery';
import Lists from './Lists';
import Campaigns from './Campaigns';
import CampaignContent from './campaign/CampaignContent';
import CampaignInfluencers from './campaign/CampaignInfluencers';
import CampaignAnalytics from './campaign/CampaignAnalytics';
import Campaign from './campaign/Campaign';
import List from './List';
import CloserPlusLogo from "../assets/closerNewLogo.svg"

import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import '../sass/algoliaStyles.css'
import AlgoliaSearchBox from './AlgoliaSearchBox';
import Settings from './Settings';
import CampaignChat from './campaign/CampaignChat';
import Dashboard from './dashboard/Dashboard';
import TrackingInfluencers from './dashboard/tracking/TrackingInfluencers';
import TrackingContent from './dashboard/tracking/TrackingContent';
import TrackingAnalytics from './dashboard/tracking/TrackingAnalytics';
import Tracking from './dashboard/tracking/Tracking';
import LeaderboardHome from "./leaderboard/LeaderboardHome";
import CommunityHome from './community/CommunityHome';


const navItems = ['Discover', 'Lists', 'Campaigns', "Dashboard"];
const pageIcons = [<SearchOutlinedIcon />, <ReceiptLongOutlinedIcon />, <CampaignOutlinedIcon />, <SettingsIcon />];

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 315,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

function Topbar(props) {

    const source = props.source;

    const navigate = useNavigate();

    function handleLogout() {
        signOut(auth).then(() => {
            console.log("logout successfull")
        }).catch((error) => {
            console.log("logout failed")
        });
    }

    const location = useLocation();

    const [user, setUser] = useState("");
    const [isUserFetched, setIsUserFetched] = useState(false);
    const [showPage, setShowPage] = useState(false);
    const [firstName, setFirstName] = useState("")


    const [showDashboard, setShowDashboard] = useState(true);
    const [showTracking, setShowTracking] = useState(false);
    const [brandHandle, setBrandHandle] = useState("");
    // const [firstName, setFirstName] = useState("")

    async function getDashboardAccess() {
        if (user != "" && user != null) {
            const token2 = await user.getIdToken();
            let options = {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }

            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/check_dashboard_access', options)
                .then(result => result.json())
                .then(body => { setShowDashboard(body["showDashboard"]); setShowTracking(body["showTracking"]); setBrandHandle(body["instaHandle"]) });
        }
    }

    // console.log("showDashboard topbar")
    // console.log(showDashboard)

    useEffect(() => {
        if (user == null && isUserFetched) {
            console.log("no user found")
            navigate("/login")
        } else if (isUserFetched == false) {
            onAuthStateChanged(auth, (user) => {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                setIsUserFetched(true)
                setUser(user)
                if (user) {
                    setFirstName(user.email.split('@')[0])
                    setShowPage(true)
                    const tab = location.pathname;
                    if (tab == "/dashboard" || tab.includes("tracking")) {
                        setShowTracking(true)
                    }
                }
            });
        }
    }, [user])

    useEffect(() => {
        getDashboardAccess();
    }, [])




    // const userData = location.state;
    // if (userData != null && "user" in userData) {
    //     console.log("userData discovery")
    //     console.log(userData);
    // } else {
    //     console.log("route to login")
    //     navigate('/login')
    // }

    const tabStyles = {
        // border: "1px solid white",
        backgroundColor: "#E4F87F",
        color: "black",
        borderRadius: "10px",
        marginTop: "5px",
        padding: "5px 10px 5px 10px",
    }

    const tabStyles2 = {
        borderRadius: "10px",
        marginTop: "5px",
        padding: "5px 10px 5px 10px",
    }


    // const [tab, setTab] = useState(location.pathname);
    const tab = location.pathname;

    const discoverBackground = (tab == "/discovery") ? true : false;
    const listBackground = (tab == "/lists" || tab == "/list") ? true : false;
    const campaignBackground = (tab == "/campaigns" || tab == "/campaign/influencers" || tab == "/campaign/content" || tab == "/campaign/analytics") ? true : false;
    const leaderboardBackground = (tab == "/leaderboard") ? true : false;
    const communityBackground = (tab == "/community") ? true : false;
    const dashboardBackground = (tab == "/dashboard" || tab.includes("tracking")) ? true : false;
    const logoutBackground = (tab == "/login") ? true : false;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // const handleInfluencerSearchOpen = () => setInfluencerSearchOpen(true);

    const handleInfluencerSearchOpen = (event) => {
        // console.log(event.currentTarget)
        setAnchorEl(event.currentTarget);
    };
    const handleInfluencerSearchClose = () => { setAnchorEl(null) };

    const [influencerUsername, setInfluencerUsername] = useState("")

    const handleInfluencerUsername = event => {
        setInfluencerUsername(event.target.value);
    };

    const [searchQuery, setSearchQuery] = useState("");

    const handleInfluencerSearch = (query, search) => {
        // console.log("QUERY")
        // console.log(query)
        // console.log(search)
        setSearchQuery(query)

        if (searchQuery.length != 0) {
            search(searchQuery)
        }
    }

    getDashboardAccess();

    const [anchorElLogout, setAnchorElLogout] = useState(null);
    const openSettings = Boolean(anchorElLogout);
    const handleClickSettings = (event) => {
        setAnchorElLogout(event.currentTarget);
    };
    const handleCloseSettings = () => {
        setAnchorElLogout(null);
    };

    function handleSettings() {
        console.log("handle settings")
        setAnchorElLogout(null)
    }

    var displayToolbar = ((source == "campaign/influencers" || source == "campaign/content" || source == "campaign/analytics" || source == "tracking/influencers" || source == "tracking/content" || source == "tracking/analytics") ? "none" : "block");


    if (showPage == false) {
        return (
            <Box>

            </Box>
        );
    }
    else if (source != undefined) {

        return (
            <Box >
                <AppBar component="nav" style={{ backgroundColor: "#232323", height: "80px", display: "flex", justifyContent: "center" }}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 0.6, display: { xs: 'none', sm: 'block' } }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    height: "30px",
                                    width: "171px",
                                }}
                                alt="Discovery No Results"
                                src={CloserPlusLogo}
                            />
                        </Box>

                        {source != "settings" && showTracking == false && <AlgoliaSearchBox userData={user} />}

                        <Box sx={{ flexGrow: 1, display: "flex", mt: "1300px" }}>
                        </Box>

                        <Box sx={{ padding: "20px 0px 20px 20px", display: "flex", gap: "5px" }}>
                            {showTracking == false &&
                                <Box>
                                    <Link style={{ color: '#fff' }} to="/discovery">
                                        <Button startIcon={pageIcons[0]} sx={{ color: '#fff' }} style={discoverBackground ? tabStyles : tabStyles2} >{navItems[0]}</Button>
                                    </Link>
                                    <Link style={{ color: '#fff' }} to="/lists">
                                        <Button startIcon={pageIcons[1]} sx={{ color: '#fff' }} style={listBackground ? tabStyles : tabStyles2} >{navItems[1]}</Button>
                                    </Link>
                                    <Link style={{ color: '#fff' }} to="/campaigns">
                                        <Button startIcon={pageIcons[2]} sx={{ color: '#fff' }} style={campaignBackground ? tabStyles : tabStyles2} >{navItems[2]}</Button>
                                    </Link>
                                    <Link style={{ color: '#fff' }} to="/leaderboard">
                                        <Button startIcon={pageIcons[3]} sx={{ color: '#fff' }} style={leaderboardBackground ? tabStyles : tabStyles2} >Leaderboard</Button>
                                    </Link>
                                    <Link style={{ color: '#fff' }} to="/community">
                                        <Button startIcon={pageIcons[3]} sx={{ color: '#fff' }} style={communityBackground ? tabStyles : tabStyles2} >Applications</Button>
                                    </Link>
                                </Box>}
                            {showTracking &&
                                <Box>
                                    <Link style={{ color: '#fff' }} to="/discovery">
                                        <Button startIcon={pageIcons[0]} sx={{ color: '#fff' }} style={discoverBackground ? tabStyles : tabStyles2} >{navItems[0]}</Button>
                                    </Link>
                                    <Link style={{ color: '#fff' }} to="/dashboard">
                                        <Button startIcon={pageIcons[2]} sx={{ color: '#fff' }} style={dashboardBackground ? tabStyles : tabStyles2} >{navItems[3]}</Button>
                                    </Link>
                                </Box>
                            }
                            <Button sx={{ color: '#fff' }} style={{ color: "black", padding: "1px" }} onClick={handleClickSettings} ><Avatar src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/brands%2F" + brandHandle + ".jpg?alt=media"} /></Button>
                            <Menu
                                anchorEl={anchorElLogout}
                                open={openSettings}
                                onClose={handleCloseSettings}
                            >
                                <MenuItem sx={{ gap: "10px" }}>
                                    <ListItemText primary={`Welcome ${firstName.charAt(0).toUpperCase() + firstName.slice(1)}`} />
                                </MenuItem>
                                <Divider sx={{ my: 0.5 }} />
                                <Link to="/settings" style={{ color: "black", textDecoration: "none" }}>
                                    <MenuItem sx={{ gap: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <SettingsIcon />
                                        <ListItemText onClick={() => handleSettings()} primary={"Settings"} />
                                    </MenuItem>
                                </Link>
                                <MenuItem sx={{ gap: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <LogoutIcon />
                                    <ListItemText onClick={() => handleLogout()} primary={"Logout"} />
                                </MenuItem>
                                {/* <MenuItem sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button onClick={() => sendNewInfluencersToCampaigns()} sx={{ width: "80%", color: "black", background: (campaignInfluencers.length > 0 ? "#E4F87F" : "#C4C4C4") }} disabled={(campaignInfluencers.length > 0 ? false : true)}>{sentStatus}</Button>
                                </MenuItem> */}
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box sx={{ p: 1, display: displayToolbar }}>
                    <Toolbar />
                </Box>
                {source == "discovery" && <Discovery userData={user} showDashboard={showDashboard} />}
                {source == "lists" && <Lists userData={user} showDashboard={showDashboard} />}
                {source == "list" && <List userData={user} />}
                {source == "campaigns" && <Campaigns userData={user} showDashboard={showDashboard} />}
                {source == "campaign" && <Campaign userData={user} />}
                {source == "campaign/influencers" && <CampaignInfluencers userData={user} />}
                {source == "campaign/content" && <CampaignContent userData={user} />}
                {source == "campaign/analytics" && <CampaignAnalytics userData={user} />}
                {source == "campaign/chat" && <CampaignChat userData={user} />}
                {source == "settings" && <Settings userData={user} />}
                {source == "dashboard" && <Dashboard userData={user} />}
                {source == "tracking" && <Tracking userData={user} />}
                {source == "tracking/influencers" && <TrackingInfluencers userData={user} />}
                {source == "tracking/content" && <TrackingContent userData={user} />}
                {source == "tracking/analytics" && <TrackingAnalytics userData={user} />}
                {source == "leaderboard" && <LeaderboardHome userData={user} />}
                {source == "community" && <CommunityHome userData={user} />}
            </Box >
        );
    }
}

export default Topbar;