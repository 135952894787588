import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import CampaignVideoCard from './CampaignVideoCard';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CampaignContentNoResults from "../../../assets/campaignContentNoResults.png";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { Avatar, Card, CardMedia, Grid, Typography, Box, CircularProgress, Modal, TextField, Button, Slide, Radio, FormLabel, FormControl, Pagination, Divider, InputLabel, ButtonGroup, LinearProgress, FormControlLabel, RadioGroup, Checkbox, NativeSelect, Select } from '@mui/material'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import dayjs from 'dayjs';

import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import "../main.css";

const theme = createTheme({
    palette: {
        primary: {
            main: "#F8C036",
        },
    },
});

const StyledModal = styled(Modal)`
position: fixed;
z-index: 1300;
inset: 0;
display: flex;
align-items: center;
justify-content: center;
`;

const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'MuiBackdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});

Backdrop.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
};

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: "50%",
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: "10px",
    p: 3,
};

const style2 = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: "50%",
    height: "60%",
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: "10px",
    p: 3,
};

const buttonStyleSelected = {
    background: "#F8C036",
    color: "black"
}

const buttonStyleNotSelected = {
    background: "#DCDCDC",
    color: "black"
}

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const dropzoneStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: "100%",
    padding: '20px',
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    backgroundColor: '#f5f5f5',
    cursor: 'pointer',
    boxShadow: "none"
};

function TrackingContent(props) {

    const location = useLocation();
    const propsData = location.state;

    const campaignData = propsData.campaignData;
    const userData = props.userData;

    const [isLoading, setIsLoading] = useState(false)
    const [campaignContent, setCampaignContent] = useState([])
    const [filteredContent, setFilteredContent] = useState([])
    const [searchInfluencer, setSearchInfluencer] = useState("");


    const [uploadedFileName, setUploadedFileName] = useState('');
    const [uploadedFileType, setUploadedFileType] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [isUploadDisabled, setIsUploadDisabled] = useState(true)

    const [sortBy, setSortBy] = useState();

    const [username, setUsername] = useState("");
    const [views, setViews] = useState(0);
    const [comments, setComments] = useState(0);
    const [likes, setLikes] = useState(0);
    const [shares, setShares] = useState(0);
    const [saves, setSaves] = useState(0);
    const [manualMediaType, setManualMediaType] = useState('reel');
    const handleManualMediaType = (event) => {
        setManualMediaType(event.target.value);
    };

    const [contentLink, setContentLink] = useState("")

    const handleContentLink = event => {
        setContentLink(event.target.value);
    };

    // dayjs('2022-04-17T00:00')
    const [contentDate, setContentDate] = useState()

    const mediaTypeBg = { "POST": "#D9BF00", "STORY": "#E69EFF", "REEL": "#E4F87F", "YOUTUBE": "#FF0000", "TWEET": "#1A8CD8" }

    // const showCampaignContent = (campaignContent.length > 0) ? true : false;
    const showCampaignContent = true;

    const [openIndex, setOpenIndex] = useState(-1); // Initialize with -1
    const handleOpen = (index) => {
        setOpenIndex(index); // Set the index of the opened modal
    };
    const handleClose = () => {
        setOpenIndex(-1); // Reset the index when closing the modal
    };


    const [openNewContent, setOpenNewContent] = useState(false);
    const handleOpenNewContent = () => { setOpenNewContent(true); }
    const handleCloseNewContent = () => {
        setOpenNewContent(false);
        setAddNewContentText("");
        setUsername("");
        setViews(0);
        setComments(0);
        setLikes(0);
        setShares(0);
        setSaves(0);
        setFileToUpload(null);
        setUploadProgress(0);
        setUploadedFileName("")
    }

    const [openAddedLinksSummary, setOpenAddedLinksSummary] = useState(false);
    const handleOpenAddedLinksSummary = () => { setOpenAddedLinksSummary(true); }
    const handleCloseAddedLinksSummary = () => { setOpenAddedLinksSummary(false); }

    async function fetchCampaignContent() {
        var campaignId = campaignData.campaignId;
        if (campaignId != null) {
            setIsLoading(true);
            fetchAddedLinksSummary()
            const token2 = await userData.getIdToken()

            let data = {
                campaignId: campaignId
            }

            let options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }


            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_campaign_content', options)
                .then(result => result.json())
                .then(body => { setFilteredContent(body); setCampaignContent(body); setIsLoading(false); });
        }
        else { console.log("Invalid campaign id") }
    }

    const [linksSummary, setLinksSummary] = useState([])
    const [deletedUrls, setDeletedUrls] = useState([])
    const [linksSummaryTab, setLinksSummaryTab] = useState(0)

    async function fetchAddedLinksSummary() {
        var campaignId = campaignData.campaignId;
        if (campaignId != null) {
            setIsLoading(true);
            const token2 = await userData.getIdToken()

            let data = {
                campaignId: campaignId
            }

            let options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }


            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_added_links_summary', options)
                .then(result => result.json())
                // setLinksSummaryTab(body["addedLinksSummary"].length - 1);
                .then(body => { console.log(body); console.log("body"); setLinksSummaryTab(body["addedLinksSummary"].length - 1); setDeletedUrls(body["deletedUrls"]); setLinksSummary(body["addedLinksSummary"]); setIsLoading(false); });
        }
        else { console.log("Invalid campaign id") }
    }

    useEffect(() => {
        fetchCampaignContent()
        // fetchAddedLinksSummary()
    }, [])

    function getMediaComponent(url) {
        const urlWithoutQuery = url.split('?')[0];

        const urlParts = urlWithoutQuery.split('/');
        const lastPart = urlParts[urlParts.length - 1];
        const fileExtension = lastPart.split('.').pop();

        return (fileExtension == "jpg") ? "image" : "video"
    }

    function formatNumber(number) {
        if (number >= 1e6) {
            return (number / 1e6).toFixed(1) + "M";
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(1) + "K";
        } else {
            if (number == 0) {
                return number
            } else {
                return number;
            }
        }
    }


    function getMediaStyle(mediaComponent) {
        const imageStyle = { width: "550px", height: "390px", borderRadius: "20px", cursor: "pointer", borderRadius: "20px" }
        const videoStyle = { width: "550px", height: "390px", background: "black", borderRadius: "20px", cursor: "pointer", borderRadius: "20px" }
        if (mediaComponent == "image") {
            return imageStyle;
        } else {
            return videoStyle;
        }
    }

    const [selectedValue, setSelectedValue] = useState('a');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const [isLoadingAddNewContent, setIsLoadingAddNewContent] = useState(false)
    const [addNewContentText, setAddNewContentText] = useState("")

    async function addNewContent() {
        var campaignId = campaignData.campaignId;
        var campaignName = campaignData.campaignName;
        // console.log("CAMPAIGN NAME")
        // console.log(campaignName)
        if (campaignId != null) {
            setIsLoadingAddNewContent(true);
            const token2 = await userData.getIdToken()

            const links = contentLink.split('\n');

            let data = {
                campaignId: campaignId,
                campaignName: campaignName,
                contentLinks: links
            }

            let options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }


            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/add_new_tracking_content', options)
                .then(result => result.json())
                .then(body => {
                    console.log(body);
                    setIsLoadingAddNewContent(false);
                    if (body == "Success") {
                        setAddNewContentText("Links added successfully");

                        setTimeout(() => {
                            setAddNewContentText("");
                            setOpenNewContent(false);
                        }, 2000);
                    }
                    else {
                        setAddNewContentText("Error while adding links. Please try again");
                    }

                });
        }
        else { console.log("Invalid campaign id") }
    }

    const handleEditMetrics = (metric, event) => {
        const value = event.target.value; // Get the current value from the event

        switch (metric) {
            case "username":
                setUsername(value);
                break;
            case "views":
                setViews(value);
                break;
            case "comments":
                setComments(value);
                break;
            case "likes":
                setLikes(value);
                break;
            case "shares":
                setShares(value);
                break;
            case "saves":
                setSaves(value);
                break;
            default:
                // Handle unknown metric, if necessary
                break;
        }
    };



    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        // console.log("FILE TYPE")
        // console.log(file)
        // console.log(file.type)
        setUploadedFileName(file.name);
        // setUploadedFileType(file.type);

        // Simulate file upload progress (for demonstration purposes)
        let progress = 0;
        const interval = setInterval(() => {
            progress += 20;
            setUploadProgress(progress);

            if (progress >= 100) {
                clearInterval(interval);
                setFileToUpload(file);
                // setIsUploadDisabled(false);
            }
        }, 100);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: ['.jpg', '.png', 'jpeg', '.mp4', '.wav'],
        onDrop
    });

    function generateRandomShortcode(length = 6) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    async function uploadFileToStorage() {
        try {

            // const metadata = {
            //     contentType: uploadedFileType
            // };

            const storage = getStorage();

            var shortcodeFileName = generateRandomShortcode(6)
            // console.log("shortcodeFileName")
            // console.log(shortcodeFileName)
            // console.log(uploadedFileType)

            var finalUploadFileName = `campaignContent/${shortcodeFileName}`

            // Create a reference to 'campaignContent/test123'
            const storageRef = ref(storage, finalUploadFileName);
            const uploadTask = uploadBytesResumable(storageRef, fileToUpload);

            return await new Promise((resolve, reject) => {
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        // console.log('Upload is ' + progress + '% done');
                    },
                    (error) => {
                        console.log("error file upload");
                        // console.log(error);
                        resolve("error");
                    },
                    () => {
                        // Upload completed successfully, now we can get the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // console.log('File available at', downloadURL);
                            // return downloadURL;
                            resolve(downloadURL);
                        });
                    }
                );
            });


        } catch (error) {
            console.error("Error uploading file: ", error);
            throw error;
        }
    };


    async function addContentManually() {
        try {
            setIsLoadingAddNewContent(true);

            const downloadURL = await uploadFileToStorage();
            // console.log('File available at', downloadURL);
            // console.log("INSIDE MANUAL FILE UPLOAD")
            // console.log(manualMediaType)
            // console.log(username)
            // console.log(views)
            // console.log(likes)
            // console.log(comments)
            // console.log(shares)
            // console.log(saves)

            const token2 = await userData.getIdToken()

            var campaignId = campaignData.campaignId;
            var campaignName = campaignData.campaignName;

            // console.log("CAMPAIGN ID")
            // console.log(campaignId)
            // console.log(campaignName)
            // console.log(manualMediaType.toUpperCase())

            const timestampNow = Math.floor(Date.now() / 1000);
            // console.log("timestamp now");
            // console.log(timestampNow);

            var timePosted = contentDate == undefined ? "" : contentDate?.$d
            const unixTimestamp = timePosted == "" ? timestampNow : Math.floor(timePosted.getTime() / 1000);
            // console.log(unixTimestamp)

            let data = {
                campaignId: campaignId,
                campaignName: campaignName,
                contentLink: "",
                mediaUrl: downloadURL,
                mediaType: manualMediaType.toUpperCase(),
                username: username,
                likes: likes,
                comments: comments,
                shares: shares,
                saves: saves,
                reach: views,
                timePosted: unixTimestamp
            }


            let options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }


            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/add_content_manually', options)
                .then(result => result.json())
                .then(body => {
                    console.log("BODY");
                    console.log(body);

                    setIsLoadingAddNewContent(false);
                    if (body == "Success") {
                        setAddNewContentText("Content added successfully");

                        setTimeout(() => {
                            setAddNewContentText("");
                            setOpenNewContent(false);
                            fetchCampaignContent();
                        }, 2000);
                    }
                    else {
                        setAddNewContentText("Error while uploading content. Please try again");
                    }
                });

        } catch (error) {
            console.error('Error uploading content to Firebase: ', error);
        }
    }

    function getDateFromTimePosted() {
        const date = new Date(campaignContent[0]["timePosted"] * 1000);

        // Format the date to a readable string
        const dateString = date.toUTCString(); // Converts to a string with a UTC time zone
        // Or, for a local time string
        const localDateString = date.toLocaleString();

        return localDateString;
    }

    const handleSearchInfluencer = (event) => {
        const searchText = event.target.value.toLowerCase();
        setSearchInfluencer(searchText);

        if (searchText === '') {
            // If the search input is empty, reset to the complete list
            setFilteredContent(campaignContent);
            setPage(1);
        } else {
            // Otherwise, filter based on the search input
            const filteredData = campaignContent.filter(item =>
                item.influencer.toLowerCase().includes(searchText)
            );
            setFilteredContent(filteredData);
            setPage(1);
        }
    };

    const [page, setPage] = useState(1);
    const handlePageChange = (event, value) => {
        setPage(value);
    };


    const [openDeletedContent, setOpenDeletedContent] = useState(false);
    const handleOpenDeletedContent = () => { setOpenDeletedContent(true); }
    const handleCloseDeletedContent = () => { setOpenDeletedContent(false); }


    const [displayAddContentViaLinkScreen, setDisplayAddContentViaLinkScreen] = useState(true);
    function handleAddContentLink() {
        setDisplayAddContentViaLinkScreen(true);
    }
    function handleAddContentManually() {
        setDisplayAddContentViaLinkScreen(false);
    }

    const [displayDeletedContent, setDisplayDeletedContent] = useState(true);
    function handleDisplayDeletedContent() {
        setDisplayDeletedContent(true);
    }
    function handleDisplayDuplicateContent() {
        setDisplayDeletedContent(false);
    }


    const [checkedStory, setCheckedStory] = useState(true)
    const [checkedReel, setCheckedReel] = useState(true)
    const [checkedPost, setCheckedPost] = useState(true)
    const [checkedTweet, setCheckedTweet] = useState(true)

    function handleMediaTypeFilter(mediaType) {
        if (mediaType == "reel") {
            setCheckedReel(!checkedReel)
        }
        else if (mediaType == "post") {
            setCheckedPost(!checkedPost)
        }
        else if (mediaType == "story") {
            setCheckedStory(!checkedStory)
        }
        else if (mediaType == "tweet") {
            setCheckedTweet(!checkedTweet)
        }
    }

    useEffect(() => {
        if (checkedStory && checkedPost && checkedReel && checkedTweet) {
            setFilteredContent(campaignContent);
            setPage(1);
        } else {
            const filteredData = campaignContent.filter(item =>
                (checkedStory && item.mediaType.toLowerCase() == "story") ||
                (checkedReel && item.mediaType.toLowerCase() == "reel") ||
                (checkedPost && item.mediaType.toLowerCase() == "post") ||
                (checkedTweet && item.mediaType.toLowerCase() == "tweet")
            );
            // console.log("filtered data checkbox")
            // console.log(filteredData)
            setFilteredContent(filteredData);
            setPage(1);
        }
    }, [checkedStory, checkedPost, checkedReel, checkedTweet])


    const options = ["Date(desc)", "Date(asc)", "Reach(desc)", "Likes(desc)", "Comments(desc)", "Shares(desc)", "Saves(desc)"]

    const handleChangeSortBy = (event) => {
        setSortBy(event.target.value);
    };



    useEffect(() => {
        // var sortByType = getSortByType(sortBy);
        var filteredData = [];
        if (sortBy == 0) {
            setFilteredContent(campaignContent);
            setPage(1);
        }
        else if (sortBy == 1) {
            var filteredData = filteredContent.slice(0).sort((a, b) => a.timePosted - b.timePosted);
            setFilteredContent(filteredData);
            setPage(1);
        }
        else if (sortBy == 2) {
            var filteredData = filteredContent.slice(0).sort((a, b) => b.views - a.views);
            setFilteredContent(filteredData);
            setPage(1);
        }
        else if (sortBy == 3) {
            var filteredData = filteredContent.slice(0).sort((a, b) => b.likes - a.likes);
            setFilteredContent(filteredData);
            setPage(1);
        }
        else if (sortBy == 4) {
            var filteredData = filteredContent.slice(0).sort((a, b) => b.comments - a.comments);
            setFilteredContent(filteredData);
            setPage(1);
        }
        else if (sortBy == 5) {
            var filteredData = filteredContent.slice(0).sort((a, b) => b.shares - a.shares);
            setFilteredContent(filteredData);
            setPage(1);
        }
        else if (sortBy == 6) {
            var filteredData = filteredContent.slice(0).sort((a, b) => b.saves - a.saves);
            setFilteredContent(filteredData);
            setPage(1);
        }
    }, [sortBy])

    function handleCustomDateFormat(input) {
        // Determine the type of input and convert to Date object
        let date;
        if (!isNaN(input) && input.toString().length === 10) { // Check if input is a Unix timestamp
            date = new Date(Number(input) * 1000); // Convert Unix timestamp from seconds to milliseconds
        } else if (!isNaN(Date.parse(input))) { // Check if input is an ISO 8601 date string
            date = new Date(input);
        } else {
            return ''; // Handle other formats of input
        }

        // Extract the date components
        const day = date.getUTCDate();
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[date.getUTCMonth()];
        const year = date.getUTCFullYear().toString().substr(-2); // Get the last two digits of the year

        // Format the date
        return `${day} ${month} ${year}`;
    }

    const [checkedContent, setCheckedContent] = useState([]);
    const [checkedMap, setCheckedMap] = useState(new Map());

    function handleToggle(shortcode) {
        const currentIndex = checkedContent.indexOf(shortcode);
        const newChecked = [...checkedContent];

        if (currentIndex === -1) {
            newChecked.push(shortcode);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedContent(newChecked);

        const newCheckedMap = new Map(checkedMap);
        newCheckedMap.set(shortcode, !checkedMap.get(shortcode));
        setCheckedMap(newCheckedMap);
    };

    const [isDeleting, setIsDeleting] = useState(false);
    async function deleteContentPieces() {
        var campaignId = campaignData.campaignId;
        var campaignName = campaignData.campaignName;
        if (campaignId != null) {
            setIsDeleting(true);
            const token2 = await userData.getIdToken()

            let data = {
                campaignId: campaignId,
                campaignName: campaignName,
                shortcodes: checkedContent
            }

            let options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }


            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/delete_campaign_content', options)
                .then(result => result.json())
                .then(body => { console.log(body); setIsDeleting(false); setCheckedContent([]); setCheckedMap(new Map()); fetchCampaignContent() });
        }
        else { console.log("Invalid campaign id") }
    }

    function handlePrevNextLinksSummary(btn) {
        var linksSummaryLen = linksSummary.length - 1

        if (btn == 0) {
            console.log("prev")
            if (linksSummaryTab > 0) {
                setLinksSummaryTab(linksSummaryTab - 1)
            }
        }
        else if (btn == 1) {
            console.log("next")
            if (linksSummaryTab < linksSummaryLen) {
                setLinksSummaryTab(linksSummaryTab + 1)
            }
        }

        // console.log(new Date(linksSummary[0]?.createdAt))
    }

    function formatDateString(dateString) {
        // Parse the original date string into a Date object
        const date = new Date(dateString);

        // Specify options for toLocaleString to format date and time
        const options = {
            weekday: 'short', // "Mon" etc.
            year: 'numeric', // "2024" etc.
            month: 'short', // "Apr" etc.
            day: 'numeric', // "22" etc.
            hour: '2-digit', // "01" etc.
            minute: '2-digit', // "44" etc.
            second: '2-digit', // "15" etc.
            hour12: true // Use 12-hour time format
        };

        // Format the date using toLocaleString with specified options
        return date.toLocaleString('en-US', options);
    }

    function TimestampDisplay({ dateString }) {
        // Parse the date string to a JavaScript Date object
        const dateObject = new Date(dateString);

        // Check if the date is valid
        const displayDate = isNaN(dateObject.getTime()) ? "Invalid Date" : dateObject;

        const display_date = formatDateString(displayDate.toString())

        return (
            <Typography>{display_date}</Typography>
        );
    }


    if (showCampaignContent) {
        return (
            <>
                {isLoading && <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress style={{ color: "black", }} size={70} /></Box>}
                {!isLoading &&
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <Grid container sx={{ width: "80%", display: "flex", flexDirection: "row", gap: "20px", alignItems: "center", justifyContent: "space-between" }}>
                            <Grid item xs sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <TextField
                                    hiddenLabel
                                    value={searchInfluencer}
                                    onChange={handleSearchInfluencer}
                                    size='small'
                                    fullWidth
                                    placeholder='Search Meme Pages'
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                <SearchIcon />
                                            </Box>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography sx={{ textAlign: "center" }}>Media Type</Typography>
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <FormControlLabel
                                            value="reel"
                                            control={<Checkbox checked={checkedReel} onClick={() => handleMediaTypeFilter("reel")} defaultChecked size='small' />}
                                            label="Reel"
                                            labelPlacement="top"
                                        />
                                        <FormControlLabel
                                            value="static"
                                            control={<Checkbox checked={checkedPost} onClick={() => handleMediaTypeFilter("post")} defaultChecked size='small' />}
                                            label="Static"
                                            labelPlacement="top"
                                        />
                                        <FormControlLabel
                                            value="story"
                                            control={<Checkbox checked={checkedStory} onClick={() => handleMediaTypeFilter("story")} defaultChecked size='small' />}
                                            label="Story"
                                            labelPlacement="top"
                                        />
                                        <FormControlLabel
                                            value="tweet"
                                            control={<Checkbox checked={checkedTweet} onClick={() => handleMediaTypeFilter("tweet")} defaultChecked size='small' />}
                                            label="Tweet"
                                            labelPlacement="top"
                                        />

                                        {/* <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <Typography>Top</Typography>
                                            <Checkbox size='small' />
                                        </Box> */}
                                    </Box>
                                </Box>
                            </Grid>
                            {checkedContent.length > 0 && <Grid item xs sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                {/* <Button onClick={deleteContentPieces} sx={{ border: "1px solid #DCDCDC", background: "#F8C036", color: "black", width: "fit-content" }}>Delete Content Pieces</Button> */}
                                <ThemeProvider theme={theme}>
                                    <Button onClick={() => deleteContentPieces()} variant="contained" sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black" }} disabled={isDeleting} endIcon={isDeleting && <CircularProgress sx={{ color: "black" }} size={20} />}>{isDeleting ? "Deleting" : "Delete Content Pieces"}</Button>
                                </ThemeProvider>
                            </Grid>}
                            {checkedContent.length > 0 && <Grid item xs sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Typography>{checkedContent.length} Content Piece(s) Selected</Typography>
                            </Grid>}
                            {checkedContent.length == 0 && <Grid item xs sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel >Sort By</InputLabel>
                                        <Select
                                            value={sortBy}
                                            label="Sort By"
                                            onChange={(event) => handleChangeSortBy(event)}
                                        >{options.map((value, index) => (
                                            <MenuItem value={index}>{value}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>}
                            {/* <Grid item xs></Grid> */}
                            {checkedContent.length == 0 && <Grid item xs sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "right", gap: "5px" }}>
                                {/* <Button onClick={handleOpenDeletedContent} sx={{ border: "1px solid #DCDCDC", background: "#F8C036", color: "black", width: "fit-content" }}>Deleted & Duplicate Content</Button> */}

                                <Button onClick={handleOpenAddedLinksSummary} sx={{ border: "1px solid #DCDCDC", background: "#F8C036", color: "black", width: "fit-content" }}>Content Links Summary</Button>
                                <Button onClick={handleOpenNewContent} sx={{ border: "1px solid #DCDCDC", background: "#F8C036", color: "black", width: "fit-content" }}>Add Content Link</Button>

                                {/*Modal for displaying deleted and duplicate content */}
                                <Modal
                                    open={openAddedLinksSummary}
                                    onClose={handleCloseAddedLinksSummary}
                                    BackdropProps={{ style: { backgroundColor: 'rgb(0,0,0,0.1)' } }}
                                >
                                    <Box sx={style2}>
                                        <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "10px", height: "5%" }}>
                                            <Typography>Content Links Summary</Typography>
                                        </Box>
                                        {linksSummary.length > 0 && <Grid container sx={{ gap: "5px" }}>
                                            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                                {/* <Typography>{linksSummary[linksSummaryTab]?.createdAt}</Typography> */}
                                                <TimestampDisplay dateString={linksSummary[linksSummaryTab]?.createdAt} />
                                            </Box>
                                            {/* <Button sx={buttonStyleSelected} onClick={() => handlePrevLinksSummary()}>Prev</Button> */}
                                            <Button sx={linksSummaryTab == 0 ? buttonStyleNotSelected : buttonStyleSelected} disabled={linksSummaryTab == 0} onClick={() => handlePrevNextLinksSummary(0)} ><NavigateBeforeOutlinedIcon /></Button>
                                            <Grid item xs>
                                                <Card sx={{ padding: "5px", textAlign: "center", }}>
                                                    <Typography sx={{ fontWeight: "900" }}>Total Links</Typography>
                                                    <Typography>{linksSummary[linksSummaryTab]?.totalLinks}</Typography>
                                                </Card>
                                            </Grid>
                                            <Grid item xs>
                                                <Card sx={{ padding: "5px", textAlign: "center" }}>
                                                    <Typography sx={{ fontWeight: "900" }}>Valid Links</Typography>
                                                    <Typography>{linksSummary[linksSummaryTab]?.addedLinks}</Typography>
                                                </Card>
                                            </Grid>
                                            <Grid item xs>
                                                <Card sx={{ padding: "5px", textAlign: "center" }}>
                                                    <Typography sx={{ fontWeight: "900" }}>Duplicate Links</Typography>
                                                    <Typography>{linksSummary[linksSummaryTab]?.duplicateLinks}</Typography>
                                                </Card>
                                            </Grid>
                                            <Grid item xs>
                                                <Card sx={{ padding: "5px", textAlign: "center" }}>
                                                    <Typography sx={{ fontWeight: "900" }}>Deleted Links</Typography>
                                                    <Typography>{linksSummary[linksSummaryTab]?.deletedLinks}</Typography>
                                                </Card>
                                            </Grid>
                                            {/* <Button sx={buttonStyleSelected} onClick={() => handleNextLinksSummary()}>Next</Button> */}
                                            <Button sx={linksSummaryTab == linksSummary.length - 1 ? buttonStyleNotSelected : buttonStyleSelected} disabled={linksSummaryTab == linksSummary.length - 1} onClick={() => handlePrevNextLinksSummary(1)}><NavigateNextOutlinedIcon /></Button>
                                        </Grid>}
                                        <Box sx={{ height: "65%", display: "flex", justifyContent: "center", flexDirection: "column", marginTop: "20px" }}>
                                            <TableContainer component={Paper} sx={{}}>
                                                <Table >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: "center", fontWeight: "800" }}>DELETED CONTENT</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {deletedUrls.map((row) => (
                                                            <TableRow
                                                                key={row}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0, } }}
                                                            >
                                                                <TableCell sx={{ textAlign: "center" }}>
                                                                    {row}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    </Box>
                                </Modal>

                                {/*Modal for adding new content */}
                                <Modal
                                    open={openNewContent}
                                    onClose={handleCloseNewContent}
                                    BackdropProps={{ style: { backgroundColor: 'rgb(0,0,0,0.1)' } }}
                                >
                                    <Box sx={style}>
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <Typography variant='h5'>Add New Content Pieces</Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                                            <ButtonGroup variant="text" sx={{ border: "1px solid #DCDCDC" }}>
                                                <Button sx={displayAddContentViaLinkScreen ? buttonStyleSelected : buttonStyleNotSelected} onClick={() => handleAddContentLink()}>Add via link</Button>
                                                <Button sx={!displayAddContentViaLinkScreen ? buttonStyleSelected : buttonStyleNotSelected} onClick={() => handleAddContentManually()}>Upload Manually</Button>
                                            </ButtonGroup>
                                        </Box>

                                        {displayAddContentViaLinkScreen &&
                                            <Box sx={{ marginTop: "30px" }}>
                                                <TextField
                                                    sx={{ width: "100%", marginTop: "10px" }}
                                                    placeholder="Insert link(s) here"
                                                    value={contentLink}
                                                    onChange={handleContentLink}
                                                    rows={8}
                                                    multiline />
                                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                    {!isLoadingAddNewContent && addNewContentText == "" && <Button sx={{ background: "#F8C036", color: "black", marginTop: "10px" }} onClick={() => addNewContent()}>Add</Button>}
                                                    {!isLoadingAddNewContent && addNewContentText != "" && <Typography sx={{ marginTop: "10px" }} >{addNewContentText}</Typography>}
                                                    {isLoadingAddNewContent && <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}><CircularProgress style={{ color: "black", }} size={30} /></Box>}
                                                </Box>
                                            </Box>
                                        }
                                        {!displayAddContentViaLinkScreen &&
                                            <Box sx={{ marginTop: "30px" }}>
                                                <Grid container>
                                                    <Grid item xs={4} sx={{ textAlign: "center" }}>
                                                        <Typography>Upload Media</Typography>
                                                    </Grid>
                                                    <Grid item xs={8} sx={{ textAlign: "center" }}>
                                                        <Typography>Add Metrics</Typography>
                                                    </Grid>
                                                    <Grid item xs={4} sx={{ display: "flex", flexDirection: "column" }}>
                                                        {/* Upload your content image/video */}
                                                        <Paper elevation={3} {...getRootProps()} style={dropzoneStyles}>
                                                            <input {...getInputProps()} />
                                                            {uploadProgress == 0 && <Typography component="div">
                                                                Drag or Drop your media file here
                                                            </Typography>}
                                                            {uploadProgress > 0 &&
                                                                <Typography>
                                                                    File Uploaded: {uploadProgress}%
                                                                </Typography>}
                                                        </Paper>
                                                        <Typography variant="body1" style={{ marginTop: '16px' }}>
                                                            Uploaded File: {uploadedFileName}
                                                        </Typography>
                                                        <Box style={{ color: "#000", position: "relative" }}>
                                                            <LinearProgress
                                                                color='inherit'
                                                                variant="determinate"
                                                                value={uploadProgress}
                                                                style={{ marginTop: '16px', width: '100%' }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={8} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingLeft: "10px" }}>
                                                        {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}> */}
                                                        <Grid container sx={{ gap: "10px" }}>
                                                            <Grid item xs={12}>
                                                                <InputLabel>Select media type</InputLabel>
                                                                <RadioGroup row
                                                                    value={manualMediaType}
                                                                    onChange={handleManualMediaType}
                                                                >
                                                                    <FormControlLabel value="story" control={<Radio />} label="Story" />
                                                                    <FormControlLabel value="post" control={<Radio />} label="Post" />
                                                                    <FormControlLabel value="reel" control={<Radio />} label="Reel" />
                                                                    <FormControlLabel value="tweet" control={<Radio />} label="Tweet" />
                                                                </RadioGroup>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <InputLabel>Select Content Date</InputLabel>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DemoContainer components={['DateTimePicker']}>
                                                                        <DateTimePicker value={contentDate} onChange={(newValue) => setContentDate(newValue)} />
                                                                    </DemoContainer>
                                                                </LocalizationProvider>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <InputLabel>Add Memepage Username</InputLabel>
                                                                <TextField
                                                                    required
                                                                    placeholder="Enter Username"
                                                                    value={username}
                                                                    onChange={(event) => handleEditMetrics("username", event)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={5}>
                                                                <InputLabel>Add Reach (views)</InputLabel>
                                                                <TextField
                                                                    type="number"
                                                                    placeholder="Enter Reach"
                                                                    value={views}
                                                                    onChange={(event) => handleEditMetrics("views", event)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <InputLabel>Add Likes</InputLabel>
                                                                <TextField
                                                                    type="number"
                                                                    placeholder="Add Likes"
                                                                    value={likes}
                                                                    onChange={(event) => handleEditMetrics("likes", event)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={5}>
                                                                <InputLabel>Add Comments</InputLabel>
                                                                <TextField
                                                                    type="number"
                                                                    placeholder="Add Comments"
                                                                    value={comments}
                                                                    onChange={(event) => handleEditMetrics("comments", event)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <InputLabel>Add Shares (retweets)</InputLabel>
                                                                <TextField
                                                                    type="number"
                                                                    placeholder="Add Shares"
                                                                    value={shares}
                                                                    onChange={(event) => handleEditMetrics("shares", event)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1}></Grid>
                                                            <Grid item xs={5}>
                                                                <InputLabel>Add Saves (bookmarks)</InputLabel>
                                                                <TextField
                                                                    type="number"
                                                                    placeholder="Add Saves"
                                                                    value={saves}
                                                                    onChange={(event) => handleEditMetrics("saves", event)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        {/* </Box> */}
                                                    </Grid>
                                                </Grid>
                                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                    {!isLoadingAddNewContent && addNewContentText == "" && <Button sx={{ background: "#F8C036", color: "black", marginTop: "10px" }} onClick={() => addContentManually()}>Add</Button>}
                                                    {!isLoadingAddNewContent && addNewContentText != "" && <Typography sx={{ marginTop: "10px" }} >{addNewContentText}</Typography>}
                                                    {isLoadingAddNewContent && <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}><CircularProgress style={{ color: "black", }} size={30} /></Box>}
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                </Modal>
                            </Grid>}
                        </Grid>
                        <Grid container style={{ width: "85%" }}>
                            {filteredContent.slice((page - 1) * 20, page * 20).map((value, index) => (
                                <Grid item xs={3} key={index} style={{ padding: "10px 2px 5px 2px", display: "flex", justifyContent: "center" }}>
                                    <Card style={{ height: "500px", borderRadius: "20px", background: (checkedMap.get(value.shortcode) ? "#DCDCDC" : "white"), border: "1px solid #DCDCDC", boxShadow: "none", padding: "10px 10px 10px 10px" }}>
                                        <Grid container>
                                            <Grid item xs={9} style={{ marginBottom: "10px", display: "flex", justifyContent: "left", alignItems: "center", gap: "5px" }}>
                                                <Checkbox checked={checkedMap.get(value.shortcode) || false} onChange={() => handleToggle(value.shortcode)} />
                                                <Avatar style={{ width: "40px", height: "40px" }} src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + value.influencer + ".jpg?alt=media"} />
                                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                    <Typography sx={{ fontWeight: "900" }}>@{value.influencer.substring(0, 8)}..</Typography>
                                                    {/* <Typography>Posted on:</Typography> */}
                                                    <Typography>{handleCustomDateFormat(value["timePosted"])}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={3} style={{ marginBottom: "10px", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                                <Typography style={{ background: mediaTypeBg[value.mediaType], padding: "4px 7px 4px 7px", borderRadius: "10px" }}>{(value.mediaType == "POST" ? "STATIC" : value.mediaType)}</Typography>
                                            </Grid>
                                            <Grid item xs onClick={() => handleOpen(index)} sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                                <CardMedia
                                                    component={getMediaComponent(value.mediaUrl)}
                                                    image={value.mediaUrl}
                                                    style={getMediaStyle(getMediaComponent(value.mediaUrl))}
                                                >
                                                </CardMedia>
                                            </Grid>
                                            <StyledModal
                                                open={openIndex === index}
                                                onClose={handleClose}
                                                slots={{ backdrop: StyledBackdrop }}
                                            >
                                                <CampaignVideoCard key={index} userData={userData} videoData={{ "campaignId": campaignData.campaignId, "postedOn": handleCustomDateFormat(value.timePosted), "mediaType": value.mediaType, "mediaComponent": getMediaComponent(value.mediaUrl), "mediaUrl": value.mediaUrl, "likes": value.likes, "comments": value.comments, "views": value.views, "shares": value.shares, "saves": value.saves, "caption": value.caption, "shortcode": value.shortcode, "permalink": value.permalink, "isManuallyUploaded": value.isManuallyUploaded, "isAnalyticsContent": false, "brands": [] }} />
                                            </StyledModal>
                                            <Grid container xs={12} sx={{ marginTop: "10px" }}>
                                                <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                                                    {/* sx={{ gap: "2px", display: "flex", justifyContent: "center", alignItems: "center" }} */}
                                                    <div className='trackingContentOuterCardMetrics'>
                                                        <FavoriteBorderOutlinedIcon fontSize='small' /> {formatNumber(value.likes)}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                                                    <div className='trackingContentOuterCardMetrics'>
                                                        <CommentOutlinedIcon fontSize='small' /> {formatNumber(value.comments)}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={4} style={{ gap: "5px", display: "flex", alignItems: "center" }}>
                                                    <div className='trackingContentOuterCardMetrics'>
                                                        <VisibilityOutlinedIcon fontSize='small' /> {formatNumber(value.views)}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                <Typography variant='body1' sx={{ display: "flex", fontWeight: "800" }}>
                                                    {caption[index]}
                                                </Typography>
                                            </Grid> */}
                                        </Grid>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid >
                        <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                            <Pagination count={Math.ceil(filteredContent.length / 20)} page={page} color='standard' shape='rounded' onChange={handlePageChange} />
                        </Box>
                    </div >}
            </>
        )
    }
    else {
        return (
            <div style={{ display: "flex", justifyContent: "center", }}>
                <Box
                    component="img"
                    sx={{
                        height: "380px",
                        width: "350px",
                    }}
                    alt="Campaigns Content No Results"
                    src={CampaignContentNoResults}
                />
            </div>
        )
    }
}

export default TrackingContent