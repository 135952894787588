import React from 'react';

import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

function PieCharts(props) {

    var outerRadius = 90;

    var sizing = {
        width: 300,
        height: 180,
        gap: "5px"

    };

    var distribution = props.distribution;
    var labels = props.labels;
    var type = props.type;

    // sizing = {
    //     width: 600,
    //     height: 360,
    //     gap: "5px"
    // };
    // outerRadius = 180

    if (type == "followers") {
        var colors = ["#E4F87F", "#FF8E8E"]
    } else if (type == "gender") {
        var colors = ["#B7E4FD", "#EFC3FF"]
    } else if (type == "ContentDistribution" || type == "InfluencerDistribution") {
        var colors = ["#E4F87F", "#EFC3FF", "#B7E4FD"];
        sizing = {
            width: 400,
            height: 260,
            gap: "5px"
        };
        outerRadius = 120
    }


    // const data = [
    //     { label: labels[0], value: distribution[0], color: '#0088FE' },
    //     { label: labels[1], value: distribution[1], color: '#00C49F' },
    // ];

    const data = labels.map((label, index) => ({
        label,
        value: (distribution[index] > 1 ? Math.round(distribution[index]) : distribution[index]),
        color: colors[index]
    }));

    const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

    const getArcLabel = (params) => {
        const percent = params.value / TOTAL;
        return `${(percent * 100).toFixed(0)}%`;
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <PieChart
                series={[
                    {
                        outerRadius: outerRadius,
                        data,
                        arcLabel: getArcLabel,
                    },
                ]}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'black',
                        fontSize: 20,
                    }
                }}
                {...sizing}
            />
        </div>
    );
}

export default PieCharts