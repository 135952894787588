import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import SelectFilter from './SelectFilter';
import { Box, Chip, Grid } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const filters = ["Followers", "Categories", "Engagement", "Age", "Gender", "Region", "Brands worked with", "Video Views"]

const categoryDisplay = "block"


const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    minHeight: 80,
    maxHeight: 80,
    '&.Mui-expanded': {
        minHeight: 30,
        maxHeight: 100,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function BasicAccordion(props) {

    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <div style={{ borderTop: "1px solid #C4C4C4" }}>
            <Accordion >
                <StyledAccordionSummary
                    expandIcon={<AddIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ display: "flex" }}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>{props.title}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ display: categoryDisplay }}>
                            {personName.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Grid>
                    </Grid>
                </StyledAccordionSummary>
                <AccordionDetails>
                    <div>
                        <FormControl sx={{ width: 300 }}>
                            <InputLabel id="demo-multiple-chip-label">Chip</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={personName}
                                onChange={handleChange}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {/* {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))} */}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {names.map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                        style={getStyles(name, personName, theme)}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
