import React, { useEffect, useState } from 'react'
import { Avatar, Card, CardMedia, Grid, Typography, Box, CircularProgress, Modal } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CampaignContentNoResults from "../../assets/campaignContentNoResults.png";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CampaignVideoCard from './CampaignVideoCard';


const StyledModal = styled(Modal)`
position: fixed;
z-index: 1300;
inset: 0;
display: flex;
align-items: center;
justify-content: center;
`;

const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'MuiBackdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});

Backdrop.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
};

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

function CampaignContent(props) {

    const location = useLocation();
    const propsData = location.state;

    const campaignData = propsData.campaignData;
    const userData = props.userData;

    const [isLoading, setIsLoading] = useState(false)
    const [campaignContent, setCampaignContent] = useState([])

    async function fetchCampaignContent() {
        var campaignId = campaignData.campaignId;
        if (campaignId != null) {
            setIsLoading(true);
            const token2 = await userData.getIdToken()

            let data = {
                campaignId: campaignId
            }

            let options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }


            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_campaign_content', options)
                .then(result => result.json())
                .then(body => { setCampaignContent(body); setIsLoading(false); });
        }
        else { console.log("Invalid campaign id") }
    }

    useEffect(() => {
        fetchCampaignContent()
    }, [])

    const mediaTypeBg = { "POST": "#5BCEFF", "STORY": "#E69EFF", "REEL": "#E4F87F" }

    const showCampaignContent = (campaignContent.length > 0) ? true : false;

    const [openIndex, setOpenIndex] = React.useState(-1); // Initialize with -1
    const handleOpen = (index) => {
        setOpenIndex(index); // Set the index of the opened modal
    };
    const handleClose = () => {
        setOpenIndex(-1); // Reset the index when closing the modal
    };

    function getMediaComponent(url) {
        const urlWithoutQuery = url.split('?')[0];

        const urlParts = urlWithoutQuery.split('/');
        const lastPart = urlParts[urlParts.length - 1];
        const fileExtension = lastPart.split('.').pop();

        return (fileExtension == "jpg") ? "image" : "video"
    }

    function formatNumber(number) {
        if (number >= 1e6) {
            return (number / 1e6) + "M";
        } else if (number >= 1e3) {
            return (number / 1e3) + "K";
        } else {
            if (number == 0) {
                return "NA"
            } else {
                return number;
            }
        }
    }


    function getMediaStyle(mediaComponent) {
        const imageStyle = { width: "550px", height: "390px", borderRadius: "20px", cursor: "pointer", borderRadius: "20px" }
        const videoStyle = { width: "550px", height: "390px", background: "black", borderRadius: "20px", cursor: "pointer", borderRadius: "20px" }
        if (mediaComponent == "image") {
            return imageStyle;
        } else {
            return videoStyle;
        }
    }

    if (showCampaignContent) {
        return (
            <>
                {isLoading && <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress style={{ color: "black", }} size={70} /></Box>}
                {!isLoading &&
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Grid container style={{ width: "80%" }}>
                            {campaignContent.map((value, index) => (
                                <Grid item xs={3} key={index} style={{ padding: "10px 2px 5px 2px", display: "flex", justifyContent: "center" }}>
                                    <Card style={{ height: "500px", borderRadius: "20px", border: "1px solid #DCDCDC", boxShadow: "none", padding: "10px" }}>
                                        <Grid container>
                                            <Grid item xs={9} style={{ marginBottom: "10px", display: "flex", justifyContent: "left", alignItems: "center", gap: "5px" }}>
                                                <Avatar style={{ width: "40px", height: "40px" }} src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + value.influencer + ".jpg?alt=media"} />
                                                <Typography >@{value.influencer.substring(0, 12)}..</Typography>
                                            </Grid>
                                            <Grid item xs={3} style={{ marginBottom: "10px", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                                <Typography style={{ background: mediaTypeBg[value.mediaType], padding: "4px 7px 4px 7px", borderRadius: "10px" }}>{value.mediaType}</Typography>
                                            </Grid>
                                            <Grid item xs onClick={() => handleOpen(index)} sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                                <CardMedia
                                                    component={getMediaComponent(value.mediaUrl)}
                                                    image={value.mediaUrl}
                                                    style={getMediaStyle(getMediaComponent(value.mediaUrl))}
                                                >
                                                </CardMedia>
                                            </Grid>
                                            <StyledModal
                                                open={openIndex === index}
                                                onClose={handleClose}
                                                slots={{ backdrop: StyledBackdrop }}
                                            >
                                                <CampaignVideoCard key={index} videoData={{ "mediaType": value.mediaType, "mediaComponent": getMediaComponent(value.mediaUrl), "mediaUrl": value.mediaUrl, "likes": value.likes, "comments": value.comments, "views": value.views, "caption": value.caption, "shortcode": value.contentId, "shares": value.shares, "saves": value.saves, "permalink": value.permalink, "brands": [] }} />
                                            </StyledModal>
                                            <Grid container xs sx={{ marginTop: "10px", padding: "0px 10px 0px 10px" }}>
                                                <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                                                    <Typography sx={{ gap: "2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <FavoriteBorderOutlinedIcon /> {formatNumber(value.likes)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                                                    <Typography sx={{ gap: "2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <CommentOutlinedIcon /> {formatNumber(value.comments)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} style={{ gap: "5px", display: "flex", alignItems: "center" }}>
                                                    <Typography sx={{ gap: "2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <VisibilityOutlinedIcon /> {formatNumber(value.views)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                <Typography variant='body1' sx={{ display: "flex", fontWeight: "800" }}>
                                                    {caption[index]}
                                                </Typography>
                                            </Grid> */}
                                        </Grid>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid >
                    </div >}
            </>
        )
    }
    else {
        return (
            <div style={{ display: "flex", justifyContent: "center", }}>
                <Box
                    component="img"
                    sx={{
                        height: "380px",
                        width: "350px",
                    }}
                    alt="Campaigns Content No Results"
                    src={CampaignContentNoResults}
                />
            </div>
        )
    }
}

export default CampaignContent