import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Mixpanel } from '../../mixpanel/mixpanel.js';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { Avatar, AvatarGroup, Badge, Box, Button, Card, Chip, CircularProgress, Grid, InputAdornment, Modal, Slide, Stack, TextField, Typography } from '@mui/material';

import "../memechat/main.css";


function LeaderboardInfluencers(props) {

    // const location = useLocation();
    // const campaignData = location.state;
    // const user = props.userData

    const leaderboardInfluencers = props.leaderboardInfluencers;

    const [isLoading, setIsLoading] = useState(false);
    const [campaignInfluencers, setCampaignInfluencers] = useState([])


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); }

    // async function fetchCampaignInfluencers() {

    //     var campaignId = campaignData.campaignData.campaignId;
    //     if (campaignId != null) {
    //         setIsLoading(true);
    //         const token2 = await user.getIdToken()

    //         let data = {
    //             campaignId: campaignId
    //         }

    //         let fetchCampaignInfluencersOptions = {
    //             method: 'POST',
    //             body: JSON.stringify(data),
    //             headers: new Headers({
    //                 'Authorization': `Bearer ${token2}`,
    //                 'Content-Type': "application/json"
    //             })
    //         }


    //         await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_tracking_influencers', fetchCampaignInfluencersOptions)
    //             .then(result => result.json())
    //             .then(body => { setCampaignInfluencers(body); setIsLoading(false); });
    //     }
    //     else { console.log("Invalid campaign id") }
    // };


    // useEffect(() => {
    //     fetchCampaignInfluencers()
    // }, [])

    // useEffect(() => {
    //     const c = [0, 0, 0, 0, 0, 0]
    //     for (const row of campaignInfluencers) {
    //         const rowStatus = row.status;
    //         if (rowStatus == "Shortlisted") {
    //             c[0] += 1;
    //         } else if (rowStatus == "Messaged") {
    //             c[1] += 1;
    //         } else if (rowStatus == "In Conversation") {
    //             c[2] += 1;
    //         } else if (rowStatus == "Product Sent") {
    //             c[3] += 1;
    //         } else if (rowStatus == "Content Review") {
    //             c[4] += 1;
    //         } else if (rowStatus == "Content Published") {
    //             c[5] += 1;
    //         }
    //     }
    //     setCounts(c)
    // }, [campaignInfluencers, filteredRows])


    // useEffect(() => {
    //     const fRows = []
    //     for (const row of campaignInfluencers) {
    //         var rowStatus = 0;
    //         if (row.status == "Shortlisted") { rowStatus = 0 }
    //         else if (row.status == "Messaged") { rowStatus = 1 }
    //         else if (row.status == "In Conversation") { rowStatus = 2 }
    //         else if (row.status == "Product Sent") { rowStatus = 3 }
    //         else if (row.status == "Content Review") { rowStatus = 4 }
    //         else if (row.status == "Content Published") { rowStatus = 5 }
    //         // const rowStatus = row.status;

    //         if (statusTab === rowStatus) {
    //             fRows.push(row);
    //         }
    //     }
    //     setFilteredRows(fRows)
    // }, [campaignInfluencers, statusTab, isLoadingAction])

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    const renderDetailsAvatar = (params) => {
        const influencerPhoto = "https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + params.value.username + ".jpg?alt=media"
        // console.log("params.value.category")
        // console.log(params.value.category)
        return (
            <Box sx={{ display: "flex", width: "100%" }}>
                <Grid container style={{ gap: "5px" }}>
                    <Grid item >
                        <Avatar src={influencerPhoto} />
                    </Grid>
                    <Grid item >
                        {params.value.username}
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const renderContentDelivered = (params) => {
        const contentDelivered = params.row.contentDelivered;

        return (
            <Box >
                <Typography className='trackingInfluencersGrid'>
                    {contentDelivered["reel"]} Reel(s),
                </Typography>
                <Typography className='trackingInfluencersGrid'>
                    {contentDelivered["post"]} Post(s)
                </Typography>
            </Box>
        )
    }


    const columns = [
        { field: 'id', headerName: 'id' },
        {
            field: 'users',
            headerName: 'Users',
            flex: 2,
            renderCell: renderDetailsAvatar,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'shares',
            headerName: 'Shares',
            flex: 1,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'saves',
            headerName: 'Saves',
            flex: 1,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'views',
            headerName: 'Views',
            flex: 1,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'likes',
            headerName: 'Likes',
            flex: 1,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'comments',
            headerName: 'Comments',
            flex: 1,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'points',
            headerName: 'Points',
            flex: 1,
            headerAlign: 'center',
            align: 'center'
        },
        // {
        //     field: 'contentDelivered',
        //     headerName: 'Content Delivered',
        //     flex: 2,
        //     renderCell: renderContentDelivered,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        // {
        //     field: 'followers',
        //     headerName: 'Followers',
        //     flex: 1.5,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
    ];

    const [deleteInfluencers, setDeleteInfluencers] = useState([]);

    // async function removeInfluencersFromCampaign() {
    //     const token2 = await user.getIdToken();

    //     let data = { influencers: deleteInfluencers, campaignId: campaignData.campaignData.campaignId }

    //     let options = {
    //         method: 'POST',
    //         body: JSON.stringify(data),
    //         headers: new Headers({
    //             'Authorization': `Bearer ${token2}`,
    //             'Content-Type': "application/json"
    //         })
    //     }

    //     // console.log(data)

    //     await fetch(process.env.REACT_APP_API_ADDRESS + '/api/remove_campaign_influencers', options)
    //         .then(result => result.json())
    //         .then(body => { setDeleteInfluencers([]); fetchCampaignInfluencers(); });
    // }

    function QuickSearchToolbar() {
        return (
            <Box>
                <Grid container>
                    <Grid item xs sx={{ pt: 1, pl: 1, display: "flex", alignItems: 'center' }} >
                        <GridToolbarQuickFilter />
                    </Grid>
                    {/* <Grid item xs sx={{ pr: 1, pt: 1, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <Button sx={{ color: "black", background: (deleteInfluencers.length > 0 ? "#FF8E8E" : "#C4C4C4"), width: "fit-content", border: "1px solid black" }} disabled={(deleteInfluencers.length > 0 ? false : true)}
                            onClick={() => removeInfluencersFromCampaign()}>
                            REMOVE FROM CAMPAIGN
                        </Button>
                    </Grid> */}
                </Grid>
            </Box>
        );
    }


    const onRowsSelectionHandler = (ids) => {
        const selectedRowsData = ids.map((id) => leaderboardInfluencers.find((row) => row.id === id));
        var influencers = []
        // console.log(selectedRowsData)
        selectedRowsData.forEach(function (d) {
            influencers.push({ "username": d.users.username })
        })
        // console.log(influencers)
        setDeleteInfluencers(influencers);
    };

    function formatNumber(number) {
        if (number >= 1e6) {
            return (number / 1e6).toFixed(2) + "M";
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(2) + "K";
        } else {
            if (number == 0) {
                return number
            } else {
                return number;
            }
        }
    }

    return (
        <Grid container style={{ display: "flex", justifyContent: "center", marginBottom: "20px", height: "80px" }}>
            <Box sx={{ width: "95%", padding: "20px" }}>
                {isLoading && <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress style={{ color: "black" }} size={50} /></Box>}
                {!isLoading &&
                    <DataGrid
                        onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        rowHeight={80}
                        rows={leaderboardInfluencers}
                        columns={columns}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                    viewProfile: true
                                }
                            },
                            pagination: {
                                paginationModel: {
                                    pageSize: 100,
                                },
                            },
                        }}
                        pageSizeOptions={[100]}
                        checkboxSelection
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableRowSelectionOnClick
                        disableColumnMenu
                        slots={{ toolbar: QuickSearchToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                printOptions: {
                                    disableToolbarButton: true
                                },
                                csvOptions: {
                                    disableToolbarButton: true
                                }
                            }
                        }}
                    />}
            </Box>
        </Grid>
    )
}

export default LeaderboardInfluencers