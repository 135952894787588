import React from 'react'
import LockIcon from '@mui/icons-material/Lock';
import { Avatar, Button, Card, Grid, LinearProgress, Typography } from '@mui/material'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Link } from 'react-router-dom';

function ListBar(props) {

    var listData = props.listData;
    var totalLists = props.totalLists;

    var isListLocked = listData.isListLocked;
    var lockedListLink = (isListLocked == true) ? "/lists" : "/list";

    return (
        <div>
            <Link to={lockedListLink} state={{ listData: listData, totalLists: totalLists }} style={{ textDecoration: "none" }}>
                <Grid container style={{ justifyContent: "center", height: "120px" }}>
                    <Card sx={{ width: "100%", border: "2px solid #DCDCDC", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "25px", boxShadow: "none" }}>
                        <Grid container pr={4} pl={5} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Grid item xs={11}>
                                <Grid container style={{ gap: "10px", display: "flex", alignItems: "center" }}>
                                    <div style={{ width: "80px", height: "80px", background: `radial-gradient(#FFFFFF, #F8C036)`, borderRadius: "50%" }} />
                                    <div style={{ display: "flex", height: "50%", gap: "10px" }}>
                                        <Grid item sx={{ display: "flex", flexDirection: "column", }}>
                                            <Grid item fontSize={20} sx={{ textAlign: "left", marginBottom: "10px" }}>{listData.listName}</Grid>
                                            {isListLocked && <Grid item fontSize={16}>Identify influencers from your customers</Grid>}
                                            {/* <Grid item fontSize={18} sx={{ display: "flex", alignItems: "center" }}><PeopleOutlineOutlinedIcon />  100</Grid> */}
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>


                            <Grid item xs={1} style={{ display: "flex", justifyContent: "right" }} fontSize={30}>
                                {!isListLocked && <ArrowForwardIosOutlinedIcon />}
                                {isListLocked && <LockIcon fontSize="large" />}
                            </Grid>
                        </Grid>
                    </Card>
                </Grid >
            </Link>
        </div >
    )
}

export default ListBar