import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import StarIcon from '@mui/icons-material/Star';
import { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Fuse from 'fuse.js'
import { idID } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: "#E4F87F",
        },
    },
});

const style = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: "30%",
    height: "auto",
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: "10px",
    p: 3,
};

function Address(props) {

    const handleCloseProp = props.handleClose;
    const userData = props.userData;


    const [isLoading, setIsLoading] = useState(false)
    const [address, setAddress] = useState({ 'address1': '', 'address2': '', 'city': '', 'zip': '', 'province': '', "country_code": "IN" })
    function handleAddressChange(key, value) {
        setAddress((prevAdd) => ({
            ...prevAdd,
            [key]: value
        }))
    }

    useEffect(() => {
        if (props.savedAddress != "") {
            setAddress(props.savedAddress)
        }
    }, [props.savedAddress])

    const handleSaveAddress = () => {
        props.saveAddress(address)
    }

    async function fetchAllProducts() {
        const token2 = await userData.getIdToken()

        // let data = { influencer: influencer.username }
        let options = {
            method: 'GET',
            // body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_all_products', options)
            .then(result => result.json())
            .then(body => { setIsLoading(false) });
    }

    // useEffect(() => {
    //     setIsLoading(true)
    //     fetchAllProducts()
    // }, []);

    const isAddressDisabled = Object.values(address).every(value => value.trim() !== '');

    return (
        <Box sx={style}>
            <Typography variant="h6">
                Ship Free Product(s)
            </Typography>
            <Typography variant="h8" sx={{ color: "gray" }}>
                Add address and select the product to ship
            </Typography>
            <Grid container sx={{ gap: "20px", marginTop: "30px" }}>
                <Grid item xs={12}>
                    {/* <TextField
                            style={{ width: "70%" }}
                            required
                            placeholder="List Name"
                            value={listName}
                            onChange={handleListName}
                        /> */}
                    <InputLabel shrink sx={{ display: "flex" }}>
                        Address Line 1
                        <StarIcon sx={{ height: "10px", color: "red", marginTop: "2px" }} />
                    </InputLabel>
                    <TextField
                        sx={{ width: "100%" }}
                        required
                        placeholder="Address Line 1"
                        value={address['address1']}
                        onChange={(e) => handleAddressChange('address1', e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} >
                    <InputLabel shrink sx={{ display: "flex" }}>
                        Address Line 2
                        <StarIcon sx={{ height: "10px", color: "red", marginTop: "2px" }} />
                    </InputLabel>
                    <TextField
                        sx={{ width: "100%" }}
                        required
                        placeholder="Address Line 2"
                        value={address['address2']}
                        onChange={(e) => handleAddressChange('address2', e.target.value)}
                    />
                </Grid>

                <Grid item>
                    <InputLabel shrink sx={{ display: "flex" }}>
                        City
                        <StarIcon sx={{ height: "10px", color: "red", marginTop: "2px" }} />
                    </InputLabel>
                    <TextField
                        required
                        placeholder="City"
                        value={address['city']}
                        onChange={(e) => handleAddressChange('city', e.target.value)}
                    />
                </Grid>

                <Grid item>
                    <InputLabel shrink sx={{ display: "flex" }}>
                        Postal Code
                        <StarIcon sx={{ height: "10px", color: "red", marginTop: "2px" }} />
                    </InputLabel>
                    <TextField
                        required
                        placeholder="Postal Code"
                        value={address['zip']}
                        onChange={(e) => handleAddressChange('zip', e.target.value)}
                    />
                </Grid>

                <Grid item>
                    <InputLabel shrink sx={{ display: "flex" }}>
                        State
                        <StarIcon sx={{ height: "10px", color: "red", marginTop: "2px" }} />
                    </InputLabel>
                    <TextField
                        required
                        placeholder="State"
                        value={address['province']}
                        onChange={(e) => handleAddressChange('province', e.target.value)}
                    />
                </Grid>

                {/* <Grid item>
                    <InputLabel shrink sx={{ display: "flex" }}>
                        Country
                        <StarIcon sx={{ height: "10px", color: "red", marginTop: "2px" }} />
                    </InputLabel>
                    <TextField
                        required
                        placeholder="Country"
                        value={address['country']}
                        onChange={(e) => handleAddressChange('country', e.target.value)}
                    />
                </Grid> */}
                {/* {isLoading && <Button style={{ backgroundColor: "#E4F87F", color: "black", borderRadius: "5px", width: "20%", border: "1px solid #C4C4C4" }} ><CircularProgress style={{ color: "black", }} size={50} /></Button>} */}
                <ThemeProvider theme={theme}>
                    <Button disabled={!isAddressDisabled} variant='contained' style={{ borderRadius: "5px", width: "25%", border: "1px solid #C4C4C4", boxShadow: "none" }} onClick={handleSaveAddress}>Save</Button>
                </ThemeProvider>
            </Grid>
        </Box >
    )
}

export default Address
