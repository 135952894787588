import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Avatar, Button, CircularProgress } from '@mui/material';
import { CardMedia, } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import PieCharts from '../PieCharts';
import React, { useEffect, useState } from 'react'
import TrackingLineCharts from './TrackingLineCharts';
import ShareCampaignVideoCard from './ShareCampaignVideoCard';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { Box, Modal, Grid, Card, Typography, Divider } from '@mui/material'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CampaignAnalyticsNoResults from "../../../assets/campaignAnalyticsNoResults.png";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import ShareIcon from "../../../assets/shareIcon.png"
import SaveIcon from "../../../assets/saveIcon.png"

import "../main.css";

const StyledModal = styled(Modal)`
position: fixed;
z-index: 1300;
inset: 0;
display: flex;
align-items: center;
justify-content: center;
`;

const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'MuiBackdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});

Backdrop.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
};

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

function ShareTrackingAnalytics(props) {

    const location = useLocation();
    const propsData = location.state;

    const campaignId = props.campaignId;
    const userData = props.userData;


    const renderDetailsAvatar = (params) => {
        const influencerPhoto = "https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + params.row.username + ".jpg?alt=media"

        return (
            <Grid container style={{ gap: "10px" }}>
                <Grid item >
                    <Avatar src={influencerPhoto} />
                </Grid>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                    {params.row.username}
                </Grid>
            </Grid>
        )
    }

    const renderDeliverables = (params) => {
        const contentDelivered = params.row.deliverables;
        return (

            <Box sx={{ display: "flex", gap: "5px" }}>
                <Box>
                    {contentDelivered["reel"]} Reel(s),
                </Box>
                <Box>
                    {contentDelivered["post"]} Post(s)
                </Box>
            </Box>
        )
    }

    const columns = [
        // {
        //     field: 'id',
        //     headerName: 'Rank',
        //     flex: 1,
        //     renderCell: renderRank
        // },
        {
            field: 'users',
            headerName: 'Users',
            flex: 2,
            sortable: false,
            renderCell: renderDetailsAvatar
        },
        {
            field: 'deliverables',
            headerName: 'Deliverables',
            flex: 2,
            sortable: false,
            renderCell: renderDeliverables
        },
        {
            field: 'shares',
            headerName: 'Shares',
            sortable: true,
            flex: 1,
        },
        {
            field: 'saves',
            headerName: 'Saves',
            sortable: true,
            flex: 1,
        },
        {
            field: 'engagement',
            headerName: 'Engagement',
            sortable: true,
            flex: 1,
        },
        {
            field: 'comments',
            headerName: 'Comments',
            sortable: true,
            flex: 1,
        },
        {
            field: 'likes',
            headerName: 'Likes',
            sortable: true,
            flex: 1,
        }
    ];

    // const rows = [
    //     { id: 1, username: "abcd", deliverables: ["2 Reels", "1 Story"], shares: 5, saves: 3, engagement: "2.3%", comments: 23, likes: 12 },
    //     { id: 2, username: "abcd", deliverables: ["Reel", "Story"], shares: 4, saves: 3, engagement: "2.3%", comments: 23, likes: 12 },
    //     { id: 3, username: "abcd", deliverables: ["Reel", "Story"], shares: 4, saves: 3, engagement: "2.3%", comments: 23, likes: 12 },
    //     { id: 4, username: "abcd", deliverables: ["Reel", "Story"], shares: 4, saves: 3, engagement: "2.3%", comments: 23, likes: 12 },
    //     { id: 5, username: "abcd", deliverables: ["Reel", "Story"], shares: 4, saves: 3, engagement: "2.3%", comments: 23, likes: 12 }
    // ]; 


    const reels = ["reel1", "reel2", "reel3", "reel4"];

    // const mediaTypeBg = { "POST": "#5BCEFF", "STORY": "#E69EFF", "REEL": "#E4F87F" }
    const mediaTypeBg = { "POST": "#D9BF00", "STORY": "#E69EFF", "REEL": "#E4F87F", "YOUTUBE": "#FF0000", "TWEET": "#1A8CD8" }



    const [campaignAnalytics, setCampaignAnalytics] = useState({})
    const [viewsGraph, setViewsGraph] = useState({})
    const [stats, setStats] = useState([])
    const [currRefresh, setCurrRefresh] = useState(0)
    const [displayCampaignAnalytics, setDisplayCampaignAnalytics] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [openIndex, setOpenIndex] = useState(-1); // Initialize with -1

    async function fetchCampaignAnalytics() {

        // console.log("fetching campaign analytics")
        const c_id = campaignId;
        if (c_id != null) {
            setIsLoading(true);
            const token2 = await userData.getIdToken()

            let data = {
                campaignId: c_id
            }

            let options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }

            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_campaign_analytics', options)
                .then(result => result.json())
                .then(body => { setCampaignAnalytics(body); setViewsGraph(body["viewsGraph"]); setStats(body["stats"]); if ("stats" in body) { setCurrRefresh(body["stats"].length - 1) } setDisplayCampaignAnalytics(body["displayCampaignAnalytics"]); setIsLoading(false) });
        }
        else { console.log("Invalid campaign id") }
    }

    useEffect(() => {
        fetchCampaignAnalytics()
    }, [])

    const handleOpen = (index) => {
        setOpenIndex(index); // Set the index of the opened modal
    };
    const handleClose = () => {
        setOpenIndex(-1); // Reset the index when closing the modal
    };

    function getMediaComponent(url) {
        const urlWithoutQuery = url.split('?')[0];

        const urlParts = urlWithoutQuery.split('/');
        const lastPart = urlParts[urlParts.length - 1];
        const fileExtension = lastPart.split('.').pop();

        return (fileExtension == "jpg") ? "image" : "video"
    }

    function getMediaStyle(mediaComponent) {
        const imageStyle = { width: "550px", height: "390px", borderRadius: "20px", cursor: "pointer", borderRadius: "20px" }
        const videoStyle = { width: "550px", height: "390px", background: "black", borderRadius: "20px", cursor: "pointer", borderRadius: "20px" }
        if (mediaComponent == "image") {
            return imageStyle;
        } else {
            return videoStyle;
        }
    }

    function formatNumber(number) {
        if (number >= 1e6) {
            return (number / 1e6).toFixed(1) + "M";
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(1) + "K";
        } else {
            if (number == 0) {
                return "NA"
            } else {
                return number;
            }
        }
    }



    function handleDisplayStats(buttonPress) {
        var s = campaignAnalytics["stats"].length
        if (buttonPress == "prev") {
            var tempRefresh = currRefresh;
            if (tempRefresh != 0) {
                setCurrRefresh(tempRefresh - 1)
            }
        }
        else if (buttonPress == "next") {
            var tempRefresh = currRefresh;
            if (tempRefresh != (s - 1)) {
                setCurrRefresh(tempRefresh + 1)
            }
        }
    }

    // console.log("Stats")
    // console.log(stats)

    var displayViewsGraph = true;
    if (Object.keys(viewsGraph).length == 0) {
        console.log("views graph empty")
        displayViewsGraph = false;
    }

    if (isLoading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress style={{ color: "black", }} size={50} />
            </Box>
        )
    } else {

        if (displayCampaignAnalytics) {
            return (
                <Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box sx={{ width: "80%", display: "flex", justifyContent: "right" }}>
                            {/* <Button sx={{ color: "black", border: "1px solid black", marginBottom: "20px" }} endIcon={<DownloadIcon />}>Export Report</Button> */}
                        </Box>
                    </Box>
                    <Grid container style={{ justifyContent: "center", marginBottom: "20px", height: "100%" }}>
                        <Card sx={{ width: "80%", border: "2px solid #DCDCDC", textAlign: "center", borderRadius: "25px", boxShadow: "none", padding: "20px 0px 20px 0px" }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 10px 20px 10px" }}>
                                {/* <Box >
                                <Button onClick={() => handleDisplayStats("prev")} startIcon={<NavigateBeforeIcon />} sx={{ marginRight: "5px", border: "1px solid black", color: "black", background: "#E4F87F", width: "fit-content" }}>Prev</Button>
                                <Button onClick={() => handleDisplayStats("next")} endIcon={<NavigateNextIcon />} sx={{ border: "1px solid black", color: "black", background: "#E4F87F", width: "fit-content" }}>Next</Button>
                            </Box> */}
                                {/* <Box >
                                <Typography variant='h5'>Refresh: {currRefresh}</Typography>
                                <Typography variant='body'>Refresh Date: {stats[currRefresh]["refreshedAt"]}</Typography>
                            </Box> */}
                            </Box>
                            <Grid container pr={8} pl={8} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Grid item xs={3} fontSize={30}>{formatNumber(stats[currRefresh]["totalInfluencers"])}</Grid>
                                <Grid item xs={3} fontSize={30}>{formatNumber(stats[currRefresh]["totalContent"])}</Grid>
                                <Grid item xs={3} fontSize={30}>{formatNumber(stats[currRefresh]["totalViews"])}</Grid>
                                <Grid item xs={3} fontSize={30}>{formatNumber(stats[currRefresh]["totalLikes"])}</Grid>
                                {/* <Grid item xs={3} fontSize={30}>123</Grid> */}

                                <div style={{ height: "40px" }}></div>
                                <Grid item xs={3} fontSize={15}>Total Influencers</Grid>
                                <Grid item xs={3} fontSize={15}>Content Generated</Grid>
                                <Grid item xs={3} fontSize={15}>Total Reach</Grid>
                                <Grid item xs={3} fontSize={15}>Total Likes</Grid>
                                {/* <Grid item xs={3} fontSize={15}>Cost Incurred</Grid> */}
                            </Grid>
                            <Grid sx={{ display: "flex", justifyContent: "center", padding: "20px 0px 20px 0px" }}>
                                <Divider style={{ width: "90%", display: "flex", justifyContent: "center", alignItems: 'center' }} />
                            </Grid>
                            <Grid container pr={8} pl={8} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Grid item xs={3} fontSize={30}>{formatNumber(stats[currRefresh]["totalShares"])}</Grid>
                                <Grid item xs={3} fontSize={30}>{formatNumber(stats[currRefresh]["totalEngagement"])}</Grid>
                                <Grid item xs={3} fontSize={30}>{formatNumber(stats[currRefresh]["totalSaves"])}</Grid>
                                <Grid item xs={3} fontSize={30}>{formatNumber(stats[currRefresh]["totalComments"])}</Grid>
                                {/* <Grid item xs={3} fontSize={30}>123</Grid> */}

                                <div style={{ height: "40px" }}></div>
                                <Grid item xs={3} fontSize={15}>Total Shares</Grid>
                                <Grid item xs={3} fontSize={15}>Total Engagement</Grid>
                                <Grid item xs={3} fontSize={15}>Total Saves</Grid>
                                <Grid item xs={3} fontSize={15}>Total Comments</Grid>
                                {/* <Grid item xs={3} fontSize={15}>Link Clicks</Grid> */}
                            </Grid>
                        </Card>
                    </Grid>
                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Grid container style={{ width: "80%", display: "flex", justifyContent: "center" }}>
                            {/* <Grid item xs={12} style={{ border: "2px solid #DCDCDC", borderRadius: "25px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                            <Typography variant="h4" sx={{ textAlign: "center", marginTop: "10px" }}>Refresh Metrics</Typography>
                            <TrackingLineCharts showVideo={false} showRefresh={true} campaignAnalytics={campaignAnalytics} />
                        </Grid> */}

                            {displayViewsGraph && <Grid item xs={12} style={{ border: "2px solid #DCDCDC", borderRadius: "25px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                                <Typography variant="h4" sx={{ textAlign: "center", marginTop: "10px" }}>Reach</Typography>
                                <TrackingLineCharts showVideo={true} showRefresh={false} campaignAnalytics={campaignAnalytics} />
                            </Grid>}
                            <Grid item xs={12} style={{ border: "2px solid #DCDCDC", borderRadius: "25px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                                <Typography variant="h4" sx={{ textAlign: "center", marginTop: "10px" }}>Eng vs Shares vs Saves</Typography>
                                <TrackingLineCharts showVideo={false} showRefresh={false} campaignAnalytics={campaignAnalytics} />
                            </Grid>
                            <Grid item xs={12} style={{ padding: "20px 0px 20px 0px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", border: "2px solid #DCDCDC", borderRadius: "25px", marginBottom: "20px" }}>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                    {/* variant="h4" sx={{ textAlign: "center", marginTop: "10px" }} */}
                                    <div className='pieChartHeading'>Content Distribution</div>
                                    <PieCharts labels={["Reel", "Static", "Story", "Tweet"]} distribution={[campaignAnalytics["pieCharts"]["contentDistribution"]["reel"], campaignAnalytics["pieCharts"]["contentDistribution"]["post"], campaignAnalytics["pieCharts"]["contentDistribution"]["story"], campaignAnalytics["pieCharts"]["contentDistribution"]["tweet"] == undefined ? 0 : campaignAnalytics["pieCharts"]["contentDistribution"]["tweet"]]} type="ContentDistribution" />
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                    <div className='pieChartHeading'>Meme Page Distribution</div>
                                    <PieCharts labels={["Macro", "Premium", "Micro"]} distribution={[campaignAnalytics["pieCharts"]["influencerDistribution"]["micro"], campaignAnalytics["pieCharts"]["influencerDistribution"]["macro"], campaignAnalytics["pieCharts"]["influencerDistribution"]["nano"]]} type="InfluencerDistribution" />
                                </Box>
                            </Grid>
                            {/* <Grid item xs={5} style={{ height: "50vh", display: "flex", flexDirection: "column", justifyContent: "center", border: "2px solid #DCDCDC", borderRadius: "25px", marginBottom: "20px" }}>
                            <Typography variant="h4" sx={{ textAlign: "center", marginTop: "10px" }}>Influencer Distribution</Typography>
                            <PieCharts labels={["Micro", "Macro", "Nano"]} distribution={[campaignAnalytics["pieCharts"]["influencerDistribution"]["micro"], campaignAnalytics["pieCharts"]["influencerDistribution"]["macro"], campaignAnalytics["pieCharts"]["influencerDistribution"]["nano"]]} type="InfluencerDistribution" />
                        </Grid> */}
                            <Grid item xs={12} sx={{ border: "2px solid #DCDCDC", borderRadius: "25px", marginBottom: "20px", padding: "20px" }}>
                                <Box sx={{ height: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Typography variant="h4">Top Content</Typography>
                                </Box>
                                <Box style={{ display: "flex", justifyContent: "center", }}>
                                    <Grid container style={{ width: "100%" }}>
                                        {campaignAnalytics["topContent"].slice(0, 4).map((value, index) => (
                                            <Grid item xs={3} key={index} style={{ padding: "10px 5px 5px 5px", display: "flex", justifyContent: "center" }}>
                                                <Card style={{ width: "100%", borderRadius: "10px", border: "1px solid #DCDCDC", boxShadow: "none", padding: "10px" }}>
                                                    <Grid container>
                                                        <Grid item xs={9} style={{ marginBottom: "10px", display: "flex", justifyContent: "left", alignItems: "center", gap: "5px" }}>
                                                            <img className='avatarTopContent' alt='' src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + value.influencer + ".jpg?alt=media"} />
                                                            <div className='textTopContent'>@{value.influencer}</div>
                                                        </Grid>
                                                        <Grid item xs={3} style={{ marginBottom: "10px", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                                            <Typography style={{ background: mediaTypeBg[value.mediaType], padding: "4px 7px 4px 7px", borderRadius: "10px" }}>{(value.mediaType == "POST" ? "STATIC" : value.mediaType)}</Typography>
                                                        </Grid>
                                                        <Grid item xs onClick={() => handleOpen(index)} sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                                            <CardMedia
                                                                component={getMediaComponent(value.mediaUrl)}
                                                                image={value.mediaUrl}
                                                                style={getMediaStyle(getMediaComponent(value.mediaUrl))}
                                                            >
                                                            </CardMedia>
                                                        </Grid>
                                                        <StyledModal
                                                            open={openIndex === index}
                                                            onClose={handleClose}
                                                            slots={{ backdrop: StyledBackdrop }}
                                                        >
                                                            <ShareCampaignVideoCard key={index} videoData={{ "mediaType": value.mediaType, "mediaComponent": getMediaComponent(value.mediaUrl), "mediaUrl": value.mediaUrl, "likes": value.likes, "comments": value.comments, "views": value.views, "shares": value.shares, "saves": value.saves, "caption": value.caption, "shortcode": value.shortcode, "permalink": value.permalink, "isAnalyticsContent": true, "brands": [] }} />
                                                        </StyledModal>
                                                        <Grid container xs sx={{ marginTop: "10px", padding: "0px 2px 0px 2px" }}>
                                                            <Grid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <div className='trackingContentOuterCardMetrics'>
                                                                    <FavoriteBorderOutlinedIcon fontSize='small' /> {formatNumber(value.likes)}
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <div className='trackingContentOuterCardMetrics'>
                                                                    <img src={ShareIcon} style={{ height: "15px", width: "15px" }} /> {formatNumber(value.shares)}
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <div className='trackingContentOuterCardMetrics'>
                                                                    <img src={SaveIcon} style={{ height: "15px", width: "15px" }} /> {formatNumber((value.saves))}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        {/* <Grid item xs={12}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem distinctio quam repudiandae ea impedit? Perferendis sequi nihil totam necessitatibus ullam maxime porro, dolore maiores culpa quas eos quis deserunt repellendus?</Grid> */}
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={{ border: "2px solid #DCDCDC", borderRadius: "25px", padding: "10px", height: "600px", marginBottom: "10px" }}>
                                <Box sx={{ height: "10%", display: "flex", justifyContent: "center", alignItems: "center", margin: "10px 0px 10px 0px" }}>
                                    <Typography variant="h4">Top Meme Pages</Typography>
                                </Box>
                                <Box sx={{ height: "90%" }}>
                                    <DataGrid
                                        rowHeight={80}
                                        sx={{ height: "100%", border: "1px solid #DCDCDC", borderRadius: "20px" }}
                                        rows={campaignAnalytics["topInfluencers"]}
                                        columns={columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 100,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[100]}
                                        disableRowSelectionOnClick
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box >
                </Box>
            )
        } else {
            return (
                // <div style={{ display: "flex", justifyContent: "center" }}>
                //     <Grid container style={{ width: "80%" }}>
                //         <Grid item xs={4} style={{ padding: "10px 5px 5px 5px", display: "flex", justifyContent: "center" }}>
                //             <Typography></Typography>
                //         </Grid>
                //     </Grid>
                // </div>
                <div style={{ display: "flex", justifyContent: "center", }}>
                    <Box
                        component="img"
                        sx={{
                            height: "400px",
                            width: "300px",
                        }}
                        alt="Campaigns Analytics No Results"
                        src={CampaignAnalyticsNoResults}
                    />
                </div>
            )
        }
    }
}

export default ShareTrackingAnalytics