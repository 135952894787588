import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Avatar, Chip, CircularProgress, Divider, Grid, List, ListItem, ListItemText, Modal } from '@mui/material';
import { Mixpanel } from '../mixpanel/mixpanel.js';


function SavedFilters(props) {

    var handleCloseProp = props.handleClose;
    var userData = props.userData;
    var getSavedFilters = props.getSavedFilters;

    const [isLoading, setIsLoading] = useState(true)
    const [savedFilters, setSavedFilters] = useState([])

    async function fetchSavedFilters() {

        setIsLoading(true);
        const token2 = await userData.getIdToken()

        let options = {
            method: 'GET',
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_saved_filters', options)
            .then(result => result.json())
            .then(body => { setSavedFilters(body); setIsLoading(false) });
    };

    function applySavedFilters(filterName, filterValue) {
        Mixpanel.identify(userData.email);
        Mixpanel.track('Saved Filters Applied', { filterName });

        getSavedFilters(filterValue)
    }


    useEffect(() => {
        fetchSavedFilters()
    }, []);

    return (
        <Box sx={{ height: "100vh", overflow: 'auto' }}>
            {isLoading && <Box sx={{ display: "flex", justifyContent: "center", marginTop: "50px" }}><CircularProgress style={{ color: "black", }} size={40} /></Box>}
            {!isLoading &&
                <Grid container sx={{}}>
                    <CloseOutlinedIcon style={{ cursor: "pointer" }} onClick={handleCloseProp} />
                    <Typography sx={{ padding: "10px 0px 10px 0px", marginTop: "30px" }} variant="h5">Saved Filters</Typography>
                    <Divider style={{ width: "100%" }} />
                    {savedFilters.map((value, index) => (
                        <Grid key={index} item xs={12} style={{ padding: "5px" }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px" }}>
                                <Typography variant="h7">{value.filterName}</Typography>
                                <Button sx={{ border: "1px solid black", color: "black", fontSize: "12px" }} onClick={() => applySavedFilters(value.filterName, value.filterValue)}>Apply</Button>
                            </Box>
                            <Divider style={{ width: "100%" }} />
                        </Grid>
                    ))}
                </Grid>}
        </Box>
    );
}

export default SavedFilters