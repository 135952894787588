import { useEffect, useState } from 'react'
// import { storage } from 'webextension-polyfill'
// import { getCurrentTab } from '../../helpers/tabs'
import Card from '@mui/material/Card';
import { Avatar, AvatarGroup, Box, CardContent, CardHeader, Chip, Grid, IconButton, Stack, Tooltip, Typography, SvgIcon, Slide, MenuItem, Select, InputLabel, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import Button from '@mui/material/Button';
import StarIcon from '@mui/icons-material/Star';
import Modal from '@mui/material/Modal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Meme from "../../assets/memeError.png"

export const Counter = () => {
    const [value, setValue] = useState()

    // useEffect(() => {
    //     const readBackgroundMessage = async () => {
    //         const tab = await getCurrentTab()
    //         const tabId = tab.id

    //         if (tabId) {
    //             const data = await storage.local.get(tabId.toString())
    //             const currentValue = data?.[tabId] ?? 0

    //             setValue(currentValue)
    //         }
    //     }

    //     readBackgroundMessage()
    // }, [])

    const avatarGroupStyle = {
        width: "calc(min(55vw, 55vh) / 10)",
        height: "calc(min(55vw, 55vh) / 10)",
        backgroundColor: "#EBEBEB",
        color: "black",
        border: "1px solid black"
    };

    const engagementIcon = (
        <SvgIcon fontSize="small">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.4946 2.63554L12.8936 1.0061L11.2641 2.4051C8.75014 4.56354 5.03696 4.56354 2.52299 2.4051L0.893555 1.0061L2.29256 2.63554C4.451 5.14951 4.451 8.86269 2.29256 11.3767L0.893555 13.0061L2.52299 11.6071C5.03696 9.44866 8.75014 9.44866 11.2641 11.6071L12.8936 13.0061L11.4946 11.3767C9.33611 8.86269 9.33611 5.14951 11.4946 2.63554Z"
                    stroke="black" strokeWidth="1.20367" strokeLinejoin="round" />
            </svg>
        </SvgIcon>
    )

    const [list, setList] = useState('');

    const handleSelectList = (event) => {
        setList(event.target.value);
    };

    const [createListPage, setCreateListPage] = useState(false);

    const handleCreateListOpen = () => setCreateListPage(true);
    const handleCreateListClose = () => setCreateListPage(false);

    var message = "Error Message"

    if (createListPage) {
        // return (
        //     <div
        //         style={{
        //             fontSize: '24px',
        //             border: "1px solid black",
        //             height: "400px",
        //             padding: "10px"
        //         }}
        //     >
        //         <Box sx={{ width: "20%" }}>
        //             <Button sx={{ color: "black" }} startIcon={<ArrowBackIcon />} onClick={handleCreateListClose}>BACK</Button>
        //         </Box>
        //         <Box sx={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
        //             <Box sx={{ width: "80%" }}>
        //                 <InputLabel shrink sx={{ display: "flex" }}>
        //                     Enter List Name
        //                     <StarIcon sx={{ height: "10px", color: "red", marginTop: "2px" }} />
        //                 </InputLabel>
        //                 <TextField
        //                     sx={{ width: "100%" }}
        //                     required
        //                     placeholder="Enter List Name"
        //                 />
        //             </Box>
        //         </Box>
        //         <Button variant="contained" style={{ marginTop: "10px", color: 'black', boxShadow: "none", border: "1px solid #C4C4C4", backgroundColor: "#E4F87F" }}>
        //             CREATE LIST
        //         </Button>
        //     </div >
        // )

        return (
            <div
                style={{
                    fontSize: '24px',
                    border: "1px solid black",
                    height: "400px",
                    padding: "10px"
                }}
            >
                <Box sx={{ width: "20%" }}>
                    <Button sx={{ color: "black" }} startIcon={<ArrowBackIcon />} onClick={handleCreateListClose}>BACK</Button>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                    <img
                        src={Meme}
                        height="250px"
                        width="250px" />
                </Box>
                <Typography>{message}</Typography>
            </div >
        )
    } else {
        return (
            <div
                style={{
                    fontSize: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: "1px solid black",
                    height: "400px"
                }}
            >

                <Card variant="outlined" style={{ border: "1px solid #C4C4C4", width: "100%" }} >
                    <Grid container style={{ padding: "10px", display: "flex", flexDirection: "column" }}>
                        <Grid container >
                            <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Avatar style={{ width: "64px", height: "64px" }} src="https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2Fradhalife.style.jpg?alt=media" />
                            </Grid>
                            <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", }}>
                                <Box>
                                    <Typography variant="body1">Shubham Batra</Typography>
                                    <Typography variant="body1">@harshalaapatil</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={5} sx={{ display: "flex", flexDirection: "column", minHeight: "80px" }} >
                                <Typography >Partnered Brands</Typography>
                                <AvatarGroup sx={{ '& .MuiAvatar-root': avatarGroupStyle, display: "flex", justifyContent: "left", alignItems: "center" }} total={1} max={4}>
                                    {["birdsofparadyes"].map((value, index) => (
                                        <Avatar key={index} style={{ border: "1px solid black", cursor: "pointer" }} src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/brands%2F" + value + ".jpg?alt=media"} onClick={() => window.open("https://www.instagram.com/" + value, "_blank")} />
                                    ))}
                                </AvatarGroup>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container style={{ display: "flex", textAlign: "center", justifyContent: "center", gap: "10px" }}>

                                <Grid item xs={5} sx={{ padding: "10px" }}>
                                    <Card sx={{ width: "100%", padding: "5px 15px 5px 15px", border: "1px solid black" }}>
                                        <Typography fontWeight="bold" sx={{ fontSize: "20px" }}>{100}</Typography>
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <PeopleAltOutlinedIcon fontSize="medium" />
                                            <Typography variant="body1">Followers</Typography>
                                        </Box>
                                    </Card>
                                </Grid>

                                <Grid item xs={5} sx={{ padding: "10px" }}>
                                    <Card sx={{ width: "100%", padding: "5px 55px 5px 25px", border: "1px solid black" }}>
                                        <Typography fontWeight="bold" style={{ fontSize: "20px" }}>{5.2} %</Typography>
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            {engagementIcon}
                                            <Typography variant="body1" >Engagement</Typography>
                                        </Box>
                                    </Card>
                                </Grid>

                                <Grid item xs={5} sx={{ padding: "10px" }}>
                                    <Card sx={{ width: "100%", padding: "5px 15px 5px 15px", border: "1px solid black" }}>
                                        <Typography fontWeight="bold" style={{ fontSize: "20px" }}>{1234}</Typography>
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <PlayArrowOutlinedIcon fontSize="medium" />
                                            <Typography variant="body1">Reel Views</Typography>
                                        </Box>
                                    </Card>
                                </Grid>

                                <Grid item xs={5} sx={{ padding: "10px" }}>
                                    <Card sx={{ width: "100%", padding: "5px 15px 5px 15px", border: "1px solid black" }}>
                                        <Typography fontWeight="bold" style={{ fontSize: "20px" }}>{1234}</Typography>
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <PlayArrowOutlinedIcon fontSize="medium" />
                                            <Typography variant="body1">Reel Views</Typography>
                                        </Box>
                                    </Card>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                            <Box sx={{ width: "80%" }}>
                                <InputLabel shrink sx={{ display: "flex" }}>Select list</InputLabel>
                                <Select
                                    value={list}
                                    onChange={handleSelectList}
                                    sx={{ width: "100%", height: "40px" }}
                                >
                                    <MenuItem value={10}>List 1</MenuItem>
                                    <MenuItem value={20}>List 2</MenuItem>
                                    <MenuItem value={30}>List 3</MenuItem>
                                </Select>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ padding: "10px" }}>
                            <Button variant="contained" style={{ marginRight: "10px", backgroundColor: "white", color: 'black', boxShadow: "none", border: "1px solid #C4C4C4", background: "#E4F87F" }}>
                                ADD TO LIST
                            </Button>
                            <Button variant="contained" style={{ marginRight: "10px", backgroundColor: "white", color: 'black', boxShadow: "none", border: "1px solid #C4C4C4" }} onClick={handleCreateListOpen}>
                                CREATE LIST
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </div >
        )
    }
}

export default Counter