import '../index.css';
import Login from '../components/Login';
import Topbar from '../components/Topbar';
import JWForm from '../components/forms/JWForm';
import Chrome from '../components/campaign/chrome';
import LeafForm from '../components/forms/LeafForm';
import PlushForm from '../components/forms/PlushForm';
import NykaaForm from '../components/forms/NykaaForm';
import SnitchForm from '../components/forms/SnitchForm';
import EmprallForm from '../components/forms/EmprallForm';
import FoxtaleForm from '../components/forms/FoxtaleForm';
import MemechatLogin from '../components/memechat/MemechatLogin'
import MemechatTopbar from '../components/memechat/MemechatTopbar'
import MemechatTopbarShare from '../components/memechat/MemechatTopbarShare'
import { Navigate } from "react-router-dom";
import ShareTrackingHome from '../components/memechat/trackingShare/ShareTrackingHome';
import PeeperlyLeaderboard from '../components/leaderboard/peeperly';

export const getRoutes = (subdomain) => {

    console.log("subdomain inside routes")
    console.log(subdomain)
    // Default routes for the main domain or when no subdomain is detected
    let routes = [
        {
            path: "/",
            element: <Navigate to="/login" replace={true} />,
        },
        {
            path: "/settings",
            element: <Topbar source="settings" />,
        },
        {
            path: "/discovery",
            element: <Topbar source="discovery" />,
        },
        {
            path: "/lists",
            element: <Topbar source="lists" />,
        },
        {
            path: "/list",
            element: <Topbar source="list" />,
        },
        {
            path: "/campaigns",
            element: <Topbar source="campaigns" />,
        },
        {
            path: "/campaign",
            element: <Topbar source="campaign" />,

            children: [
                {
                    path: "/campaign/influencers",
                    element: <Topbar source="campaign/influencers" />,
                },
                {
                    path: "/campaign/content",
                    element: <Topbar source="campaign/content" />,
                },
                {
                    path: "/campaign/analytics",
                    element: <Topbar source="campaign/analytics" />,
                }
            ]
        },
        {
            path: "/campaign/chat",
            element: <Topbar source="campaign/chat" />,
        },
        {
            path: "/chrome",
            element: <Chrome />
        },
        {
            path: "/login",
            element: <Login />
        },
        {
            path: "/dashboard",
            element: <Topbar source="dashboard" />,
        },
        {
            path: "/tracking",
            element: <Topbar source="tracking" />,

            children: [
                {
                    path: "/tracking/influencers",
                    element: <Topbar source="tracking/influencers" />,
                },
                {
                    path: "/tracking/content",
                    element: <Topbar source="tracking/content" />,
                },
                {
                    path: "/tracking/analytics",
                    element: <Topbar source="tracking/analytics" />,
                }
            ]
        },
        {
            path: "/leaderboard",
            element: <Topbar source="leaderboard" />
        },
        {
            path: "/community",
            element: <Topbar source="community" />
        },
        {
            path: "/forms/nykaa",
            element: <NykaaForm />
        },
        {
            path: "/forms/foxtale",
            element: <FoxtaleForm />
        },
        {
            path: "/forms/leaf",
            element: <LeafForm />
        },
        {
            path: "/forms/plush",
            element: <PlushForm />
        },
        {
            path: "/forms/snitch",
            element: <SnitchForm />
        },
        {
            path: "/forms/emprall",
            element: <EmprallForm />
        },
        {
            path: "/forms/jokerandwitch",
            element: <JWForm />
        },
        {
            path: "/leaderboard/:campaignId",
            element: <PeeperlyLeaderboard />
        },
        {
            path: "/leaderboard/peeperly",
            element: <PeeperlyLeaderboard />
        }
    ]

    if (subdomain === 'memechat' || subdomain == 'insights' || subdomain == "a9ef-2401-4900-1c69-39b0-4c74-ec7b-6c66-37ec") {
        // Override or extend routes for the new subdomain
        routes = [
            // Routes specific to newsubdomain
            {
                path: "/",
                element: <Navigate to="/login" replace={true} />,
            },
            {
                path: "/login",
                element: <MemechatLogin /> // Assuming you have a different login component for the new subdomain
            },
            {
                path: "/dashboard",
                element: <MemechatTopbar source="dashboard" />,
            },
            {
                path: "/discovery",
                element: <MemechatTopbar source="discovery" />,
            },
            {
                path: "/lists",
                element: <MemechatTopbar source="lists" />,
            },
            {
                path: "/list",
                element: <MemechatTopbar source="list" />,
            },
            {
                path: "/tracking",
                element: <MemechatTopbar source="tracking" />,

                children: [
                    {
                        path: "/tracking/:trackingId/influencers",
                        element: <MemechatTopbar source="tracking/influencers" />,
                    },
                    {
                        path: "/tracking/:trackingId/content",
                        element: <MemechatTopbar source="tracking/content" />,
                    },
                    {
                        path: "/tracking/:trackingId/analytics",
                        element: <MemechatTopbar source="tracking/analytics" />,
                    },
                    {
                        path: "/tracking/:trackingId/bli",
                        element: <MemechatTopbar source="tracking/bli" />,
                    }
                ]
            },
            {
                path: "/:trackingId",
                element: <ShareTrackingHome />,
            },
            {
                path: "/:trackingId/share",
                element: <MemechatTopbarShare source="share" />,

                children: [
                    {
                        path: "/:trackingId/share/influencers",
                        element: <MemechatTopbarShare source="share/influencers" />
                    },
                    {
                        path: "/:trackingId/share/content",
                        element: <MemechatTopbarShare source="share/content" />
                    },
                    {
                        path: "/:trackingId/share/reporting",
                        element: <MemechatTopbarShare source="share/analytics" />
                    },
                    {
                        path: "/:trackingId/share/bli",
                        element: <MemechatTopbarShare source="share/bli" />
                    }
                ]
            }
        ];
    }

    return routes;
}