import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import StarIcon from '@mui/icons-material/Star';
import { useEffect } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Fuse from 'fuse.js'
import { createTheme, ThemeProvider } from '@mui/material/styles';


import MiniSearch from 'minisearch'

const theme = createTheme({
    palette: {
        primary: {
            main: "#E4F87F",
        },
    },
});

const style = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: "40%",
    height: "auto",
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: "10px",
    p: 3,
};

function ShipProduct(props) {

    const shipProductData = props.shipProductData;
    const handleCloseProp = props.handleClose;
    const userData = props.userData;


    const [isLoading, setIsLoading] = useState(false)
    const [products, setProducts] = useState([])
    const [finalProducts, setFinalProducts] = useState([])


    async function fetchAllProducts() {
        const token2 = await userData.getIdToken()

        // let data = { influencer: influencer.username }
        let options = {
            method: 'GET',
            // body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_all_products', options)
            .then(result => result.json())
            .then(body => { setProducts(body); setFinalProducts(body); setIsLoading(false) });
    }

    useEffect(() => {
        setIsLoading(true)
        fetchAllProducts()
    }, []);

    // [{id, image, title, price}]

    const [checked, setChecked] = useState({});
    const [checkedProducts, setCheckedProducts] = useState([]);

    const handleChangeChecked = (event) => {
        setChecked({
            ...checked,
            [event.target.name]: event.target.checked,
        });

        // setCheckedProductsFromCheckedDict()
    };

    useEffect(() => {
        const tempDs = Object.keys(checked)
            .filter((key) => checked[key])
            .map((key) => products.find((product) => product.title === key));

        setCheckedProducts(tempDs)
    }, [checked])

    useEffect(() => {
        var n = products.length;
        var myDictionary = {};
        for (let i = 0; i < n; i++) {
            const key = products[i]["title"]; // You can customize the key naming
            myDictionary[key] = false;
        }
        delete myDictionary[""]
        setChecked(myDictionary);
    }, [products])

    const [displayProductPage, setDisplayProductPage] = useState(true);

    function handleNext() {
        setDisplayProductPage(false);
    }

    function handleBack() {
        setDisplayProductPage(true);
    }


    const [searchValue, setSearchValue] = useState("")
    const handleSearchValue = event => {
        setSearchValue(event.target.value);
    };

    useEffect(() => {
        handleSearch()
    }, [searchValue])

    const fuseOptions = {
        isCaseSensitive: false,
        minMatchCharLength: 3,
        keys: [
            "title",
        ]
    };
    const fuse = new Fuse(products, fuseOptions);

    useEffect(() => {
        fuse.setCollection(products);
    }, [products]);

    let miniSearch = new MiniSearch({
        fields: ['title'], // fields to index for full-text search
        storeFields: ['id', 'image', 'price', 'productId', 'title'], // fields to return with search results
        // searchOptions: {
        //     boost: { title: 2 },
        //     fuzzy: 0.2
        // }
    })

    function handleSearch() {
        miniSearch.addAll(products)

        if (searchValue != "") {

            // var prods = fuse.search(searchValue).slice(0, 10);

            // const tempArr = prods.map(prod => prod.item);
            // setFinalProducts(tempArr);

            let results = miniSearch.search(searchValue, { fields: ['title'] })
            // console.log("RESULTS")
            // console.log(results.slice(0, 20))
            setFinalProducts(results.slice(0, 20))

            // setSearchProducts(fuse.search(searchValue))
        }
        else {
            setFinalProducts(products)
        }
    }


    const [orderResult, setOrderResult] = useState("")
    const [orderId, setOrderId] = useState("")
    const [isLoadingOrder, setIsLoadingOrder] = useState(false);
    const [productPrice, setProductPrice] = useState("");
    const handleProductPrice = (event) => {
        setProductPrice(event.target.value);
    };
    async function handleShipProduct() {

        // create affiliate -> get affiliate id 
        // then call api to ship product to address with affiliate id


        setIsLoadingOrder(true);
        const token2 = await userData.getIdToken()

        var lineItems = [];
        for (let i = 0; i < checkedProducts.length; i++) {
            var tempDict = {}
            var totalProductPrice = 0;
            if (productPrice == "1") {
                totalProductPrice = 1;
            } else if (productPrice == "2") {
                totalProductPrice = parseFloat(checkedProducts[i].price);
            }
            tempDict["quantity"] = 1
            tempDict["variantId"] = checkedProducts[i].id
            tempDict["productId"] = checkedProducts[i].productId
            tempDict["price"] = totalProductPrice
            tempDict["title"] = checkedProducts[i].title
            tempDict["image"] = checkedProducts[i].image

            lineItems.push(tempDict)
        }

        // console.log("lineItmes")
        // console.log(lineItems)

        let data = {
            influencer: shipProductData.influencer,
            influencerFullName: shipProductData.influencerFullName,
            campaignId: shipProductData.campaignId,
            address: shipProductData.influencerAddress,
            influencerEmail: shipProductData.influencerEmail,
            influencerPhone: shipProductData.influencerPhone,
            influencerAffiliateId: shipProductData.influencerAffiliateId,
            products: lineItems,
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/ship_product_to_influencer', options)
            .then(result => result.json())
            .then(body => {
                console.log(body)
                setOrderResult(body["status"])
                setOrderId(body["orderNumber"])
                // console.log(body["orderId"])
                // console.log(body["orderNumber"])

                handleShippedProducts()
                // handleCloseProp()
                setIsLoadingOrder(false);
            });
    }


    const handleShippedProducts = () => {
        props.setShippedProducts(checkedProducts)
    }


    if (displayProductPage) {

        return (
            <Box sx={style}>
                <Typography variant="h6">
                    Ship Free Product(s)
                </Typography>
                <Typography variant="h8" sx={{ color: "gray" }}>
                    Add address and select the product to ship
                </Typography>
                <Grid container sx={{ gap: "20px", marginTop: "30px" }}>
                    <Grid item xs={12}>
                        <InputLabel shrink sx={{ display: "flex" }}>
                            Select Product
                            <StarIcon sx={{ height: "10px", color: "red", marginTop: "2px" }} />
                        </InputLabel>
                        <TextField
                            sx={{ width: "100%" }}
                            required
                            placeholder="Search product"
                            value={searchValue}
                            onChange={handleSearchValue}
                        />

                        <Box sx={{ height: "240px", border: "1px solid #CDCDCD", marginTop: "10px", overflow: "auto" }}>
                            {finalProducts.slice(0, 20).map((value, index) => (
                                <Box sx={{ height: "fit-content", padding: "5px 0px 5px 0px", overflow: "auto", display: "flex", width: "100%" }}>
                                    <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img
                                            src={value["image"]}
                                            height="60px"
                                            width="60px"
                                        />
                                    </Grid>
                                    <Grid item xs={8} sx={{ display: "flex", flexDirection: "column" }}>
                                        <Typography >{value["title"]}</Typography>
                                        <Typography >{value["price"]}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Checkbox
                                            name={value["title"]}
                                            checked={checked[value["title"]]}
                                            onChange={handleChangeChecked}
                                        />
                                    </Grid>
                                </Box>
                            ))}
                        </Box>

                        <InputLabel shrink sx={{ display: "flex", marginTop: "30px", }}>
                            Selected Products
                        </InputLabel>
                        <Box sx={{ maxHeight: "80px", minHeight: "30px", border: "1px solid #CDCDCD", overflow: "auto" }}>
                            {checkedProducts.map((value, index) => (
                                <Box sx={{ height: "fit-content", padding: "5px 0px 5px 0px", overflow: "auto", display: "flex", width: "100%" }}>
                                    <Grid item xs={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img
                                            src={value["image"]}
                                            height="60px"
                                            width="60px"
                                        />
                                    </Grid>
                                    <Grid item xs={9} sx={{ display: "flex", flexDirection: "column" }}>
                                        <Typography >{value["title"]}</Typography>
                                        <Typography >{value["price"]}</Typography>
                                    </Grid>
                                </Box>
                            ))}
                        </Box>
                    </Grid>

                    {isLoading && <Button disabled={true} variant='contained' style={{ borderRadius: "5px", width: "50%", border: "1px solid #C4C4C4" }} ><CircularProgress style={{ color: "black", }} size={25} /></Button>}
                    {!isLoading &&
                        <ThemeProvider theme={theme}>
                            <Button variant="contained" disabled={checkedProducts.length > 0 ? false : true} style={{ borderRadius: "5px", width: "50%", border: "1px solid #C4C4C4", boxShadow: "none" }} onClick={() => handleNext()}>Ship Products</Button>
                        </ThemeProvider>
                    }
                </Grid>
            </Box >
        )
    }
    else {
        return (
            <Box sx={style}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>
                        <Typography variant="h6">
                            Ship Free Product(s)
                        </Typography>
                        <Typography variant="h8" sx={{ color: "gray" }}>
                            Add address and select the product to ship
                        </Typography>
                    </Box>
                    <Box onClick={handleCloseProp} sx={{ cursor: "pointer" }}>
                        <CloseOutlinedIcon />
                    </Box>
                </Box>
                <Grid container sx={{ gap: "20px", marginTop: "30px" }}>
                    <Grid item xs={12}>
                        <InputLabel sx={{ display: "flex" }}>
                            Order Summary
                        </InputLabel>

                        <Box sx={{ maxHeight: "180px", minHeight: "30px", border: "1px solid #CDCDCD", overflow: "auto" }}>
                            {checkedProducts.map((value, index) => (
                                <Box sx={{ height: "fit-content", padding: "5px 0px 5px 0px", overflow: "auto", display: "flex", width: "100%" }}>
                                    <Grid item xs={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img
                                            src={value["image"]}
                                            height="60px"
                                            width="60px"
                                        />
                                    </Grid>
                                    <Grid item xs={9} sx={{ display: "flex", flexDirection: "column" }}>
                                        <Typography >{value["title"]}</Typography>
                                        <Typography >{value["price"]}</Typography>
                                    </Grid>
                                </Box>
                            ))}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography sx={{ marginTop: "5px" }} >{orderResult}</Typography>
                        {orderResult != "" && <Typography sx={{ marginTop: "5px" }} >{"(Order Id: " + orderId + " )"}</Typography>}
                        <FormControl>
                            <FormLabel sx={{ textAlign: "center", color: "black" }}>Select Product Price</FormLabel>
                            <RadioGroup sx={{ display: "flex", justifyContent: "center" }} row value={productPrice} onChange={handleProductPrice}>
                                <FormControlLabel value="0" control={<Radio />} label="0 Rupee" />
                                <FormControlLabel value="1" control={<Radio />} label="1 Rupee" />
                                <FormControlLabel value="2" control={<Radio />} label="Full Price" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    {/* {isLoading && <Button disabled={true} variant='contained' style={{ borderRadius: "5px", width: "50%", border: "1px solid #C4C4C4" }} ><CircularProgress style={{ color: "black", }} size={25} /></Button>} */}
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <ThemeProvider theme={theme}>
                            <Button variant="contained" style={{ borderRadius: "5px", width: "30%", border: "1px solid #C4C4C4", boxShadow: "none" }} onClick={() => handleBack()}>Back</Button>
                        </ThemeProvider>

                        {isLoadingOrder && <Button disabled={true} variant='contained' style={{ borderRadius: "5px", border: "1px solid #C4C4C4" }} ><CircularProgress style={{ color: "black", }} size={25} /></Button>}
                        {!isLoadingOrder && <ThemeProvider theme={theme}>
                            <Button variant="contained" disabled={orderResult != "" || productPrice == ""} style={{ borderRadius: "5px", width: "fit-content", border: "1px solid #C4C4C4", boxShadow: "none" }} onClick={() => handleShipProduct()}>Create Order</Button>
                        </ThemeProvider>}
                    </Box>

                </Grid>
            </Box >
        )
    }
}

export default ShipProduct
