import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import StatsBar from './influencerCard/StatsBar'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Avatar, AvatarGroup, Card, Checkbox, Chip, CircularProgress, Divider, Grid, ListItemText, Menu, MenuItem, Modal, Slide, Stack, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import MainCard from './influencerCard/MainCard';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import auth from '../api/firebase.js';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SearchListMainCard from './SearchListMainCard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const renderBrands = (params) => {
    var influencerData = params.row.influencerData;
    var collabBrands = influencerData.collabBrands
    return (
        <Box>
            <AvatarGroup spacing="medium" sx={{ '& .MuiAvatar-root': { width: 36, height: 36, backgroundColor: "#EBEBEB", color: "black", border: "1px solid black", display: "flex", alignItems: "center", fontSize: "16px" } }} total={collabBrands.length} max={4}>
                {collabBrands.map((value, index) => (
                    <Avatar key={index} style={{ border: "1px solid black" }} src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/brands%2F" + value + ".jpg?alt=media"} />
                ))}
            </AvatarGroup>
        </Box>
    )
}


const rows2 = [
    { id: 1, followers: '233', date: "11/12/2023", engagement: "2.3%", affiliatedBrands: "Perfora, Renee", viewProfile: 1 },
    // { id: 2, followers: '233', date: "11/12/2023", engagement: "2.3%", affiliatedBrands: "Perfora, Renee", viewProfile: 1 },
];


const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 20,
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black"
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    '&:before': {
        width: 20,
        height: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    }
});

const style2 = {
    position: 'absolute',
    left: '20%',
    // transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "100%",
    bgcolor: 'background.paper',
    boxShadow: 1,
    p: 1,
    border: "1px solid #C4C4C4"
};

function List(props) {

    const location = useLocation();
    const listData = location.state.listData;
    const totalLists = location.state.totalLists;
    const user = props.userData;

    const [lists, setLists] = useState(totalLists);

    const [openInfluencerModal, setOpenInfluencerModal] = useState(false);
    const handleOpenInfluencerModal = () => setOpenInfluencerModal(true);
    const handleCloseInfluencerModal = () => setOpenInfluencerModal(false);

    const [influencerUsername, setInfluencerUsername] = useState("");

    function openInfluencerDetails(username) {
        setInfluencerUsername(username)
        setOpenInfluencerModal(true);
    }

    const renderDetailsAvatar = (params) => {

        var influencerData = params.row.influencerData;
        var categories = influencerData.category;

        const influencerPhoto = "https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + influencerData.username + ".jpg?alt=media"

        return (
            <Grid container style={{ gap: "10px" }}>
                <Grid item >
                    <Avatar src={influencerPhoto} />
                </Grid>
                <Grid item>
                    <Grid item xs={1} sx={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => openInfluencerDetails(influencerData.username)}>{influencerData.username}</Grid>
                    <Grid item xs={11}>
                        <Stack direction="row" spacing={1}>
                            {categories.slice(0, 2).map((value, index) => (
                                <Chip size='small' label={value} key={index} />
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderViewProfile = (params) => {
        // console.log("RENDER VIEW PROFILE")
        // console.log(params.row.influencerData.username)
        const influencerUsername = params.row.influencerData.username;

        return (
            <Card style={{ width: "5vh", height: "5vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F0F0F0", boxShadow: "none", borderRadius: "15px", cursor: "pointer" }} onClick={handleOpen}>
                <RemoveRedEyeOutlinedIcon onClick={() => openInfluencerDetails(influencerUsername)} />
            </Card>
        )
    }

    const columns = [
        { field: 'id', headerName: 'Users', flex: 2, renderCell: renderDetailsAvatar },
        {
            field: 'followers',
            headerName: 'Followers',
            flex: 1,
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 1,
        },
        {
            field: 'engagement',
            headerName: 'Engagement',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            flex: 1,
            height: 260,
        },
        {
            field: 'affiliatedBrands',
            headerName: 'Affiliated Brands',
            flex: 1.5,
            renderCell: renderBrands
        },
        {
            field: 'viewProfile',
            headerName: 'View Profile',
            flex: 1,
            sortable: false,
            renderCell: renderViewProfile,
        },
    ];

    const style = {
        position: 'absolute',
        left: '20%',
        // transform: 'translate(-50%, -50%)',
        width: "80%",
        height: "100%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [listInfluencers, setListInfluencers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    let listInfluencersApiData = { listId: listData.listId }

    async function fetchListInfluencers() {
        setIsLoading(true);
        const token2 = await user.getIdToken();

        let listInfluencersApiOptions = {
            method: 'POST',
            body: JSON.stringify(listInfluencersApiData),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_list_influencers', listInfluencersApiOptions)
            .then(result => result.json())
            .then(body => { setListInfluencers(body); setIsLoading(false) });
    }

    const [campaigns, setCampaigns] = useState([]);

    async function fetchListCampaigns() {
        const token2 = await user.getIdToken();

        var data = { listId: listData.listId }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_list_campaigns', options)
            .then(result => result.json())
            .then(body => { setCampaigns(body); });
    }

    useEffect(() => {
        fetchListInfluencers()
        fetchListCampaigns()
    }, []);


    function formatNumber(number) {
        if (number >= 1e6) {
            return (number / 1e6).toFixed(2) + "M";
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(2) + "K";
        } else {
            return number.toFixed(2);
        }
    }

    const rows = [];
    listInfluencers.map((value, index) => {
        rows.push({ id: index + 1, followers: formatNumber(value.followers), date: value.createdAt.slice(0, 16), engagement: formatNumber(value.engagement) + " %", affiliatedBrands: value.collabBrands, viewProfile: 1, influencerData: value })
    })

    const [deleteInfluencers, setDeleteInfluencers] = useState([]);
    const [campaignInfluencers, setCampaignInfluencers] = useState([]);
    const [sentStatus, setSentStatus] = useState("Send");

    const onRowsSelectionHandler = (ids) => {
        const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
        var influencers = []
        selectedRowsData.forEach(function (d) {
            influencers.push({
                "username": d.influencerData.username, "category": d.influencerData.category, "hasEimuId": d.influencerData.hasEimuId,
                "eimuId": d.influencerData.eimuId, "followers": d.influencerData.followers, "engagement": d.influencerData.engagement, "collabBrands": d.influencerData.collabBrands
            })
        })
        setDeleteInfluencers(influencers);
        setCampaignInfluencers(influencers)
    };

    async function removeInfluencersFromList(influencers) {
        const token2 = await user.getIdToken();

        let data = { influencers: influencers, listId: listData.listId }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/remove_list_influencers', options)
            .then(result => result.json())
            .then(body => { setDeleteInfluencers([]); setCampaignInfluencers([]); fetchListInfluencers(); });
    }

    async function sendNewInfluencersToCampaigns() {
        setSentStatus("Sent!")
        const token2 = await user.getIdToken();

        let data = { influencers: campaignInfluencers, campaigns: checkedCampaigns, listId: listData.listId }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/send_new_influencers_to_campaigns', options)
            .then(result => result.json())
            .then(body => { setDeleteInfluencers([]); setCampaignInfluencers([]); setSentStatus("Send"); handleCloseCampaigns(); fetchListInfluencers(); });
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const openCampaigns = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseCampaigns = () => {
        setCheckedCampaigns([]);
        setAnchorEl(null);
    };

    const [checkedCampaigns, setCheckedCampaigns] = useState([]);

    function handleChange(campaign) {
        const isCampaignChecked = checkedCampaigns.some((c) => c.campaignId === campaign.campaignId);

        if (isCampaignChecked) {
            setCheckedCampaigns((prevCampaigns) => prevCampaigns.filter((c) => c.campaignId !== campaign.campaignId));
        } else {
            setCheckedCampaigns((prevCampaigns) => [...prevCampaigns, campaign]);
        }
    };



    const [anchorElLists, setAnchorElLists] = useState(null);
    const openLists = Boolean(anchorElLists);
    const handleClickLists = (event) => {
        setAnchorElLists(event.currentTarget);
    };
    const handleCloseLists = () => {
        setCheckedLists([]);
        setAnchorElLists(null);
    };

    const [checkedLists, setCheckedLists] = useState([]);

    function handleChangeList(list) {
        const isListChecked = checkedLists.some((c) => c.listId === list.listId);

        if (isListChecked) {
            setCheckedLists((prevLists) => prevLists.filter((c) => c.listId !== list.listId));
        } else {
            setCheckedLists((prevLists) => [...prevLists, list]);
        }
    };

    async function sendNewInfluencersToLists() {
        setSentStatus("Sent!")
        const token2 = await user.getIdToken();

        let data = { influencers: campaignInfluencers, lists: checkedLists }

        // console.log(data)

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/send_new_influencers_to_lists', options)
            .then(result => result.json())
            .then(body => { setDeleteInfluencers([]); setCampaignInfluencers([]); setSentStatus("Send"); handleCloseLists(); fetchListInfluencers(); });
    }


    return (
        <Grid container style={{ display: "flex", flexDirection: "column" }}>
            <Grid item style={{ padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {/* <StatsBar subHeadings={["Total Influencers", "Total Reach", "Cumulative Engagement", "Top Category"]} values={[listData.totalInfluencers, listData.totalReach, listData.cumulativeEng, listData.topCategory]} /> */}
                <Grid container style={{ width: "90%", padding: "10px" }}>
                    <Grid xs={3} item sx={{ display: "flex", justifyContent: "left" }}>
                        <Link to="/lists" >
                            <Button style={{ color: '#000' }} ><ArrowBackIosNewIcon />Back to lists</Button>
                        </Link>
                        {/* <Typography>Showing {(page - 1) * 10} - {Math.min((page * 10), (listInfluencers.length % 10))} of {listInfluencers.length} results</Typography> */}
                    </Grid>
                    <Grid xs={9} item sx={{ display: "flex", justifyContent: "right", gap: "40px" }}>
                        {/* onClick={() => sendNewInfluencersToCampaign()} */}
                        <Button sx={{ color: "black", background: (campaignInfluencers.length > 0 ? "#E4F87F" : "#C4C4C4"), width: "fit-content", padding: "0px 15px 0px 15px" }} disabled={(campaignInfluencers.length > 0 ? false : true)} onClick={handleClickLists} endIcon={<KeyboardArrowDownIcon />}>SEND TO LIST</Button>
                        <Menu
                            anchorEl={anchorElLists}
                            open={openLists}
                            onClose={handleCloseLists}
                            sx={{
                                '& .MuiPaper-root': { // Targeting the root paper component of the Menu for additional styling.
                                    maxHeight: '450px', // Set the maximum height for the entire Menu.
                                    overflow: 'hidden', // Prevent scrolling on the Menu itself.
                                }
                            }}
                        >
                            <div style={{ maxHeight: 'calc(400px - 48px)', overflowY: 'auto' }}>
                                {lists.map((list) => (
                                    <MenuItem key={list.listId} value={list.listName} onClick={() => handleChangeList(list)}>
                                        <Checkbox checked={checkedLists.some((c) => c.listId === list.listId)} />
                                        <ListItemText primary={list.listName} />
                                    </MenuItem>
                                ))}
                            </div>
                            <MenuItem sx={{ display: "flex", justifyContent: "center", position: 'sticky', bottom: 0, background: 'white', padding: "15px 0px 15px 0px" }}>
                                <Button onClick={() => sendNewInfluencersToLists()} sx={{ width: "80%", color: "black", background: (campaignInfluencers.length > 0 ? "#E4F87F" : "#C4C4C4") }} disabled={(campaignInfluencers.length > 0 ? false : true)}>{sentStatus}</Button>
                            </MenuItem>
                        </Menu>

                        <Button sx={{ color: "black", background: (campaignInfluencers.length > 0 ? "#E4F87F" : "#C4C4C4"), width: "fit-content", padding: "0px 15px 0px 15px" }} disabled={(campaignInfluencers.length > 0 ? false : true)} onClick={handleClick} endIcon={<KeyboardArrowDownIcon />}>SEND TO CAMPAIGN</Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={openCampaigns}
                            onClose={handleCloseCampaigns}
                        >
                            {campaigns.map((campaign) => (
                                <MenuItem key={campaign.campaignId} value={campaign.campaignName} onClick={() => handleChange(campaign)}>
                                    <Checkbox checked={checkedCampaigns.some((c) => c.campaignId === campaign.campaignId)} />
                                    <ListItemText primary={campaign.campaignName} />
                                </MenuItem>
                            ))}
                            <MenuItem sx={{ display: "flex", justifyContent: "center" }}>
                                <Button onClick={() => sendNewInfluencersToCampaigns()} sx={{ width: "80%", color: "black", background: (campaignInfluencers.length > 0 ? "#E4F87F" : "#C4C4C4") }} disabled={(campaignInfluencers.length > 0 ? false : true)}>{sentStatus}</Button>
                            </MenuItem>
                        </Menu>
                        <Button sx={{ color: "black", background: (deleteInfluencers.length > 0 ? "#FF8E8E" : "#C4C4C4"), width: "fit-content" }} disabled={(deleteInfluencers.length > 0 ? false : true)} onClick={() => removeInfluencersFromList(deleteInfluencers)}>REMOVE FROM LIST</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
                {isLoading && <Box style={{ marginTop: "50px" }}><CircularProgress style={{ color: "black", }} size={100} /></Box>}
                {!isLoading &&
                    <Box sx={{ height: '100%', width: "85%", padding: "20px 20px 20px 20px" }}>
                        <DataGrid
                            slots={{
                                baseCheckbox: (props) => (
                                    <Checkbox {...props} disableRipple checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />
                                )
                            }}
                            onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                            rowHeight={80}
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 100,
                                    },
                                },
                            }}
                            pageSizeOptions={[100]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            disableColumnMenu
                        />
                    </Box>}
                <Modal
                    BackdropProps={{ style: { backgroundColor: 'rgb(0,0,0,0.1)' } }}
                    open={openInfluencerModal}
                    onClose={handleCloseInfluencerModal}
                >
                    <Slide direction="left" in={openInfluencerModal} mountOnEnter unmountOnExit timeout={300}>
                        <Box sx={style2}>
                            <SearchListMainCard userData={user} influencerUsername={influencerUsername} handleClose={handleCloseInfluencerModal} />
                        </Box>
                    </Slide>
                </Modal>
            </Grid>
        </Grid>
    )
}

export default List