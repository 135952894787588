import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Link, Outlet, useLocation } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { Avatar, Button, Card, CircularProgress, Grid, LinearProgress, Typography } from '@mui/material'

const theme = createTheme({
    palette: {
        primary: {
            main: "#E4F87F",
        },
    },
});

function Tracking(props) {

    const location = useLocation();
    const propsData = location.state;

    const campaignData = propsData.campaignData;
    const gradientColor = propsData.gradientColor;

    const user = props.userData

    const [tab, setTab] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function handleTabChange(tab) {
        if (tab == 0) { setTab(0) }
        if (tab == 1) { setTab(1) }
        if (tab == 2) { setTab(2) }
    };

    const influencerButton = (tab == 0) ? "#E4F87F" : "white";
    const contentButton = (tab == 1) ? "#E4F87F" : "white";
    const analyticsButton = (tab == 2) ? "#E4F87F" : "white";



    async function checkProcessing() {
        setIsLoading(true)
        const token2 = await user.getIdToken()

        let data = {
            campaignId: campaignData.campaignId
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/check_tracking_processing', options)
            .then(result => result.json())
            .then(body => {
                if (body["isCampaignProcessing"]) {
                    setIsProcessing(true);
                } else {
                    setIsProcessing(false)
                }
            });
        setIsLoading(false)
    }

    async function refreshMetrics() {
        setIsProcessing(true)

        const token2 = await user.getIdToken()

        let data = {
            campaignId: campaignData.campaignId
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }


        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/refresh_tracking_metrics', options)
            .then(result => result.json())
            .then(body => { console.log("refresh body"); console.log(body) });
    }

    useEffect(() => {
        checkProcessing()
    }, [])

    return (
        <div style={{ padding: "20px" }}>
            <Grid container style={{ display: "flex", justifyContent: "center", marginBottom: "20px", height: "100px" }}>
                <Card sx={{ width: "85%", border: "2px solid #DCDCDC", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "25px", boxShadow: "none" }}>
                    <Grid container pr={8} pl={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid item xs={5}>
                            <Grid container style={{ gap: "10px", display: "flex", alignItems: "center" }}>
                                <div style={{ width: "80px", height: "80px", background: `radial-gradient(#FFFFFF, ${gradientColor})`, borderRadius: "50%" }} />
                                <div style={{ display: "flex", height: "50%", gap: "10px" }}>
                                    <Grid item >
                                        <Grid item fontSize={20} >{campaignData.campaignName}</Grid>
                                    </Grid>
                                    <Grid item >
                                        <Card sx={{ background: `${gradientColor}`, padding: "5px 10px 5px 10px", borderRadius: "5px" }}>Active</Card>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} style={{ display: "flex", justifyContent: "center", textAlign: "left" }}>
                            <Divider orientation="vertical" variant="middle" flexItem sx={{ marginRight: "20px" }} />
                            <Grid container sx={{ gap: "4px" }}>
                                <Grid item fontSize={18} xs={8}>Influencers Onboard</Grid>
                                <Grid item fontSize={26} xs={8} sx={{ fontWeight: 800 }}>{campaignData.totalInfluencers}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <ThemeProvider theme={theme}>
                                {isLoading && <Button variant="contained" sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black" }} ><CircularProgress size={20} sx={{ color: "black" }} /></Button>}
                                {!isLoading &&
                                    <Box>
                                        <Button onClick={() => refreshMetrics()} variant="contained" sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black" }} disabled={isProcessing} endIcon={isProcessing && <CircularProgress sx={{ color: "black" }} size={20} />}>{isProcessing ? "Processing" : "Refresh Metrics"}</Button>
                                        {/* onClick={() => addNewContent()} */}
                                        {/* <Button variant="contained" sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black" }} disabled={isProcessing} endIcon={isProcessing && <CircularProgress sx={{ color: "black" }} size={20} />}>Add Content Link</Button> */}
                                    </Box>}
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>


            <Grid container style={{ justifyContent: "center", marginBottom: "20px", height: "80px" }}>
                <Card sx={{ width: "85%", border: "2px solid #DCDCDC", display: "flex", alignItems: "center", display: "flex", borderRadius: "15px", boxShadow: "none" }}>
                    <Grid container columns={9} style={{ display: "flex", textAlign: "center", padding: "5px" }}>
                        <Grid item xs={3}>
                            <Link to="/tracking/content" state={propsData}>
                                <Button startIcon={<PeopleOutlineOutlinedIcon />} onClick={() => handleTabChange(1)} style={{ color: "black", background: contentButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Content</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to="/tracking/influencers" state={propsData}>
                                <Button startIcon={<PeopleOutlineOutlinedIcon />} onClick={() => handleTabChange(0)} style={{ color: "black", background: influencerButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Influencers</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to="/tracking/analytics" state={propsData}>
                                <Button startIcon={<PeopleOutlineOutlinedIcon />} onClick={() => handleTabChange(2)} style={{ color: "black", background: analyticsButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Analytics</Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Outlet />
        </div>
    )
}

export default Tracking