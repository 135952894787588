import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Avatar, Button, Card, CircularProgress, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Modal, Popover, Select, Typography } from '@mui/material'
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { Outlet } from 'react-router-dom';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Divider from '@mui/material/Divider';

import InstagramLogin from './InstagramLogin';
import Campaign from './campaign/Campaign';
import CampaignBar from './CampaignBar';
import { useState } from 'react';
import CreateCampaign from './CreateCampaign';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CampaignsNoResults from "../assets/campaignsNoResults.png";


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex'
    },
    '& .MuiTabs-indicatorSpan': {
        width: '100%',
        backgroundColor: '#000',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.4)',
        '&.Mui-selected': {
            color: 'rgba(0, 0, 0, 1)',
        },
        '&.Mui-focusVisible': {
        },
    }),
);

function Campaigns(props) {

    const [tabValue, setTabValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const activeTab = (tabValue == 0) ? "#E4F87F" : "#F0F0F0";
    const pausedTab = (tabValue == 1) ? "#E4F87F" : "#F0F0F0";
    const draftsTab = (tabValue == 2) ? "#E4F87F" : "#F0F0F0";
    const archivedTab = (tabValue == 3) ? "#E4F87F" : "#F0F0F0";

    const [activeCampaigns, setActiveCampaigns] = useState([])
    const [pausedCampaigns, setPausedCampaigns] = useState([])
    const [draftCampaigns, setDraftCampaigns] = useState([])
    const [archivedCampaigns, setArchivedCampaigns] = useState([])

    // const activeLists = [{ "campaignName": "test campaign", "campaignId": "123" }, { "campaignName": "test campaign 2", "campaignId": "123" }];
    const pausedLists = [{ "campaignName": "test campaign", "campaignId": "123" }, { "campaignName": "test campaign 2", "campaignId": "123" }];
    const draftsLists = [{ "campaignName": "test campaign", "campaignId": "123" }, { "campaignName": "test campaign 2", "campaignId": "123" }];
    const archivedLists = [{ "campaignName": "test campaign", "campaignId": "123" }, { "campaignName": "test campaign 2", "campaignId": "123" }];

    function setTabLabel(label, icon) {
        return (<div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "6px" }}>
            {icon}
            <Typography>{label}</Typography>
        </div>)
    }
    const tabLabels = [setTabLabel("Active", <PlayArrowOutlinedIcon />), setTabLabel("Paused", <PauseOutlinedIcon />), setTabLabel("Drafts", <NoteAddOutlinedIcon />), setTabLabel("Archived", <Inventory2OutlinedIcon />)]
    const tabColors = [activeTab, pausedTab, draftsTab, archivedTab]
    const tabLengths = [activeCampaigns.length, pausedCampaigns.length, draftCampaigns.length, archivedCampaigns.length]

    const [isLoading, setIsLoading] = useState(true)

    function setCampaigns(allCampaigns) {
        for (const [key, value] of Object.entries(allCampaigns)) {
            if (key == 0) {
                setActiveCampaigns(value)
            } else if (key == 1) {
                setPausedCampaigns(value)
            } else if (key == 2) {
                setDraftCampaigns(value)
            } else if (key == 3) {
                setArchivedCampaigns(value)
            }
        }
    }

    const user = props.userData;
    const showDashboard = props.showDashboard;

    // const [user, setUser] = useState(null)
    // const navigate = useNavigate();
    // onAuthStateChanged(auth, (user) => {
    //     if (user) {
    //         // User is signed in, see docs for a list of available properties
    //         // https://firebase.google.com/docs/reference/js/auth.user
    //         setUser(user)
    //     } else {
    //         // console.log("no user found logged in")
    //         navigate("/login")
    //     }
    // });

    async function fetchAllCampaigns() {

        setIsLoading(true)
        const token2 = await user.getIdToken()

        let data = {
            showTrackingCampaigns: false
        }

        let campaignApiOptions = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_all_campaigns', campaignApiOptions)
            .then(result => result.json())
            .then(body => { setCampaigns(body); setIsLoading(false) });
    }

    const [instaUsernames, setInstaUsernames] = useState([])
    const [isLoadingInstaButton, setIsLoadingInstaButton] = useState(false)

    async function fetchInstagramAccounts() {
        setIsLoadingInstaButton(true);
        const token2 = await user.getIdToken()

        let options = {
            method: 'GET',
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_brand_insta_usernames', options)
            .then(result => result.json())
            .then(body => { setInstaUsernames(body); setIsLoadingInstaButton(false) });
    }

    useEffect(() => {
        fetchAllCampaigns()
        fetchInstagramAccounts()
    }, [])



    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setIsLoading(true); fetchAllCampaigns() }

    var displayCampaignsNoResults = (isLoading == false && activeCampaigns.length == 0) ? "block" : "none";

    const handleCloseDialog = () => {
        console.log("No access")
    };



    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickInstaAccount = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseInstaAccount = () => {
        setAnchorEl(null);
    };

    const openInstaAccount = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={{ padding: "20px" }}>
            {!showDashboard && <Dialog
                open={true}
                onClose={handleCloseDialog}
                fullWidth={true}
                maxWidth='sm'
            >
                <Box style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: 'center' }}>
                    <DialogTitle>
                        {"Your free trial has ended."}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText >
                            Please contact us at shubham@closerapp.in to get your access.
                        </DialogContentText>
                    </DialogContent>
                </Box>
            </Dialog>}
            <Grid container style={{ justifyContent: "center", marginBottom: "20px", flexDirection: "column", alignItems: "center" }}>
                <Button style={{ width: "85%", height: "100px", borderRadius: "25px", boxShadow: "none", backgroundColor: "#E4F87F", color: "black", fontWeight: 600, fontSize: "18px" }} onClick={handleOpen}>
                    + Start a Campaign
                </Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <Box>
                        <CreateCampaign handleClose={handleClose} userData={user} />
                    </Box>
                </Modal>
                {isLoading && <Box style={{ marginTop: "50px" }}><CircularProgress style={{ color: "black", }} size={100} /></Box>}
                {!isLoading &&
                    <Box sx={{ width: "80%" }}>
                        <Box sx={{ display: "flex" }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", marginTop: "25px", width: "70%" }}>
                                <StyledTabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" >
                                    {tabLabels.map((value, index) => (
                                        <StyledTab key={index} icon={<div style={{ background: tabColors[index], color: "black", padding: "5px 10px 5px 10px", borderRadius: "5px" }}>{tabLengths[index]}</div>} iconPosition="end" label={value} />
                                    ))}
                                </StyledTabs>
                            </Box>

                            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", marginTop: "25px", width: "30%", justifyContent: "right", alignItems: "center" }}>
                                {/* <InstagramLogin userData={user} /> */}
                                <Button endIcon={<ArrowDropDownIcon />} onClick={(e) => handleClickInstaAccount(e)} sx={{ color: "black", background: "#E4F87F", padding: "15px" }}>
                                    Instagram Connections
                                </Button>
                                <Popover
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    id={id}
                                    open={openInstaAccount}
                                    anchorEl={anchorEl}
                                    onClose={handleCloseInstaAccount}
                                >
                                    <Box sx={{ padding: "5px", width: "600px" }}>
                                        {isLoadingInstaButton && <Box style={{ marginTop: "50px", border: "1px solid black" }}><CircularProgress style={{ color: "black", }} size={100} /></Box>}
                                        {!isLoadingInstaButton && instaUsernames.map((value, index) => (
                                            <Grid container sx={{ padding: "5px" }}>
                                                <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                                                    <Typography>{value}</Typography>
                                                </Grid>
                                                <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                                                    <InstagramLogin userData={user} username={value} />
                                                </Grid>
                                                <Divider sx={{ width: "100%", marginTop: "10px" }} />
                                            </Grid>
                                            // <Box key={index} sx={{ display: "flex", alignItems: "center", margin: "10px 5px 10px 5px", gap: "5px" }}>
                                            // <Typography>{value}</Typography>
                                            // <InstagramLogin userData={user} username={value} />
                                            // </Box>
                                        ))}
                                    </Box>
                                </Popover>
                            </Box>
                        </Box>

                        <CustomTabPanel value={tabValue} index={0} >
                            {activeCampaigns.map((value, index) => (
                                <div style={{ padding: "8px" }} key={index}>
                                    <CampaignBar campaignStatus={0} gradientColor="#E4F87F" campaignData={value} userData={user} />
                                </div>
                            ))}
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={1}>
                            {pausedCampaigns.map((value, index) => (
                                <div style={{ padding: "8px" }} key={index}>
                                    <CampaignBar campaignStatus={1} gradientColor="#EFC3FF" campaignData={value} userData={user} />
                                </div>
                            ))}
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={2}>
                            {draftCampaigns.map((value, index) => (
                                <div style={{ padding: "8px" }} key={index}>
                                    <CampaignBar campaignStatus={2} gradientColor="#B7E4FD" campaignData={value} userData={user} />
                                </div>
                            ))}
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={3}>
                            {archivedCampaigns.map((value, index) => (
                                <div style={{ padding: "8px" }} key={index}>
                                    <CampaignBar campaignStatus={3} gradientColor="#F0F0F0" campaignData={value} userData={user} />
                                </div>
                            ))}
                        </CustomTabPanel>
                    </Box>}
                <div style={{ display: displayCampaignsNoResults }}>
                    <div style={{ display: "flex", justifyContent: "center", }}>
                        <Box
                            component="img"
                            sx={{
                                height: "490px",
                                width: "370px",
                            }}
                            alt="Campaigns No Results"
                            src={CampaignsNoResults}
                        />
                    </div>
                </div>
            </Grid>
            <Outlet />
        </div >
    )
}

export default Campaigns