import { Box, Card, Tooltip } from "@mui/material";
import React, { Component } from "react";

class CustomTooltip extends Component {
    render() {
        const style = {
            backgroundColor: "red",
            fontSize: 12,
            padding: 10,
        };

        const elements = document.getElementsByClassName('css-advny6-MuiPopper-root')

        for (const element of elements) {
            // Example style modification
            element.style.color = 'white';
            element.style.zIndex = 2000;
        }

        return (
            <Card sx={{ padding: "15px", border: "1px solid #DCDCDC", borderRadius: "10px" }}>
                <Box style={{ color: "red", height: "100px", width: "100px" }}>abcdddd</Box>
            </Card>
        );
    }
}

export default CustomTooltip;