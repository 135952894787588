import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Mixpanel } from "../../../mixpanel/mixpanel.js";
import React, { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import UploadFormat from "../../../assets/UploadFormat.csv";
import UploadFormatContent from "../../../assets/UploadFormatContent.csv";
import DownloadIcon from '@mui/icons-material/Download';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import { Checkbox, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, Tab, Tabs, TextField } from '@mui/material';

const style = {
    p: 3,
    top: '50%',
    left: '50%',
    width: "50%",
    boxShadow: 24,
    height: "auto",
    bgcolor: 'white',
    position: 'absolute',
    borderRadius: "10px",
    transform: 'translate(-50%, -50%)',
};

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex'
    },
    '& .MuiTabs-indicatorSpan': {
        width: '100%',
        backgroundColor: '#000',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.4)',
        '&.Mui-selected': {
            color: 'rgba(0, 0, 0, 1)',
        },
        '&.Mui-focusVisible': {
        },
    }),
);

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function StartTracking(props) {

    const handleCloseProp = props.handleClose;
    var userData = props.userData;



    const [uploadedFileName, setUploadedFileName] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileToUpload, setFileToUpload] = useState(null);

    const [displayCampaignError, setDisplayCampaignError] = useState(false)
    const [fileError, setFileError] = useState(false)

    const [campaignName, setCampaignName] = useState("")
    const [campaignDescription, setCampaignDescription] = useState("")
    const [entityDescription, setEntityDescription] = useState("")
    const [entityPerception, setEntityPerception] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [source, setSource] = useState("");

    const [checkedBli, setCheckedBli] = useState(false)



    const handleCampaignName = event => {
        setCampaignName(event.target.value);
    };

    const handleCampaignDescription = event => {
        setCampaignDescription(event.target.value);
    };

    const handleEntityDescription = event => {
        setEntityDescription(event.target.value);
    };


    const handleEntityPerception = event => {
        setEntityPerception(event.target.value);
    };

    const handleChangeTabs = (event, newValue) => {
        setTabValue(newValue);
    };

    const dropzoneStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        border: '2px dashed #cccccc',
        borderRadius: '4px',
        backgroundColor: '#f5f5f5',
        cursor: 'pointer',
        boxShadow: "none"
    };

    function setTabLabel(label) {
        return (<div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "6px" }}>
            <Typography>{label}</Typography>
        </div>)
    }
    const tabLabels = [setTabLabel("Import Instagram Content Links"), setTabLabel("Import YouTube Content Links"), setTabLabel("Import Influencers")]

    async function uploadFile() {
        setIsLoading(true);
        // Create a FormData object to send the file
        const formData = new FormData();

        // Append the Excel file to the FormData
        formData.append('file_path', fileToUpload);
        formData.append("campaignName", campaignName)
        // formData.append("campaignId", campaignName)

        // console.log("UPLOAD FILE CALLED")
        // console.log(fileToUpload)

        const token2 = await userData.getIdToken()

        var api = ""
        if (tabValue == 2) {
            api = "/api/create_new_tracking_csv"
            formData.append("source", "")
        } else {
            api = "/api/create_new_tracking_content_csv"
            if (tabValue == 0) {
                formData.append("source", "instagram")
            }
            else if (tabValue == 1) {
                formData.append("source", "youtube")
            }
        }

        let options = {
            method: 'POST',
            body: formData,
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
            })
        }

        // var api2 = "/api/save_excel"

        try {
            fetch(process.env.REACT_APP_API_ADDRESS + api, options);
            // console.log("tracking response")
            // console.log(response)
            setTimeout(() => {
                setIsLoading(false);
                handleCloseProp();
            }, 5000);
        } catch (error) {
            setIsLoading(false);
            handleCloseProp()
        }

        // ---END

        // setIsLoading(false);
        // handleCloseProp()

        // await fetch(process.env.REACT_APP_API_ADDRESS + api, options)
        //     .then((response) => {
        //         // Handle the API response here
        //         // console.log('API Response:', response);
        //         setIsLoading(false); handleCloseProp()
        //     })
        //     .catch((error) => {
        //         // Handle any errors here
        //         // console.log('API Error:', error);
        //         setIsLoading(false); handleCloseProp()
        //     })
    };


    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        setUploadedFileName(file.name);

        // Simulate file upload progress (for demonstration purposes)
        let progress = 0;
        const interval = setInterval(() => {
            progress += 20;
            setUploadProgress(progress);

            if (progress >= 100) {
                clearInterval(interval);
                // uploadFile(file)
                setFileToUpload(file)
            }
        }, 100);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: ['.xlsx', '.csv', 'text/csv'],
        onDrop
    });

    function checkCampaignType(tabValue) {
        if (campaignName == "") {
            setDisplayCampaignError(true)
        } else {
            if (fileToUpload == null) {
                setDisplayCampaignError(false)
                setFileError(true)
            } else {
                uploadFile(tabValue)
            }
        }
    }

    function changeTabsOnClick(buttonVal) {
        // console.log("changing tabs")
        // console.log(tabValue)
        // console.log(buttonVal)

        if (buttonVal == "next") {
            if (tabValue != 2) {
                var tempTabVal = tabValue + 1;
                setTabValue(tempTabVal)
            }
        }
        else if (buttonVal == "prev") {
            if (tabValue != 0) {
                var tempTabVal = tabValue - 1;
                setTabValue(tempTabVal)
            }
        }
    }

    async function createEmptyTracking() {
        setIsLoading(true);
        const token2 = await userData.getIdToken();

        var data = { campaignName: campaignName, campaignDescription: campaignDescription, entityDescription: entityDescription, entityPerception: entityPerception, isBliActive: checkedBli };

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/create_empty_tracking', options)
            .then(result => result.json())
            .then(body => { handleCloseProp(); setIsLoading(false); });
    }


    return (
        <Box sx={style}>
            <Grid container sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <Grid item>
                    <Typography variant="h6">
                        Enter Campaign Details
                    </Typography>
                </Grid>
                <Grid item sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <Typography>
                        Enter Campaign Name
                    </Typography>
                    <TextField
                        style={{ width: "100%", margin: "10px 0px 5px 0px" }}
                        required
                        placeholder="Campaign 1"
                        value={campaignName}
                        onChange={handleCampaignName}
                        error={displayCampaignError}
                        helperText={displayCampaignError ? "Please enter campaign name" : ""}
                    />

                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px", marginBottom: "20px" }}>
                        <Box>
                            <InfoIcon />
                        </Box>
                        <Box>
                            <Typography start sx={{ color: "grey" }} variant='h7'>Content links can be added inside the campaign under the content tab</Typography>
                        </Box>
                    </Box>

                    <Typography>
                        Enter Campaign Description (optional)
                    </Typography>
                    <TextField
                        style={{ width: "100%", margin: "0px 0px 15px 0px" }}
                        required
                        placeholder="Campaign Description"
                        value={campaignDescription}
                        onChange={handleCampaignDescription}
                    // error={displayCampaignError}
                    // helperText={displayCampaignError ? "Please enter campaign name" : ""}
                    />


                    <Typography>
                        Enter Entity Description (optional)
                    </Typography>
                    <TextField
                        style={{ width: "100%", margin: "0px 0px 15px 0px" }}
                        required
                        placeholder="Entity Description"
                        value={entityDescription}
                        onChange={handleEntityDescription}
                    // error={displayCampaignError}
                    // helperText={displayCampaignError ? "Please enter campaign name" : ""}
                    />


                    <Typography>
                        Enter Entity Perception (optional)
                    </Typography>
                    <TextField
                        style={{ width: "100%", margin: "0px 0px 15px 0px" }}
                        required
                        placeholder="Entity Perception"
                        value={entityPerception}
                        onChange={handleEntityPerception}
                    // error={displayCampaignError}
                    // helperText={displayCampaignError ? "Please enter campaign name" : ""}
                    />

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography>
                            Enable BLI
                        </Typography>
                        <Checkbox checked={checkedBli} onClick={() => setCheckedBli(!checkedBli)} size='medium' />
                    </Box>

                    {/* <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", width: "100%" }}>
                        <Button onClick={() => changeTabsOnClick("prev")} ><NavigateBeforeOutlinedIcon fontSize="small" sx={{ color: "black" }} /></Button>
                        <StyledTabs value={tabValue} onChange={handleChangeTabs} >
                            {tabLabels.map((value, index) => (
                                <StyledTab key={index} iconPosition="end" label={value} />
                            ))}
                        </StyledTabs>
                        <Button sx={{ color: "black" }} onClick={() => changeTabsOnClick("next")}><NavigateNextOutlinedIcon /></Button>
                    </Box> */}


                    {/* <CustomTabPanel value={tabValue} index={0}>
                        <Box sx={{ marginBottom: "10px", display: "flex", justifyContent: "right" }}>
                            <a href={UploadFormatContent} download="UploadFormat.csv" target='_blank'>
                                <Button sx={{ color: "black" }} endIcon={<DownloadIcon />}>Download Format</Button>
                            </a>
                        </Box>
                        <Paper elevation={3} {...getRootProps()} style={dropzoneStyles}>
                            <input {...getInputProps()} />
                            {uploadProgress == 0 && <Typography component="div">
                                Drag or Drop your CSV file here
                            </Typography>}
                            {uploadProgress > 0 &&
                                <Typography>
                                    File Uploaded: {uploadProgress}%
                                </Typography>}
                        </Paper>
                        {fileError && <FormHelperText sx={{ color: "red" }}>Please upload your file</FormHelperText>}
                        <Typography variant="body1" style={{ marginTop: '16px' }}>
                            Uploaded File: {uploadedFileName}
                        </Typography>
                        <Box style={{ color: "#000", position: "relative" }}>
                            <LinearProgress
                                color='inherit'
                                variant="determinate"
                                value={uploadProgress}
                                style={{ marginTop: '16px', width: '100%' }}
                            />
                        </Box>
                    </CustomTabPanel>

                    <CustomTabPanel value={tabValue} index={1}>
                        <Box sx={{ marginBottom: "10px", display: "flex", justifyContent: "right" }}>
                            <a href={UploadFormatContent} download="UploadFormat.csv" target='_blank'>
                                <Button sx={{ color: "black" }} endIcon={<DownloadIcon />}>Download Format</Button>
                            </a>
                        </Box>
                        <Paper elevation={3} {...getRootProps()} style={dropzoneStyles}>
                            <input {...getInputProps()} />
                            {uploadProgress == 0 && <Typography component="div">
                                Drag or Drop your CSV file here
                            </Typography>}
                            {uploadProgress > 0 &&
                                <Typography>
                                    File Uploaded: {uploadProgress}%
                                </Typography>}
                        </Paper>
                        {fileError && <FormHelperText sx={{ color: "red" }}>Please upload your file</FormHelperText>}
                        <Typography variant="body1" style={{ marginTop: '16px' }}>
                            Uploaded File: {uploadedFileName}
                        </Typography>
                        <Box style={{ color: "#000", position: "relative" }}>
                            <LinearProgress
                                color='inherit'
                                variant="determinate"
                                value={uploadProgress}
                                style={{ marginTop: '16px', width: '100%' }}
                            />
                        </Box>
                    </CustomTabPanel>

                    <CustomTabPanel value={tabValue} index={2} >
                        <Box sx={{ marginBottom: "10px", display: "flex", justifyContent: "right" }}>
                            <a href={UploadFormat} download="UploadFormat.csv" target='_blank'>
                                <Button sx={{ color: "black" }} endIcon={<DownloadIcon />}>Download Format</Button>
                            </a>
                        </Box>
                        <Paper elevation={3} {...getRootProps()} style={dropzoneStyles}>
                            <input {...getInputProps()} />
                            {uploadProgress == 0 && <Typography component="div">
                                Drag or Drop your CSV file here
                            </Typography>}
                            {uploadProgress > 0 &&
                                <Typography>
                                    File Uploaded: {uploadProgress}%
                                </Typography>}
                        </Paper>
                        {fileError && <FormHelperText sx={{ color: "red" }}>Please upload your file</FormHelperText>}
                        <Typography variant="body1" style={{ marginTop: '16px' }}>
                            Uploaded File: {uploadedFileName}
                        </Typography>
                        <Box style={{ color: "#000", position: "relative" }}>
                            <LinearProgress
                                color='inherit'
                                variant="determinate"
                                value={uploadProgress}
                                style={{ marginTop: '16px', width: '100%' }}
                            />
                        </Box>
                    </CustomTabPanel> */}

                    {isLoading && <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "black" }} size={50} /></Box>}
                    {/* checkCampaignType(tabValue) */}
                    {!isLoading && <Box sx={{ display: "flex", justifyContent: "center" }}><Button style={{ margin: "10px 0px 10px 0px", backgroundColor: "#F8C036", color: "black", borderRadius: "5px", width: "fit-content", border: "1px solid #C4C4C4", paddingRight: "20px", paddingLeft: "20px" }} onClick={() => createEmptyTracking()}>Create Campaign</Button></Box>}
                </Grid>
            </Grid>
        </Box>
    )
}

export default StartTracking
