import React from 'react'
import Box from '@mui/material/Box'
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Mixpanel } from "../mixpanel/mixpanel.js";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import goaffpro from "../assets/goaffpro.png"
import { Button, Card, CircularProgress, Divider, Grid, InputLabel, Modal, Tab, Tabs, TextField, Typography } from '@mui/material'

const theme = createTheme({
    palette: {
        primary: {
            main: "#E4F87F",
        },
    },
});

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex'
    },
    '& .MuiTabs-indicatorSpan': {
        width: '100%',
        backgroundColor: '#000',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.4)',
        '&.Mui-selected': {
            color: 'rgba(0, 0, 0, 1)',
        },
        '&.Mui-focusVisible': {
        },
    }),
);

function Settings(props) {

    const userData = props.userData;

    const integrations = ["Klaviyo", "Growlytics", "Contlo", "Omnisend", "WebEngage", "Shopify x GoAffPro"]
    const integrationVals = ["klaviyo", "growlytics", "contlo", "omnisend", "webengage", "goaffpro"]
    const imgs = ["https://i.ibb.co/tqyjxD2/Klaviyo.png", "https://i.ibb.co/RD0RVL4/growlytics2.png", "https://i.ibb.co/4jPKs7x/contlo.jpg",
        "https://i.ibb.co/6NjMsCM/omnisend.png", "https://i.ibb.co/FHfTCXJ/webengage.png", goaffpro]

    const [tabValue, setTabValue] = useState(0);
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function setTabLabel(label) {
        return (<div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "6px" }}>
            {/* {icon} */}
            <Typography>{label}</Typography>
        </div>)
    }
    const tabLabels = [setTabLabel("Integrations"), setTabLabel("Instagram Connection")]

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingFetch, setIsLoadingFetch] = useState(false)
    const [open, setOpen] = useState(false);
    const [integrationIndex, setIntegrationIndex] = useState(0);
    function handleOpen(index) {
        setOpen(true);
        setIntegrationIndex(index)
    }
    const handleClose = () => { setOpen(false); setKey(""); setDisplayError(false); setDisplayErrorText("") }

    const style = {
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        width: "30%",
        height: "auto",
        bgcolor: 'white',
        boxShadow: 24,
        borderRadius: "20px",
        p: 3,
    };

    const [key, setKey] = useState("")
    const [displayError, setDisplayError] = useState(false)
    const [displayErrorText, setDisplayErrorText] = useState("")

    const handleKey = event => {
        setKey(event.target.value);
        setDisplayError(false)
        setDisplayErrorText("")
    };

    async function saveIntegration() {
        if (key != "") {
            setIsLoading(true);
            const token2 = await userData.getIdToken()

            let data = {
                platform: integrationVals[integrationIndex],
                privateKey: key
            }

            // Mixpanel.identify(userData.email);
            // Mixpanel.track('Integration Added', { data });

            let options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }


            fetch(process.env.REACT_APP_API_ADDRESS + '/api/save_integration/', options)
                .then(result => result.json())
                .then(body => {
                    if (body == "Success") {
                        setIsLoading(false);
                        setDisplayErrorText("")
                        fetchAllIntegrations()
                        handleClose()

                    } else {
                        setIsLoading(false);
                        setDisplayError(true)
                        setDisplayErrorText("The API key you entered is invalid. Please enter a correct API key.")
                    }
                });
        }
        else {
            setDisplayError(true)
            setDisplayErrorText("Please input an API key")
        }
    }

    const [activeIntegrations, setActiveIntegrations] = useState({ "activeIntegration": -1, "goaffproApiKey": false })

    async function fetchAllIntegrations() {
        setIsLoadingFetch(true);
        const token2 = await userData.getIdToken()

        let options = {
            method: 'GET',
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }


        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_active_integrations/', options)
            .then(result => result.json())
            .then(body => {
                setActiveIntegrations(body)
                setIsLoadingFetch(false)
            });
    }

    useEffect(() => {
        fetchAllIntegrations()
    }, [])


    return (
        <Box style={{ padding: "40px" }}>
            <Typography variant="h4" sx={{ marginBottom: "30px", marginLeft: "10px" }}>Settings</Typography>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", marginTop: "25px", width: "100%" }}>
                    <StyledTabs value={tabValue} onChange={handleChange} >
                        {tabLabels.map((value, index) => (
                            <StyledTab key={index} label={value} />
                        ))}
                    </StyledTabs>
                </Box>
            </Box>
            <CustomTabPanel value={tabValue} index={0} >
                {isLoadingFetch && <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress style={{ color: "black" }} size={50} />
                </Box>}
                {!isLoadingFetch && <Grid container style={{ marginBottom: "20px" }}>
                    {integrations.map((value, index) => (
                        <Grid key={index} item xs={2} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "auto", padding: "20px" }}>
                            <Card sx={{ gap: "10px", padding: "10px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", border: "1px solid black" }}>
                                <Typography>{value}</Typography>
                                {index != imgs.length - 1 && <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                                    <img
                                        src={imgs[index]}
                                        height="100px"
                                        width="100px" />
                                    {(index != activeIntegrations["activeIntegration"]) && <Button sx={{ marginTop: "10px", border: "1px solid #CDCDCD", color: "black", width: "80%" }} onClick={() => handleOpen(index)}>CONNECT</Button>}
                                    {(index == activeIntegrations["activeIntegration"]) && <Button sx={{ marginTop: "10px", border: "1px solid #CDCDCD", color: "black", width: "80%" }} >CONNECTED</Button>}
                                </Box>}
                                {index == imgs.length - 1 && <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "center" }}>
                                    <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
                                        <img
                                            src={imgs[index]}
                                            height="40px"
                                            width="150px" />
                                    </Box>
                                    {(activeIntegrations["goaffproApiKey"] == false) && <Button sx={{ marginTop: "10px", border: "1px solid #CDCDCD", color: "black", width: "80%" }} onClick={() => handleOpen(index)}>CONNECT</Button>}
                                    {(activeIntegrations["goaffproApiKey"]) && <Button sx={{ marginTop: "10px", border: "1px solid #CDCDCD", color: "black", width: "80%" }} >CONNECTED</Button>}
                                </Box>}
                            </Card>
                        </Grid>
                    ))}
                    <Modal
                        open={open}
                        onClose={handleClose}
                    >
                        <Box sx={style}>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography sx={{ marginBottom: "10px" }}>Add API Key to complete integration with {integrations[integrationIndex]}</Typography>
                                <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
                            </Box>
                            <Divider sx={{ width: "100%", marginBottom: "20px", marginTop: "20px" }} />
                            <InputLabel sx={{ margin: "10px" }}>API Key</InputLabel>
                            <TextField
                                style={{ width: "90%" }}
                                required
                                placeholder="Enter API Key"
                                value={key}
                                onChange={handleKey}
                                error={displayError}
                                helperText={displayErrorText}
                            />
                            <Divider sx={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} />
                            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
                                <Button sx={{ color: "black", border: "1px solid #DCDCDC", boxShadow: "none" }} onClick={handleClose}>Cancel</Button>
                                <ThemeProvider theme={theme}>
                                    <Button onClick={() => saveIntegration()} variant="contained" sx={{ border: "1px solid #DCDCDC" }} disabled={key == "" ? true : false}>Connect {integrations[integrationIndex]}</Button>
                                </ThemeProvider>
                                {/* color: "black", background: "#E4F87F", border: "1px solid black", */}
                            </Box>
                        </Box>
                    </Modal>
                </Grid>}
            </CustomTabPanel>

            <CustomTabPanel value={tabValue} index={1} >
                Coming Soon
            </CustomTabPanel>
            <Outlet />
        </Box >
    )
}

export default Settings