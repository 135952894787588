import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Avatar, Button, Card, CircularProgress, Grid, LinearProgress, Modal, Typography } from '@mui/material'
import { Outlet } from 'react-router-dom';
import CreateList from './CreateList';
import { useEffect, useState } from 'react';
import ListBar from './ListBar';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import listsNoResults from "../assets/listsNoResults.png";

function Lists(props) {

    const [activeLists, setActiveLists] = useState([]);


    const user = props.userData;
    const showDashboard = props.showDashboard;

    const [isLoading, setIsLoading] = useState(false)

    async function fetchAllLists() {

        const token2 = await user.getIdToken()

        let listApiOptions = {
            method: 'GET',
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_all_lists', listApiOptions)
            .then(result => result.json())
            .then(body => { setActiveLists(body); setIsLoading(false) });
    }

    useEffect(() => {
        setIsLoading(true)
        fetchAllLists()
    }, []);


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setIsLoading(true); fetchAllLists() }

    var displayListsNoResults = (isLoading == false && activeLists.length == 0) ? "block" : "none";

    const handleCloseDialog = () => {
        console.log("No access")
    };

    return (
        <div style={{ padding: "20px" }}>
            {!showDashboard && <Dialog
                open={true}
                onClose={handleCloseDialog}
                fullWidth={true}
                maxWidth='sm'
            >
                <Box style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: 'center' }}>
                    <DialogTitle>
                        {"Your free trial has ended."}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText >
                            Please contact us at shubham@closerapp.in to get your access.
                        </DialogContentText>
                    </DialogContent>
                </Box>
            </Dialog>}
            <Grid container style={{ justifyContent: "center", marginBottom: "20px" }}>
                <Button style={{ width: "75%", height: "100px", borderRadius: "25px", boxShadow: "none", backgroundColor: "#E4F87F", color: "black", fontWeight: 600, fontSize: "18px" }} onClick={handleOpen}>
                    + Create A List
                </Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <Box>
                        <CreateList handleClose={handleClose} userData={user} />
                    </Box>
                </Modal>
                <Box sx={{ width: "80%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px" }}>
                    {isLoading && <CircularProgress style={{ color: "black", }} size={100} />}
                    {!isLoading &&
                        <Box sx={{ width: "80%", marginTop: "30px" }}>
                            {activeLists.map((value, index) => (
                                <div style={{ padding: "8px" }} key={index}>
                                    <ListBar listData={value} totalLists={activeLists} />
                                </div>
                            ))}
                        </Box>}
                </Box>
                <div style={{ display: displayListsNoResults }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Box
                            component="img"
                            style={{
                                height: "490px",
                                width: "370px",
                            }}
                            alt="Lists No Results"
                            src={listsNoResults}
                        />
                    </div>
                </div>
            </Grid>
            <Outlet />
        </div>
    )
}

export default Lists