import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid, TextField } from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import CreateList from './CreateList.jsx';
import { useEffect } from 'react';
import auth from '../api/firebase.js';

import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import { Mixpanel } from "../mixpanel/mixpanel.js";

const style = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: "30%",
    height: "auto",
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: "10px",
    p: 3,
};

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

function DisplayListsDiscovery(props) {

    const influencers = props.influencers;
    const handleCloseProp = props.handleClose;
    const userData = props.userData;

    const [listName, setListName] = useState()

    const handleListName = event => {
        setListName(event.target.value);
    };

    const [isLoading, setIsLoading] = useState(false)


    const [checked, setChecked] = React.useState([]);


    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };


    async function addToList(value, transition) {

        if (value.length > 0) {
            const token2 = await userData.getIdToken()

            let addInfluencersToListsData = {
                listIds: value,
                influencers: influencers
            }

            console.log("ADD TO LIST DATA")
            console.log(addInfluencersToListsData)
            console.log(influencers)

            Mixpanel.identify(userData.email);
            Mixpanel.track('Added to List', { value });

            let addInfluencerToListApiOptions = {
                method: 'POST',
                body: JSON.stringify(addInfluencersToListsData),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }
            var res = "";
            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/add_influencers_to_lists', addInfluencerToListApiOptions)
                .then(result => result.json())
                .then(body => {
                    // console.log("API RES: " + body)
                    if (body == "Success") {
                        setState({
                            open: true,
                            transition,
                        });
                    }
                });
        }
    }


    const [activeLists, setActiveLists] = useState([]);

    async function fetchAllLists() {

        const token2 = await userData.getIdToken()
        let listApiOptions = {
            method: 'GET',
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_all_lists', listApiOptions)
            .then(result => result.json())
            .then(body => { setActiveLists(body); setIsLoading(false); });
    }

    useEffect(() => {
        setIsLoading(true)
        fetchAllLists()
    }, []);

    async function createNewList(listName) {
        setIsLoading(true)

        const token2 = await userData.getIdToken()
        let data = {
            listName: listName
        }

        let fetchData = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }


        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/create_new_list/', fetchData)
            .then(result => result.json())
            // fetchAllListsForInfluencer();
            .then(body => { fetchAllLists(); setIsLoading(false) });
    };


    const [state, setState] = React.useState({
        open: false,
        Transition: Slide,
    });

    // const handleClick = (Transition) => () => {
    //     setState({
    //         open: true,
    //         Transition,
    //     });
    // };

    const handleClose = () => {
        setState({
            ...state,
            open: false,
        });

        handleCloseProp()
    };


    return (
        <Box sx={style}>
            <Grid container sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <Grid item>
                    <Typography variant="h6">
                        Add to List(s)
                    </Typography>
                    {isLoading && <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}><CircularProgress style={{ color: "black", }} size={50} /></div>}
                    {!isLoading &&
                        <List sx={{ bgcolor: 'background.paper', maxHeight: "400px", overflowY: "auto" }}>
                            {activeLists.map((value, index) => {
                                const labelId = `checkbox-list-label-${value}`;
                                return (
                                    <ListItem
                                        key={index}
                                        disablePadding
                                    >
                                        <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(value) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={`${value.listName}`} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>}
                    <Button style={{ backgroundColor: "#E4F87F", color: "black", borderRadius: "5px", width: "100%", border: "1px solid #000" }} onClick={() => addToList(checked, SlideTransition)}>ADD</Button>
                    {/* <Button onClick={handleClick(SlideTransition)}>Slide Transition</Button> */}
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        autoHideDuration={1000}
                        open={state.open}
                        onClose={handleClose}
                        TransitionComponent={state.Transition}
                        message="Successfully added to list"
                    />
                </Grid>
                <Grid item>
                    <Grid item xs={12} >
                        <Typography variant="h6">
                            Create New List
                        </Typography>
                    </Grid>
                    <Grid item sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <TextField
                            style={{ width: "70%" }}
                            required
                            placeholder="List Name"
                            value={listName}
                            onChange={handleListName}
                        />
                        {isLoading && <Button style={{ backgroundColor: "#E4F87F", color: "black", borderRadius: "5px", width: "20%", border: "1px solid #C4C4C4" }} onClick={() => createNewList(listName)}><CircularProgress style={{ color: "black", }} size={50} /></Button>}
                        {!isLoading && <Button style={{ backgroundColor: "#E4F87F", color: "black", borderRadius: "5px", width: "25%", border: "1px solid #C4C4C4" }} onClick={() => createNewList(listName)}>CREATE</Button>}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default DisplayListsDiscovery
