import React from 'react';

import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

function PieCharts(props) {

    var outerRadius = 90;

    var sizing = {
        width: 300,
        height: 180,
        gap: "5px"

    };

    var distribution = props.distribution;
    var labels = props.labels;
    var type = props.type

    // sizing = {
    //     width: 600,
    //     height: 360,
    //     gap: "5px"
    // };
    // outerRadius = 180

    if (type == "followers") {
        var colors = ["#E4F87F", "#FF8E8E"]
    } else if (type == "gender") {
        var colors = ["#B7E4FD", "#EFC3FF"]
    } else if (type == "InfluencerDistribution") {
        var colors = ["#E4F87F", "#EFC3FF", "#B7E4FD"];
        sizing = {
            width: 360,
            height: 260,
            gap: "5px"
        };
        outerRadius = 120
    } else if (type == "ContentDistribution") {
        var colors = ["#E4F87F", "#EFC3FF", "#B7E4FD", "#5BCEFF"];
        sizing = {
            width: 360,
            height: 260,
            gap: "5px"
        };
        outerRadius = 120
    } else if (type == "phraseDistribution") {
        // var colors = ["#E4F87F", "#EFC3FF", "#B7E4FD", "#5BCEFF"];
        sizing = {
            width: 360,
            height: 460,
            gap: "5px"
        };
        outerRadius = 120
    }


    // const data = [
    //     { label: labels[0], value: distribution[0], color: '#0088FE' },
    //     { label: labels[1], value: distribution[1], color: '#00C49F' },
    // ];
    var data = {}
    var TOTAL = 0;

    if (type == 'phraseDistribution') {

        // var TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

        const categories = {
            Category10: [
                '#1f77b4',
                '#ff7f0e',
                '#2ca02c',
                '#d62728',
                '#9467bd',
                '#8c564b',
                '#e377c2',
                '#7f7f7f',
                '#bcbd22',
                '#17becf',
            ],
            Accent: [
                '#7fc97f',
                '#beaed4',
                '#fdc086',
                '#ffff99',
                '#386cb0',
                '#f0027f',
                '#bf5b17',
                '#666666',
            ],
            Dark2: [
                '#1b9e77',
                '#d95f02',
                '#7570b3',
                '#e7298a',
                '#66a61e',
                '#e6ab02',
                '#a6761d',
                '#666666',
            ],
            Paired: [
                '#a6cee3',
                '#1f78b4',
                '#b2df8a',
                '#33a02c',
                '#fb9a99',
                '#e31a1c',
                '#fdbf6f',
                '#ff7f00',
                '#cab2d6',
                '#6a3d9a',
                '#ffff99',
                '#b15928',
            ],
            Pastel1: [
                '#fbb4ae',
                '#b3cde3',
                '#ccebc5',
                '#decbe4',
                '#fed9a6',
                '#ffffcc',
                '#e5d8bd',
                '#fddaec',
                '#f2f2f2',
            ],
            Pastel2: [
                '#b3e2cd',
                '#fdcdac',
                '#cbd5e8',
                '#f4cae4',
                '#e6f5c9',
                '#fff2ae',
                '#f1e2cc',
                '#cccccc',
            ],
            Set1: [
                '#e41a1c',
                '#377eb8',
                '#4daf4a',
                '#984ea3',
                '#ff7f00',
                '#ffff33',
                '#a65628',
                '#f781bf',
                '#999999',
            ],
            Set2: [
                '#66c2a5',
                '#fc8d62',
                '#8da0cb',
                '#e78ac3',
                '#a6d854',
                '#ffd92f',
                '#e5c494',
                '#b3b3b3',
            ],
            Set3: [
                '#8dd3c7',
                '#ffffb3',
                '#bebada',
                '#fb8072',
                '#80b1d3',
                '#fdb462',
                '#b3de69',
                '#fccde5',
                '#d9d9d9',
                '#bc80bd',
                '#ccebc5',
                '#ffed6f',
            ],
            Tableau10: [
                '#4e79a7',
                '#f28e2c',
                '#e15759',
                '#76b7b2',
                '#59a14f',
                '#edc949',
                '#af7aa1',
                '#ff9da7',
                '#9c755f',
                '#bab0ab',
            ],
        };

        const totalDist = distribution.reduce((a, b) => a + b, 0);


        var data = labels.map((label, index) => ({
            label: label + ": " + Math.round((distribution[index] / totalDist) * 100) + "% ",
            value: Math.round((distribution[index] / totalDist) * 100),
            color: categories["Paired"][index % 12]
        }));

    } else {
        var data = labels.map((label, index) => ({
            label,
            value: (distribution[index] > 1 ? Math.round(distribution[index]) : distribution[index]),
            color: colors[index]
        }));

        // var TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);
    }

    var TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);


    const getArcLabel = (params) => {
        if (type == 'phraseDistribution') {
            const percent = params.value / TOTAL;
            return `${(percent * 100).toFixed(0)}%`;
        } else {
            if (params.value == 0) { return "" }
            else { return params.value; }
        }
    };


    if (type == 'phraseDistribution') {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <PieChart
                    series={[
                        {
                            outerRadius: outerRadius,
                            data: data,
                            arcLabel: getArcLabel,
                            arcLabelMinAngle: 30
                        },
                    ]}
                    sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                            fill: (type == 'phraseDistribution' ? 'black' : 'black'),
                            fontSize: 16
                        }
                    }}
                    {...sizing}
                />
            </div>
        );
    } else {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <PieChart
                    series={[
                        {
                            outerRadius: outerRadius,
                            data: data,
                            arcLabel: getArcLabel,
                            arcLabelMinAngle: 30,
                        },
                    ]}
                    sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                            fill: 'black',
                            fontSize: 16,
                        }
                    }}
                    {...sizing}
                />
            </div>
        );
    }
}

export default PieCharts