import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import { Grid, SvgIcon, Typography } from '@mui/material';
import Organic from "./Organic"
import Sponsored from "./Sponsored"
import Stats from "./Stats"
import { useEffect } from 'react';
import { useState } from 'react';


export default function CustomizedTabs(props) {

  const sponsored = props.sponsored;
  const nonSponsored = props.nonSponsored;
  const stats = props.stats;
  const sponsoredStats = props.sponsoredStatsBar;
  const organicStats = props.organicStatsBar;

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      marginBottom: '70px',
    },
    '& .MuiTabs-indicatorSpan': {
      width: '100%',
      backgroundColor: '#000',
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      color: 'rgba(0, 0, 0, 0.3)',
      '&.Mui-selected': {
        color: 'rgba(0, 0, 0, 1)',
        fontWeight: theme.typography.fontWeightBold,
      },
      '&.Mui-focusVisible': {
      },
    }),
  );

  const [tab, setTab] = React.useState(<Stats content={stats} />);

  function handleTabChange(label) {
    if (label == 0) { setTab(<Stats content={stats} />) }
    if (label == 1) { setTab(<Sponsored content={sponsored} stats={sponsoredStats} />) }
    if (label == 2) { setTab(<Organic content={nonSponsored} stats={organicStats} />) }
  };

  const [value, setValue] = React.useState(0);
  const pieChartIconWeight = (value == 0) ? 1.5 : 0.3;
  const dollarIconWeight = (value == 1) ? 1.5 : 0.3;
  const organicIconWeight = (value == 2) ? 1.5 : 0.3;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const pieChartIcon =
    (<SvgIcon fontSize="large">
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.1751 13.4195C17.6449 14.6732 16.8157 15.778 15.7599 16.6372C14.7042 17.4965 13.454 18.084 12.1187 18.3484C10.7835 18.6129 9.40374 18.5463 8.10017 18.1543C6.79661 17.7623 5.60891 17.057 4.6409 16.1C3.6729 15.143 2.95406 13.9634 2.54725 12.6644C2.14043 11.3654 2.05802 9.98654 2.30722 8.64833C2.55641 7.31012 3.12963 6.05332 3.97676 4.98782C4.82388 3.92233 5.91912 3.08056 7.16672 2.53613"
          stroke="black"
          strokeWidth={pieChartIconWeight}
          strokeLinecap="round"
          strokeLinejoin="round" />
        <path d="M18.8333 10.1781C18.8333 9.08371 18.6178 8.00008 18.199 6.98903C17.7802 5.97798 17.1664 5.05933 16.3926 4.2855C15.6187 3.51168 14.7001 2.89785 13.689 2.47906C12.678 2.06027 11.5943 1.84473 10.5 1.84473V10.1781H18.8333Z"
          stroke="black"
          strokeWidth={pieChartIconWeight}
          strokeLinecap="round"
          strokeLinejoin="round" />
      </svg>
    </SvgIcon>)

  const dollarIcon = (<SvgIcon fontSize="large">
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.02922 8.07957C3.89543 7.47689 3.91597 6.85018 4.08895 6.25755C4.26193 5.66492 4.58174 5.12556 5.01873 4.68948C5.45573 4.2534 5.99576 3.93472 6.58874 3.76298C7.18173 3.59125 7.80849 3.57201 8.41089 3.70707C8.74245 3.18851 9.19922 2.76177 9.73909 2.46617C10.279 2.17057 10.8846 2.01562 11.5001 2.01562C12.1156 2.01562 12.7211 2.17057 13.261 2.46617C13.8009 2.76177 14.2577 3.18851 14.5892 3.70707C15.1925 3.57143 15.8204 3.59057 16.4143 3.76273C17.0082 3.93488 17.549 4.25445 17.9862 4.69171C18.4235 5.12897 18.7431 5.66972 18.9152 6.26366C19.0874 6.85759 19.1065 7.48542 18.9709 8.08874C19.4894 8.42031 19.9162 8.87708 20.2118 9.41694C20.5074 9.95681 20.6623 10.5624 20.6623 11.1779C20.6623 11.7934 20.5074 12.399 20.2118 12.9389C19.9162 13.4787 19.4894 13.9355 18.9709 14.2671C19.1059 14.8695 19.0867 15.4962 18.915 16.0892C18.7432 16.6822 18.4246 17.2222 17.9885 17.6592C17.5524 18.0962 17.013 18.416 16.4204 18.589C15.8278 18.762 15.2011 18.7825 14.5984 18.6487C14.2673 19.1693 13.8101 19.5979 13.2693 19.8948C12.7285 20.1917 12.1216 20.3474 11.5046 20.3474C10.8877 20.3474 10.2807 20.1917 9.73994 19.8948C9.19915 19.5979 8.74202 19.1693 8.41089 18.6487C7.80849 18.7838 7.18173 18.7646 6.58874 18.5928C5.99576 18.4211 5.45573 18.1024 5.01873 17.6663C4.58174 17.2302 4.26193 16.6909 4.08895 16.0983C3.91597 15.5056 3.89543 14.8789 4.02922 14.2762C3.50668 13.9455 3.07627 13.4881 2.77802 12.9464C2.47976 12.4046 2.32336 11.7963 2.32336 11.1779C2.32336 10.5595 2.47976 9.95117 2.77802 9.40946C3.07627 8.86775 3.50668 8.41027 4.02922 8.07957Z"
        stroke="black" strokeWidth={dollarIconWeight} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.1667 7.51123H9.66671C9.18048 7.51123 8.71416 7.70439 8.37035 8.0482C8.02653 8.39202 7.83337 8.85833 7.83337 9.34456C7.83337 9.83079 8.02653 10.2971 8.37035 10.6409C8.71416 10.9847 9.18048 11.1779 9.66671 11.1779H13.3334C13.8196 11.1779 14.2859 11.3711 14.6297 11.7149C14.9736 12.0587 15.1667 12.525 15.1667 13.0112C15.1667 13.4975 14.9736 13.9638 14.6297 14.3076C14.2859 14.6514 13.8196 14.8446 13.3334 14.8446H7.83337"
        stroke="black" strokeWidth={dollarIconWeight} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.5 16.6777V5.67773" stroke="black" strokeWidth={dollarIconWeight} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </SvgIcon>)

  const organicIcon = (<SvgIcon fontSize="large">
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5833 18.5112C8.97372 18.5161 7.42107 17.9159 6.23331 16.8295C5.04555 15.7432 4.30945 14.2502 4.17101 12.6465C4.03256 11.0428 4.50188 9.44573 5.48589 8.17191C6.46989 6.89809 7.8967 6.04062 9.48333 5.76956C14.7083 4.76123 16.0833 4.28456 17.9167 2.01123C18.8333 3.84456 19.75 5.8429 19.75 9.34456C19.75 14.3862 15.3683 18.5112 10.5833 18.5112Z"
        stroke="black" strokeWidth={organicIconWeight} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.33337 19.4277C2.33337 16.6777 4.02921 14.5144 6.99004 13.9277C9.20837 13.4877 11.5 12.0944 12.4167 11.1777" stroke="black" strokeWidth={organicIconWeight} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </SvgIcon>)


  return (
    <Grid container direction='column'>
      <Grid item >
        <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
          <StyledTabs
            value={value}
            onChange={handleChange}
          >
            <StyledTab icon={pieChartIcon} iconPosition="start" label="Stats" onClick={() => handleTabChange(0)} />
            <StyledTab icon={dollarIcon} iconPosition="start" label="Branded" onClick={() => handleTabChange(1)} />
            <StyledTab icon={organicIcon} iconPosition="start" label="Organic" onClick={() => handleTabChange(2)} />
          </StyledTabs>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {tab}
      </Grid>
    </Grid >
  );
}