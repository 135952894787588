import React, { useState } from 'react'

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import { Avatar, AvatarGroup, Box, CardContent, CardHeader, Chip, Grid, IconButton, Stack, Tooltip, Typography, SvgIcon, Slide, Checkbox } from '@mui/material';
import { red } from '@mui/material/colors';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined';
import ShapeLineOutlinedIcon from '@mui/icons-material/ShapeLineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import MainCard from './influencerCard/MainCard';
import CreateList from './CreateList';
import DisplayLists from './DisplayLists';
import LineCharts from './campaign/LineChart';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import InfoIcon from '@mui/icons-material/Info';

const style = {
    position: 'absolute',
    left: '20%',
    // transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "100%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
};

const engagementIcon = (
    <SvgIcon fontSize="small">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4946 2.63554L12.8936 1.0061L11.2641 2.4051C8.75014 4.56354 5.03696 4.56354 2.52299 2.4051L0.893555 1.0061L2.29256 2.63554C4.451 5.14951 4.451 8.86269 2.29256 11.3767L0.893555 13.0061L2.52299 11.6071C5.03696 9.44866 8.75014 9.44866 11.2641 11.6071L12.8936 13.0061L11.4946 11.3767C9.33611 8.86269 9.33611 5.14951 11.4946 2.63554Z"
                stroke="black" strokeWidth="1.20367" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
)

function InfluencerMinDetails(props) {

    var influencer = props.influencer;
    var userData = props.userData;
    var checkedInfluencer = props.checkedInfluencer;


    const influencerPhoto = "https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + influencer.username + ".jpg?alt=media"

    // const brandPhoto = "https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/brands%2F" + influencer.collabBrands[i] + ".jpg?alt=media"

    async function saveViewedInfluencer() {
        const token2 = await userData.getIdToken()

        var data = { influencer: influencer.username }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }
        fetch(process.env.REACT_APP_API_ADDRESS + '/api/save_viewed_influencer/', options)
            .then(result => result.json())
            .then(body => { console.log("") });
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => { setOpen(true); saveViewedInfluencer() }
    const handleClose = () => setOpen(false);

    const [openList, setOpenList] = useState(false);
    const handleOpenList = () => setOpenList(true);
    const handleCloseList = () => setOpenList(false);


    const avatarGroupStyle = {
        width: "calc(min(55vw, 55vh) / 10)",
        height: "calc(min(55vw, 55vh) / 10)",
        backgroundColor: "#EBEBEB",
        color: "black",
        border: "1px solid black"
    };

    function formatNumber(number) {
        if (number >= 1e6) {
            return (number / 1e6).toFixed(2) + "M";
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(2) + "K";
        } else {
            return number.toFixed(2);
        }
    }

    // async function changeGender() {
    //     const token2 = await userData.getIdToken()

    //     // influencer.username
    //     var data = { influencer: influencer.username }

    //     let options = {
    //         method: 'POST',
    //         body: JSON.stringify(data),
    //         headers: new Headers({
    //             'Authorization': `Bearer ${token2}`,
    //             'Content-Type': "application/json"
    //         })
    //     }
    //     fetch(process.env.REACT_APP_API_ADDRESS + '/api/change_influencer_gender', options)
    //         .then(result => result.json())
    //         .then(body => { console.log(body) });
    // }

    const [checked, setChecked] = useState(false);

    function handleChange(username) {
        // console.log("checked")
        // console.log(username)
        setChecked(!checked);
        props.handleToggle(username)
    };


    return (
        <Card variant="outlined" style={{ borderRadius: "20px", border: "1px solid #C4C4C4" }} >
            <Grid container style={{ padding: "10px", display: "flex", flexDirection: "column" }}>
                <Grid container >
                    <Grid item xs={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
                        <Checkbox checked={checkedInfluencer} onChange={() => handleChange(influencer)} sx={{ height: 16, width: 16 }} />
                        <Avatar style={{ width: "50px", height: "50px" }} src={influencerPhoto} />
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Typography variant="body1">{influencer.fullName}</Typography>
                        <Typography variant="body1">@{influencer.username}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ display: "flex", flexDirection: "column", minHeight: "80px" }} >
                        <Typography >Partnered Brands</Typography>
                        <AvatarGroup sx={{ '& .MuiAvatar-root': avatarGroupStyle, display: "flex", justifyContent: "left", alignItems: "center" }} total={influencer.collabBrands.length} max={4}>
                            {influencer["collabBrands"].map((value, index) => (
                                <Avatar key={index} style={{ border: "1px solid black", cursor: "pointer" }} src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/brands%2F" + value + ".jpg?alt=media"} onClick={() => window.open("https://www.instagram.com/" + value, "_blank")} />
                            ))}
                        </AvatarGroup>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ padding: "10px", minHeight: "55px" }}>
                    <Stack direction="row" spacing={1}>
                        {influencer["category"].map((value, index) => (
                            <Chip size='medium' style={{ fontSize: "14px" }} key={index} label={value.charAt(0).toUpperCase() + value.slice(1)} />
                        ))}
                    </Stack>
                </Grid>
                <Grid item xs={12} sx={{ padding: "10px" }}>
                    <Grid container style={{ display: "flex", textAlign: "center" }}>
                        <Grid item xs={4} >
                            <Typography fontWeight="bold" style={{ fontSize: "20px" }}>{formatNumber(influencer.followers)}</Typography>
                        </Grid>
                        <Grid item xs={4} >
                            <Typography fontWeight="bold" style={{ fontSize: "20px" }}>{formatNumber(influencer.avgEngagement)} %</Typography>
                        </Grid>
                        <Grid item xs={4} >
                            <Typography fontWeight="bold" style={{ fontSize: "20px" }}>{formatNumber(influencer.avgViews)}</Typography>
                        </Grid>
                        <Grid item xs={4} style={{ gap: "5px", justifyContent: "center", display: "flex" }} >
                            <PeopleAltOutlinedIcon fontSize="medium" />
                            <Typography variant="body1">Followers</Typography>
                        </Grid>
                        <Grid item xs={4} style={{ gap: "5px", justifyContent: "center", display: "flex" }} >
                            {engagementIcon}
                            <Typography variant="body1" >Engagement</Typography>
                        </Grid>
                        <Grid item xs={4} style={{ gap: "5px", justifyContent: "center", display: "flex" }} >
                            <PlayArrowOutlinedIcon fontSize="medium" />
                            <Typography variant="body1">Reel Views</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ padding: "10px" }}>
                    <Button onClick={handleOpen} variant="contained" style={{ marginRight: "10px", borderRadius: "13px", backgroundColor: "white", color: 'black', boxShadow: "none", border: "1px solid #C4C4C4" }} startIcon={<RemoveRedEyeOutlinedIcon />}>
                        View Full Profile
                    </Button>
                    <Modal
                        open={open}
                        onClose={handleClose}
                    >
                        <Slide direction="left" in={open} mountOnEnter unmountOnExit timeout={300}>
                            <Box sx={style}>
                                <MainCard handleClose={handleClose} influencer={influencer} userData={userData} />
                            </Box>
                        </Slide>
                    </Modal>
                    <Button variant="contained" style={{ borderRadius: "13px", backgroundColor: "#E4F87F", color: 'black', boxShadow: "none" }} onClick={handleOpenList}>
                        Add to list
                    </Button>
                    {/* <Button onClick={() => changeGender()}>Change Male to Female</Button> */}
                    <Modal
                        open={openList}
                        onClose={handleCloseList}
                    >
                        <Box>
                            <DisplayLists influencer={influencer} handleClose={handleCloseList} userData={userData} />
                        </Box>
                    </Modal>
                    {influencer.hasCostData && <Box sx={{ backgroundColor: "black", gap: "5px", padding: "7px", border: "1px solid black", float: "right", width: "fit-content", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ color: "white" }}>₹ {formatNumber(influencer.estimatedCost)}/reel</Typography>
                        <Tooltip title={<React.Fragment>
                            <Typography color="inherit" >This is an estimated cost for a 20-25 second reel with the influencer. Cost is calculated based on various parameters like engagement/views on previous sponsored content, total followers, real followers, audience demographics and past data.</Typography>
                        </React.Fragment>}>
                            <InfoIcon sx={{ color: "white" }} />
                        </Tooltip>
                    </Box>}
                </Grid>
            </Grid>
        </Card>
    )
}

export default InfluencerMinDetails