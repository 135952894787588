import React from 'react'
import { Avatar, Button, Card, Grid, LinearProgress, Typography } from '@mui/material'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import { Link } from 'react-router-dom';

function CampaignBar(props) {

    var campaignData = props.campaignData;
    var gradientColor = props.gradientColor;
    var campaignStatus = props.campaignStatus;

    return (
        <div >
            <Link to="/campaign/influencers" state={{ campaignData: campaignData, gradientColor: gradientColor, campaignStatus: campaignStatus }} style={{ textDecoration: "none" }}>
                <Grid container style={{ justifyContent: "center", height: "120px" }}>
                    <Card sx={{ width: "100%", border: "2px solid #DCDCDC", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "25px", boxShadow: "none" }}>
                        <Grid container pr={4} pl={5} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Grid item xs={11}>
                                <Grid container style={{ gap: "10px", display: "flex", alignItems: "center" }}>
                                    <div style={{ width: "80px", height: "80px", background: `radial-gradient(#FFFFFF, ${gradientColor})`, borderRadius: "50%" }} />
                                    <div style={{ display: "flex", height: "50%", gap: "10px" }}>
                                        <Grid item >
                                            <Grid item fontSize={20} >{campaignData.campaignName}</Grid>
                                            <Grid item fontSize={18} sx={{ display: "flex", alignItems: "center" }}><PeopleOutlineOutlinedIcon /> {campaignData.totalInfluencers}</Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item xs={1} style={{ display: "flex", justifyContent: "right" }} fontSize={30}>
                                <ArrowForwardIosOutlinedIcon />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid >
            </Link>
        </div >
    )
}

export default CampaignBar