import { Avatar, Box, Card, CardMedia, Grid, SvgIcon, Typography } from '@mui/material'
import { Divider } from '@mui/material';
import Modal from '@mui/material/Modal';
import React from 'react'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import '../../sass/components/video.scss';
import VideoCard from './VideoCard';
import StatsBar from './StatsBar';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const commentsIcon = (
    <SvgIcon style={{ width: "15px", height: "15px" }}>
        <svg width="33" height="33" viewBox="0 0 33 33" fill="white" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.60693 31.0434L4.71518 21.7187C3.23816 18.7655 2.86024 15.3831 3.64906 12.1767C4.43788 8.97036 6.34195 6.14934 9.02053 4.21849C11.6991 2.28764 14.9774 1.37299 18.2687 1.63821C21.56 1.90343 24.6495 3.33122 26.9843 5.66606C29.3192 8.0009 30.747 11.0904 31.0122 14.3817C31.2774 17.673 30.3627 20.9513 28.4319 23.6299C26.501 26.3084 23.68 28.2125 20.4737 29.0013C17.2673 29.7901 13.8849 29.4122 10.9317 27.9352L1.60693 31.0434Z"
                stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
)

const likesIcon = (
    <SvgIcon style={{ width: "30px", height: "30px" }}>
        <svg width="43" height="39" viewBox="0 0 43 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.332 3.84515C16.3391 -0.628426 8.66055 -0.466139 3.8624 4.33201C-1.10371 9.29812 -1.10371 17.3498 3.8624 22.3159L18.3347 36.7882C19.9901 38.4436 22.674 38.4436 24.3294 36.7882L38.8017 22.3159C43.7678 17.3498 43.7678 9.29812 38.8017 4.33201C34.0035 -0.466139 26.325 -0.628426 21.332 3.84515ZM18.849 7.32932L19.8334 8.31372C20.6611 9.14141 22.003 9.14141 22.8307 8.31372L23.8151 7.32932C27.1258 4.01858 32.4936 4.01858 35.8044 7.32932C39.1151 10.6401 39.1151 16.0078 35.8044 19.3186L21.332 33.7909L6.85972 19.3186C3.54898 16.0078 3.54898 10.6401 6.85972 7.32932C10.1705 4.01858 15.5382 4.01858 18.849 7.32932Z"
                fill="white" strokeWidth="3" />
        </svg>
    </SvgIcon>
)

const MyCardMedia = styled(CardMedia)({
    transition: 'opacity 0.2s ease',
    '&:hover': {
        opacity: 0.7
    },
});



const StyledModal = styled(Modal)`
position: fixed;
z-index: 1300;
inset: 0;
display: flex;
align-items: center;
justify-content: center;
`;

const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'MuiBackdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});

Backdrop.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
};

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;


const gridContainerStyles = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr', // Three columns
    // gridTemplateRows: `repeat(${rows}, 1fr)`, // Number of rows based on l
    justifyContent: "center",
    marginBottom: "10px",
    width: "935px",
};

function Sponsored(props) {

    const content = props.content;
    const stats = props.stats;

    const [openIndex, setOpenIndex] = React.useState(-1); // Initialize with -1
    const [open, setOpen] = React.useState(false);
    const handleOpen = (index) => {
        setOpenIndex(index); // Set the index of the opened modal
        setOpen(true);
    };

    const handleClose = () => {
        setOpenIndex(-1); // Reset the index when closing the modal
        setOpen(false);
    };

    function formatNumber(number) {
        if (number >= 1e6) {
            return (number / 1e6).toFixed(1) + "M";
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(0) + "K";
        } else {
            return number;
        }
    }

    function checkSponsoredReel(isSponsored) {
        if (isSponsored == true) {
            return "Sponsored"
        }
        else {
            return "Non Sponsored"
        }
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "40px" }}>
            <Box style={{ height: "fit-content", width: "95%", overflow: "auto", }}>

                <StatsBar subHeadings={["Sponsored Engagement", "Average reel views", "Average reel likes", "Average comments"]} values={stats} />

                {/* https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/content%2FCm0otHkh1nT.mp4?alt=media */}
                <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <Grid container style={gridContainerStyles}>
                        {content.map((value, index) => (
                            <div key={index} style={{ margin: "0px 1px 2px 1px", width: "230.75px", height: "410px", background: "black" }}>
                                <Grid item xs style={{ display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => handleOpen(index)} className="container">
                                    <CardMedia className="im"
                                        component='image'
                                        image={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/thumbnail%2F" + value["shortcode"] + ".jpg?alt=media"}
                                        style={{ width: "230.75px", height: "410px", }}
                                    />
                                    {/* color: "white", fontWeight: "bold", fontSize: "18px", */}
                                    <div className="overlay"></div>
                                    <div className="text" style={{ display: "flex", justifyContent: "center" }}>
                                        <PlayArrowIcon />
                                        <Typography fontWeight={"bold"}>{formatNumber(value.views)}</Typography>
                                    </div>
                                    <div className='text2' style={{ display: "flex", justifyContent: "center", border: "1px solid black", padding: "5px 10px 5px 10px", borderRadius: "10px", background: "white" }}>
                                        <Typography sx={{ color: "black" }}>
                                            {checkSponsoredReel(value["isSponsored"])}
                                        </Typography>
                                    </div>
                                    <Button startIcon={<FavoriteIcon fontSize='large' />} style={{ position: "absolute", fontSize: "20px", color: "white" }} className="plays">{formatNumber(value.likes)}</Button>
                                    <Button startIcon={commentsIcon} style={{ position: "absolute", fontSize: "20px", color: "white" }} className="comments">{value.comments}</Button>
                                </Grid>
                                <StyledModal
                                    open={openIndex === index}
                                    onClose={handleClose}
                                    slots={{ backdrop: StyledBackdrop }}
                                >
                                    <VideoCard key={index} videoData={value} />
                                </StyledModal>
                            </div>
                        ))}
                    </Grid>
                </div>
            </Box>
        </div >
    )
}

export default Sponsored