import React from 'react'
// import "./extension.css"
import Counter from './chromeExtension'

function Chrome() {
    return (
        <div className="App">
            <div style={{ height: "50px", backgroundColor: "black", display: "flex", flexDirection: "row" }}>
                <div style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                    <img className="CloserLogo" style={{ width: "105px", height: "30px" }} src="https://i.ibb.co/L9hM8sV/Group-273.png" />
                </div>
                <div style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                    <a className="CloserAppLink" href="https://www.google.com">Open App</a>
                </div>
            </div>
            <Counter />
        </div>
    )
}

export default Chrome