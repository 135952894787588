import React, { useEffect, useState } from 'react'
import StarIcon from '@mui/icons-material/Star';
import InstagramLogo from "../../assets/instagramLogo.png"
import InstaBackground from "../../assets/instaBackground.jpg"
import JokerAndWitchLogo from "../../assets/jokerandwitchLogo.png"
import Checkmark from "../../assets/checkmark.png"
import { Box, Button, CircularProgress, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'


function JWForm() {

    const shop = "jokerandwitch";

    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [gender, setGender] = useState('female');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [usernameError, setUsernameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [genderError, setGenderError] = useState(false)

    const handleUsername = event => {
        setUsername(event.target.value);
        setUsernameError(false)
    };
    const handleEmail = event => {
        setEmail(event.target.value);
        setEmailError(false)
    };
    const handlePhone = event => {
        setPhone(event.target.value);
        setPhoneError(false)
    };
    const handleGender = (event) => {
        setGender(event.target.value);
        setGenderError(false)
    };

    function getCookie(name) {
        var nameEQ = name + "=";
        var cookies = document.cookie.split(';');

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1, cookie.length);
            }
            if (cookie.indexOf(nameEQ) === 0) {
                return cookie.substring(nameEQ.length, cookie.length);
            }
        }
        return false;
    }

    useEffect(() => {
        var closerFormValue = getCookie('closerForm');
        // console.log("CLOSER COOKIE VALUE")
        // console.log(closerFormValue)
        if (closerFormValue === 'true') {
            setIsFormSubmitted(true)
        }
    }, [])


    function createCookie(name, value, days) {
        var expires = '';
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + value + expires + '; path=/forms/jokerandwitch';
    }



    function handleSubmitForm() {

        if (username == "") {
            setUsernameError(true)
        } else if (email == "") {
            setEmailError(true)
        } else if (phone == "") {
            setPhoneError(true)
        } else if (gender == "") {
            setGenderError(true)
        } else {
            // console.log("submit form called")
            setUsernameError(false)
            setEmailError(false)
            setPhoneError(false)
            setGenderError(false)

            setIsLoading(true)
            setIsFormSubmitted(true);

            let data = {
                data:
                {
                    username: username,
                    email: email,
                    phone: phone,
                    gender: gender,
                    shop: shop
                }
            }

            createCookie('closerForm', 'true', 7);


            // Mixpanel.identify(shop);
            // Mixpanel.track(shop + ' Form Submitted', { data });

            let options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Content-Type': "application/json"
                })
            }

            // submitFormData
            fetch(process.env.REACT_APP_API_ADDRESS + '/api/send_form_data', options)
                .then(result => result.json())
                .then(body => { console.log("body"); setIsLoading(false) });
        }
    }

    if (isFormSubmitted) {
        return (
            <Box style={{ background: "rgba(254, 22, 22, 0.2)", overflow: "auto", height: "100%", position: "fixed", height: "100%", width: "100%" }}>
                <Box sx={{ background: "white", height: "70px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px 20px 0px 10px" }}>
                    <img
                        src={JokerAndWitchLogo}
                        width="200"
                        height="30px"
                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "auto", marginBottom: "20px", padding: "15px", borderRadius: "10px", gap: "10px" }}>
                    <Typography variant="h4" sx={{ color: "black", textAlign: "center", fontWeight: "900" }}>Joker & Witch Influencer Program</Typography>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "30px", width: "100%" }}>
                        <img
                            src={Checkmark}
                            width="280px"
                            height="220px"
                        />
                        <Box sx={{ height: "auto", width: "90%", background: "black", padding: "10px", marginTop: "50px", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                            <Typography sx={{ color: "white", textAlign: "center" }}>Form submitted successfully</Typography>
                            <Typography sx={{ color: "white", textAlign: "center" }}>We will contact you shortly</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    } else {
        return (
            <Box style={{ background: "rgba(254, 22, 22, 0.2)", overflow: "auto", height: "100%", position: "fixed", height: "100%", width: "100%" }}>
                <Box sx={{ background: "white", height: "70px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px 20px 0px 10px", border: "1px solid white" }}>
                    <img
                        src={JokerAndWitchLogo}
                        width="200px"
                        height="30px"
                    />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", height: "auto" }}>
                    <Box style={{ padding: "20px", height: "auto", borderRadius: "25px", zIndex: 99, display: "flex", flexDirection: "column" }}>
                        {/* <Typography>Connect Instagram Account</Typography> */}
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "auto", marginBottom: "20px", padding: "15px", borderRadius: "10px", gap: "10px" }}>
                            <Typography variant="h4" sx={{ color: "black", textAlign: "center", fontWeight: "900" }}>Joker & Witch Influencer Program</Typography>
                            <Typography sx={{ color: "black", textAlign: "center" }}>Join our exclusive influencer squad! Work directly with Joker & Witch team and avail exciting goodies, merch, incentives, and much more!</Typography>
                        </Box>
                        {/* <Button startIcon={<img
                        src={InstagramLogo}
                        width="30px"
                        height="30px"
                    />} sx={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundImage: `url(${InstaBackground})`, marginBottom: "10px" }}>
                        <Typography sx={{ color: "black", fontSize: "12px" }}>Connect Instagram Account</Typography>
                    </Button> */}
                        <Grid container>
                            <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                                <Box sx={{ display: "flex", alignItems: "start" }}>
                                    <Typography>Username</Typography>
                                    <StarIcon sx={{ height: "10px", color: "red" }} />
                                </Box>
                                <TextField
                                    error={usernameError}
                                    helperText={usernameError ? "Please enter username" : ""}
                                    value={username} onChange={handleUsername} sx={{ width: "100%" }} placeholder="Enter your username" variant="standard" />
                            </Grid>
                            {/* <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                <Typography>Full Name</Typography>
                                <StarIcon sx={{ height: "10px", color: "red" }} />
                            </Box>
                            <TextField sx={{ width: "100%" }} label="Enter your Full Name" variant="standard" />
                        </Grid> */}
                            <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                                <Box sx={{ display: "flex", alignItems: "start" }}>
                                    <Typography>Email</Typography>
                                    <StarIcon sx={{ height: "10px", color: "red" }} />
                                </Box>
                                <TextField
                                    error={emailError}
                                    helperText={emailError ? "Please enter valid email" : ""}
                                    value={email} onChange={handleEmail} sx={{ width: "100%" }} placeholder="Enter your email" variant="standard" />
                            </Grid>
                            <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                                <Box sx={{ display: "flex", alignItems: "start" }}>
                                    <Typography>Phone No</Typography>
                                    <StarIcon sx={{ height: "10px", color: "red" }} />
                                </Box>
                                <TextField
                                    error={phoneError}
                                    helperText={phoneError ? "Please enter valid phone no" : ""}
                                    value={phone} onChange={handlePhone} sx={{ width: "100%" }} placeholder="Enter your Phone No" variant="standard" />
                            </Grid>
                            {/* <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                <Typography>Age</Typography>
                                <StarIcon sx={{ height: "10px", color: "red" }} />
                            </Box>
                            <TextField sx={{ width: "100%" }} label="Enter your age" variant="standard" />
                        </Grid> */}
                            <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                                <Box sx={{ display: "flex", alignItems: "start" }}>
                                    <Typography>Gender</Typography>
                                    <StarIcon sx={{ height: "10px", color: "red" }} />
                                </Box>
                                {/* <TextField sx={{ width: "100%" }} label="Enter your gender" variant="standard" /> */}
                                <FormControl>
                                    <RadioGroup
                                        value={gender}
                                        onChange={handleGender}
                                    >
                                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                                        <FormControlLabel value="other" control={<Radio />} label="Other" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {/* <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                <Typography>State</Typography>
                                <StarIcon sx={{ height: "10px", color: "red" }} />
                            </Box>
                            <TextField sx={{ width: "100%" }} label="Enter your state" variant="standard" />
                        </Grid>
                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white", boxShadow: "0 25.7437px 34.325px rgba(41,41,41,.50)" }}>
                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                <Typography>City</Typography>
                                <StarIcon sx={{ height: "10px", color: "red" }} />
                            </Box>
                            <TextField sx={{ width: "100%" }} label="Enter your city" variant="standard" />
                        </Grid> */}
                        </Grid>
                        {isLoading && <Button sx={{ background: "black", color: "white", borderRadius: "5px", marginTop: "5px" }}><CircularProgress style={{ color: "white", }} size={25} /></Button>}
                        {!isLoading && <Button onClick={() => handleSubmitForm()} sx={{ background: "black", color: "white", borderRadius: "5px", marginTop: "5px" }}>SUBMIT FORM</Button>}
                    </Box>
                </Box>
            </Box >
        )
    }
}

export default JWForm