import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Alert, Avatar, Button, Card, CircularProgress, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Modal, Popover, Select, Snackbar, TextField, Typography } from '@mui/material'
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { useLocation, Outlet } from 'react-router-dom';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Divider from '@mui/material/Divider';

import InstagramLogin from '../../InstagramLogin';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CampaignsNoResults from "../../../assets/campaignsNoResults.png";
import TrackingBar from './TrackingBar';
import MemechatTopbar from '../MemechatTopbar';
import Tracking from './Tracking';

import MemechatLogo from "../../../assets/memechatLogo2.jpeg"
import auth from '../../../api/firebase.js';
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";


function ShareTrackingHome(props) {

    // console.log("SHARE TRACKING HOME CALLED")

    // const user = props.userData;
    const showDashboard = true;

    const location = useLocation();

    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const activeTab = (tabValue == 0) ? "#F8C036" : "#F0F0F0";
    const pausedTab = (tabValue == 1) ? "#E4F87F" : "#F0F0F0";
    const draftsTab = (tabValue == 2) ? "#E4F87F" : "#F0F0F0";
    const archivedTab = (tabValue == 3) ? "#E4F87F" : "#F0F0F0";

    const [activeCampaigns, setActiveCampaigns] = useState([])
    const [pausedCampaigns, setPausedCampaigns] = useState([])
    const [draftCampaigns, setDraftCampaigns] = useState([])
    const [archivedCampaigns, setArchivedCampaigns] = useState([])

    function setTabLabel(label, icon) {
        return (<div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "6px" }}>
            {icon}
            <Typography>{label}</Typography>
        </div>)
    }
    const tabLabels = [setTabLabel("Active", <PlayArrowOutlinedIcon />), setTabLabel("Paused", <PauseOutlinedIcon />), setTabLabel("Drafts", <NoteAddOutlinedIcon />), setTabLabel("Archived", <Inventory2OutlinedIcon />)]
    const tabColors = [activeTab, pausedTab, draftsTab, archivedTab]
    const tabLengths = [activeCampaigns.length, pausedCampaigns.length, draftCampaigns.length, archivedCampaigns.length]

    const [isLoading, setIsLoading] = useState(false)

    function setCampaigns(allCampaigns) {
        for (const [key, value] of Object.entries(allCampaigns)) {
            if (key == 0) {
                setActiveCampaigns(value)
            } else if (key == 1) {
                setPausedCampaigns(value)
            } else if (key == 2) {
                setDraftCampaigns(value)
            } else if (key == 3) {
                setArchivedCampaigns(value)
            }
        }
    }

    const [instaUsernames, setInstaUsernames] = useState([])
    const [isLoadingInstaButton, setIsLoadingInstaButton] = useState(false)

    useEffect(() => {
        // fetchAllCampaigns()
        // fetchInstagramAccounts()
    }, [])



    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setIsLoading(true); }

    var displayCampaignsNoResults = (isLoading == false && activeCampaigns.length == 0) ? "block" : "none";

    const handleCloseDialog = () => {
        console.log("No access")
    };

    const [code, setCode] = useState(null)
    const [success, setSuccess] = useState(false)

    const handleCode = event => {
        setCode(event.target.value);
    };

    const navigate = useNavigate();

    async function checkCampaignCode(code, campaignId) {
        var data = { "code": code, "campaignId": campaignId }
        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Content-Type': "application/json"
            })
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_ADDRESS + '/api/check_campaign_code', options);
            const body = await response.json();
            return body; // Ensure the body is returned here

        } catch (error) {
            console.error("Error in checkCampaignCode:", error);
            return { "success": false }; // Return null or appropriate error handling
        }
        // await fetch(process.env.REACT_APP_API_ADDRESS + '/api/check_campaign_code', options)
        //     .then(result => result.json())
        //     .then(body => { console.log("BODy"); console.log(body); return body });
    }

    async function checkCode() {
        setIsLoading(true);
        var localStorageTrackingId = JSON.parse(window.localStorage.getItem('campaignIds'));
        // console.log("tracking id local storage")
        // console.log(localStorageTrackingId)

        const trackingId = location.pathname.split("/")[1]
        // console.log("TRACKING ID")
        // console.log(trackingId)
        const { email, password, campaignId, success } = await checkCampaignCode(code, trackingId);

        if (success) {
            // const trackingId = campaignId;
            // console.log("inside sign-in")
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in 
                    // console.log("Inside ")
                    const user = userCredential.user;
                    // console.log("USER first login")
                    // console.log(user)
                    console.log("correct code. sign-in successfull")
                    if (localStorageTrackingId == undefined) {
                        var data = {};
                        data[campaignId] = 1;
                        window.localStorage.setItem('campaignIds', JSON.stringify(data));
                    } else {
                        localStorageTrackingId[campaignId] = 1;
                        window.localStorage.setItem('campaignIds', JSON.stringify(localStorageTrackingId));
                    }
                    console.log("navigating inside")
                    navigate(`/${trackingId}/share/influencers`)
                })
                .catch((error) => {
                    setIsLoading(false)
                    // setLoginMessage("Wrong Email/Password Entered")
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(error)
                    // console.log(errorCode)
                    // console.log(errorMessage)
                    setOpenAlert(true);
                });
            // setSuccess(true)
        }
        else {
            // console.log("incorrect code")
            setLoginMessage("Please enter a valid code")
            setOpenAlert(true);
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setIsLoading(true);

        const fetchData = async () => {
            var localStorageTrackingId = JSON.parse(window.localStorage.getItem('campaignIds') || '{}');
            // console.log("tracking id local storage");
            // console.log(localStorageTrackingId);

            const trackingId = location.pathname.split("/")[1];
            try {
                onAuthStateChanged(auth, (user) => {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/auth.user
                    // setIsUserFetched(true)
                    // setUser(user)
                    if (user) {
                        // console.log("USER FOUND")
                        if (localStorageTrackingId != null && (trackingId in localStorageTrackingId)) {
                            // setShowPage(true)
                            // console.log("found campaign in local storage")
                            navigate(`/${trackingId}/share/influencers`)
                        }
                        else {
                            // console.log("campaign opening for the first time or new campaign not found in local storage")
                            navigate(`/${trackingId}`)
                            setIsLoading(false)
                        }
                    }
                    else {
                        // console.log('no user found');
                        navigate(`/${trackingId}`)
                        setIsLoading(false)
                    }
                });

            } catch (error) {
                setIsLoading(false);
                console.log(error);
                // console.log(error.code);
                // console.log(error.message);
                setOpenAlert(true);
            }
        };

        fetchData();
    }, [])


    const closerLogoTop = (
        <svg width="943" height="699" viewBox="0 0 943 699" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M351.499 698.127C677.745 698.127 942.219 433.653 942.219 107.407C942.219 -218.839 677.745 -483.313 351.499 -483.313C25.2537 -483.313 -239.221 -218.839 -239.221 107.407C-239.221 433.653 25.2537 698.127 351.499 698.127ZM359.367 -198.892L351.499 -302.286L343.632 -198.892C331.494 -39.3705 204.722 87.4015 45.2007 99.5397L-58.1936 107.407L45.2007 115.274C204.722 127.413 331.494 254.185 343.632 413.706L351.499 517.1L359.367 413.706C371.505 254.185 498.277 127.413 657.798 115.274L761.192 107.407L657.798 99.5397C498.277 87.4015 371.505 -39.3705 359.367 -198.892Z" fill="#272727" />
        </svg>
    )

    const closerLogoBottom = (
        <svg width="481" height="480" viewBox="0 0 481 480" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_558_4113)">
                <path fillRule="evenodd" clipRule="evenodd" d="M287.316 424.111C362.944 424.111 424.252 362.803 424.252 287.175C424.252 211.548 362.944 150.239 287.316 150.239C211.689 150.239 150.38 211.548 150.38 287.175C150.38 362.803 211.689 424.111 287.316 424.111ZM289.14 216.171L287.316 192.203L285.492 216.171C282.679 253.15 253.292 282.538 216.313 285.351L192.345 287.175L216.313 288.999C253.292 291.813 282.679 321.2 285.492 358.179L287.316 382.147L289.14 358.179C291.954 321.2 321.341 291.813 358.32 288.999L382.288 287.175L358.32 285.351C321.341 282.538 291.954 253.15 289.14 216.171Z" fill="#272727" />
                <path d="M287.316 426.611C364.325 426.611 426.752 364.183 426.752 287.175C426.752 210.167 364.325 147.739 287.316 147.739C210.308 147.739 147.88 210.167 147.88 287.175C147.88 364.183 210.308 426.611 287.316 426.611ZM287.316 352.124C282.21 318.643 255.849 292.282 222.367 287.175C255.849 282.068 282.21 255.707 287.316 222.226C292.423 255.707 318.784 282.068 352.265 287.175C318.784 292.282 292.423 318.643 287.316 352.124Z" stroke="#232323" strokeWidth="5" />
            </g>
            <defs>
                <filter id="filter0_d_558_4113" x="0.380371" y="0.239258" width="573.872" height="573.872" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="72.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.137255 0 0 0 0 0.137255 0 0 0 0 0.137255 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_558_4113" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_558_4113" result="shape" />
                </filter>
            </defs>
        </svg>
    )
    const [openAlert, setOpenAlert] = useState(false);
    const [loginMessage, setLoginMessage] = useState("")

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    return (
        // <div style={{ padding: "20px" }}>
        //     {!showDashboard && <Dialog
        //         open={true}
        //         onClose={handleCloseDialog}
        //         fullWidth={true}
        //         maxWidth='sm'
        //     >
        //         <Box style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: 'center' }}>
        //             <DialogTitle>
        //                 {"Your free trial has ended."}
        //             </DialogTitle>
        //             <DialogContent>
        //                 <DialogContentText >
        //                     Please contact us at shubham@closerapp.in to get your access.
        //                 </DialogContentText>
        //             </DialogContent>
        //         </Box>
        //     </Dialog>}
        //     <Grid container style={{ justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        //         {/* {isLoading && <Box style={{ marginTop: "50px" }}><CircularProgress style={{ color: "black", }} size={100} /></Box>} */}
        //         <Box sx={{ width: "80%", display: "flex", flexDirection: "column" }}>
        //             <TextField
        //                 placeholder="Enter Code"
        //                 value={code}
        //                 onChange={handleCode}
        //             />
        //             <Button onClick={() => checkCode()}>Enter</Button>
        //         </Box>
        //     </Grid>
        //     <Outlet />
        // </div >

        <Box style={{ position: "fixed", top: 0, left: 0, bottom: 0, right: 0, background: "#232323", display: "flex", alignItems: "center", justifyContent: "center", }}>
            <Box style={{ top: 0, left: 0, position: "absolute" }}>
                {closerLogoTop}
            </Box>
            <Box style={{ bottom: 0, right: "5%", position: "absolute" }}>
                {closerLogoBottom}
            </Box>
            <Box style={{ backgroundColor: "white", width: "30%", borderRadius: "25px", zIndex: 99 }}>
                <Grid container style={{ padding: "15% 10% 10% 10%", }}>
                    <Grid item xs={12} style={{ gap: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}>
                        <img width="240" height="120" src={MemechatLogo} alt='' />
                        Enter your credentials to continue
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
                        {/* <BootstrapInput fullWidth placeholder="Enter your Password" id="bootstrap-input" value={password} onChange={handlePassword} /> */}
                        <TextField
                            placeholder="Enter Code"
                            value={code}
                            onChange={handleCode}
                            sx={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
                        {isLoading && <Button disableElevation variant="contained" size="large" style={{ minHeight: "50px", backgroundColor: "#F8C036", color: 'black', width: "100%", borderRadius: "10px", fontWeight: 800 }}>
                            <CircularProgress size={30} style={{ color: "black", height: "fit-content" }} />
                        </Button>}
                        {!isLoading && <Button disableElevation variant="contained" size="large" style={{ minHeight: "50px", backgroundColor: "#F8C036", color: 'black', width: "100%", borderRadius: "10px", fontWeight: 800 }} onClick={() => checkCode()}>Enter</Button>}
                        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                            <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                                {loginMessage}
                            </Alert>
                        </Snackbar>
                    </Grid>
                </Grid>
            </Box>
            <Outlet />
        </Box>
    )
}

export default ShareTrackingHome