export const getSubdomain = () => {
    // console.log(window.location.hostname)
    const subdomain = splitSubdomain(window.location.hostname)

    console.log("subdomain")
    console.log(subdomain)

    return subdomain
}

const splitSubdomain = (location) => {
    const locationParts = location.split(".")

    let sliceTill = -2

    const isLocalhost = locationParts.slice(-1)[0] == "localhost";

    if (isLocalhost) sliceTill = -1

    return locationParts.slice(0, sliceTill).join("");
}