import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Avatar, Button, ButtonGroup, CircularProgress, FormControlLabel, FormGroup, Switch, TextField, Tooltip } from '@mui/material';
import { CardMedia, } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';
import PieCharts from '../PieCharts';
import React, { useEffect, useRef, useState } from 'react'
import TrackingLineCharts from './TrackingLineCharts';
import CampaignVideoCard from './CampaignVideoCard';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { Box, Modal, Grid, Card, Typography, Divider } from '@mui/material'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CampaignAnalyticsNoResults from "../../../assets/campaignAnalyticsNoResults.png";
import BliNoResults from "../../../assets/bliNoResults.png";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import ShareIcon from "../../../assets/shareIcon.png"
import SaveIcon from "../../../assets/saveIcon.png"
import SpeedIcon from '@mui/icons-material/Speed';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import LoadingButton from '@mui/lab/LoadingButton';

import "../main.css";

// import GaugeChart from 'react-gauge-chart'
import GaugeComponent from 'react-gauge-component'

const theme = createTheme({
    palette: {
        primary: {
            main: "#F8C036",
        },
    },
});

const StyledModal = styled(Modal)`
position: fixed;
z-index: 1300;
inset: 0;
display: flex;
align-items: center;
justify-content: center;
`;

const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'MuiBackdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});

Backdrop.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
};

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

function TrackingBli(props) {

    const location = useLocation();
    const propsData = location.state;


    const campaignData = propsData.campaignData;
    const userData = props.userData;


    const renderDetailsAvatar = (params) => {
        const influencerPhoto = "https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + params.row.username + ".jpg?alt=media"

        return (
            <Grid container style={{ gap: "10px" }}>
                <Grid item >
                    <Avatar src={influencerPhoto} />
                </Grid>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                    {params.row.username}
                </Grid>
            </Grid>
        )
    }

    const renderRank = (params) => {
        return (
            <Card style={{ width: "5vh", height: "5vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#E4F87F" }}>
                #{params.id}
            </Card>
        )
    }

    const renderDeliverables = (params) => {
        const contentDelivered = params.row.deliverables;
        return (

            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                <Box>
                    {contentDelivered["reel"]} Reel(s),
                </Box>
                <Box>
                    {contentDelivered["post"]} Post(s)
                </Box>
            </Box>
        )
    }

    const columns = [
        // {
        //     field: 'id',
        //     headerName: 'Rank',
        //     flex: 1,
        //     renderCell: renderRank
        // },
        {
            field: 'users',
            headerName: 'Users',
            flex: 2,
            sortable: false,
            renderCell: renderDetailsAvatar
        },
        {
            field: 'deliverables',
            headerName: 'Deliverables',
            flex: 1.5,
            sortable: false,
            renderCell: renderDeliverables
        },
        {
            field: 'shares',
            headerName: 'Shares',
            sortable: true,
            flex: 1,
        },
        {
            field: 'saves',
            headerName: 'Saves',
            sortable: true,
            flex: 1,
        },
        {
            field: 'engagement',
            headerName: 'Eng',
            sortable: true,
            flex: 1,
        },
        {
            field: 'comments',
            headerName: 'Comments',
            sortable: true,
            flex: 1,
        },
        {
            field: 'likes',
            headerName: 'Likes',
            sortable: true,
            flex: 1,
        }
    ];

    // const rows = [
    //     { id: 1, username: "abcd", deliverables: ["2 Reels", "1 Story"], shares: 5, saves: 3, engagement: "2.3%", comments: 23, likes: 12 },
    //     { id: 2, username: "abcd", deliverables: ["Reel", "Story"], shares: 4, saves: 3, engagement: "2.3%", comments: 23, likes: 12 },
    //     { id: 3, username: "abcd", deliverables: ["Reel", "Story"], shares: 4, saves: 3, engagement: "2.3%", comments: 23, likes: 12 },
    //     { id: 4, username: "abcd", deliverables: ["Reel", "Story"], shares: 4, saves: 3, engagement: "2.3%", comments: 23, likes: 12 },
    //     { id: 5, username: "abcd", deliverables: ["Reel", "Story"], shares: 4, saves: 3, engagement: "2.3%", comments: 23, likes: 12 }
    // ]; 


    const reels = ["reel1", "reel2", "reel3", "reel4"];

    const mediaTypeBg = { "POST": "#5BCEFF", "STORY": "#E69EFF", "REEL": "#E4F87F" }



    const [campaignAnalytics, setCampaignAnalytics] = useState(false)
    const [viewsGraph, setViewsGraph] = useState({})
    const [stats, setStats] = useState([])
    const [currRefresh, setCurrRefresh] = useState(0)
    const [displayCampaignAnalytics, setDisplayCampaignAnalytics] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [openIndex, setOpenIndex] = useState(-1); // Initialize with -1

    async function fetchCampaignAnalytics() {

        // console.log("fetching campaign analytics")
        var campaignId = campaignData.campaignId;
        if (campaignId != null) {
            setIsLoading(true);
            const token2 = await userData.getIdToken()

            let data = {
                campaignId: campaignId
            }

            let options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }

            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_campaign_analytics', options)
                .then(result => result.json())
                .then(body => { setCampaignAnalytics(body); setViewsGraph(body["viewsGraph"]); setStats(body["stats"]); if ("stats" in body) { setCurrRefresh(body["stats"].length - 1) } setDisplayCampaignAnalytics(body["displayCampaignAnalytics"]); setIsLoading(false) });
        }
        else { console.log("Invalid campaign id") }
    }

    const handleOpen = (index) => {
        setOpenIndex(index); // Set the index of the opened modal
    };
    const handleClose = () => {
        setOpenIndex(-1); // Reset the index when closing the modal
    };

    function getMediaComponent(url) {
        if (url != undefined) {
            const urlWithoutQuery = url.split('?')[0];

            const urlParts = urlWithoutQuery.split('/');
            const lastPart = urlParts[urlParts.length - 1];
            const fileExtension = lastPart.split('.').pop();

            return (fileExtension == "jpg") ? "image" : "video"
        }
        return "video";

    }

    function getMediaStyle(mediaComponent) {
        const imageStyle = { width: "550px", height: "390px", borderRadius: "20px", cursor: "pointer", borderRadius: "20px" }
        const videoStyle = { width: "550px", height: "390px", background: "black", borderRadius: "20px", cursor: "pointer", borderRadius: "20px" }
        if (mediaComponent == "image") {
            return imageStyle;
        } else {
            return videoStyle;
        }
    }

    function formatNumber(number) {
        if (number >= 1e6) {
            return (number / 1e6).toFixed(1) + "M";
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(1) + "K";
        } else {
            // if (number == 0) {
            // return "NA"
            // } else {
            return number;
            // }
        }
    }



    function handleDisplayStats(buttonPress) {
        var s = campaignAnalytics["stats"].length
        if (buttonPress == "prev") {
            var tempRefresh = currRefresh;
            if (tempRefresh != 0) {
                setCurrRefresh(tempRefresh - 1)
            }
        }
        else if (buttonPress == "next") {
            var tempRefresh = currRefresh;
            if (tempRefresh != (s - 1)) {
                setCurrRefresh(tempRefresh + 1)
            }
        }
    }

    // console.log("Stats")
    // console.log(stats)

    const kbitsToMbits = (value) => {
        if (value >= 1000) {
            value = value / 1000;
            if (Number.isInteger(value)) {
                return value.toFixed(0) + ' mbit/s';
            } else {
                return value.toFixed(1) + ' mbit/s';
            }
        } else {
            return value.toFixed(0) + ' kbit/s';
        }
    }

    const [bliButton, setBliButton] = useState(0)
    const [bliContentCategory, setBliContentCategory] = useState("Good")

    const [campaignBliGraph, setCampaignBliGraph] = useState([])
    const [bliScore, setBliScore] = useState(0)
    const [bliSummary, setBliSummary] = useState("")
    const [bliContent, setBliContent] = useState([])
    const [goodContent, setGoodContent] = useState([])
    const [neutralContent, setNeutralContent] = useState([])
    const [badContent, setBadContent] = useState([])
    const [bliPieChart, setBliPieChart] = useState({})

    const [displayHardcodeSummary, setDisplayHardcodeSummary] = useState(false)
    const [editHardcodeSummary, setEditHardcodeSummary] = useState(false)
    const [originalHardcodeSummary, setOriginalHardcodeSummary] = useState("")
    const [hardcodeSummary, setHardcodeSummary] = useState("")
    const [isLoadingHardcodeSummary, setIsLoadingHardcodeSummary] = useState(false)




    async function fetchTrackingBli() {

        var campaignId = campaignData.campaignId;
        if (campaignId != null) {
            setIsLoading(true);
            const token2 = await userData.getIdToken()

            let data = {
                campaignId: campaignId
            }

            let options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }

            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_tracking_bli', options)
                .then(result => result.json())
                .then(body => {
                    // console.log("body"); console.log(body);
                    setDisplayCampaignAnalytics(body["displayBli"]);
                    if (body["displayBli"]) {
                        setCampaignBliGraph(body["campaignBliGraph"])
                        setBliScore(body["campaignBliScore"]);
                        setBliSummary(body["campaignBliSummary"]);
                        setBliContent(body["goodContent"]);
                        setGoodContent(body["goodContent"]);
                        setNeutralContent(body["neutralContent"]);
                        setBadContent(body["badContent"]);
                        setBliPieChart(body['pieChart'])
                        setDisplayHardcodeSummary(body['displayHardcodeSummary'])
                        setHardcodeSummary(body['hardcodeSummary'])
                        setOriginalHardcodeSummary(body['hardcodeSummary'])
                    }

                    setIsLoading(false)
                });

        }
        else { console.log("Invalid campaign id") }
    }

    // console.log("goodContent")
    // console.log(goodContent)

    useEffect(() => {
        // fetchCampaignAnalytics()
        fetchTrackingBli()
    }, [])

    function selectBliContent(tab) {
        if (tab == 0) {
            setBliContent(goodContent)
            setBliContentCategory("Good")
        }
        else if (tab == 1) {
            setBliContent(neutralContent)
            setBliContentCategory("Neutral")
        }
        if (tab == 2) {
            setBliContent(badContent)
            setBliContentCategory("Bad")
        }
        setBliButton(tab)
    }

    const containerRef = useRef(null);
    // const [scrollAmount, setScrollAmount] = useState(0);

    // const handleScroll = (scrollOffset) => {
    //     if (containerRef.current) {
    //         const container = containerRef.current;
    //         container.scrollBy({ left: scrollOffset, behavior: 'smooth' });
    //         setScrollAmount(scrollAmount + scrollOffset);
    //     }
    // };

    const [chunkIndex, setChunkIndex] = useState(0);

    const handleScroll = (direction) => {
        const maxChunks = Math.ceil(bliContent.length / 8) - 1;
        const newIndex = chunkIndex + direction;

        if (newIndex >= 0 && newIndex <= maxChunks) {
            setChunkIndex(newIndex);

            if (containerRef.current) {
                const container = containerRef.current;
                container.scrollBy({ left: direction * 600, behavior: 'smooth' });
            }
        }
    };

    var displayBliGraph = false;
    if (campaignBliGraph.length == 0) {
        console.log("bli graph empty")
        displayBliGraph = false;
    }


    function handleCustomDateFormat(input) {
        // Determine the type of input and convert to Date object
        let date;
        if (!isNaN(input) && input.toString().length === 10) { // Check if input is a Unix timestamp
            date = new Date(Number(input) * 1000); // Convert Unix timestamp from seconds to milliseconds
        } else if (!isNaN(Date.parse(input))) { // Check if input is an ISO 8601 date string
            date = new Date(input);
        } else {
            return ''; // Handle other formats of input
        }

        // Extract the date components
        const day = date.getUTCDate();
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[date.getUTCMonth()];
        const year = date.getUTCFullYear().toString().substr(-2); // Get the last two digits of the year

        // Format the date
        return `${day} ${month} ${year}`;
    }

    function isNonEmptyObject(obj) {
        return typeof obj === 'object' && obj !== null && !Array.isArray(obj) && Object.keys(obj).length > 0;
    }

    async function updateCampaignBliData(type) {
        console.log('handle summary change called')
        // add api call to save the value

        const token2 = await userData.getIdToken()
        var data = {}

        if (type == 'switch') {
            var summary_change = !displayHardcodeSummary;
            data = {
                campaignId: campaignData.campaignId,
                option: { 'displayHardcodeSummary': summary_change }
            }
        } else if (type == 'summary') {
            setIsLoadingHardcodeSummary(true)
            setOriginalHardcodeSummary(hardcodeSummary)
            data = {
                campaignId: campaignData.campaignId,
                option: { 'hardcodeSummary': hardcodeSummary }
            }
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/update_bli_data_campaign', options)
            .then(result => result.json())
            .then(body => { console.log('body'); console.log(body); setIsLoadingHardcodeSummary(false); setEditHardcodeSummary(false); });

        if (type == 'switch') {
            setDisplayHardcodeSummary(!displayHardcodeSummary)
        }
    }

    const handleHardcodeSummary = event => {
        setHardcodeSummary(event.target.value);
    };



    if (isLoading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress style={{ color: "black", }} size={50} />
            </Box>
        )
    } else {

        if (displayCampaignAnalytics) {
            return (
                <Box >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Grid container style={{ width: "90%", display: "flex", justifyContent: "space-between" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                <Box sx={{ width: "90%", height: "fit-content", display: "flex", justifyContent: "center", aignItems: "center" }}>
                                    {/* <Typography>Control Campaign Summary</Typography> */}
                                    <FormGroup sx={{}}>
                                        <FormControlLabel control={<Switch checked={displayHardcodeSummary} onChange={() => updateCampaignBliData('switch')} />} label={displayHardcodeSummary ? "Custom Summary" : "LLM Summary"} />
                                    </FormGroup>
                                </Box>
                            </Grid>
                            <Grid item xs={4} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography variant='h5'>Brand Likeability Index (BLI)<span style={{ verticalAlign: "super", fontSize: "90%" }}>®</span></Typography>
                                    <Tooltip title={<React.Fragment>
                                        <Typography >Measures how the brand is being perceived by the audience.</Typography>
                                    </React.Fragment>}>
                                        <InfoIcon sx={{ color: "black" }} />
                                    </Tooltip>
                                </Box>
                                <GaugeComponent
                                    id="gauge-component4"
                                    type="semicircle"
                                    arc={{
                                        width: 0.2,
                                        padding: 0.005,
                                        cornerRadius: 1,
                                        subArcs: [
                                            {
                                                limit: 40,
                                                color: '#EA4228',
                                                showTick: true,
                                                tooltip: {
                                                    text: 'Poor/Bad!'
                                                }
                                            },
                                            {
                                                limit: 60,
                                                color: '#F5CD19',
                                                showTick: true,
                                                tooltip: {
                                                    text: 'Neutral!'
                                                }
                                            },
                                            {
                                                limit: 100,
                                                color: '#5BE12C',
                                                showTick: true,
                                                tooltip: {
                                                    text: 'Good'
                                                }
                                            },
                                        ]
                                    }}
                                    labels={{
                                        textColor: "black",
                                        valueLabel: { formatTextValue: value => value },
                                        tickLabels: {
                                            type: 'outer',
                                            ticks: [
                                                { value: 0 },
                                                { value: 40 },
                                                { value: 60 },
                                                { value: 100 }
                                            ],
                                            valueConfig: { formatTextValue: value => value },
                                        }
                                    }}
                                    value={bliScore}
                                    labelTextColor={"black"}
                                    minValue={0}
                                    maxValue={100}
                                    pointer={{ type: "arrow", elastic: true }}
                                    style={{ width: "80%", color: "black", textColor: "black" }}
                                />
                            </Grid>
                            <Grid item xs={8} sx={{ display: "flex", flexDirection: "column", gap: "15px", padding: "0px 10px 0px 10px" }}>
                                <Box sx={{ textAlign: "center" }}>
                                    <Typography variant='h5'>Campaign Summary</Typography>
                                </Box>
                                <Box sx={{ textAlign: "center" }}>
                                    <Typography variant='h7' sx={{ gap: "5px" }}>Brand Likeability Index: {bliScore}/100</Typography>
                                </Box>
                                <Typography>Key Insights:</Typography>
                                {!displayHardcodeSummary && <Typography>{bliSummary}</Typography>}
                                {displayHardcodeSummary &&
                                    <ThemeProvider theme={theme}>
                                        <Box>
                                            {editHardcodeSummary && <Box>
                                                <TextField
                                                    style={{ width: "100%", marginBottom: "10px" }}
                                                    required
                                                    multiline
                                                    rows={2}
                                                    placeholder="Enter Campaign Summary"
                                                    value={hardcodeSummary}
                                                    onChange={handleHardcodeSummary} />
                                                <LoadingButton loading={isLoadingHardcodeSummary} loadingIndicator="Saving.." variant="contained" disabled={isLoadingHardcodeSummary} onClick={() => updateCampaignBliData('summary')} sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black", marginRight: "5px" }}>Save</LoadingButton>
                                                <Button variant="contained" disabled={isLoadingHardcodeSummary} onClick={() => { setHardcodeSummary(originalHardcodeSummary); setEditHardcodeSummary(false) }} sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black" }}>Cancel</Button>
                                            </Box>}
                                            {!editHardcodeSummary && <Box>
                                                {hardcodeSummary == "" && <Box sx={{ padding: "5px", border: "1px solid #CDCDCD", height: "60px", marginBottom: "10px" }}>
                                                    <Typography>Enter Custom Campaign Summary</Typography>
                                                </Box>}
                                                {hardcodeSummary != "" && <Typography>{hardcodeSummary}</Typography>}
                                                <Button variant="contained" onClick={() => setEditHardcodeSummary(true)} sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black" }}>Edit</Button>
                                            </Box>}
                                        </Box>
                                    </ThemeProvider>
                                }

                                {/* <Typography>
                                    {[
                                        "Overall brand sentiment is very positive, with a Brand Likeability Index of 82. Customers appreciate the quality and innovation of the products.",
                                        "Recent launches have generated a lot of excitement, with 68% of mentions expressing positive sentiment.",
                                        "However, there are some concerns about pricing and perceived value, with 22% of comments expressing dissatisfaction in this area.",
                                        "Customer service is a bright spot, with 85% of service-related comments indicating satisfaction. Consumers appreciate the responsive and helpful support.",
                                        "Competitors are mentioned in 15% of discussions, indicating a need to monitor market positioning and differentiation."
                                    ].map((point, index) => (
                                        <React.Fragment key={index}>
                                            - {point}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </Typography> */}

                                {/* <Typography>Recommended Actions:</Typography>
                                <Typography>
                                    {[
                                        "Highlight product quality and innovation in marketing to capitalize on positive sentiment",
                                        "Address pricing concerns through promotional offers or positioning adjustments.",
                                        "Continue to invest in excellent customer service to maintain this competitive advantage.",
                                        "Monitor competitor activity and product launches to stay ahead of the curve."
                                    ].map((point, index) => (
                                        <React.Fragment key={index}>
                                            - {point}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </Typography> */}
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>

                        {isNonEmptyObject(bliPieChart) && <Grid item xs={12} style={{ width: "70%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", border: "2px solid #DCDCDC", borderRadius: "25px", }}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%" }}>
                                <div className='pieChartHeading'>Semantic Share Chart</div>
                                <PieCharts labels={bliPieChart?.labels} distribution={bliPieChart?.distribution} type="phraseDistribution" />
                            </Box>
                        </Grid>}

                        {/* {isNonEmptyObject(bliPieChart) && <Grid item xs={12} sx={{ width: "70%", border: "2px solid #DCDCDC", borderRadius: "25px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                            <div className='pieChartHeading'>Phrase Distribution</div>
                            <PieCharts labels={bliPieChart?.labels} distribution={bliPieChart?.distribution} type="phraseDistribution" />
                        </Grid>} */}
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: "20px", marginTop: "40px" }}>
                        <Grid container xs={7} sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                            <Grid item xs={2}>
                                <Card sx={{ background: (bliButton == 0 ? "#F8C036" : "white"), boxShadow: "none", border: "1px solid black", color: "black", textAlign: "center", padding: "10px", cursor: "pointer" }} onClick={() => selectBliContent(0)} >60-100 Score</Card>
                            </Grid>
                            <Grid item xs={2}>
                                <Card sx={{ background: (bliButton == 1 ? "#F8C036" : "white"), boxShadow: "none", border: "1px solid black", color: "black", textAlign: "center", padding: "10px", cursor: "pointer" }} onClick={() => selectBliContent(1)} >40-60 Score</Card>
                            </Grid>
                            <Grid item xs={2}>
                                <Card sx={{ background: (bliButton == 2 ? "#F8C036" : "white"), boxShadow: "none", border: "1px solid black", color: "black", textAlign: "center", padding: "10px", cursor: "pointer" }} onClick={() => selectBliContent(2)} >0-40 Score</Card>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Grid container sx={{ width: "80%", display: "flex", justifyContent: "center" }}>

                            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                                {/* onClick={() => handleScroll(-600)} */}
                                {bliContent.length > 0 && <Button sx={{ color: "black" }} onClick={() => handleScroll(-1)} disabled={chunkIndex === 0} ><NavigateBeforeOutlinedIcon /></Button>}

                                <Grid item xs={12} sx={{ border: "2px solid #DCDCDC", borderRadius: "25px", marginBottom: "20px", padding: "20px" }}>
                                    {/* <Box sx={{ height: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Typography variant="h4">{bliButton == 0 ? "Good Content" : (bliButton == 1 ? "Neutral Content" : "Bad Content")}</Typography>
                                    </Box> */}
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", minHeight: "400px" }}>
                                        {bliContent.length == 0 && <Typography variant='h5'>No Content Found</Typography>}
                                        {bliContent.length > 0 && <Grid container ref={containerRef} style={{ width: "100%", minHeight: "400px" }}>
                                            {/* flexWrap: "nowrap", overflowX: "hidden", add to the above Grid Container*/}
                                            {bliContent.slice(chunkIndex * 8, (chunkIndex + 1) * 8).map((value, index) => (
                                                <Grid item xs={3} key={index} style={{ padding: "10px 5px 5px 5px", display: "flex", justifyContent: "center" }}>
                                                    <Card style={{ width: "100%", borderRadius: "10px", border: "1px solid #DCDCDC", boxShadow: "none", padding: "10px" }}>
                                                        <Grid container sx={{ display: "flex" }}>
                                                            <Grid item xs={8} sx={{ marginBottom: "10px", display: "flex", justifyContent: "left", alignItems: "center", gap: "5px" }}>
                                                                <img alt='' className='avatarTopContent' src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + value.influencer + ".jpg?alt=media"} />
                                                                <div className='textTopContent' >@{value.influencer.substring(0, 8)}..</div>
                                                            </Grid>
                                                            <Grid item xs={4} sx={{ marginBottom: "10px", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                                                <Typography style={{ background: mediaTypeBg[value.mediaType], padding: "4px 7px 4px 7px", borderRadius: "10px" }}>{(value.mediaType == "POST" ? "STATIC" : value.mediaType)}</Typography>
                                                            </Grid>
                                                            <Grid item xs onClick={() => handleOpen(index)} sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                                                <CardMedia
                                                                    component={getMediaComponent(value.mediaUrl)}
                                                                    image={value.mediaUrl}
                                                                    style={getMediaStyle(getMediaComponent(value.mediaUrl))}
                                                                >
                                                                </CardMedia>
                                                            </Grid>
                                                            <StyledModal
                                                                open={openIndex === index}
                                                                onClose={handleClose}
                                                                slots={{ backdrop: StyledBackdrop }}
                                                            >
                                                                <CampaignVideoCard key={index} videoData={{ "score": value.score_int, "summary": value.summary, "mediaType": value.mediaType, "postedOn": handleCustomDateFormat(value.timePosted), "mediaComponent": getMediaComponent(value.mediaUrl), "mediaUrl": value.mediaUrl, "likes": value.likes, "comments": value.comments, "views": value.views, "shares": value.shares, "saves": value.saves, "caption": value.caption, "shortcode": value.shortcode, "brands": [] }} />
                                                            </StyledModal>
                                                            <Grid container xs sx={{ marginTop: "10px", padding: "0px 2px 0px 2px" }}>
                                                                <Grid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <div className='trackingContentOuterCardMetrics'>
                                                                        <FavoriteBorderOutlinedIcon fontSize='small' /> {formatNumber(value.likes)}
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <div className='trackingContentOuterCardMetrics'>
                                                                        <img src={ShareIcon} style={{ height: "15px", width: "15px" }} /> {formatNumber(value.shares)}
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <div className='trackingContentOuterCardMetrics'>
                                                                        <img src={SaveIcon} style={{ height: "15px", width: "15px" }} /> {formatNumber((value.saves))}
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container xs sx={{ display: "flex", gap: "5px", marginTop: "10px" }}>
                                                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start", alignItems: 'center', gap: "5px" }}><span style={{ fontWeight: "1000" }}>Score: </span>{value?.score_int}</Grid>
                                                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start", textAlign: "left", gap: "5px" }}>{value?.summary}</Grid>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>}
                                    </Box>
                                </Grid>

                                {/* onClick={() => handleScroll(600)} */}
                                {bliContent.length > 0 && <Button sx={{ color: "black" }} onClick={() => handleScroll(1)} disabled={chunkIndex >= Math.ceil(bliContent.length / 8) - 1}><NavigateNextOutlinedIcon /></Button>}

                            </Box>

                            {displayBliGraph && <Grid item xs={12} sx={{ border: "2px solid #DCDCDC", borderRadius: "25px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                                <Typography variant="h4" sx={{ textAlign: "center", marginTop: "10px" }}>BLI Trend Analysis<span style={{ verticalAlign: "super", fontSize: "90%" }}>®</span></Typography>
                                <TrackingLineCharts showBli={true} showVideo={false} showRefresh={false} campaignBliGraph={campaignBliGraph} />
                            </Grid>}
                        </Grid>

                    </Box >
                </Box >
            )
        } else {
            return (
                // <div style={{ display: "flex", justifyContent: "center" }}>
                //     <Grid container style={{ width: "80%" }}>
                //         <Grid item xs={4} style={{ padding: "10px 5px 5px 5px", display: "flex", justifyContent: "center" }}>
                //             <Typography></Typography>
                //         </Grid>
                //     </Grid>
                // </div>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Box
                        component="img"
                        sx={{
                            height: "250px",
                            width: "250px",
                        }}
                        alt="BLI No Results"
                        src={BliNoResults}
                    />
                    <Typography sx={{ marginTop: "30px" }} variant='h5'>Our AI is currently working hard to bring you BLI. Please check back later</Typography>
                </Box>
            )
        }
    }
}

export default TrackingBli