import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid, TextField } from '@mui/material';
import auth from '../api/firebase.js';
import { Mixpanel } from "../mixpanel/mixpanel.js";

const style = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: "30%",
    height: "auto",
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: "20px",
    p: 3,
};

function CreateList(props) {

    const handleCloseProp = props.handleClose;
    const userData = props.userData;

    const [listName, setListName] = useState("")

    const handleListName = event => {
        setListName(event.target.value);
    };

    const [isLoading, setIsLoading] = useState(false);

    const createNewList = async () => {

        if (listName != "") {
            setIsLoading(true);
            const token2 = await userData.getIdToken()

            let data = {
                listName: listName
            }

            Mixpanel.identify(userData.email);
            Mixpanel.track('List Created', { data });

            let fetchData = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }


            fetch(process.env.REACT_APP_API_ADDRESS + '/api/create_new_list/', fetchData)
                .then(result => result.json())
                .then(body => { setIsLoading(false); handleCloseProp() });
        }
        else { console.log("Please input list name") }
    };

    return (
        <Box sx={style}>
            <Grid container sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <Grid item>
                    <Typography variant="h6">
                        Enter List Name
                    </Typography>
                </Grid>
                <Grid item sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <TextField
                        style={{ width: "70%" }}
                        required
                        placeholder="List 1"
                        value={listName}
                        onChange={handleListName}
                    />
                    {isLoading && <CircularProgress style={{ color: "black" }} size={50} />}
                    {!isLoading && <Button style={{ backgroundColor: "#E4F87F", color: "black", borderRadius: "5px", width: "20%", border: "1px solid #C4C4C4" }} onClick={createNewList}>SAVE</Button>}
                </Grid>
            </Grid>
        </Box>
    )
}

export default CreateList
