import { Box, Card, Tooltip, Typography } from "@mui/material";
import React, { Component } from "react";

// class TrackingCustomTooltip extends Component {
export default function TrackingCustomTooltip(props) {
    // console.log("props")
    // console.log(props)
    var dataIndex = props.dataIndex;
    var combinedData = props.series[0]["customData"][dataIndex]
    var date = props.axisValue;
    const style = {
        backgroundColor: "red",
        fontSize: 12,
        padding: 10,
    };

    const elements = document.getElementsByClassName('css-advny6-MuiPopper-root')

    for (const element of elements) {
        // Example style modification
        element.style.color = 'white';
        element.style.zIndex = 2000;
    }

    return (
        <Card sx={{ padding: "15px", border: "1px solid #DCDCDC", borderRadius: "10px" }}>
            <Box>
                <Typography variant='subtitle2'><span style={{ fontWeight: "900" }}>Date:</span> {date}</Typography>
            </Box>
            <Box>
                <Typography variant='subtitle2'><span style={{ fontWeight: "900" }}>BLI Score:</span> {combinedData?.score}</Typography>
                <Typography variant='subtitle2'></Typography>
            </Box>
            <Box sx={{ width: "300px" }}>
                <Typography variant='subtitle2' sx={{ fontWeight: "900" }}>BLI Summary</Typography>
                <Typography variant='subtitle2' >{combinedData?.summary}</Typography>
            </Box>
        </Card >
    );
    // }
}

// export default TrackingCustomTooltip;