import React from 'react'
import { Link } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import { Avatar, Box, Button, Card, CircularProgress, Grid, LinearProgress, Typography } from '@mui/material'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';

function TrackingBar(props) {

    var campaignData = props.campaignData;
    var gradientColor = props.gradientColor;

    var isCampaignProcessing = campaignData.isCampaignProcessing;
    var processingCampaignLink = (isCampaignProcessing == true) ? `/tracking/${campaignData.campaignId}/influencers` : `/tracking/${campaignData.campaignId}/influencers`;

    return (
        <div >
            <Link to={processingCampaignLink} state={{ campaignData: campaignData, gradientColor: gradientColor }} style={{ textDecoration: "none" }}>
                <Grid container style={{ justifyContent: "center", height: "120px" }}>
                    <Card sx={{ width: "100%", border: "2px solid #DCDCDC", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "25px", boxShadow: "none" }}>
                        <Grid container pr={4} pl={5} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Grid item xs={11}>
                                <Grid container style={{ gap: "10px", display: "flex", alignItems: "center" }}>
                                    <div style={{ width: "80px", height: "80px", background: `radial-gradient(#FFFFFF, ${gradientColor})`, borderRadius: "50%" }} />
                                    <div style={{ display: "flex", height: "50%", gap: "10px" }}>
                                        <Grid item sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                            <Grid item fontSize={20} sx={{ textAlign: "left" }}>{campaignData.campaignName}</Grid>
                                            {isCampaignProcessing && <Grid item fontSize={16}>Processing data. Please refresh the page after sometime.</Grid>}
                                            <Grid item fontSize={18} sx={{ display: "flex", alignItems: "center" }}><PeopleOutlineOutlinedIcon /> {campaignData.totalInfluencers}</Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item xs={1} style={{ display: "flex", justifyContent: "right" }} fontSize={30}>
                                {!isCampaignProcessing && <ArrowForwardIosOutlinedIcon />}
                                {/* {isCampaignProcessing && <LockIcon fontSize="large" />} */}
                                {isCampaignProcessing && <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "black" }} size={50} /></Box>}
                            </Grid>
                        </Grid>
                    </Card>
                </Grid >
            </Link>
        </div >
    )
}

export default TrackingBar