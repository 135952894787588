import React from 'react';
import Stack from '@mui/material/Stack';
import { LineChart } from '@mui/x-charts/LineChart';
import TrackingCustomTooltip from './TrackingCustomTooltip';


const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const vData = [1400, 2398, 5800, 6908, 3800, 5800, 8300];
const xLabels = [
    'Page A',
    'Page B',
    'Page C',
    'Page D',
    'Page E',
    'Page F',
    'Page G',
];

export default function TrackingLineCharts(props) {

    const dimensions = window.screen;
    const screenHeight = dimensions.height;
    const screenWidth = dimensions.width;

    var viewsWidth = 800;
    var viewsHeight = 420;

    if (screenWidth > 1000) {
        viewsWidth = 1000;
        viewsHeight = 420;
    }
    else {
        viewsWidth = 700;
        viewsHeight = 400;
    }

    function formatDate(dateString) {
        // Create a new Date object from the input date string
        const date = new Date(dateString);

        // Define options for formatting the date
        const options = {
            day: '2-digit',   // "12"
            month: 'short',   // "Apr"
            year: '2-digit'   // "24"
        };

        // Convert the date to the specified format
        return date.toLocaleDateString('en-GB', options);
    }

    const showVideo = props.showVideo;
    const showRefresh = props.showRefresh;
    const showBli = props?.showBli;
    const campaignAnalytics = props?.campaignAnalytics;
    const campaignBliGraph = props?.campaignBliGraph;

    const [color, setColor] = React.useState('#5BCEFF');

    var newViewsArray = []
    var newSharesArray = []
    var newSavesArray = []
    var newEngagementArray = []

    if (showBli) {
        console.log("inside if (bli)")
        console.log("campaignBliGrah")
        // console.log(campaignBliGraph)

        var bliDates = campaignBliGraph.map(item => item.date);

        var bliValues = campaignBliGraph.map(item => item.score);

        function formatBliDates(dates) {
            const months = [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return dates.map(date => {
                const [year, month, day] = date.split('-');
                const monthName = months[parseInt(month, 10) - 1];
                return `${monthName} ${parseInt(day, 10)}`;
            });
        }

        var formattedBliDates = formatBliDates(bliDates)

        // console.log("bliDates")
        // console.log(bliDates)


        // var bliDates = [
        //     "5 Mar 24",
        //     "6 Mar 24",
        //     "7 Mar 24",
        //     "8 Mar 24",
        //     "9 Mar 24",
        //     "10 Mar 24",
        //     "11 Mar 24",
        //     "12 Mar 24"
        // ]

        // var bliValues = [
        //     72,
        //     75,
        //     81,
        //     85,
        //     40,
        //     74,
        //     81,
        //     86
        // ]

        // var bliSummary = [
        //     "test summary 1",
        //     "test summary 2",
        //     "test summary 3",
        //     "test summary 4",
        //     "test summary 5",
        //     "test summary 6",
        //     "test summary 7",
        //     "test summary 8"
        // ]

    } else {
        console.log("inside else")
        const viewsGraph = campaignAnalytics["viewsGraph"]
        var sortedDatesViews = Object.keys(viewsGraph).sort();
        const viewsArray = sortedDatesViews.map(date => (viewsGraph[date].views) / 1000);

        // new array where every element is the sum of all its previous elements
        newViewsArray = viewsArray.map((_, i) => viewsArray.slice(0, i + 1).reduce((a, b) => a + b))


        const sharesSavesGraph = campaignAnalytics["sharesSavesGraph"]
        const sortedDates = Object.keys(sharesSavesGraph).sort();
        const sharesArray = sortedDates.map(date => (sharesSavesGraph[date].shares));
        const savesArray = sortedDates.map(date => (sharesSavesGraph[date].saves));
        const engagementArray = sortedDates.map(date => (sharesSavesGraph[date].engagement / 100));

        // new array where every element is the sum of all its previous elements
        newSharesArray = sharesArray.map((_, i) => sharesArray.slice(0, i + 1).reduce((a, b) => a + b))
        newSavesArray = savesArray.map((_, i) => savesArray.slice(0, i + 1).reduce((a, b) => a + b))
        newEngagementArray = engagementArray.map((_, i) => engagementArray.slice(0, i + 1).reduce((a, b) => a + b))

        const stats = campaignAnalytics["stats"]
        // Create an array of 'refreshedAt' dates from the data
        var refreshedAtDates = stats.map(item => item.refreshedAt);

        // Create an array of 'totalViews' from the data
        var totalViews = stats.map(item => item.totalViews / 100);
        var totalShares = stats.map(item => item.totalShares);
        var totalEngagement = stats.map(item => item.totalEngagement / 10);
        var totalSaves = stats.map(item => item.totalSaves);

        var newSortedDatesViews = sortedDatesViews.map(date => formatDate(date));
        var newSortedDates = sortedDates.map(date => formatDate(date));
    }



    const handleChange = (event, nextColor) => {
        setColor(nextColor);
    };

    if (showRefresh) {
        return (
            <LineChart
                width={800}
                height={420}
                // series={[
                //     { id: "Content", data: totalViews, label: 'Total Views', area: true, color: color }
                // ]}
                series={[
                    { data: totalViews, label: 'Total Views' },
                    { data: totalSaves, label: 'Total Saves' },
                    { data: totalShares, label: 'Total Shares' },
                    { data: totalEngagement, label: 'Total Engagement' },
                ]}
                xAxis={[{
                    scaleType: 'point', data: refreshedAtDates, tickLabelProps: () => ({
                        angle: 45,
                        textAnchor: 'start',
                        dominantBaseline: 'ideographic',
                    }),
                }]}
                sx={{
                    '& .MuiLineElement-root': {
                        strokeWidth: 2,
                    },
                    '& .MuiMarkElement-series-Content': {
                        fill: "#5BCEFF",
                        strokeWidth: 3
                    },
                    '& .MuiChartsTooltip-root': {
                        display: 'none !important',

                    },
                    '& .MuiAreaElement-series-Content': {
                        fill: "url('#myGradient')",
                    },
                    '& .MuiChartsAxis-tickLabel': {
                        transform: 'translate(-25px, 15px) rotate(-30deg)',
                    },
                    '& .css-iael1t-MuiChartsAxis-root.MuiChartsAxis-left .MuiChartsAxis-tickLabel': {
                        transform: 'translate(0px, 0px)',
                    },
                    '& .css-1dsr0yt.MuiChartsAxis-directionY .MuiChartsAxis-tickLabel': {
                        transform: 'translate(-5px, 1px) rotate(0deg)',
                    }
                }}
            >
                <defs>
                    <linearGradient id="myGradient" gradientTransform="rotate(90)">
                        <stop offset="0%" stopColor="#89DCFF" />
                        <stop offset="100%" stopColor="#50CBFF00" />
                    </linearGradient>
                </defs>
            </LineChart>
        );
    }

    else if (showVideo) {
        return (
            <LineChart
                width={viewsWidth}
                height={viewsHeight}
                series={[{ id: "Content", data: newViewsArray, label: 'Reach (per 1K)', area: true, color: color }]}
                // tooltip={{ trigger: "axis", axisContent: TrackingCustomTooltip, itemContent: TrackingCustomTooltip }}
                xAxis={[{
                    scaleType: 'point', data: newSortedDatesViews, tickLabelProps: () => ({
                        angle: 45,
                        textAnchor: 'start',
                        dominantBaseline: 'ideographic',
                    }),
                }]}
                margin={{ left: 100 }}
                sx={{
                    '& .MuiLineElement-root': {
                        strokeWidth: 2,
                    },
                    '& .MuiMarkElement-series-Content': {
                        fill: "#5BCEFF",
                        strokeWidth: 3
                    },
                    '& .MuiChartsTooltip-root': {
                        display: 'none !important',

                    },
                    '& .MuiAreaElement-series-Content': {
                        fill: "url('#myGradient')",
                    },
                    '& .MuiChartsAxis-tickLabel': {
                        transform: 'translate(-25px, 15px) rotate(-30deg)',
                    },
                    '& .css-iael1t-MuiChartsAxis-root.MuiChartsAxis-left .MuiChartsAxis-tickLabel': {
                        transform: 'translate(0px, 0px)',
                    },
                    '& .css-1dsr0yt.MuiChartsAxis-directionY .MuiChartsAxis-tickLabel': {
                        transform: 'translate(-5px, 1px) rotate(0deg)',
                    }
                }}
            >
                <defs>
                    <linearGradient id="myGradient" gradientTransform="rotate(90)">
                        <stop offset="0%" stopColor="#89DCFF" />
                        <stop offset="100%" stopColor="#50CBFF00" />
                    </linearGradient>
                </defs>
            </LineChart>
        );
    } else if (showBli) {
        return (
            <LineChart
                width={viewsWidth}
                height={viewsHeight}
                series={[{ id: "Content", customData: campaignBliGraph, data: bliValues, label: `BLI® Trend Analysis`, area: true, color: color }]}
                tooltip={{ trigger: "axis", axisContent: TrackingCustomTooltip, itemContent: TrackingCustomTooltip }}
                xAxis={[{
                    scaleType: 'point', data: formattedBliDates, tickLabelProps: () => ({
                        angle: 45,
                        textAnchor: 'start',
                        dominantBaseline: 'ideographic',
                    }),
                }]}
                sx={{
                    '& .MuiLineElement-root': {
                        strokeWidth: 2,
                    },
                    '& .MuiMarkElement-series-Content': {
                        fill: "#5BCEFF",
                        strokeWidth: 3
                    },
                    '& .MuiChartsTooltip-root': {
                        display: 'none !important',

                    },
                    '& .MuiAreaElement-series-Content': {
                        fill: "url('#myGradient')",
                    },
                    '& .MuiChartsAxis-tickLabel': {
                        transform: 'translate(-15px, 15px) rotate(-35deg)',
                    },
                    '& .css-iael1t-MuiChartsAxis-root.MuiChartsAxis-left .MuiChartsAxis-tickLabel': {
                        transform: 'translate(0px, 0px)',
                    },
                    '& .css-1dsr0yt.MuiChartsAxis-directionY .MuiChartsAxis-tickLabel': {
                        transform: 'translate(-5px, 1px) rotate(0deg)',
                    }
                }}
            >
                <defs>
                    <linearGradient id="myGradient" gradientTransform="rotate(90)">
                        <stop offset="0%" stopColor="#89DCFF" />
                        <stop offset="100%" stopColor="#50CBFF00" />
                    </linearGradient>
                </defs>
            </LineChart>
        );
    }
    else {
        return (
            <LineChart
                width={viewsWidth}
                height={viewsHeight}
                series={[
                    { data: newSharesArray, label: 'shares' },
                    { data: newSavesArray, label: 'saves' },
                    { data: newEngagementArray, label: 'eng (per 100 likes + comments)' },
                ]}
                xAxis={[{ scaleType: 'point', data: newSortedDates }]}
                margin={{ left: 100 }}
                sx={{
                    '& .MuiLineElement-root': {
                        strokeWidth: 2,
                    },
                    '& .MuiMarkElement-series-Content': {
                        fill: "#5BCEFF",
                        strokeWidth: 3
                    },
                    '& .MuiChartsTooltip-root': {
                        display: 'none !important',

                    },
                    '& .MuiAreaElement-series-Content': {
                        fill: "url('#myGradient')",
                    },
                    '& .MuiChartsAxis-tickLabel': {
                        transform: 'translate(-25px, 15px) rotate(-30deg)',
                    },
                    '& .css-iael1t-MuiChartsAxis-root.MuiChartsAxis-left .MuiChartsAxis-tickLabel': {
                        transform: 'translate(0px, 0px)',
                    },
                    '& .css-1dsr0yt.MuiChartsAxis-directionY .MuiChartsAxis-tickLabel': {
                        transform: 'translate(-5px, 1px) rotate(0deg)',
                    }
                }}
            />
        )
    }
}