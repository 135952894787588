import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Avatar, Button, Card, CircularProgress, Grid, LinearProgress, Typography } from '@mui/material'
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link, Outlet, useLocation } from 'react-router-dom';

import CommunityContent from "./CommunityContent"

import CommunityApplicants from './CommunityApplicants';
import CommunityAccepted from './CommunityAccepted';
import CommunityRejected from './CommunityRejected';

function CommunityHome(props) {

    const userData = props.userData;

    console.log(userData)

    const [tab, setTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [applications, setApplications] = useState([])
    const [accepted, setAccepted] = useState([])
    const [rejected, setRejected] = useState([])


    function handleTabChange(tab) {
        if (tab == 0) { setTab(0) }
        else if (tab == 1) { setTab(1) }
        else if (tab == 2) { setTab(2) }
    };

    const applicantsButton = (tab == 0) ? "#E4F87F" : "white";
    const acceptedButton = (tab == 1) ? "#E4F87F" : "white";
    const rejectedButton = (tab == 2) ? "#E4F87F" : "white";


    async function fetchApplicants() {

        setIsLoading(true);
        const token2 = await userData.getIdToken()

        let options = {
            method: 'GET',
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_applicants', options)
            .then(result => result.json())
            .then(body => {
                // console.log("BODY")
                // console.log(body)
                if (body["success"] == true) {
                    // console.log("API RESPONSE SUCCESS")
                    setApplications(body["applications"]); setAccepted(body["accepted"]); setRejected(body["rejected"]); setIsLoading(false);
                } else {
                    console.log("error while fetching applicants");
                }
            });
    }

    useEffect(() => {
        fetchApplicants()
    }, [])


    function onStatusChange(influencer, status) {

        var _applications = JSON.parse(JSON.stringify(applications))

        const index = _applications.findIndex(app => app.username === influencer);

        if (index === -1) return;

        // Extract the influencer using the index
        const updatedInfluencer = _applications[index];

        // Remove the influencer from the applications array
        const newApplications = _applications.filter((_, i) => i !== index);

        // Update the state based on the status
        if (status == 1) {
            setAccepted(prevAccepted => [...prevAccepted, updatedInfluencer]);
        } else if (status == 2) {
            setRejected(prevRejected => [...prevRejected, updatedInfluencer]);
        }

        // Update the applications array to exclude the moved influencer
        setApplications(newApplications);

    }



    if (isLoading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress style={{ color: "black" }} size={50} /></Box>
        )
    }
    else {
        return (
            <Box style={{ padding: "20px" }}>
                <Grid container style={{ alignItems: "center", justifyContent: "center", marginBottom: "20px", height: "80px", gap: "10px" }}>
                    <Card sx={{ width: "65%", border: "2px solid #DCDCDC", display: "flex", alignItems: "center", display: "flex", borderRadius: "15px", boxShadow: "none" }}>
                        <Grid container columns={12} style={{ display: "flex", textAlign: "center", padding: "5px" }}>
                            <Grid item xs={4}>
                                <Button startIcon={<PeopleOutlineOutlinedIcon />} onClick={() => handleTabChange(0)} style={{ color: "black", background: applicantsButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Applicants</Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button startIcon={<PeopleOutlineOutlinedIcon />} onClick={() => handleTabChange(1)} style={{ color: "black", background: acceptedButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Accepted</Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button startIcon={<PeopleOutlineOutlinedIcon />} onClick={() => handleTabChange(2)} style={{ color: "black", background: rejectedButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Rejected</Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                {tab == 0 && <CommunityApplicants userData={userData} influencers={applications} onStatusChange={onStatusChange} />}
                {tab == 1 && <CommunityAccepted influencers={accepted} />}
                {tab == 2 && <CommunityRejected influencers={rejected} />}
                <Outlet />
            </Box>
        )
    }
}

export default CommunityHome