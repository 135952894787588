import { Avatar, Box, Card, Divider, Grid, LinearProgress, Typography } from '@mui/material'
import React from 'react'
import StatsBar from './StatsBar'
import PieCharts from '../campaign/PieCharts'
import LineCharts from '../campaign/LineChart'

function Stats(props) {

    const content = props.content;

    var showStats = ("labels" in content.genderData) ? true : false;
    if (showStats) {
        var totalCountryCount = content.countryData.distribution.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        var countryDist = content.countryData.distribution;

        var finalCountryDist = countryDist.map(element => (element / totalCountryCount) * 100);
    }


    const brands = ["birdsofparadyes", "sanfe", "renee", "boat.nirvana", "leaf.studios"]
    const ageDistribution = ["0-17", "18-24", "25-30", "Above 30"]

    if (showStats) {
        return (
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "40px" }}>
                <Box style={{ height: "fit-content", width: "95%", overflow: "auto" }}>
                    <Grid container style={{ marginBottom: "20px", padding: "15px", }}>
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                            <Typography variant="h5" fontWeight={900}>Engaged Audience Insights</Typography>
                        </Grid>

                        <Grid item xs>
                            <Grid item style={{ width: "100%", height: "10%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Typography variant="h6">Fake vs Real Followers</Typography>
                            </Grid>
                            <Grid item style={{ width: "100%", height: "90%", }}>
                                <PieCharts labels={content.fakeFollowersData.labels} distribution={content.fakeFollowersData.distribution} type="followers" />
                            </Grid>
                        </Grid>

                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center" }}>
                            <Divider orientation="vertical" variant="middle" flexItem sx={{ height: "95%", marginTop: "50px", borderRightWidth: 3 }} />
                        </div>

                        <Grid item xs style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                            <div style={{ display: "flex", justifyContent: "left", width: "100%", paddingLeft: "20px" }}>
                                <Typography variant="h6">Country Distribution</Typography>
                            </div>

                            {content.countryData.labels.map((value, index) => (
                                <Grid container key={index} style={{ padding: "5px 20px 5px 20px" }}>
                                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                        <Typography >{value}</Typography>
                                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            <Box style={{ width: "90%", color: "#B169FE", position: "relative" }}>
                                                <LinearProgress variant="determinate" color='inherit' value={finalCountryDist[index]} style={{ height: "3vh", borderRadius: "5px", border: "1px solid white" }} />
                                            </Box>
                                            <Typography>{finalCountryDist[index].toFixed(1)}%</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center" }}>
                            <Divider orientation="vertical" variant="middle" flexItem sx={{ height: "95%", marginTop: "50px", borderRightWidth: 3 }} />
                        </div>
                        <Grid item xs>
                            <Grid item style={{ width: "100%", height: "10%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Typography variant="h6">Gender Distribution</Typography>
                            </Grid>
                            <Grid item style={{ width: "100%", height: "90%", }}>
                                <PieCharts labels={content.genderData.labels} distribution={content.genderData.distribution} type="gender" />
                            </Grid>
                        </Grid>
                    </Grid>

                    <StatsBar subHeadings={["Overall Engagement", "Average reel views", "Average reel likes", "Average comments"]} values={content.statsBar} />

                    <Grid container>

                        {/* <Grid item xs style={{ display: "flex", justifyContent: "center", }}>
                        <Card style={{ padding: "20px", width: "95%", border: "1px solid #DCDCDC", boxShadow: "none", borderRadius: "35px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography variant="h5">Brand Affinity</Typography>
                            {brands.map((value, index) => (
                                <Grid container key={index} style={{ padding: "5px" }}>
                                    <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                                        <Avatar style={{ width: "54px", height: "54px", border: "1px solid black" }} src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/brands%2F" + "birdsofparadyes" + ".jpg?alt=media"} />
                                    </Grid>
                                    <Grid item xs={10} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                        <Typography >{value}</Typography>

                                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            <Box style={{ width: "90%", color: "#5BCEFF", position: "relative" }}>
                                                <LinearProgress variant="determinate" color='inherit' value={40} style={{ height: "3vh", borderRadius: "5px", border: "1px solid white" }} />
                                            </Box>
                                            <Typography>20</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            ))}
                        </Card>
                    </Grid> */}

                        {/* <Grid item xs style={{ display: "flex", justifyContent: "center" }}>
                            <Card style={{ padding: "20px", width: "95%", border: "1px solid #DCDCDC", boxShadow: "none", borderRadius: "35px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Typography variant="h5">Video Views</Typography>
                                <LineCharts />
                            </Card>
                        </Grid> */}

                    </Grid>
                </Box >
            </div >
        )
    } else {
        return (
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "40px", flexDirection: "column" }}>
                <Typography style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginBottom: "30px" }}>Audience Insights Unavailable</Typography>
                <StatsBar subHeadings={["Overall Engagement", "Average reel views", "Average reel likes", "Average comments"]} values={content.statsBar} />
            </div>
        );
    }
}

export default Stats