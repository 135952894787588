import React from 'react'
import StarIcon from '@mui/icons-material/Star';
import InstagramLogo from "../../assets/instagramLogo.png"
import NykaaLogo from "../../assets/nykaaLogo.png"
import { Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'

function NykaaForm() {

    const [value, setValue] = React.useState('female');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        // display: "flex", justifyContent: "center",
        <Box style={{ background: "#E80071", overflow: "auto", height: "100%", position: "fixed", height: "100%", width: "100%" }}>
            <Box sx={{ background: "#232323", height: "50px", display: "flex", alignItems: "center", padding: "0px 20px 0px 20px" }}>
                {/* {INSERT BRAND LOGO HERE} */}
                {/* <Typography>{NykaaLogo}</Typography> */}
                <img
                    src={NykaaLogo}
                    width="100px"
                    height="30px"
                />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", height: "auto" }}>
                <Box style={{ padding: "20px", height: "auto", borderRadius: "25px", zIndex: 99, margin: "20px", display: "flex", flexDirection: "column" }}>
                    {/* <Typography>Connect Instagram Account</Typography> */}
                    <Box sx={{ background: "black", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "auto", marginBottom: "20px", padding: "15px", borderRadius: "10px" }}>
                        <Typography sx={{ color: "white" }}>INFLUENCER FORM</Typography>
                        <Typography sx={{ color: "white" }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum </Typography>
                    </Box>
                    <Button startIcon={<img
                        src={InstagramLogo}
                        width="30px"
                        height="30px"
                    />} sx={{ display: "flex", alignItems: "center", justifyContent: "center", background: "white" }}>

                        <Typography sx={{ color: "black", fontSize: "12px" }}>Connect Instagram Account</Typography>
                    </Button>
                    <Grid container>
                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 10px 0px", backgroundColor: "white" }}>
                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                <Typography>Username</Typography>
                                <StarIcon sx={{ height: "10px", color: "red" }} />
                            </Box>
                            <TextField sx={{ width: "100%" }} label="Enter your username" variant="standard" />
                        </Grid>
                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white" }}>
                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                <Typography>Full Name</Typography>
                                <StarIcon sx={{ height: "10px", color: "red" }} />
                            </Box>
                            <TextField sx={{ width: "100%" }} label="Enter your Full Name" variant="standard" />
                        </Grid>
                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white" }}>
                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                <Typography>Email</Typography>
                                <StarIcon sx={{ height: "10px", color: "red" }} />
                            </Box>
                            <TextField sx={{ width: "100%" }} label="Enter your email" variant="standard" />
                        </Grid>
                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white" }}>
                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                <Typography>Phone No</Typography>
                                <StarIcon sx={{ height: "10px", color: "red" }} />
                            </Box>
                            <TextField sx={{ width: "100%" }} label="Enter your Phone No" variant="standard" />
                        </Grid>
                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white" }}>
                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                <Typography>Age</Typography>
                                <StarIcon sx={{ height: "10px", color: "red" }} />
                            </Box>
                            <TextField sx={{ width: "100%" }} label="Enter your age" variant="standard" />
                        </Grid>
                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white" }}>
                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                <Typography>Gender</Typography>
                                <StarIcon sx={{ height: "10px", color: "red" }} />
                            </Box>
                            {/* <TextField sx={{ width: "100%" }} label="Enter your gender" variant="standard" /> */}
                            <FormControl>
                                <RadioGroup
                                    value={value}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white" }}>
                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                <Typography>State</Typography>
                                <StarIcon sx={{ height: "10px", color: "red" }} />
                            </Box>
                            <TextField sx={{ width: "100%" }} label="Enter your state" variant="standard" />
                        </Grid>
                        <Grid item xs={12} sx={{ borderRadius: "5px", padding: "20px", margin: "10px 0px 5px 0px", backgroundColor: "white" }}>
                            <Box sx={{ display: "flex", alignItems: "start" }}>
                                <Typography>City</Typography>
                                <StarIcon sx={{ height: "10px", color: "red" }} />
                            </Box>
                            <TextField sx={{ width: "100%" }} label="Enter your city" variant="standard" />
                        </Grid>
                    </Grid>
                    <Button sx={{ background: "#E4F87F", color: "black", borderRadius: "5px" }}>SUBMIT FORM</Button>
                </Box>
            </Box>
        </Box >
    )
}

export default NykaaForm