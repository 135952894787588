import React from 'react'
import { Card, Grid } from '@mui/material'

function StatsBar(props) {

    const subHeadings = props.subHeadings;
    const values = props.values;

    function formatNumber(number) {
        if (number >= 1e6) {
            return (number / 1e6).toFixed(2) + "M";
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(2) + "K";
        } else {
            return number.toFixed(2);
        }
    }

    return (
        <Grid container style={{ justifyContent: "center", marginBottom: "20px", height: "100px" }}>
            <Card sx={{ width: "85%", border: "2px solid #DCDCDC", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "25px", boxShadow: "none" }}>
                <Grid container pr={8} pl={8} >
                    <Grid item xs={3} fontSize={30}>{formatNumber(values[0]) + " %"}</Grid>
                    <Grid item xs={3} fontSize={30}>{formatNumber(values[1])}</Grid>
                    <Grid item xs={3} fontSize={30}>{formatNumber(values[2])}</Grid>
                    <Grid item xs={3} fontSize={30}>{formatNumber(values[3])}</Grid>

                    <div style={{ height: "40px" }}></div>
                    <Grid item xs={3} fontSize={15}>{subHeadings[0]}</Grid>
                    <Grid item xs={3} fontSize={15}>{subHeadings[1]}</Grid>
                    <Grid item xs={3} fontSize={15}>{subHeadings[2]}</Grid>
                    <Grid item xs={3} fontSize={15}>{subHeadings[3]}</Grid>
                </Grid>
            </Card>
        </Grid>
    );
}

export default StatsBar