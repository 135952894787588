import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import Login from './components/Login';
import Topbar from './components/Topbar';
import './index.css';
import App from './App';
import Lists from './components/Lists';
import Discovery from './components/Discovery';
import Campaigns from './components/Campaigns';


import CampaignInfluencers from './components/campaign/CampaignInfluencers';
import CampaignContent from './components/campaign/CampaignContent';
import CampaignAnalytics from './components/campaign/CampaignAnalytics';
import Campaign from './components/campaign/Campaign';
import List from './components/List';
import NotFound from './components/NotFound';
import NykaaForm from './components/forms/NykaaForm';
import FoxtaleForm from './components/forms/FoxtaleForm';
import LeafForm from './components/forms/LeafForm';
import PlushForm from './components/forms/PlushForm';
import SnitchForm from './components/forms/SnitchForm';
import EmprallForm from './components/forms/EmprallForm';
import JWForm from './components/forms/JWForm';
import Settings from './components/Settings';
import Chrome from './components/campaign/chrome';
import { getSubdomain } from './helper/getSubdomain';
import { getRoutes } from './helper/getRoutes';

// [
//   {
//     path: "/",
//     element: <Navigate to="/login" replace={true} />,
//   },
//   {
//     path: "/settings",
//     element: <Topbar source="settings" />,
//   },
//   {
//     path: "/discovery",
//     element: <Topbar source="discovery" />,
//   },
//   {
//     path: "/lists",
//     element: <Topbar source="lists" />,
//   },
//   {
//     path: "/list",
//     element: <Topbar source="list" />,
//   },
//   {
//     path: "/campaigns",
//     element: <Topbar source="campaigns" />,
//   },
//   {
//     path: "/campaign",
//     element: <Topbar source="campaign" />,

//     children: [
//       {
//         path: "/campaign/influencers",
//         element: <Topbar source="campaign/influencers" />,
//       },
//       {
//         path: "/campaign/content",
//         element: <Topbar source="campaign/content" />,
//       },
//       {
//         path: "/campaign/analytics",
//         element: <Topbar source="campaign/analytics" />,
//       }
//     ]
//   },
//   {
//     path: "/campaign/chat",
//     element: <Topbar source="campaign/chat" />,
//   },
//   {
//     path: "/chrome",
//     element: <Chrome />
//   },
//   {
//     path: "/login",
//     element: <Login />
//   },
//   {
//     path: "/dashboard",
//     element: <Topbar source="dashboard" />,
//   },
//   {
//     path: "/tracking",
//     element: <Topbar source="tracking" />,

//     children: [
//       {
//         path: "/tracking/influencers",
//         element: <Topbar source="tracking/influencers" />,
//       },
//       {
//         path: "/tracking/content",
//         element: <Topbar source="tracking/content" />,
//       },
//       {
//         path: "/tracking/analytics",
//         element: <Topbar source="tracking/analytics" />,
//       }
//     ]
//   },
//   {
//     path: "/forms/nykaa",
//     element: <NykaaForm />
//   },
//   {
//     path: "/forms/foxtale",
//     element: <FoxtaleForm />
//   },
//   {
//     path: "/forms/leaf",
//     element: <LeafForm />
//   },
//   {
//     path: "/forms/plush",
//     element: <PlushForm />
//   },
//   {
//     path: "/forms/snitch",
//     element: <SnitchForm />
//   },
//   {
//     path: "/forms/emprall",
//     element: <EmprallForm />
//   },
//   {
//     path: "/forms/jokerandwitch",
//     element: <JWForm />
//   }
// ]


// {
//   path: '*',
//   element: <NotFound />
// }

const subdomain = getSubdomain()
const routes = getRoutes(subdomain)
const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);