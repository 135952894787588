import React, { useEffect, useRef, useState } from 'react'
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useLocation } from 'react-router-dom';
// import { styled } from '@mui/system';
import { styled } from '@mui/material/styles';
import EditInfluencerDetails from './EditInfluencerDetails';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Avatar, Badge, Box, Button, CircularProgress, Divider, Grid, InputAdornment, Modal, Slide, TextField, Tooltip, Typography } from '@mui/material';


// brandIgUserId = 17841456178364098
// igUserId = 5914705528599061
// conversationid = aWdfZAG06MTpJR01lc3NhZA2VUaHJlYWQ6MTc4NDE0NTYxNzgzNjQwOTg6MzQwMjgyMzY2ODQxNzEwMzAwOTQ5MTI4MjMyMDY1NTIzNzE3NzMz

const theme = createTheme({
    palette: {
        primary: {
            main: "#232323",
        },
    },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: "20%",
        top: "50%",
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

function CampaignChat(props) {

    const location = useLocation();
    const campaignData = location.state;
    const user = props.userData

    const [accessToken, setAccessTokens] = useState({})
    const [chatInfluencers, setChatInfluencers] = useState({})
    const [filteredInfluencers, setFilteredInfluencers] = useState({})
    const [sortedInfluencers, setSortedInfluencers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingMessages, setIsLoadingMessages] = useState(false)
    const [sendMessage, setSendMessage] = useState("")
    const [messages, setMessages] = useState([])
    const messagesBoxRef = useRef(null);
    const [currInfluencer, setCurrInfluencer] = useState("")
    const [searchInfluencer, setSearchInfluencer] = useState("");
    const [brandIgId, setBrandIgId] = useState("");
    const [sendButtonEnabled, setSendButtonEnabled] = useState(false);
    const [attachmentType, setAttachmentType] = useState();
    // var brandIgId = "17841456178364098";

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); }

    const handleTextMessage = event => {
        setSendMessage(event.target.value);
    };

    // let sortedInfluencers = Object.values(chatInfluencers).sort((a, b) => {
    //     // Sorting in descending order
    //     return new Date(b.lastUpdatedTime) - new Date(a.lastUpdatedTime);
    // });

    const handleSearchInfluencer = (event) => {
        const searchText = event.target.value.toLowerCase();
        setSearchInfluencer(searchText);

        if (searchText === '') {
            // If the search input is empty, reset to the complete list
            setFilteredInfluencers(chatInfluencers);
            let s_influencers = Object.values(chatInfluencers).sort((a, b) => {
                // Sorting in descending order
                return new Date(b.lastUpdatedTime) - new Date(a.lastUpdatedTime);
            });
            setSortedInfluencers(s_influencers)
        } else {
            // Otherwise, filter based on the search input
            const filteredInfluencers = Object.fromEntries(
                Object.entries(chatInfluencers).filter(([key]) =>
                    key.toLowerCase().includes(searchText)
                )
            );
            setFilteredInfluencers(filteredInfluencers);
            let s_influencers = Object.values(filteredInfluencers).sort((a, b) => {
                // Sorting in descending order
                return new Date(b.lastUpdatedTime) - new Date(a.lastUpdatedTime);
            });
            setSortedInfluencers(s_influencers)
        }
    };


    // console.log("INSIDE CAMPAIGN CHAT")
    // console.log(campaignData)
    // console.log(user)

    const to_style = { borderRadius: "20px", margin: "10px 20px 0px 0px", width: "40%", background: "#E4F87F", height: "auto", padding: "15px", marginLeft: "auto" }
    const from_style = { borderRadius: "20px", margin: "10px 0px 0px 20px", width: "40%", background: "#CDCDCD", height: "auto", padding: "15px" }

    const lineStyles = {
        position: "fixed",
        height: "100%",
        left: '25%',
        width: '2px',
        backgroundColor: '#C4C4C4', // Adjust the line color as needed
    };

    async function fetchChatInfluencers() {

        var campaignId = campaignData.campaignData.campaignId;
        setIsLoading(true);
        const token2 = await user.getIdToken()

        let data = {
            campaignId: campaignId
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_chat_influencers', options)
            .then(result => result.json())
            .then(body => {
                // console.log("chatInfluencers");
                // console.log(body["chatInfluencers"]);
                setSortedInfluencers(Object.values(body["chatInfluencers"]).sort((a, b) => {
                    // Sorting in descending order
                    return new Date(b.lastUpdatedTime) - new Date(a.lastUpdatedTime);
                }))
                setChatInfluencers(body["chatInfluencers"]); setFilteredInfluencers(body["chatInfluencers"]); setAccessTokens(body["brand"]); setIsLoading(false);
            });
    }

    useEffect(() => {
        fetchChatInfluencers()
    }, [])

    useEffect(() => {
        if (messagesBoxRef.current) {
            messagesBoxRef.current.scrollTop = messagesBoxRef.current.scrollHeight;
        }
    }, [messages])

    // console.log("MESSAGES")
    // console.log(messages)

    async function fetchMessages(influencer) {
        setIsLoadingMessages(true);
        // console.log("fetching messages")
        // console.log(chatInfluencers)
        // console.log(influencer)

        setMessages([])
        setCurrInfluencer(influencer)

        var conversationId = chatInfluencers[influencer].conversationId
        var brandIgUserId = chatInfluencers[influencer].brandIgUserId

        var isSendButtonEnabled = accessToken[brandIgUserId]?.isSendButtonEnabled;

        setBrandIgId(brandIgUserId);
        setSendButtonEnabled(isSendButtonEnabled)

        // console.log("page access token")
        // console.log(conversationId)
        // console.log(accessToken[brandIgUserId]?.pageAccessToken)

        var options = {
            method: 'GET',
            headers: new Headers({
                'Content-Type': "application/json"
            })
        }

        if (conversationId != "") {
            // const url = "https://graph.facebook.com/v16.0/" + conversationId + "?fields=messages&access_token=" + accessToken[brandIgUserId]?.pageAccessToken

            // await fetch(url, options)
            //     .then(result => result.json())
            //     .then(body => {
            //         console.log("FB BODY");
            //         console.log(body)

            //         var response = body.messages.data;
            //         var tempMessages = []

            //         response.forEach(
            //             item => {
            //                 // console.log(item.id)
            //                 const url2 = "https://graph.facebook.com/v16.0/" + item.id + "?fields=id%2Ccreated_time%2Cfrom%2Cto%2Cmessage&access_token=" + accessToken[brandIgUserId]?.pageAccessToken;
            //                 fetch(url2, options)
            //                     .then(result => result.json())
            //                     .then(body => {
            //                         const utcTime = body.created_time;

            //                         // Convert UTC time to JavaScript Date object
            //                         const date = new Date(utcTime);

            //                         // Convert to IST (UTC + 5 hours 30 minutes)
            //                         date.setUTCHours(date.getUTCHours() + 5);
            //                         date.setUTCMinutes(date.getUTCMinutes() + 30);

            //                         // Format the date in IST
            //                         const istTime = date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });

            //                         tempMessages.push({ "to": { "username": body.to.data[0].username, "id": body.to.data[0].id }, "from": { "username": body.from.username, "id": body.from.id }, "message": body.message, "time": istTime })
            //                     })
            //             });

            //         setMessages(tempMessages)
            //         console.log("all messages fetched")
            //     });

            const url = "https://graph.facebook.com/v16.0/" + conversationId + "?fields=messages&access_token=" + accessToken[brandIgUserId]?.pageAccessToken;

            // console.log(accessToken[brandIgUserId]?.pageAccessToken)

            try {
                const result = await fetch(url, options);
                const body = await result.json();
                // console.log("FB BODY");
                // console.log(body);

                var response = body.messages.data;
                var tempMessages = [];

                // Define an async function for the inner fetch
                const fetchDetails = async (item) => {
                    const url2 = "https://graph.facebook.com/v16.0/" + item.id + "?fields=id%2Ccreated_time%2Cfrom%2Cto%2Cmessage%2Cattachments%2Ctags%2Creactions&access_token=" + accessToken[brandIgUserId]?.pageAccessToken;
                    const result2 = await fetch(url2, options);
                    const body2 = await result2.json();

                    // console.log("MESSAGE BODY")
                    // console.log(body2)

                    const utcTime = body2.created_time;

                    // Create Date object from string 
                    const utcDate = new Date(utcTime);

                    // Format to IST string
                    const istString = utcDate.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });

                    var attachment = ""
                    if ("attachments" in body2) {
                        var d = body2?.attachments.data[0];
                        if ("image_data" in d) {
                            attachment = body2?.attachments.data[0].image_data;
                            setAttachmentType("image")
                        }
                        else if ("video_data" in d) {
                            attachment = body2?.attachments.data[0].video_data;
                            setAttachmentType("video")
                        }
                        // console.log(attachment)
                    }
                    tempMessages.push({ "attachment": attachment, "to": { "username": body2.to.data[0].username, "id": body2.to.data[0].id }, "from": { "username": body2.from.username, "id": body2.from.id }, "message": body2.message, "time": istString, "timeInMilliseconds": utcDate.getTime() });
                };

                // Use Promise.all to wait for all inner fetches to complete
                await Promise.all(response.map(fetchDetails));

                const getSortableDateTime = (message) => {
                    const [date, time] = message.time.split(', ');
                    const [day, month, year] = date.split('/');
                    const [hour, minute, second] = time.split(':');

                    // Create a Date object for sorting
                    return new Date(`${year}-${month}-${day} ${hour}:${minute}:${second}`);
                };

                // Sort the array based on the time and date
                tempMessages = tempMessages.sort((a, b) => {
                    const dateA = getSortableDateTime(a);
                    const dateB = getSortableDateTime(b);

                    return dateA - dateB;
                });


                setMessages(tempMessages);
                console.log("all messages fetched");
                setIsLoadingMessages(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setIsLoadingMessages(false);
            }
        }
        else {
            console.log('empty conversation id')
            setIsLoadingMessages(false);
        }
    }

    function getCurrentDateTime() {
        const currentDate = new Date();

        // Get date components
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1; // Months are zero-based
        const year = currentDate.getFullYear();

        // Get time components
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const seconds = currentDate.getSeconds();

        // Format AM/PM
        const ampm = hours >= 12 ? 'pm' : 'am';
        const formattedHours = hours % 12 || 12;

        // Create the formatted date and time string
        const formattedDateTime = `${day}/${month}/${year}, ${formattedHours}:${minutes} ${ampm}`;

        return formattedDateTime;
    }

    async function handleSendMessage() {
        console.log("Calling send message api")

        var influencerIgId = chatInfluencers[currInfluencer]?.igUserId;
        var brandIgUserId = chatInfluencers[currInfluencer]?.brandIgUserId;
        var fbPageId = accessToken[brandIgUserId]?.fbPageId;
        var pageAccessToken = accessToken[brandIgUserId]?.pageAccessToken;

        var recipient = { "id": influencerIgId }
        var message = { 'text': sendMessage }

        // console.log("LAST MESSAGE TIME")
        // console.log(messages[messages.length - 1]['time'])
        var lastMessageTime = "";
        for (var i = messages.length - 1; i >= 0; i--) {
            if (messages[i].to.id == brandIgUserId) {
                lastMessageTime = messages[i]['timeInMilliseconds'];
                break;
            }
        }

        // if (lastMessageTime == "") {
        //     lastMessageTime = messages[messages.length - 1]['timeInMilliseconds']
        // }

        // console.log("CURR TIME")
        const currDate = new Date();


        // console.log("TIMES")
        // console.log(currDate.getTime())
        // console.log(lastMessageTime)


        const timeDifference = currDate.getTime() - lastMessageTime;
        const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;

        // console.log("TIME DIFFERENCE")
        // console.log(timeDifference)

        // calculating messaging_type
        var messageData = {};
        if (timeDifference >= twentyFourHoursInMilliseconds) {
            // console.log("More than 24 hours have passed since the last message.");

            // outside 24 hrs - MESSAGE_TAG (HUMAN_AGENT)
            messageData = {
                recipient: recipient,
                messaging_type: "MESSAGE_TAG",
                message: message,
                access_token: pageAccessToken,
                tag: "HUMAN_AGENT"
            }
        } else {
            // console.log("Less than 24 hours have passed since the last message.");

            // within 24 hrs - RESPONSE
            messageData = {
                recipient: recipient,
                messaging_type: "RESPONSE",
                message: message,
                access_token: pageAccessToken
            }
        }


        const url = "https://graph.facebook.com/v16.0/" + fbPageId + "/messages"
        // const messageData = {
        //     recipient: recipient,
        //     messaging_type: "RESPONSE",
        //     message: message,
        //     access_token: pageAccessToken
        // }

        var options = {
            method: 'POST',
            body: JSON.stringify(messageData),
            headers: new Headers({
                'Content-Type': "application/json"
            })
        }



        await fetch(url, options)
            .then(result => result.json())
            .then(body => {
                console.log("SEND MESSAGE BODY");
                // console.log(body)

                var tempDict = {
                    "to": { "username": currInfluencer, "id": influencerIgId },
                    "from": { "username": accessToken[brandIgUserId]?.username, "id": brandIgUserId },
                    "message": sendMessage,
                    "time": getCurrentDateTime(),
                    "attachment": ""
                }

                setMessages(prevArray => [...prevArray, tempDict]);
                setSendMessage("")

            })
    }


    const removeSeconds = (dateTimeString) => {
        const [datePart, timePart] = dateTimeString.split(', ');
        const [time, period] = timePart.split(' ');
        const [hours, minutes, seconds] = time.split(":")


        return `${datePart}, ${hours}:${minutes} ${period}`;
    };

    const style = {
        position: "absolute",
        left: '70%',
        // transform: 'translate(-50%, -50%)',
        width: "30%",
        height: "100%",
        bgcolor: 'background.paper',
        boxShadow: 2,
        p: 1,
        overflow: "auto",
        border: "none"
    };

    const shapeStyles = { bgcolor: 'primary.main', width: 40, height: 40 };
    const rectangle = <Box component="span" sx={shapeStyles} />;


    return (
        <Box sx={{ height: "calc(100vh - 120px)", marginTop: "10px" }}>
            <Grid container sx={{ height: "100%" }}>

                <Grid item xs={3} sx={{ height: "100%" }}>
                    <Box sx={{ height: "15%", display: "flex", flexDirection: "column" }}>

                        <Box sx={{ display: "flex", alignItems: "center", height: "50%", }}>
                            <Link to="/campaign/influencers" state={campaignData}>
                                <Button startIcon={<KeyboardBackspaceIcon />} sx={{ marginLeft: "20px", color: "black", background: "#E4F87F", padding: "10px 15px 10px 15px" }}>Back</Button>
                            </Link>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50%", }}>
                            <Typography variant="h4">Conversations</Typography>
                        </Box>

                    </Box>

                    <Box sx={{ height: "85%" }}>
                        {isLoading && <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center", height: "100%", }}><CircularProgress style={{ color: "black" }} size={40} /></Box>}
                        {!isLoading && <Box sx={{ height: "100%" }}>
                            <Box sx={{ height: "100%", overflow: "auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Box sx={{ width: "95%", marginBottom: "10px" }}>
                                    <TextField
                                        hiddenLabel
                                        value={searchInfluencer}
                                        onChange={handleSearchInfluencer}
                                        size='small'
                                        fullWidth
                                        placeholder='Search Influencer'
                                        InputProps={{
                                            disableUnderline: true,
                                            startAdornment: (
                                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                    <SearchIcon />
                                                </Box>
                                            ),
                                        }}
                                        variant="filled"
                                    />
                                </Box>
                                {/* {Object.keys(filteredInfluencers).map((key, index) => (
                                    <Box key={index} sx={{ width: "100%", cursor: "pointer" }} onClick={() => { fetchMessages(key) }} >
                                        <Box sx={{ display: "flex", flexDirection: "row", width: "100%", padding: "10px 0px 10px 0px", marginTop: "5px", backgroundImage: (key == currInfluencer ? "linear-gradient(to left, rgba(228, 248, 127, 0.2), rgba(228, 248, 127, 0.5))" : "white") }}>
                                            <Box sx={{ width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                <Avatar src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + key + ".jpg?alt=media"} sx={{ width: "54px", height: "54px" }} />
                                            </Box>
                                            <Box sx={{ width: "70%", display: "flex", justifyContent: "flex-start", alignItems: "center", paddingLeft: "10px" }}>
                                                <Typography variant="h7">{chatInfluencers[key].influencer}</Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <Divider sx={{ width: "90%", color: "black" }} />
                                        </Box>
                                    </Box>
                                ))} */}
                                {sortedInfluencers.map((influencer, index) => (
                                    <StyledBadge key={index} color="info" badgeContent=" " invisible={!influencer.isMessageReceived} sx={{ width: "100%" }}>
                                        <Box sx={{ width: "100%", cursor: "pointer" }} onClick={() => { fetchMessages(influencer.influencer) }} >
                                            <Box sx={{ display: "flex", flexDirection: "row", width: "100%", padding: "10px 0px 10px 0px", marginTop: "5px", backgroundImage: (influencer.influencer == currInfluencer ? "linear-gradient(to left, rgba(228, 248, 127, 0.2), rgba(228, 248, 127, 0.5))" : "white") }}>
                                                <Box sx={{ width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                    <Avatar src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + influencer.influencer + ".jpg?alt=media"} sx={{ width: "54px", height: "54px" }} />
                                                </Box>
                                                <Box sx={{ width: "70%", display: "flex", flexDirection: "column", justifyContent: "center", paddingLeft: "10px" }}>
                                                    <Typography sx={{ fontWeight: (influencer.isMessageReceived ? "900" : "0") }} variant="h7">{influencer.influencer}</Typography>
                                                    <Typography variant="caption" sx={{ display: (influencer.isMessageReceived ? "block" : "none") }}>You have unread messages</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                <Divider sx={{ width: "90%", color: "black" }} />
                                            </Box>
                                        </Box>
                                    </StyledBadge>
                                ))}
                            </Box>
                        </Box>}
                    </Box>
                </Grid>

                <div style={lineStyles}></div>

                <Grid item xs={9} sx={{ height: "100%" }}>
                    <Box sx={{ height: "5%", display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid #CDCDCD", background: "#DCDCDC" }}>
                        <Box sx={{ width: "70%", display: "flex", justifyContent: "flex-start", alignItems: "center", paddingLeft: "20px", gap: "10px", }}>
                            <Box sx={{ display: (currInfluencer == "" ? "none" : "block") }}>
                                <Avatar src={"https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + currInfluencer + ".jpg?alt=media"} sx={{ height: "30px", width: "30px" }} />
                            </Box>

                            <Typography>{currInfluencer}</Typography>
                        </Box>
                        <Box sx={{ cursor: "pointer", width: "30%", display: "flex", justifyContent: "flex-end", marginRight: "10px" }} onClick={handleOpen}>
                            <Typography variant="body1" sx={{ border: "1px solid black", padding: "0px 15px 0px 15px", background: "#E4F87F", color: "black", display: (currInfluencer == "" ? "none" : "block") }}>Edit Influencer Details</Typography>
                        </Box>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            BackdropProps={{ style: { backgroundColor: 'rgb(0,0,0,0.1)' } }}
                        >
                            <Slide direction="left" in={open} mountOnEnter unmountOnExit timeout={300}>
                                <Box sx={style}>
                                    <EditInfluencerDetails campaignId={campaignData.campaignData.campaignId} userData={user} open={open} handleClose={handleClose} influencerUsername={currInfluencer} />
                                </Box>
                            </Slide>
                        </Modal>
                    </Box>
                    <Box sx={{ height: "95%", display: "flex", flexDirection: "column" }}>
                        {isLoadingMessages && <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center", height: "100%" }}><CircularProgress style={{ color: "black" }} size={50} /></Box>}
                        {!isLoadingMessages &&
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                                <Box ref={messagesBoxRef} sx={{ padding: "10px 0px 10px 0px", overflow: "auto", height: "85%" }}>
                                    {messages.map((value, index) => (
                                        <Box key={index} sx={value.from.id == brandIgId ? to_style : from_style}>
                                            {(value.attachment) == "" && <Typography sx={{ whiteSpace: "pre-wrap" }}>{value.message}</Typography>}
                                            {(value.attachment) != "" && <Box sx={{ whiteSpace: "pre-wrap" }}>
                                                {attachmentType == "image" &&
                                                    <img
                                                        src={value?.attachment?.url}
                                                        height={value?.attachment?.height / 3}
                                                        width={value?.attachment?.width / 3}
                                                    />}
                                                {attachmentType == "video" &&
                                                    <video width={value?.attachment?.width / 3} height={value?.attachment?.height / 3} controls>
                                                        <source src={value?.attachment?.url} type="video/mp4" />
                                                    </video>}
                                            </Box>}
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Typography >{removeSeconds(value.time)}</Typography>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", gap: "15px", overflow: "auto", height: "15%" }}>
                                    <Box sx={{ width: "80%", marginLeft: "20px" }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={2}
                                            placeholder="Type your message here"
                                            value={sendMessage}
                                            onChange={handleTextMessage}
                                        />
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Tooltip title={sendButtonEnabled == true ? "" : "Please contact closer team to get this enabled."}>
                                            <span >
                                                <ThemeProvider theme={theme}>
                                                    <Button variant='contained' disabled={(sendMessage == "" || messages.length == 0 ? true : false) || !sendButtonEnabled} onClick={() => { handleSendMessage() }} startIcon={<SendIcon />} sx={{ boxShadow: "none", border: "1px solid #CDCDCD", padding: "15px" }}>Send</Button>
                                                </ThemeProvider>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </Box>}
                    </Box>
                </Grid>
            </Grid>
        </Box >
    )
}

export default CampaignChat