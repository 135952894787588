import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import { Avatar, Box, Button, Card, CircularProgress, Grid, LinearProgress, Popover, Typography } from '@mui/material'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import SpeedIcon from '@mui/icons-material/Speed';
import ArticleIcon from '@mui/icons-material/Article';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

function TrackingBar(props) {

    var campaignData = props.campaignData;
    var campaignStatus = props.campaignStatus;
    var gradientColor = props.gradientColor;

    const isBliActive = campaignData.isBliActive;
    const campaignDesc = campaignData.isCampaignDescriptionFilled;
    const entityDesc = campaignData.isEntityDescriptionFilled;
    const entityPer = campaignData.isEntityPerceptionFilled;

    var isCampaignProcessing = campaignData.isCampaignProcessing;
    var processingCampaignLink = (isCampaignProcessing == true) ? `/tracking/${campaignData.campaignId}/content` : `/tracking/${campaignData.campaignId}/content`;

    const [anchorElBli, setAnchorElBli] = useState(null);

    const handlePopoverBliOpen = (event) => {
        setAnchorElBli(event.currentTarget);
    };

    const handlePopoverBliClose = () => {
        setAnchorElBli(null);
    };

    const openBli = Boolean(anchorElBli);


    const [anchorElCampaignDesc, setAnchorElCampaignDesc] = useState(null);

    const handlePopoverCampaignOpen = (event) => {
        setAnchorElCampaignDesc(event.currentTarget);
    };

    const handlePopoverCampaignClose = () => {
        setAnchorElCampaignDesc(null);
    };

    const openCampaignDesc = Boolean(anchorElCampaignDesc);

    return (
        <div >
            <Link to={processingCampaignLink} state={{ campaignData: campaignData, campaignStatus: campaignStatus, gradientColor: gradientColor }} style={{ textDecoration: "none" }}>
                <Grid container style={{ justifyContent: "center", height: "120px" }}>
                    <Card sx={{ width: "100%", border: "2px solid #DCDCDC", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "25px", boxShadow: "none" }}>
                        <Grid container pr={4} pl={5} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Grid item xs={11}>
                                <Grid container style={{ gap: "10px", display: "flex", alignItems: "center" }}>
                                    <div style={{ width: "80px", height: "80px", background: `radial-gradient(#FFFFFF, ${gradientColor})`, borderRadius: "50%" }} />
                                    <div style={{ display: "flex", height: "50%", gap: "10px" }}>
                                        <Grid item sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                            <Grid item fontSize={20} sx={{ textAlign: "left" }}>{campaignData.campaignName}</Grid>
                                            {isCampaignProcessing && <Grid item fontSize={16}>Processing data. Please refresh the page after sometime.</Grid>}
                                            {/* <Grid item fontSize={18} sx={{ display: "flex", alignItems: "center" }}><PeopleOutlineOutlinedIcon /> {campaignData.totalInfluencers}</Grid> */}
                                            <Box sx={{ display: "flex", gap: "30px" }}>
                                                <Grid item fontSize={18} sx={{ display: "flex", alignItems: "center", gap: "5px" }}><PersonalVideoIcon /> {campaignData?.totalContent}</Grid>
                                                <Grid item onMouseEnter={handlePopoverBliOpen} onMouseLeave={handlePopoverBliClose} fontSize={18} sx={{ display: "flex", alignItems: "center", gap: "5px" }}><SpeedIcon sx={{ color: (isBliActive ? "green" : "#CDCDCD") }} /></Grid>
                                                <Grid item onMouseEnter={handlePopoverCampaignOpen} onMouseLeave={handlePopoverCampaignClose} fontSize={18} sx={{ display: "flex", alignItems: "center", gap: "5px" }}><ArticleIcon sx={{ color: (campaignDesc ? "green" : "#CDCDCD") }} /></Grid>
                                            </Box>
                                            <Popover
                                                sx={{
                                                    pointerEvents: 'none',
                                                }}
                                                open={openBli}
                                                anchorEl={anchorElBli}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                onClose={handlePopoverBliClose}
                                                disableRestoreFocus
                                            >
                                                <Typography sx={{ p: 1, display: "flex", alignItems: "center" }}>BLI Active: {isBliActive ? <CheckIcon /> : <ClearIcon />}</Typography>
                                            </Popover>

                                            <Popover
                                                sx={{
                                                    pointerEvents: 'none',
                                                }}
                                                open={openCampaignDesc}
                                                anchorEl={anchorElCampaignDesc}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                onClose={handlePopoverCampaignClose}
                                                disableRestoreFocus
                                            >
                                                <Box sx={{ p: 1 }}>
                                                    <Typography sx={{ display: "flex", alignItems: "center" }}>Campaign Description: {campaignDesc ? <CheckIcon /> : <ClearIcon />}</Typography>
                                                    <Typography sx={{ display: "flex", alignItems: "center" }}>Entity Description: {entityDesc ? <CheckIcon /> : <ClearIcon />}</Typography>
                                                    <Typography sx={{ display: "flex", alignItems: "center" }}>Entity Perception: {entityPer ? <CheckIcon /> : <ClearIcon />}</Typography>
                                                </Box>
                                            </Popover>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item xs={1} style={{ display: "flex", justifyContent: "right" }} fontSize={30}>
                                {!isCampaignProcessing && <ArrowForwardIosOutlinedIcon />}
                                {/* {isCampaignProcessing && <LockIcon fontSize="large" />} */}
                                {isCampaignProcessing && <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "black" }} size={50} /></Box>}
                            </Grid>
                        </Grid>
                    </Card>
                </Grid >
            </Link>
        </div >
    )
}

export default TrackingBar