import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import Stack from '@mui/material/Stack';
import { Tooltip } from '@mui/material';
import CustomTooltip from './CustomTooltip';


const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const xLabels = [
    'Page A',
    'Page B',
    'Page C',
    'Page D',
    'Page E',
    'Page F',
    'Page G',
];

export default function LineCharts() {
    const [color, setColor] = React.useState('#5BCEFF');

    const handleChange = (event, nextColor) => {
        setColor(nextColor);
    };

    return (
        <LineChart
            width={1000}
            height={600}
            series={[{ id: "Content", data: uData, label: 'Reach', area: true, color: color }]}
            tooltip={{ trigger: "axis", axisContent: CustomTooltip, itemContent: CustomTooltip }}
            xAxis={[{ scaleType: 'point', data: xLabels }]}
            sx={{
                '& .MuiLineElement-root': {
                    strokeWidth: 3,
                },
                '& .MuiMarkElement-series-Content': {
                    fill: "#5BCEFF",
                    strokeWidth: 5
                },
                '& .MuiChartsTooltip-root': {
                    display: 'none !important',

                },
                '& .MuiAreaElement-series-Content': {
                    fill: "url('#myGradient')",
                },
            }}
        >
            <defs>
                <linearGradient id="myGradient" gradientTransform="rotate(90)">
                    <stop offset="0%" stopColor="#89DCFF" />
                    <stop offset="100%" stopColor="#50CBFF00" />
                </linearGradient>
            </defs>
        </LineChart>
    );
}