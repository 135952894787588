import { Avatar, AvatarGroup, Badge, Box, Button, Card, Chip, CircularProgress, Grid, InputAdornment, Modal, Slide, Stack, TextField, Typography } from '@mui/material';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Divider from '@mui/material/Divider';
import React from 'react'
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Tooltip from '@mui/material/Tooltip';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import EditInfluencerDetails from './EditInfluencerDetails';
import { Mixpanel } from '../../mixpanel/mixpanel.js';


function CampaignInfluencers(props) {

    const location = useLocation();
    const campaignData = location.state;
    const user = props.userData

    const [isLoading, setIsLoading] = useState(false);
    const [campaignInfluencers, setCampaignInfluencers] = useState([])
    const [editInfluencerDetails, setEditInfluencerDetails] = useState([])
    const [steps, setSteps] = useState([]);


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); }

    async function fetchCampaignInfluencers() {

        var campaignId = campaignData.campaignData.campaignId;
        if (campaignId != null) {
            setIsLoading(true);
            const token2 = await user.getIdToken()

            let data = {
                campaignId: campaignId
            }

            let fetchCampaignInfluencersOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }


            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_campaign_influencers', fetchCampaignInfluencersOptions)
                .then(result => result.json())
                .then(body => { setCampaignInfluencers(body["totalCampaignInfluencers"]); setEditInfluencerDetails(body["editButtonInfluencerDetails"]); setSteps(campaignData.campaignData.steps); setIsLoading(false); });
        }
        else { console.log("Invalid campaign id") }
    };


    useEffect(() => {
        fetchCampaignInfluencers()
    }, [])


    const [statusTab, setStatusTab] = useState(0);
    const [isLoadingAction, setIsLoadingAction] = useState(false);

    const baseStyle = {
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        boxShadow: "none",
        textAlign: "center",
        padding: "5px",
        cursor: "pointer",
        minWidth: "150px"
    }

    const status = campaignData["campaignData"]["steps"]
    const actions = [...status.slice(-5), status[status.length - 1]]
    const statusColors = ["#C4C4C4", "#FAEB78", "#FF8E8E", "#5BCEFF", "#E69EFF", "#E4F87F"]

    const viewedButtonBorder = (statusTab == 0) ? "#000000" : "#C4C4C4";
    const messagedButtonBorder = (statusTab == 1) ? "#000000" : "#C4C4C4";
    const waitingButtonBorder = (statusTab == 2) ? "#000000" : "#C4C4C4";
    const invitedButtonBorder = (statusTab == 3) ? "#000000" : "#C4C4C4";
    const onboardButtonBorder = (statusTab == 4) ? "#000000" : "#C4C4C4";
    const publishedButtonBorder = (statusTab == 5) ? "#000000" : "#C4C4C4";

    const viewedButtonoOpacity = (statusTab == 0) ? 1 : 0.4;
    const messagedButtonoOpacity = (statusTab == 1) ? 1 : 0.4;
    const waitingButtonoOpacity = (statusTab == 2) ? 1 : 0.4;
    const invitedButtonoOpacity = (statusTab == 3) ? 1 : 0.4;
    const onboardButtonoOpacity = (statusTab == 4) ? 1 : 0.4;
    const publishedButtonOpacity = (statusTab == 5) ? 1 : 0.4;

    const tabStyle = [{
        ...baseStyle,
        opacity: viewedButtonoOpacity,
        border: "2px solid" + viewedButtonBorder,
    }, {
        ...baseStyle,
        opacity: messagedButtonoOpacity,
        border: "2px solid" + messagedButtonBorder,
    }, {
        ...baseStyle,
        opacity: waitingButtonoOpacity,
        border: "2px solid" + waitingButtonBorder,
    },
    {
        ...baseStyle,
        opacity: invitedButtonoOpacity,
        border: "2px solid" + invitedButtonBorder,
    }, {
        ...baseStyle,
        opacity: onboardButtonoOpacity,
        border: "2px solid" + onboardButtonBorder,
    }, {
        ...baseStyle,
        opacity: publishedButtonOpacity,
        border: "2px solid" + publishedButtonBorder,
    }]

    const [filteredRows, setFilteredRows] = useState([])
    const [counts, setCounts] = useState([0, 0, 0, 0, 0, 0])

    useEffect(() => {
        const c = [0, 0, 0, 0, 0, 0]
        for (const row of campaignInfluencers) {
            const rowStatus = row.status;
            if (rowStatus == "Shortlisted") {
                c[0] += 1;
            } else if (rowStatus == "Messaged") {
                c[1] += 1;
            } else if (rowStatus == "In Conversation") {
                c[2] += 1;
            } else if (rowStatus == "Product Sent") {
                c[3] += 1;
            } else if (rowStatus == "Content Review") {
                c[4] += 1;
            } else if (rowStatus == "Content Published") {
                c[5] += 1;
            }
        }
        setCounts(c)
    }, [campaignInfluencers, filteredRows])


    useEffect(() => {
        const fRows = []
        for (const row of campaignInfluencers) {
            var rowStatus = 0;
            if (row.status == "Shortlisted") { rowStatus = 0 }
            else if (row.status == "Messaged") { rowStatus = 1 }
            else if (row.status == "In Conversation") { rowStatus = 2 }
            else if (row.status == "Product Sent") { rowStatus = 3 }
            else if (row.status == "Content Review") { rowStatus = 4 }
            else if (row.status == "Content Published") { rowStatus = 5 }
            // const rowStatus = row.status;

            if (statusTab === rowStatus) {
                fRows.push(row);
            }
        }
        setFilteredRows(fRows)
    }, [campaignInfluencers, statusTab, isLoadingAction])

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    function openInstagramMessaging(eimuId, influencerUsername) {
        Mixpanel.identify(user.email);
        const mixpanelObj = { "influencer": influencerUsername, "eimuId": eimuId }
        Mixpanel.track('Instagram Messaging Redirected', { mixpanelObj });
        openInNewTab('https://www.instagram.com/direct/t/' + eimuId)
    }

    const renderDetailsAvatar = (params) => {
        const influencerPhoto = "https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + params.value.username + ".jpg?alt=media"
        // console.log("params.value.category")
        // console.log(params.value.category)
        return (
            <Box sx={{ display: "flex", width: "100%" }}>
                <Grid container style={{ gap: "10px" }}>
                    <Grid item >
                        <Avatar src={influencerPhoto} />
                    </Grid>
                    <Grid item >
                        <Grid item xs={1}>{params.value.username}</Grid>
                        <Grid item xs={11}>
                            <Stack direction="row" spacing={1}>
                                {params.value.category.slice(0, 2).map((value, index) => (
                                    <Chip size='small' key={index} label={value} />
                                ))}
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                {params.value.hasEimuId &&
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title="Message on Instagram">
                            <Button sx={{ color: "black" }} onClick={() => openInstagramMessaging(params.value.eimuId, params.value.username)}>
                                <MessageOutlinedIcon />
                            </Button>
                        </Tooltip>
                    </Box>}
            </Box>
        )
    }

    const renderStatus = (params) => {
        const status = params.row.status;

        var colors = { "Shortlisted": "#C4C4C4", "Messaged": "#FAEB78", "In Conversation": "#FF8E8E", "Product Sent": "#5BCEFF", "Content Review": "#E69EFF", "Content Published": "#E4F87F" }

        return (
            <Box>
                <Card style={{ background: colors[status], padding: "10px", }}>
                    {status}
                </Card>
            </Box>
        )
    }

    async function handleActionChange(currStatus, nextStatus, steps, influencer) {
        var lastAction = actions[actions.length - 1];


        var nextStatusIndex = steps.indexOf(nextStatus)
        var i = (nextStatusIndex == steps.length - 1) ? nextStatusIndex : nextStatusIndex + 1;
        var nextAction = steps[i]

        // console.log("CURR STATUS")
        // console.log(steps)
        // console.log(currStatus)
        // console.log(nextStatus)
        // console.log(nextAction)
        // console.log(lastAction)


        if (currStatus != lastAction) {
            setIsLoadingAction(true);
            const token2 = await user.getIdToken()

            var campaignId = campaignData.campaignData.campaignId;

            let data = {
                nextStatus: nextStatus,
                nextAction: nextAction,
                campaignId: campaignId,
                influencer: influencer,
            }

            let options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }


            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/update_influencer_new_action', options)
                .then(result => result.json())
                .then(body => { setIsLoadingAction(false); });

            var updatedCampaignInfluencers = campaignInfluencers;
            updatedCampaignInfluencers.map((value, index) => {
                if (value.id == influencer) {
                    value.status = nextStatus;
                    value.action = nextAction;
                }
            })
            setCampaignInfluencers(updatedCampaignInfluencers)
        }
    }

    const renderAction = (params) => {
        const currStatus = params.row.status;
        const influencer = params.row.users.username;
        const nextStatus = params.row.action;

        var lastAction = actions[actions.length - 1];
        var cursor = ((currStatus == lastAction) ? "" : "pointer")

        return (
            <Button style={{ cursor: cursor, opacity: ((currStatus == lastAction) ? 0.3 : 1), borderRadius: "5px", color: "black", background: "white", border: "1px solid black", padding: "10px" }} onClick={() => handleActionChange(currStatus, nextStatus, steps, influencer)} >
                <Typography style={{ padding: "0px 3px 0px 3px", fontSize: "12px" }}>{"Move to " + nextStatus}</Typography>
            </Button>
        )
    }


    const [influencerUsername, setInfluencerUsername] = useState("")
    function handleEditButton(influencer) {
        Mixpanel.identify(user.email);
        const mixpanelObj = { "influencer": influencer, "campaignId": campaignData.campaignData.campaignId }
        Mixpanel.track('Edit Campaign Influencers Called', { mixpanelObj });
        handleOpen();
        setInfluencerUsername(influencer)
    }

    const style = {
        position: "absolute",
        left: '70%',
        // transform: 'translate(-50%, -50%)',
        width: "30%",
        height: "100%",
        bgcolor: 'background.paper',
        boxShadow: 2,
        p: 1,
        overflow: "auto"
    };

    const renderViewProfile = (params) => {
        // function will take influencer username as input
        const influencer = params.row.id;

        return (
            <Box>
                <Button sx={{ border: "1px solid black", color: "black", background: "#E4F87F" }} onClick={() => handleEditButton(influencer)}>Edit</Button>
                {/* <Modal
                    open={open}
                    onClose={handleClose}
                    BackdropProps={{ style: { backgroundColor: 'rgb(0,0,0,0.1)' } }}
                >
                    <Slide direction="left" in={open} mountOnEnter unmountOnExit timeout={300}>
                        <Box sx={style}>
                            <EditInfluencerDetails campaignId={campaignData.campaignData.campaignId} userData={user} open={open} handleClose={handleClose} influencerUsername={influencerUsername} />
                        </Box>
                    </Slide>
                </Modal> */}
            </Box>
        )
    }

    const renderProducts = (params) => {
        const products = params.row.productSent;

        return (
            <Box >
                {(() => {
                    const elements = [];
                    for (let index = 0; index < products.length; index++) {
                        const value = products[index];
                        elements.push(
                            <Typography key={index}>
                                {(index + 1) + ". " + (value.title).substring(0, 25) + "..."}
                            </Typography>
                        );
                    }
                    return elements;
                })()}
            </Box>
        )
    }

    // var showUnread = true;

    const renderMessageStatus = (params) => {
        const messageStatus = params.row.users.isMessageReceived
        var row_status = params.row.status;
        if (row_status != "Shortlisted" && row_status != "Messaged") {
            return (
                <Typography sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <Badge color="secondary" badgeContent="" variant='dot' invisible={!messageStatus} >
                        <MessageOutlinedIcon />
                    </Badge>
                    {messageStatus ? "There are unread messages" : "Waiting for influencer's reply"}
                </Typography>
            )
        } else {
            return (
                <Typography sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    -
                </Typography>
            )
        }
    }

    function handleStatusChange(tab) {
        setStatusTab(tab)
    };


    // var counts = [0, 0, 0, 0, 0]
    // const filteredRows = campaignInfluencers.filter((row) => {
    //     if (statusTab === 0) {
    //         counts[row.status] += 1
    //         return row.status === 0;
    //     } else if (statusTab === 1) {
    //         counts[row.status] += 1
    //         return row.status === 1;
    //     } else if (statusTab === 2) {
    //         counts[row.status] += 1
    //         return row.status === 2;
    //     } else if (statusTab === 3) {
    //         counts[row.status] += 1
    //         return row.status === 3;
    //     } else if (statusTab === 4) {
    //         counts[row.status] += 1
    //         return row.status === 4;
    //     }
    // });



    var lastIndex = status.length - 1;

    const columns = [
        { field: 'id', headerName: 'id' },
        {
            field: 'users',
            headerName: 'Users',
            flex: 2.5,
            renderCell: renderDetailsAvatar,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: renderStatus,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1.5,
            renderCell: renderAction,
            headerAlign: 'center',
            align: 'center'
        },
        // {
        //     field: 'cost',
        //     headerName: 'Cost',
        //     sortable: false,
        //     flex: 1,
        //     height: 260,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        {
            field: 'messageStatus',
            headerName: 'Messages Status',
            flex: 2,
            renderCell: renderMessageStatus,
            headerAlign: 'center',
            align: 'center'
        },
        // {
        //     field: 'productSent',
        //     headerName: 'Product Sent',
        //     flex: 1.5,
        //     renderCell: renderProducts,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        {
            field: 'viewProfile',
            headerName: 'Edit Details',
            flex: 1,
            renderCell: renderViewProfile,
            headerAlign: 'center',
            align: 'center'
        },
    ];

    const [deleteInfluencers, setDeleteInfluencers] = useState([]);

    async function removeInfluencersFromCampaign() {
        const token2 = await user.getIdToken();

        let data = { influencers: deleteInfluencers, campaignId: campaignData.campaignData.campaignId }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        // console.log(data)

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/remove_campaign_influencers', options)
            .then(result => result.json())
            .then(body => { setDeleteInfluencers([]); fetchCampaignInfluencers(); });
    }

    function QuickSearchToolbar() {
        return (
            <Box>
                <Grid container>
                    <Grid item xs sx={{ pt: 1, pl: 1, display: "flex", alignItems: 'center' }} >
                        <GridToolbarQuickFilter />
                    </Grid>
                    <Grid item xs sx={{ pr: 1, pt: 1, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <Button sx={{ color: "black", background: (deleteInfluencers.length > 0 ? "#FF8E8E" : "#C4C4C4"), width: "fit-content", border: "1px solid black" }} disabled={(deleteInfluencers.length > 0 ? false : true)}
                            onClick={() => removeInfluencersFromCampaign()}>
                            REMOVE FROM CAMPAIGN
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    }


    const onRowsSelectionHandler = (ids) => {
        const selectedRowsData = ids.map((id) => campaignInfluencers.find((row) => row.id === id));
        var influencers = []
        // console.log(selectedRowsData)
        selectedRowsData.forEach(function (d) {
            influencers.push({ "username": d.users.username })
        })
        // console.log(influencers)
        setDeleteInfluencers(influencers);
    };

    return (
        <div>
            <Grid container style={{ display: "flex", justifyContent: "center", marginBottom: "20px", height: "80px" }}>
                {status.map((value, index) => (
                    <div key={index} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Grid container style={tabStyle[index]} onClick={() => handleStatusChange(index)}>
                            {/* onClick={() => handleStatusChange(index)} */}
                            <Grid item xs sx={{ display: "flex", justifyContent: "space-between", gap: "5px" }}>
                                <Typography style={{ color: "black", width: "100%", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}>{value}</Typography>
                                <Typography style={{ color: "black", background: statusColors[index], padding: "5px 5px 5px 5px", borderRadius: "5px", display: "flex", justifyContent: "center", gap: "0px", width: "fit-content" }}><PeopleOutlineOutlinedIcon />{counts[index]}</Typography>
                            </Grid>
                        </Grid>
                        {(index != lastIndex) &&
                            <div style={{ margin: "0px 5px 0px 5px" }}>
                                <Divider width={25} />
                            </div>}
                    </div>
                ))}
                <Box sx={{ width: "90%", padding: "20px" }}>
                    {isLoading && <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress style={{ color: "black" }} size={50} /></Box>}
                    {!isLoading &&
                        <DataGrid
                            onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                            rowHeight={80}
                            rows={filteredRows}
                            columns={columns}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        viewProfile: true
                                    }
                                },
                                pagination: {
                                    paginationModel: {
                                        pageSize: 100,
                                    },
                                },
                            }}
                            pageSizeOptions={[100]}
                            checkboxSelection
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            disableRowSelectionOnClick
                            disableColumnMenu
                            slots={{ toolbar: QuickSearchToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    printOptions: {
                                        disableToolbarButton: true
                                    },
                                    csvOptions: {
                                        disableToolbarButton: true
                                    }
                                }
                            }}
                        />}
                    <Modal
                        open={open}
                        onClose={handleClose}
                        BackdropProps={{ style: { backgroundColor: 'rgb(0,0,0,0.1)' } }}
                    >
                        <Slide direction="left" in={open} mountOnEnter unmountOnExit timeout={300}>
                            <Box sx={style}>
                                <EditInfluencerDetails campaignId={campaignData.campaignData.campaignId} userData={user} open={open} handleClose={handleClose} influencerUsername={influencerUsername} />
                            </Box>
                        </Slide>
                    </Modal>
                </Box>
            </Grid>
        </div >
    )
}

export default CampaignInfluencers

