import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import SpeedIcon from '@mui/icons-material/Speed';
import React, { useEffect, useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import { Avatar, Button, Card, CircularProgress, Grid, LinearProgress, Typography } from '@mui/material'

import "../main.css"

import { db } from '../../../api/firebase.js'
import { doc, onSnapshot } from "firebase/firestore";

const theme = createTheme({
    palette: {
        primary: {
            main: "#F8C036",
        },
    },
});

function Tracking(props) {

    // const location = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    const propsData = location.state;

    const campaignData = props.campaignData;
    const isBliActive = campaignData.isBliActive;

    const user = props.userData
    // console.log("campaignData")
    // console.log(campaignData)
    // console.log(user)
    // const gradientColor = propsData.gradientColor;

    // const campaignData = { "totalInfluencers": 1, "campaignName": "Test Campaign", "campaignId": "c7dedc10-8be2-43d0-84f7-15f268789349" }
    const gradientColor = "#F8C036";

    useEffect(() => {
        if (!campaignData) {
            navigate(`/${campaignData.campaignId}`)
        }
    }, [campaignData, navigate])



    const [tab, setTab] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function handleTabChange(tab) {
        // if (tab == 0) { setTab(0) }
        // if (tab == 1) { setTab(1) }
        // if (tab == 2) { setTab(2) }
        setTab(tab)
    };

    const influencerButton = (tab == 0) ? "#F8C036" : "white";
    const contentButton = (tab == 1) ? "#F8C036" : "white";
    const analyticsButton = (tab == 2) ? "#F8C036" : "white";
    const bliButton = (tab == 3) ? "#F8C036" : "white";

    if (typeof campaignData !== "undefined" && campaignData.hasOwnProperty("documentId")) {
        const unsub = onSnapshot(doc(db, "campaigns", campaignData?.documentId), (doc) => {
            var docData = doc.data();
            var is_processing = docData["isCampaignProcessing"]
            setIsProcessing(is_processing)
            // propsData["isCampaignProcessing"] = is_processing;
        });
    }



    // async function checkProcessing() {
    //     setIsLoading(true)
    //     const token2 = await user.getIdToken()

    //     let data = {
    //         campaignId: campaignData.campaignId
    //     }

    //     let options = {
    //         method: 'POST',
    //         body: JSON.stringify(data),
    //         headers: new Headers({
    //             'Authorization': `Bearer ${token2}`,
    //             'Content-Type': "application/json"
    //         })
    //     }
    //     await fetch(process.env.REACT_APP_API_ADDRESS + '/api/check_tracking_processing', options)
    //         .then(result => result.json())
    //         .then(body => {
    //             if (body["isCampaignProcessing"]) {
    //                 setIsProcessing(true);
    //             } else {
    //                 setIsProcessing(false)
    //             }
    //         });
    //     setIsLoading(false)
    // }

    async function refreshMetrics() {
        setIsProcessing(true)

        const token2 = await user.getIdToken()

        let data = {
            campaignId: campaignData.campaignId
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }


        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/refresh_tracking_metrics', options)
            .then(result => result.json())
            .then(body => { console.log("refresh body"); console.log(body) });
    }

    // useEffect(() => {
    //     checkProcessing()
    // }, [])

    return (
        <div style={{ padding: "20px" }}>
            <Grid container style={{ display: "flex", justifyContent: "center", marginBottom: "20px", height: "100px" }}>
                <Card sx={{ width: "85%", border: "2px solid #DCDCDC", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "25px", boxShadow: "none" }}>
                    <Grid container className='trackingGrid'>
                        <Grid item xs={6}>
                            <Grid container style={{ gap: "10px", display: "flex", alignItems: "center" }}>
                                <div className="trackingGradientCircle" style={{ background: `radial-gradient(#FFFFFF, ${gradientColor})` }} />
                                <div style={{ display: "flex", height: "50%", gap: "10px" }}>
                                    <Grid item >
                                        <Grid item fontSize={20} >{campaignData.campaignName}</Grid>
                                    </Grid>
                                    <div className='activeTab'>
                                        <Grid item >
                                            <Card sx={{ background: `${gradientColor}`, padding: "5px 10px 5px 10px", borderRadius: "5px" }}>Active</Card>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} style={{ display: "flex", justifyContent: "center", textAlign: "left" }}>
                            <Divider orientation="vertical" variant="middle" flexItem sx={{ marginRight: "20px" }} />
                            <Grid container sx={{ gap: "4px" }}>
                                {/* <Grid item fontSize={18} xs={8}>Influencers Onboard</Grid>
                                <Grid item fontSize={26} xs={8} sx={{ fontWeight: 800 }}>{campaignData.totalInfluencers}</Grid> */}
                                <Grid item fontSize={18} xs={8}>Total Content</Grid>
                                <Grid item fontSize={26} xs={8} sx={{ fontWeight: 800 }}>{campaignData?.totalContent}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <ThemeProvider theme={theme}>
                                {isLoading && <Button variant="contained" sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black" }} ><CircularProgress size={20} sx={{ color: "black" }} /></Button>}
                                {!isLoading &&
                                    <Box sx={{ display: "flex", gap: "5px" }}>
                                        <Button onClick={() => refreshMetrics()} variant="contained" sx={{ boxShadow: "none", border: "1px solid #DCDCDC", width: "fit-content", color: "black" }} disabled={isProcessing} endIcon={isProcessing && <CircularProgress sx={{ color: "black" }} size={20} />}>{isProcessing ? "Processing" : "Refresh Metrics"}</Button>
                                    </Box>}
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>


            <Grid container style={{ justifyContent: "center", marginBottom: "20px", height: "80px" }}>
                <Card sx={{ width: "85%", border: "2px solid #DCDCDC", display: "flex", alignItems: "center", display: "flex", borderRadius: "15px", boxShadow: "none" }}>
                    {!isBliActive && <Grid container columns={9} style={{ display: "flex", textAlign: "center", padding: "5px" }}>
                        <Grid item xs={3}>
                            <Link to={`/${campaignData.campaignId}/share/content`} state={propsData}>
                                <Button startIcon={<OndemandVideoOutlinedIcon />} onClick={() => handleTabChange(1)} style={{ color: "black", background: contentButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Content</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to={`/${campaignData.campaignId}/share/influencers`} state={propsData}>
                                <Button startIcon={<PeopleOutlineOutlinedIcon />} onClick={() => handleTabChange(0)} style={{ color: "black", background: influencerButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Meme Pages</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to={`/${campaignData.campaignId}/share/reporting`} state={propsData}>
                                <Button startIcon={<InsertChartOutlinedRoundedIcon />} onClick={() => handleTabChange(2)} style={{ color: "black", background: analyticsButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Reporting</Button>
                            </Link>
                        </Grid>
                    </Grid>}

                    {isBliActive && <Grid container columns={12} style={{ display: "flex", textAlign: "center", padding: "5px" }}>
                        <Grid item xs={3}>
                            <Link to={`/${campaignData.campaignId}/share/content`} state={propsData}>
                                <Button startIcon={<OndemandVideoOutlinedIcon />} onClick={() => handleTabChange(1)} style={{ color: "black", background: contentButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Content</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to={`/${campaignData.campaignId}/share/influencers`} state={propsData}>
                                <Button startIcon={<PeopleOutlineOutlinedIcon />} onClick={() => handleTabChange(0)} style={{ color: "black", background: influencerButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Influencers</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to={`/${campaignData.campaignId}/share/reporting`} state={propsData}>
                                <Button startIcon={<InsertChartOutlinedRoundedIcon />} onClick={() => handleTabChange(2)} style={{ color: "black", background: analyticsButton, width: "99%", padding: "20px", borderRadius: "15px", }}>Reporting</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to={`/${campaignData.campaignId}/share/bli`} state={propsData}>
                                <Button startIcon={<SpeedIcon />} onClick={() => handleTabChange(3)} style={{ color: "black", background: bliButton, width: "99%", padding: "20px", borderRadius: "15px", }}>BLI<span style={{ verticalAlign: "super", fontSize: "90%" }}>®</span></Button>
                            </Link>
                        </Grid>
                    </Grid>}
                </Card>
            </Grid>
            <Outlet />
        </div>
    )
}

export default Tracking