import { Box, Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import FacebookLogin from "react-facebook-login";
import instagramLogo from "../assets/instagramLogo.png"

function InstagramLogin(props) {

    const userData = props.userData;
    const instaHandle = props.username;
    const [isLoading, setIsLoading] = useState(false)
    const [isInstagramActive, setIsInstagramActive] = useState(false)

    // async function checkActiveInstaConnection() {

    //     setIsLoading(true)
    //     const token2 = await userData.getIdToken()

    //     let options = {
    //         method: 'GET',
    //         headers: new Headers({
    //             'Authorization': `Bearer ${token2}`,
    //             'Content-Type': "application/json"
    //         })
    //     }
    //     await fetch(process.env.REACT_APP_API_ADDRESS + '/api/check_active_insta_connection', options)
    //         .then(result => result.json())
    //         .then(body => { console.log(body); if (body == "Success") { setIsInstagramActive(true); } setIsLoading(false) });
    // }

    async function checkActiveInstaUsernameConnection() {

        setIsLoading(true)
        const token2 = await userData.getIdToken()

        let data = {
            username: instaHandle
        }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/check_active_insta_username_connection', options)
            .then(result => result.json())
            .then(body => { if (body == "Success") { setIsInstagramActive(true); } setIsLoading(false) });
    }

    useEffect(() => {
        checkActiveInstaUsernameConnection()
    }, [])

    function handleLogout() {
        console.log("LOGOUT SUCCESSFULL")
    }

    function handleSubscribe(fbPageId, pageAccessToken) {
        // console.log("ENTERED SUBSCRIPTION");

        window.FB.api(
            "/" + fbPageId + "/subscribed_apps?access_token=" + pageAccessToken,
            "POST",
            { subscribed_fields: "mention,messages" },
            function (response) {
                // console.log("Subscribe called");
                console.log(response);
            }
        );
    }

    const [instaSettings, setInstaSettings] = useState(false)

    async function responseFacebook(response) {
        console.log("Response access token");
        // console.log(response.accessToken);

        // create instagram settings
        const token2 = await userData.getIdToken();

        let data = { accessToken: response.accessToken }

        let options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Authorization': `Bearer ${token2}`,
                'Content-Type': "application/json"
            })
        }

        await fetch(process.env.REACT_APP_API_ADDRESS + '/api/create_instagram_settings', options)
            .then(result => result.json())
            .then(body => {
                // console.log("SETTINGS CREATED: " + body["result"]);
                if (body["result"] == "Success") {
                    handleSubscribe(body["fbPageId"], body["pageAccessToken"])
                    // checkActiveInstaConnection()
                    checkActiveInstaUsernameConnection()
                }
            });
    }

    if (isLoading) {
        return (
            <Box><CircularProgress style={{ color: "black", }} size={50} /></Box>
        )
    } else {
        return (
            <>
                {
                    isInstagramActive &&
                    <Button sx={{ border: "1px solid black", color: "black", width: "auto", height: "45px", gap: "10px" }} onClick={() => handleLogout()}>
                        <img
                            src={instagramLogo}
                            alt="instagram-icon"
                            border="0"
                            width="30px"
                            height="30px"
                        />
                        Connected
                    </Button>
                }
                {
                    !isInstagramActive &&
                    <div className="content">
                        <FacebookLogin
                            size="small"
                            appId="338563195134203"
                            autoLoad={false}
                            fields="name,email,picture"
                            scope="instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement,pages_manage_metadata,instagram_manage_comments, pages_messaging,instagram_manage_messages"
                            callback={responseFacebook}
                        />
                    </div>
                }
            </>
        )
    }
}

export default InstagramLogin