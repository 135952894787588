import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Mixpanel } from '../../mixpanel/mixpanel.js';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { Avatar, AvatarGroup, Badge, Box, Button, Card, Chip, CircularProgress, Grid, InputAdornment, Modal, Slide, Stack, TextField, Typography } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import "../memechat/main.css";


function CommunityRejected(props) {

    // const location = useLocation();
    // const campaignData = location.state;
    // const user = props.userData

    const leaderboardInfluencers = props.leaderboardInfluencers;
    const rejected = props.influencers;

    const [isLoading, setIsLoading] = useState(false);
    const [campaignInfluencers, setCampaignInfluencers] = useState([])


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); }

    // async function fetchCampaignInfluencers() {

    //     var campaignId = campaignData.campaignData.campaignId;
    //     if (campaignId != null) {
    //         setIsLoading(true);
    //         const token2 = await user.getIdToken()

    //         let data = {
    //             campaignId: campaignId
    //         }

    //         let fetchCampaignInfluencersOptions = {
    //             method: 'POST',
    //             body: JSON.stringify(data),
    //             headers: new Headers({
    //                 'Authorization': `Bearer ${token2}`,
    //                 'Content-Type': "application/json"
    //             })
    //         }


    //         await fetch(process.env.REACT_APP_API_ADDRESS + '/api/fetch_tracking_influencers', fetchCampaignInfluencersOptions)
    //             .then(result => result.json())
    //             .then(body => { setCampaignInfluencers(body); setIsLoading(false); });
    //     }
    //     else { console.log("Invalid campaign id") }
    // };


    // useEffect(() => {
    //     fetchCampaignInfluencers()
    // }, [])

    // useEffect(() => {
    //     const c = [0, 0, 0, 0, 0, 0]
    //     for (const row of campaignInfluencers) {
    //         const rowStatus = row.status;
    //         if (rowStatus == "Shortlisted") {
    //             c[0] += 1;
    //         } else if (rowStatus == "Messaged") {
    //             c[1] += 1;
    //         } else if (rowStatus == "In Conversation") {
    //             c[2] += 1;
    //         } else if (rowStatus == "Product Sent") {
    //             c[3] += 1;
    //         } else if (rowStatus == "Content Review") {
    //             c[4] += 1;
    //         } else if (rowStatus == "Content Published") {
    //             c[5] += 1;
    //         }
    //     }
    //     setCounts(c)
    // }, [campaignInfluencers, filteredRows])


    // useEffect(() => {
    //     const fRows = []
    //     for (const row of campaignInfluencers) {
    //         var rowStatus = 0;
    //         if (row.status == "Shortlisted") { rowStatus = 0 }
    //         else if (row.status == "Messaged") { rowStatus = 1 }
    //         else if (row.status == "In Conversation") { rowStatus = 2 }
    //         else if (row.status == "Product Sent") { rowStatus = 3 }
    //         else if (row.status == "Content Review") { rowStatus = 4 }
    //         else if (row.status == "Content Published") { rowStatus = 5 }
    //         // const rowStatus = row.status;

    //         if (statusTab === rowStatus) {
    //             fRows.push(row);
    //         }
    //     }
    //     setFilteredRows(fRows)
    // }, [campaignInfluencers, statusTab, isLoadingAction])

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    const renderDetailsAvatar = (params) => {
        var username = params.row.username
        const influencerPhoto = "https://firebasestorage.googleapis.com/v0/b/closer-influencer.appspot.com/o/influencers%2F" + username + ".jpg?alt=media"
        return (
            <Box sx={{ display: "flex", width: "100%" }}>
                <Grid container style={{ gap: "10px", alignItems: "center" }}>
                    <Grid item >
                        <Avatar src={influencerPhoto} />
                    </Grid>
                    <Grid item >
                        {username}
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const renderContentDelivered = (params) => {
        const contentDelivered = params.row.contentDelivered;

        return (
            <Box >
                <Typography className='trackingInfluencersGrid'>
                    {contentDelivered["reel"]} Reel(s),
                </Typography>
                <Typography className='trackingInfluencersGrid'>
                    {contentDelivered["post"]} Post(s)
                </Typography>
            </Box>
        )
    }


    const renderAcceptReject = (params) => {
        // const currStatus = params.row.status;
        // const influencer = params.row.users.username;
        // const nextStatus = params.row.action;

        // var lastAction = actions[actions.length - 1];
        // var cursor = ((currStatus == lastAction) ? "" : "pointer")

        return (
            <Box sx={{ gap: "5px" }}>
                <Button style={{ color: "green", background: "white", border: "1px solid green" }} >
                    <CheckIcon />
                </Button>
                <Button style={{ color: "red", background: "white", border: "1px solid red" }} >
                    <CloseIcon />
                </Button>
            </Box>
        )
    }


    const columns = [
        { field: 'id', headerName: 'id' },
        {
            field: 'username',
            headerName: 'Users',
            flex: 2,
            renderCell: renderDetailsAvatar,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'followers',
            headerName: 'Followers',
            flex: 1,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'engRate',
            headerName: 'Eng Rate',
            flex: 1,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'dieHardFans',
            headerName: 'Die Hard Fans',
            flex: 1,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'avgReelLikes',
            headerName: 'Avg Reel Likes',
            flex: 1,
            headerAlign: 'center',
            align: 'center'
        }
    ];

    const tempRows = [
        { "id": 1, "username": "raghav", "followers": 1, "engRate": 2, "dieHardFans": 3, "avgReelLikes": 21000 },
        { "id": 2, "username": "raghav2", "followers": 2, "engRate": 2, "dieHardFans": 3, "avgReelLikes": 21000 },
        { "id": 3, "username": "raghav3", "followers": 3, "engRate": 2, "dieHardFans": 3, "avgReelLikes": 21000 },
        { "id": 4, "username": "raghav4", "followers": 4, "engRate": 2, "dieHardFans": 3, "avgReelLikes": 21000 },
        { "id": 5, "username": "raghav5", "followers": 5, "engRate": 2, "dieHardFans": 3, "avgReelLikes": 21000 },
        { "id": 6, "username": "raghav6", "followers": 6, "engRate": 2, "dieHardFans": 3, "avgReelLikes": 21000 },
        { "id": 7, "username": "raghav7", "followers": 7, "engRate": 2, "dieHardFans": 3, "avgReelLikes": 21000 },
        { "id": 8, "username": "raghav8", "followers": 8, "engRate": 2, "dieHardFans": 3, "avgReelLikes": 21000 },
    ]

    const [deleteInfluencers, setDeleteInfluencers] = useState([]);

    // async function removeInfluencersFromCampaign() {
    //     const token2 = await user.getIdToken();

    //     let data = { influencers: deleteInfluencers, campaignId: campaignData.campaignData.campaignId }

    //     let options = {
    //         method: 'POST',
    //         body: JSON.stringify(data),
    //         headers: new Headers({
    //             'Authorization': `Bearer ${token2}`,
    //             'Content-Type': "application/json"
    //         })
    //     }

    //     // console.log(data)

    //     await fetch(process.env.REACT_APP_API_ADDRESS + '/api/remove_campaign_influencers', options)
    //         .then(result => result.json())
    //         .then(body => { setDeleteInfluencers([]); fetchCampaignInfluencers(); });
    // }

    function QuickSearchToolbar() {
        return (
            <Box>
                <Grid container>
                    <Grid item xs sx={{ pt: 1, pl: 1, display: "flex", alignItems: 'center' }} >
                        <GridToolbarQuickFilter />
                    </Grid>
                    {/* <Grid item xs sx={{ pr: 1, pt: 1, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <Button sx={{ color: "black", background: (deleteInfluencers.length > 0 ? "#FF8E8E" : "#C4C4C4"), width: "fit-content", border: "1px solid black" }} disabled={(deleteInfluencers.length > 0 ? false : true)}
                            onClick={() => removeInfluencersFromCampaign()}>
                            REMOVE FROM CAMPAIGN
                        </Button>
                    </Grid> */}
                </Grid>
            </Box>
        );
    }


    const onRowsSelectionHandler = (ids) => {
        const selectedRowsData = ids.map((id) => leaderboardInfluencers.find((row) => row.id === id));
        var influencers = []
        // console.log(selectedRowsData)
        selectedRowsData.forEach(function (d) {
            influencers.push({ "username": d.users.username })
        })
        // console.log(influencers)
        setDeleteInfluencers(influencers);
    };

    function formatNumber(number) {
        if (number >= 1e6) {
            return (number / 1e6).toFixed(2) + "M";
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(2) + "K";
        } else {
            if (number == 0) {
                return number
            } else {
                return number;
            }
        }
    }

    return (
        <Grid container style={{ display: "flex", justifyContent: "center", marginBottom: "20px", height: "80px" }}>
            <Box sx={{ width: "95%", padding: "20px" }}>
                {isLoading && <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress style={{ color: "black" }} size={50} /></Box>}
                {!isLoading &&
                    <DataGrid
                        onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        rowHeight={80}
                        rows={rejected}
                        columns={columns}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                    viewProfile: true
                                }
                            },
                            pagination: {
                                paginationModel: {
                                    pageSize: 100,
                                },
                            },
                        }}
                        pageSizeOptions={[100]}
                        checkboxSelection
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableRowSelectionOnClick
                        disableColumnMenu
                        slots={{ toolbar: QuickSearchToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                printOptions: {
                                    disableToolbarButton: true
                                },
                                csvOptions: {
                                    disableToolbarButton: true
                                }
                            }
                        }}
                    />}
            </Box>
        </Grid>
    )
}

export default CommunityRejected