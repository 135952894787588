import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import InputBase from '@mui/material/InputBase';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import auth from '../../api/firebase.js';
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { Mixpanel } from '../../mixpanel/mixpanel.js';
import MemechatLogo from "../../assets/memechatLogo2.jpeg"

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function MemechatLogin() {

    // const [showDashboard, setShowDashboard] = useState(true);
    // const [showTracking, setShowTracking] = useState(false);

    const navigate = useNavigate();

    const [hasNavigated, setHasNavigated] = useState(false);

    async function getDashboardAccess(user) {
        if (user != "" && user != null) {
            const token2 = await user.getIdToken();
            let options = {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${token2}`,
                    'Content-Type': "application/json"
                })
            }

            await fetch(process.env.REACT_APP_API_ADDRESS + '/api/check_dashboard_access', options)
                .then(result => result.json())
                .then(body => {
                    var showTracking = body["showTracking"]
                    if (user.email == "admin@memechat.app") {
                        if (showTracking && !hasNavigated) {
                            console.log("navigate to dashboard")
                            setHasNavigated(true)
                            navigate("/dashboard")
                        } else if (!hasNavigated) {
                            setHasNavigated(true)
                            navigate("/discovery")
                        }
                    }
                    else {
                        console.log("incorrect user")
                    }

                });
        }
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                const uid = user.uid;
                // console.log("USER OBJ")
                // console.log(user)
                if (!hasNavigated) {
                    getDashboardAccess(user);
                }
                // navigate("/discovery")
            }
        });
    }, [hasNavigated])



    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [loginMessage, setLoginMessage] = useState("")

    const handleEmail = event => {
        setEmail(event.target.value);
    };

    const handlePassword = event => {
        setPassword(event.target.value);
    };

    const [isLoading, setIsLoading] = useState(false)

    function handleLogin() {
        setIsLoading(true)
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                // console.log("Inside ")
                const user = userCredential.user;
                // console.log("USER")
                // console.log(user)
                Mixpanel.identify(user.email);
                Mixpanel.track('Successful login');
            })
            .catch((error) => {
                setIsLoading(false)
                setLoginMessage("Wrong Email/Password Entered")
                const errorCode = error.code;
                const errorMessage = error.message;
                // console.log(errorCode)
                // console.log(errorMessage)
                setOpenAlert(true);
            });
    }

    // onAuthStateChanged(auth, (user) => {
    //     if (user) {
    //       // User is signed in, see docs for a list of available properties
    //       // https://firebase.google.com/docs/reference/js/auth.user
    //       const uid = user.uid;
    //       // ...
    //     } else {
    //       // User is signed out
    //       // ...
    //     }
    //   });


    const closerLogoTop = (
        <svg width="943" height="699" viewBox="0 0 943 699" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M351.499 698.127C677.745 698.127 942.219 433.653 942.219 107.407C942.219 -218.839 677.745 -483.313 351.499 -483.313C25.2537 -483.313 -239.221 -218.839 -239.221 107.407C-239.221 433.653 25.2537 698.127 351.499 698.127ZM359.367 -198.892L351.499 -302.286L343.632 -198.892C331.494 -39.3705 204.722 87.4015 45.2007 99.5397L-58.1936 107.407L45.2007 115.274C204.722 127.413 331.494 254.185 343.632 413.706L351.499 517.1L359.367 413.706C371.505 254.185 498.277 127.413 657.798 115.274L761.192 107.407L657.798 99.5397C498.277 87.4015 371.505 -39.3705 359.367 -198.892Z" fill="#272727" />
        </svg>
    )

    const closerLogoBottom = (
        <svg width="481" height="480" viewBox="0 0 481 480" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_558_4113)">
                <path fillRule="evenodd" clipRule="evenodd" d="M287.316 424.111C362.944 424.111 424.252 362.803 424.252 287.175C424.252 211.548 362.944 150.239 287.316 150.239C211.689 150.239 150.38 211.548 150.38 287.175C150.38 362.803 211.689 424.111 287.316 424.111ZM289.14 216.171L287.316 192.203L285.492 216.171C282.679 253.15 253.292 282.538 216.313 285.351L192.345 287.175L216.313 288.999C253.292 291.813 282.679 321.2 285.492 358.179L287.316 382.147L289.14 358.179C291.954 321.2 321.341 291.813 358.32 288.999L382.288 287.175L358.32 285.351C321.341 282.538 291.954 253.15 289.14 216.171Z" fill="#272727" />
                <path d="M287.316 426.611C364.325 426.611 426.752 364.183 426.752 287.175C426.752 210.167 364.325 147.739 287.316 147.739C210.308 147.739 147.88 210.167 147.88 287.175C147.88 364.183 210.308 426.611 287.316 426.611ZM287.316 352.124C282.21 318.643 255.849 292.282 222.367 287.175C255.849 282.068 282.21 255.707 287.316 222.226C292.423 255.707 318.784 282.068 352.265 287.175C318.784 292.282 292.423 318.643 287.316 352.124Z" stroke="#232323" strokeWidth="5" />
            </g>
            <defs>
                <filter id="filter0_d_558_4113" x="0.380371" y="0.239258" width="573.872" height="573.872" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="72.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.137255 0 0 0 0 0.137255 0 0 0 0 0.137255 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_558_4113" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_558_4113" result="shape" />
                </filter>
            </defs>
        </svg>
    )

    const closerTextLogo = (
        <svg width="200" height="40" viewBox="0 0 260 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.3439 49.9009C34.8846 49.9009 42.4786 42.9338 42.4786 31.7169V31.2989H34.4665V31.7169C34.4665 39.3807 30.9134 42.6552 22.1349 42.6552C12.4507 42.6552 8.5492 37.9873 8.5492 25.8646C8.5492 13.742 12.4507 9.07408 22.1349 9.07408C30.9134 9.07408 34.4665 12.3486 34.4665 20.0123V20.4303H42.4786V20.0123C42.4786 8.7954 34.8846 1.82837 22.3439 1.82837C8.75821 1.82837 0.537109 11.3035 0.537109 25.8646C0.537109 40.4257 8.75821 49.9009 22.3439 49.9009Z" fill="black" />
            <path d="M80.8648 49.2042V42.4462H55.9925V2.52507H48.4681V49.2042H80.8648Z" fill="black" />
            <path d="M159.414 49.9009C171.328 49.9009 176.623 43.8396 176.623 36.5242C176.623 29.4875 172.791 25.0982 164.012 23.2868L156.07 21.6147C149.66 20.2213 147.779 19.0369 147.779 14.787C147.779 10.6765 150.566 8.72573 157.463 8.72573C165.824 8.72573 168.332 11.7215 168.332 17.4345V18.2009H175.717V17.3648C175.717 9.07408 170.562 1.82837 157.673 1.82837C145.62 1.82837 140.255 8.23804 140.255 15.1354C140.255 22.5204 144.574 26.422 152.587 27.9547L160.529 29.5571C167.705 31.0202 169.098 32.553 169.098 36.8029C169.098 41.1224 166.59 43.0035 159.693 43.0035C149.939 43.0035 147.501 40.0774 147.501 34.2947V33.5284H140.116V34.7128C140.116 43.7002 145.968 49.9009 159.414 49.9009Z" fill="black" />
            <path d="M215.408 49.2042V42.4462H189.561V29.1391H214.363V22.3114H189.561V9.28309H215.408V2.52507H182.036V49.2042H215.408Z" fill="black" />
            <path d="M229.023 49.2042V31.2292H234.526C240.1 31.2292 242.051 33.1103 244.211 37.1512L250.829 49.2042H259.538L252.571 36.2455C250.829 33.0407 248.809 30.9506 245.395 29.8358V29.2785C253.616 28.8604 258.354 24.1925 258.354 16.4591C258.354 7.61101 251.874 2.52507 241.563 2.52507H221.498V49.2042H229.023ZM229.023 9.28309H242.469C248.53 9.28309 250.829 11.6519 250.829 17.1558C250.829 22.7991 248.53 25.0286 242.469 25.0286H229.023V9.28309Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M109.935 51.2463C123.92 51.2463 135.257 39.9093 135.257 25.9245C135.257 11.9397 123.92 0.602783 109.935 0.602783C95.95 0.602783 84.613 11.9397 84.613 25.9245C84.613 39.9093 95.95 51.2463 109.935 51.2463ZM110.272 12.7948L109.935 8.36267L109.598 12.7948C109.077 19.6328 103.643 25.067 96.805 25.5873L92.3729 25.9245L96.805 26.2618C103.643 26.7821 109.077 32.2163 109.598 39.0543L109.935 43.4864L110.272 39.0543C110.792 32.2163 116.227 26.7821 123.065 26.2618L127.497 25.9245L123.065 25.5873C116.227 25.067 110.792 19.6328 110.272 12.7948Z" fill="#E4F87F" />
        </svg>
    )

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            // console.log("enter pressed")
            if (email == "") {
                // console.log("Please enter email id")
                setLoginMessage("Please enter a valid email id")
                setOpenAlert(true);
            }
            else if (password == "") {
                // console.log("please enter password")
                setLoginMessage("Please enter a password")
                setOpenAlert(true);
            }
            else {
                handleLogin()
            }
        }
    }

    const [openAlert, setOpenAlert] = useState(false);

    const handleClick = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    return (
        <Box style={{ position: "fixed", top: 0, left: 0, bottom: 0, right: 0, background: "#232323", display: "flex", alignItems: "center", justifyContent: "center", }}>
            <Box style={{ top: 0, left: 0, position: "absolute" }}>
                {closerLogoTop}
            </Box>
            <Box style={{ bottom: 0, right: "5%", position: "absolute" }}>
                {closerLogoBottom}
            </Box>
            <Box style={{ backgroundColor: "white", width: "30%", borderRadius: "25px", zIndex: 99 }}>
                <Grid container style={{ padding: "15% 10% 10% 10%", }}>
                    <Grid item xs={12} style={{ gap: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}>
                        <img width="240" height="120" src={MemechatLogo} alt='Memechat Logo' />
                        Enter your credentials to continue
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
                        <InputBase fullWidth placeholder="Enter your E-mail address" style={{
                            borderRadius: 10,
                            border: '1px solid',
                            borderColor: '#C4C4C4',
                            fontSize: 16,
                            padding: '10px 12px',
                        }} value={email} onChange={handleEmail} onKeyDown={handleKeyDown} />
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
                        {/* <BootstrapInput fullWidth placeholder="Enter your Password" id="bootstrap-input" value={password} onChange={handlePassword} /> */}
                        <InputBase
                            style={{
                                borderRadius: 10,
                                border: '1px solid',
                                borderColor: '#C4C4C4',
                                fontSize: 16,
                                padding: '10px 12px'
                            }}
                            onKeyDown={handleKeyDown}
                            fullWidth
                            placeholder="Enter your Password"
                            value={password}
                            onChange={handlePassword}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    {/* <Grid item xs={12} style={{ display: "flex", justifyContent: 'right', }}>
                        <Typography>Forgot Password ?</Typography>
                    </Grid> */}
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
                        {isLoading && <Button disableElevation variant="contained" size="large" style={{ minHeight: "50px", backgroundColor: "#F8C036", color: 'black', width: "100%", borderRadius: "10px", fontWeight: 800 }}>
                            <CircularProgress size={30} style={{ color: "black", height: "fit-content" }} />
                        </Button>}
                        {!isLoading && <Button disableElevation variant="contained" size="large" style={{ minHeight: "50px", backgroundColor: "#F8C036", color: 'black', width: "100%", borderRadius: "10px", fontWeight: 800 }} onClick={() => handleLogin()}>
                            Login
                        </Button>}
                        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                            <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                                {loginMessage}
                            </Alert>
                        </Snackbar>
                    </Grid>
                    {/* <Grid item xs={12} style={{ margin: "20px" }}>
                        <Divider style={{ color: "#797979" }}>OR</Divider>
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center", margin: "10px", border: "1px solid black", width: "fit-content", borderRadius: "10px", }}>
                        <GoogleButton id="googleSignIn"
                            style={{ fontWeight: 800, color: "black", boxShadow: "none", }}
                            type="light"
                            label="Continue With Google"
                            onClick={() => { console.log('Google button clicked') }}
                        />
                    </Grid> */}
                </Grid>
            </Box>
        </Box>
    )
}

export default MemechatLogin